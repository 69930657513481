<div class="m-grid m-grid--hor m-grid--root m-page">
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
        id="m_login">
        <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
            <div class="m-stack m-stack--hor m-stack--desktop">
                <div class="m-stack__item m-stack__item--fluid">
                    <div class="m-login__wrapper">
                        <div class="m-login__logo">
                            <a>
                                <img src="../../../assets/images/skill-india-logo-prod.png">
                            </a>
                        </div>
                        <div class="m-login__signin">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                </h3>
                            </div>
                            <form *ngIf="!verifyUpdateDetails" class="m-login__form m-form"
                                [formGroup]="contactDetailForm">
                                <div class="form-group m-form__group">
                                    <label for="">Enter the email address</label>
                                    <input class="form-control m-input" type="text" name="email-address"
                                        placeholder="Enter email address" formControlName="email">
                                    <app-form-control-messages [control]="contactDetailForm.controls.email"
                                        [message]="'Enter proper email address'" [name]="'Email address'">
                                    </app-form-control-messages>
                                </div>
                                <div class="form-group m-form__group">
                                    <label for="">Enter the mobile number</label>
                                    <input class="form-control m-input" type="text" maxlength="10" name="mobile-number"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                        placeholder="Enter mobile number" formControlName="phone">
                                    <app-form-control-messages [control]="contactDetailForm.controls.phone"
                                        [message]="'Mobile Number should be 10 digits'" [name]="'Mobile number'">
                                    </app-form-control-messages>
                                </div>
                                <div class="m-login__form-action ">
                                    <button id="m_login_signin_submit" [disabled]="contactDetailForm.invalid"
                                        class="btn btn-focus  m-btn--pill btn-login" (click)="getotp()">
                                        Verify
                                    </button>
                                </div>
                            </form>
                            <!-- otp -->
                            <form *ngIf="verifyUpdateDetails" class="m-login__form m-form" [formGroup]="otpForm">
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="text" maxlength="6"
                                        placeholder="Enter Email OTP" name="eOTP" formControlName="emailOTP">
                                    <app-form-control-messages [control]="otpForm.controls.emailOTP"
                                        [message]="'Email OTP is required'" [name]="'Email OTP'">
                                    </app-form-control-messages>
                                </div>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="text" maxlength="6"
                                        placeholder="Enter Mobile OTP" name="mOTP" formControlName="mobileOTP">
                                    <app-form-control-messages [control]="otpForm.controls.mobileOTP"
                                        [message]="'Mobile OTP is required'" [name]="'Mobile OTP'">
                                    </app-form-control-messages>
                                </div>
                                <div class="m-login__form-action ">
                                    <!-- <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill btn-login"
                                        (click)="change()">
                                        Resend OTP
                                    </button> -->
                                    <button id="m_login_signin_submit" [disabled]="otpForm.invalid"
                                        class="btn btn-focus  m-btn--pill btn-login" (click)="doLogin()">
                                        Verify & Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content page-right"
            style="background-image: url(../../../../assets/images/login-right.png); background-attachment: unset">
            <h2 class="sip-text"> {{"Skill India Digital" | translatePipe}} </h2>
            <div class="m-grid__item m-grid__item--left">
                <h3 class="m-login__welcome">
                    {{"LOGIN"|translatePipe}}
                </h3>
                <p class="m-login__msg">
                    {{"WHAT_I_HERE" |translatePipe}}
                    <br>{{"YOU_NEED_LOGIN" | translatePipe}}
                    <br>
                    <br> {{"NEXT" |translatePipe}}
                    <br> {{"GO_TO_PORTAL"| translatePipe}}
                </p>
                <div class="login-right">
                    <h4 class="help-text">{{"HELPlINE" |translatePipe}}</h4>
                    <br>
                    <button class="btn btn-focus  m-btn--pill btn-back"
                        routerLink="/">{{"GO_TO_LOGIN"|translatePipe}}</button>
                </div>
            </div>
        </div>
    </div>
</div>