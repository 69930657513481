export const PMU_STATUSES = Object.seal({
	'INITIAL': 'Assessment On-going',
	'AR DRAFT': 'Assessment On-going',
	'AR DRAFTED': 'Pending for Assessment Submission',
	'AR PRESUBMIT': 'Pending for Assessment Submission',
	'AR SUBMITTED': 'Assessment Submitted by Assessor',
	'AA PREAPPROVED': 'Assessment Submitted by Assessor',
	'AA PREREJECTED': 'Assessment Submitted by Assessor',
	'AA APPROVED': 'Assessment Results Approved by AA',
	'AA REJECTED': 'Assessment Results Rejected by AA',
	'SSC PREAPPROVED': 'Assessment Results Approved by AA',
	'SSC PREREJECTED': 'Assessment Results Approved by AA',
	'SSC ACCEPTED': 'Assessment Results Accepted by SSC',
	'SSC REJECTED': 'Assessment Results Rejected by SSC',
	'SSCAAREJECTED': 'Assessment Results Rejected by SSC & AA',
	'ASSESSMENT DONE': 'Assessment Completed',

	'SSC ASSIGNED': 'Assigned to SSC', // - Assigned to SSC
	'SSC ACCEPTED BATCH': 'SSC Accepted Batch', // SSC Accepted Batch
	'AA ASSIGNED': 'SSC Assigned AA', // SSC Assigned AA
	'AR ASSIGNED': 'Assessor Assigned',
	'AR ASSESSMENT ONGOING': 'Assessment On-going', // - Assessment On-going – (PENDING)
	'AR ASSESSMENT ONGOING MARKS PARTIALLY FILLED': 'Assessment On-going', // - Assessment On-going – (PENDING)
	'AR ASSESSMENT ONGOING MARKS FILLED': 'Pending for Assessment Submission', // - Pending for Assessment Submission – (PENDING)
	'AR ASSESSMENT ONGOING MARKS SUBMITTED': 'Assessment Submitted', // – Assessment Submitted – (SUBMITTED)
	'AA APPROVED ASSESSMENT ONGOING MARKS': 'Assessment Results Approved by AA', // - Assessment Results Approved by AA – (SUBMITTED)
	'AA REJECTED ASSESSMENT ONGOING MARKS': 'AA Rejected', // - Sent back by AA – (PENDING)
	'SSC ASSESSMENT ACCEPTED': 'Assessment Results Approved by SSC', // - Assessment Results Approved by SSC – (SUBMITTED)
	'SSC ASSESSMENT REJECTED': 'Assessment Results Approved by AA', // – Assessment Results Approved by AA – (SUBMITTED)
	'SSC AA ASSESSMENT REJECTED': 'SSC & Assessment Agency Rejected', // - Sent back by SSC & AA – (PENDING)
	'ASSESSOR_CR_ASSIGNED_TO_SSC': 'Pending for Assessor Change Request(SSC)',
	'ASSESSOR_CR_ACCEPTED_BY_SSC': 'Assessor Change Request Accepted By SSC',
	'ASSESSOR_CR_REJECTED_BY_SSC': 'Assessor Change Request Rejected By SSC',
	'ASSESSOR_CR_ASSIGNED_TO_PMU': 'Pending for Assessor Change Request(PMU)',
	'ASSESSOR_CR_REJECTED_BY_PMU': 'Assessor Change Request Accepted By PMU',
	'ASSESSOR_CR_ACCEPTED_BY_PMU': 'Assessor Change Request Accepted By PMU',
	'ASSESSMENT_DATE_CR_ASSIGNED_TO_SSC': 'Pending for Assessment Date Change Request(SSC)',
	'ASSESSMENT_DATE_CR_REJECTED_BY_SSC': 'Assessment Date Change Request Rejected By SSC',
	'ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC': 'Assessment Date Change Request Accepted By SSC',
	'ASSESSMENT_DATE_CR_ASSIGNED_TO_PMU': 'Pending for Assessment Date Change Request(PMU)',
	'ASSESSMENT_DATE_CR_REJECTED_BY_PMU': 'Assessment Date Change Request Rejected By PMU',
	'ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU': 'Assessment Date Change Request Accepted By PMU',
	'ASSESSMENT_AGENCY_CR_ASSIGNED_TO_SSC': 'Pending for Assessment Agency Change Request(SSC)',
	'ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC': 'Assessment Agency Change Request Rejected By SSC',
	'ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC': 'Assessment Agency Change Request Accepted By SSC',
	'ASSESSMENT_AGENCY_CR_ASSIGNED_TO_PMU': 'Pending for Assessment Agency Change Request(PMU)',
	'ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU': 'Assessment Agency Change Request Rejected By PMU',
	'ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU': 'Assessment Agency Change Request Accepted By PMU',
	'CANCEL BY TP': 'Cancelled by Training Partner',
	'CANCEL BY TC': 'Cancelled by Training Centre'
}
);

export const PMUSTATUSES_ARRAY = [
	// {value: 'INITIAL', displayStatus: 'Assessment On-going'},
	{value:'AR DRAFT, INITIAL', displayStatus: 'Assessment On-going'},
	{value:'AR DRAFTED', displayStatus: 'Pending for Assessment Submission'},
	{value:'AR PRESUBMIT', displayStatus: 'Pending for Assessment Submission'},
	{value:'AR SUBMITTED', displayStatus: 'Assessment Submitted by Assessor'},
	{value:'AA PREAPPROVED', displayStatus: 'Assessment Submitted by Assessor'},
	{value:'AA PREREJECTED', displayStatus: 'Assessment Submitted by Assessor'},
	{value:'AA APPROVED', displayStatus: 'Assessment Results Approved by AA'},
	{value:'AA REJECTED', displayStatus: 'Assessment Results Rejected by AA'},
	{value:'SSC PREAPPROVED', displayStatus: 'Assessment Results Approved by AA'},
	{value:'SSC PREREJECTED', displayStatus: 'Assessment Results Approved by AA'},
	{value:'SSC ACCEPTED', displayStatus: 'Assessment Results Accepted by SSC'},
	{value:'SSC REJECTED', displayStatus: 'Assessment Results Rejected by SSC'},
	{value:'SSCAAREJECTED', displayStatus: 'Assessment Results Rejected by SSC & AA'},
	{value:'ASSESSMENT DONE', displayStatus: 'Assessment Completed'},
	{value:'SSC ASSIGNED', displayStatus: 'Assigned to SSC'},
	{value:'SSC ACCEPTED BATCH' , displayStatus: 'SSC Accepted Batch'},
	{value:'AA ASSIGNED' , displayStatus:'SSC Assigned AA'},
	{value:'AR ASSIGNED' , displayStatus:'Assessor Assigned'},
	{value:'AR ASSESSMENT ONGOING',displayStatus: 'Assessment On-going'},
	{value:'AR ASSESSMENT ONGOING MARKS PARTIALLY FILLED' , displayStatus: 'Assessment On-going'},
	{value:'AR ASSESSMENT ONGOING MARKS FILLED' , displayStatus: 'Pending for Assessment Submission'},
	{value:'AR ASSESSMENT ONGOING MARKS SUBMITTED' , displayStatus: 'Assessment Submitted'},
	{value:'AA APPROVED ASSESSMENT ONGOING MARKS' , displayStatus: 'Assessment Results Approved by AA'},
	{value:'AA REJECTED ASSESSMENT ONGOING MARKS' , displayStatus: 'AA Rejected'},
	{value:'SSC ASSESSMENT ACCEPTED' , displayStatus: 'Assessment Results Approved by SSC'},
	{value:'SSC ASSESSMENT REJECTED' , displayStatus: 'Assessment Results Approved by AA'},
	{value:'SSC AA ASSESSMENT REJECTED' , displayStatus: 'SSC & Assessment Agency Rejected'},
	{value:'CANCEL BY TP' , displayStatus: 'Cancelled by Training Partner'},
	{value:'CANCEL BY TC', displayStatus: 'Cancelled by Training Centre'},
	{value: 'ASSESSOR_CR_ASSIGNED_TO_SSC', displayStatus: 'Pending for Assessor Change Request(SSC)'},
	{value: 'ASSESSOR_CR_ACCEPTED_BY_SSC', displayStatus: 'Assessor Change Request Accepted By SSC'},
	{value: 'ASSESSOR_CR_REJECTED_BY_SSC', displayStatus: 'Assessor Change Request Rejected By SSC'},
	{value: 'ASSESSOR_CR_ASSIGNED_TO_PMU', displayStatus: 'Pending for Assessor Change Request(PMU)'},
	{value: 'ASSESSOR_CR_REJECTED_BY_PMU', displayStatus: 'Assessor Change Request Accepted By PMU'},
	{value: 'ASSESSOR_CR_ACCEPTED_BY_PMU', displayStatus: 'Assessor Change Request Accepted By PMU'},
	{value: 'ASSESSMENT_DATE_CR_ASSIGNED_TO_SSC', displayStatus: 'Pending for Assessment Date Change Request(SSC)'},
	{value: 'ASSESSMENT_DATE_CR_REJECTED_BY_SSC', displayStatus: 'Assessment Date Change Request Rejected By SSC'},
	{value: 'ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC', displayStatus: 'Assessment Date Change Request Accepted By SSC'},
	{value: 'ASSESSMENT_DATE_CR_ASSIGNED_TO_PMU', displayStatus: 'Pending for Assessment Date Change Request(PMU)'},
	{value: 'ASSESSMENT_DATE_CR_REJECTED_BY_PMU', displayStatus: 'Assessment Date Change Request Rejected By PMU'},
	{value: 'ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU', displayStatus: 'Assessment Date Change Request Accepted By PMU'},
	{value: 'ASSESSMENT_AGENCY_CR_ASSIGNED_TO_SSC', displayStatus: 'Pending for Assessment Agency Change Request(SSC)'},
	{value: 'ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC', displayStatus: 'Assessment Agency Change Request Rejected By SSC'},
	{value: 'ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC', displayStatus: 'Assessment Agency Change Request Accepted By SSC'},
	{value: 'ASSESSMENT_AGENCY_CR_ASSIGNED_TO_PMU', displayStatus: 'Pending for Assessment Agency Change Request(PMU)'},
	{value: 'ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU', displayStatus: 'Assessment Agency Change Request Rejected By PMU'},
	{value: 'ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU', displayStatus: 'Assessment Agency Change Request Accepted By PMU'}
]
