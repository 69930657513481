import { Helpers } from "projects/training-calendar/src/app/helpers";

export const SCHEME_STT_STATUS = Object.freeze({
	APPROVED: 'TARGET PMU APPROVED',
	PENDING: 'INPROGRESS',
	SEND_BACK: 'TARGET PMU SENT BACK',
	REJECTED: 'TARGET PMU REJECTED',
	INPROGRESS: 'init',
	STATUS_APPROVED: 'APPROVED',
	STATUS_SEND_BACK: 'SEND BACK',
	STATUS_REJECTED: 'REJECTED',
	DRAFT: 'DRAFT',
	REVISED_TARGET: 'TARGET REVISED',
	ADDITIONAL_DETAILS_REQUIRED: 'Additional Details Required',
	ALL: 'ALL',
	TP_SENT_BACK: 'TP SENT BACK',
	TARGET_REJECTED: 'TARGET REJECTED',
	SUBMIT_TO_CHECKER:'Submitted to Checker'
});
export const PROJECT_DISPLAY_STATUS = Object.freeze({
	SSDM_APPROVED: 'PMU APPROVED',
	SSDM_SENT_BACK: 'PMU Sent Back',
	PMKVY_RPL_APPROVED: 'PMU APPROVED',
});

export const USER_ROLES = Object.freeze({
	TRAINING_PARTNER: 'Training Partner',
	TRAINING_CENTRE: 'Training Centre',

	TARGET_PMU: 'Target PMU',
	RPL_PMU: 'RPL PMU',
	SP_PMU: 'SP PMU',
	SSC_SCHEME_SPOC: { ID: 5, ROLE: 'SSC Scheme Spoc' },
	BEST_IN_CLASS_EMPLOYER: 'Best In Class Employer',
	ASSESSOR: 'Assessor',
	PROCTOR: 'Proctor',
	CAMPS: 'Camps',
	EMPLOYER: 'Employer',
	TRAINER: 'Trainer',
	PMKVY_BATCH_ADMIN: 'PMKVY Batch Admin',
	RPL_APMU: 'RPL Assessment PMU',
	STT_APMU: 'STT Assessment PMU',
	SP_APMU: 'SP Assessment PMU',
	SSDM_PMU_STT: 'SSDM PMU STT', // for PMKVY CSSM Schemes PMU
	SSDM_PMU_SP: 'SSDM PMU SP', // for PMKVY CSSM Schemes PMU
	ASSESSMENT_AGENCY: 'Assessment Agency',
	BICE_SECONDARY_SPOC: 'Bice Secondary Spoc',
	AID_SP: 'AID_SP',
	AID_STT: 'AID_STT',
	SSDM_PMU_RPL: 'SSDM PMU RPL',
	STATE_SCHEME_PMU: 'State Scheme PMU',
	CMA_HEAD: 'CMA HEAD',
	DAA: 'DAA',
	LOCKDOWN_PMU: 'LOCKDOWN PMU',
	BUDGET_APPROVER: 'Budget Approver',
	NSDC_IT_ADMIN: 'NSDC IT ADMIN',
	CANDIDATE_ADMIN: 'Candidate Admin',
	SSC: 'SSC',
	SANKALPADMIN: 'SANKALAPADMIN',
	GPSPOC: 'GPSPOC',
	ENUMERATOR: 'Enumerator',
	DSC: 'DSC',
	THIRD_PARTY_AGENCIES: 'Third Party Agency',
	CANDIDATE: 'Candidate',
	SSDM_SP_MH: 'SSDM_SP_MH',
	PMKVY_PMU: 'pmkvy4pmu',
	IMPLEMENTATION_PARTNER: 'Implementation Partner',
	SANKALP_PMU: 'SANKALP PMU',
	AMBER_STT_PMU: 'AMBER STT PMU',
	SANKALP_STT_PMU: 'SANKALP_STT_PMU',
	SEO : 'SEO',
	CMA_SPOC:'CMA SPOC',
	State_Scheme_PMU_CHECKER:'State Scheme PMU CHECKER'
})

export const USER_ROLES_STRING = Object.freeze({
	SSDM_PMU_STT: 'ssdmpmustt', // for PMKVY CSSM Schemes PMU
	SSDM_PMU_SP: 'ssdmpmusp', // for PMKVY CSSM Schemes PMU
	SSDM_PMU_RPL: 'ssdmpmurpl', // for PMKVY CSSM Schemes PMU
	STATE_SCHEME_PMU: 'stateschemepmu', // for PMKVY CSSM Schemes PMU
	AMBER_STT_PMU: 'ambersttpmu',
	SANKALP_STT_PMU: 'sankalpsttpmu',
	STATE_SCHEME_PMU_CHECKER:'stateschemepmuchecker'
})

export const VIEW_ONLY_TYPE_PMU = Object.freeze({
	VIEW_ONLY: 'viewOnly'
})

export const VALIDATORS = Object.freeze({
	// tslint:disable-next-line:max-line-length
	// EMAIL: /^([a-z0-9])(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

	EMAIL: /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/
})

export const CANDIDATE_STATUS = Object.freeze({
	APPROVED: 'APPROVED',
	DROPPED: 'DROPPED',
	CERTIFIED: 'CERTIFIED'
});

export const TP_TYPES_TRAINING_TYPES = Object.freeze({
	RPL: 'PIA', SP: 'SPIA', STT: 'TP'
});

export const TC_STATUS = Object.freeze({
	QUALIFIED: 'Qualified',
	APPROVED: 'Approved',
	DE_ACTIVE: 'Deactivated',
	INPROGRESS: 'init',
	INPROGRESS1: 'In Progress',
	DRAFT: 'Draft'
});

export const PMKVY_SCHEME_MODEL_CONSTANTS: any = Object.freeze({
	TARGET_ALLOCATION_BY_TP: { id: 1, value: 'TP/PIA/SPIA' },
	TARGET_ALLOCATION_BY_TC: { id: 2, value: 'TC' },
	TC_TYPE_TWO: { id: 2, value: 'Non-A&A' },
	TC_TYPE_ONE: { id: 1, value: 'A&A' },
	TC_TYPE_BOTH: 'Both',
	TC_AA: 'Accreditation %26 Affiliation',
	TC_NON_AA: 'Non-Accreditation %26 Affiliation',
	CAAF_SUBMITTED_TC: 'CAAF Submitted TC',
	CAAF_SUBMITTED_TC_AND_NON_AA: 'CAAF Submitted TC & Non-A&A',
	TC_GOVERNMENT: { id: 1, value: 'Gov' },
	TC_NON_GOVERNMENT: { id: 2, value: 'Non-Gov' },
	TC_GOVERNMENT_MOU: { id: 3, value: 'Gov-MOU' },
	TC_AA_DISPLAY_NAME: 'Accreditation & Affiliation',
	TC_NON_AA_DISPLAY_NAME: 'Non-Accreditation & Affiliation',
	TC_PMKVY_STATUS: 'approved',
	AGREEMENT_DATE: 'agreementDate',
	PROJECT_START_DATE: 'projectStartDate',
	PROJECT_END_DATE: 'projectEndDate',
	ALL_TC: 'All'
});

export const TARGET_ALLOCATION_LEVEL = Object.freeze({
	sector: 'Sector',
	jobrole: 'jobrole/QP',
	location: 'Location'
});

export const APPROVAL_COMMITTEE = Object.freeze({
	// PEC: { ID: 1, VALUE: 'Project Evaluation Committee' },
	PEC: { ID: 1, VALUE: 'Project Screening Committee' },
	EC: { ID: 2, VALUE: 'Executive Committee' },
	EA: { ID: 3, VALUE: 'Email Approval' },
	PSC: { ID: 3, VALUE: 'PMKVY Screening Committee' },
	PMKVYEC: { ID: 3, VALUE: 'PMKVY Executive Committee' },
})

export const PMKVY_TRAINING_TYPES = Object.freeze({
	STT: { ID: 1, type: 'STT' },
	TOT: { ID: 1, type: 'TOT' },
	TOA: { ID: 1, type: 'TOA' },
	RPL: { ID: 3, type: 'RPL' },
	SPECIAL_PROJECT: { ID: 2, type: 'Special Project' },
	SP: { ID: 2, type: 'SP' },
	RPLA: 'Recognition of Prior Learning',
	STTA: 'Short Term Training'
});

export const PMKVY_ATTENDANCE_MODES = Object.freeze({
	AEBAS: 'AEBAS', // 'Short Term Training'
	MANUAL: 'Manual',
	BOTH: 'Both',
	NOT_APPLICABLE: 'Not Applicable',
});

export const PMKVY_NO_OF_DAYS = Object.freeze({
	NUMBER_OF_DAYS_TO_ADD_PROJECT_END_DATE: 30,
});

export const TP_PIA_TYPE = Object.freeze({
	GOVERNMENT: 'Gov',
	NON_GOVERNMENT: 'Non-Gov'
});

export const PMKVY_SP_PROJECT_TYPES = Object.freeze({
	GOVERNMENT: 'Government',
	NON_GOVERNMENT: 'Non-Government',
	GOVERNMENT_MOU: 'Government MOU'
});

/**
 *Scheme Batch Constants
 */
export const PMKVY_BATCH_CONSTANTS = Object.freeze({
	BATCH_SIZE: [20, 25, 30],
	BATCH_MODE: ['Offline', 'Online'],
	BATCH_TYPE: ['Regular'], // Reassessment
	BATCH_TYPE_RPL: ['Regular'],
	TRAINING_HOURS: [4, 8],
});

export const MOUHA_BATCH_TYPE_DISPLAY = Object.freeze({
	INITIAL_ASSESSMENT: 'Initial Assessment',
	FINAL_ASSESSMENT: 'Final Assessment',
});

export const BATCH_SESSION_PLANNED = Object.freeze(
	{
		class: 'Class',
		ojt: 'OJT',
		lab: 'Lab',
		examination: 'Examination',
		holiday: 'National & Regional Holidays',
		otherSession: 'Other Session',
		others: 'Others',
		EEEEE: 'E&E/EEE'
	}
)

// export c'Class''Class'onst BATCH_SESSION_PLANNED_WITHOUT_OJT = [
// 	'Class', 'Lab', 'Examination', 'National & Regional Holidays', 'Other Session', 'Others'
// ]

export const MONTHS = Object.freeze([
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'December',
]);

export const PMKVY_BATCH_MODAL_ID = Object.freeze({
	BATCH_ON_HOLD: 'batch_on_hold',
	CHANGE_ASSESSMENT_DATE: 'change_assessment_date',
	CANCEL_BATCH: 'cancel_batch',
	CHANGE_ASSESSOR: 'change_assessor',
	CHANGE_ASSESSMENT_AGENCY: 'change_assessment_agency',
	BICE_ADD_LOCATION_ID: 'bice_add_location',
	VIEW_COMMENT: 'view_comment'
}
);


export const PMKVY_RPL_TYPES = Object.freeze({
	CAMPS: { id: 1, value: `Type I - RPL at Camps`, compareWith: '1' },
	EMPLOYER_PREMISES: { id: 2, value: `Type II - RPL at Employer Premises`, compareWith: '2' },
	CENTRES: { id: 3, value: `Type III - RPL at Centres`, compareWith: '3' },
	BEST_IN_CLASS_ENPLOYER: { id: 4, value: `Type IV - Best in-Class Employer`, compareWith: '4' },
	RPL5: { id: 5, value: `Type V - RPL through Demand`, compareWith: '5' },
	GKRA_TYPE_1: { id: 6, value: `GKRA Type 1`, compareWith: '6' },
	COVID_RPL_TYPE_1: { id: 7, value: `Type I - RPL at Camps - COVID Upskilling`, compareWith: '7' },
	COVID_RPL_TYPE_3: { id: 8, value: `Type III - RPL at Centres - COVID Upskilling`, compareWith: '8' },
}
);

export const PMKVY_SP_TYPES = Object.freeze({
	COVID_SP: { id: 2, value: `Special Project - COVID Upskilling`, compareWith: '2' },
	COVID_SP_LMO: { id: 3, name: 'SPL COVID Training - LMO', compareWith: '3' }
}
);

export const RPL_SUBSCHEME_TYPES = Object.freeze(PMKVY_RPL_TYPES);

export const PMKVY_STT_SP_SUBSCHEME = Object.freeze({
	RFP: { ID: 1, SCHEME: 'RFP' },
	MOMU: { ID: 2, SHEME: 'MOMU' },
	REALLOCATION: { ID: 3, SCHEME: 'Reallocation' },
	PMKK: { ID: 4, SCHEME: 'PMKK' },
	ACADEMICS: { ID: 5, SCHEME: 'Academic Institute' },
	GKRA: { id: 10, name: 'GKRA' },
	SKILL_HUB_INITIATIVE: { id: 10, name: "SKILL HUB INITIATIVE" },
	Government_Institute: { id: 12, name: "Government Institute" },
	MSDE_Amber: {
		Amber_Tech: { id: 1, name: 'AMBER Tech Job role' },
		Amber_Mass: { id: 1, name: 'AMBER Mass Employment Job role' },
	},
	SANKALP_YUVA_and_AVSAR: {
		YUVA: { id: 1, name: "YUVA(II) and AVSAR" },
		AVSAR: { id: 2, name: "AVSAR" }
	},
	Nal_Jal_Mitra:{
		NJM_STT: { id: 1, name: "NJM-STT" }
	}
})

export const PMKVY_STT_SP_SCHEME_NAME = Object.freeze({
	PMKVY_CSSM: { ID: 2, SCHEME: 'PMKVY-CSSM' },
});

export const SCHEME_NAME_CONSTANT = Object.freeze({
	NON_PMKVY_SANKALP: { id: 6, name: 'Sankalp' },
	PMKVY_3_CSCM: { id: 7, name: 'PMKVY3.0-CSCM' },
	PMKVY_3_CSSM: { id: 8, name: 'PMKVY3.0-CSSM' },
	MOHUA_RPL_TYPE_2: { id: 14, name: 'MoHUA - Ministry of Housing and Urban Affairs' },
	MOHUA_SP: { id: 14, name: 'MoHUA - Ministry of Housing and Urban Affairs' },
	MSDE_AMBER: { id: 13, name: 'AMBER-Accelerated Mission for Employment and Retention' },
	NON_PMKVY_SANKALP_YUVA: { id: 15, name: 'Sankalp' },
	PMKVY_4_CSCM: { id: 17, name: 'PMKVY-4.0-CSCM' },
	STATE_SCHEME: { id: 18, name: 'State Scheme' },
	PMV_SCHEME: { id: 19, name: 'PM Vishwakarma' },
	Nal_Jal_Mitra: { id: 20, name: 'Nal Jal Mitra' },
	PM_DAKSH:{ id: 22, name: 'PM-DAKSH' },
	




});

export const SCHEME_NAME_FILTER_DROPDOWN = Object.freeze({
	NON_PMKVY_SANKALP: { id: 6, name: 'Sankalp' },
	PMKVY_3_CSCM: { id: 7, name: 'PMKVY-3.0-CSCM' },
	PMKVY_3_CSSM: { id: 8, name: 'PMKVY-3.0-CSSM' },
	PMKVY_4_CSSM: { id: 17, name: 'PMKVY-4.0-CSCM' },
	PMKVY_CSCM: { id: 1, name: 'PMKVY-CSCM' },
	PMKVY_CSSM: { id: 2, name: 'PMKVY-CSSM' },
	SEEKHO_AUR_KAMAO: { id: 9, name: 'MOMA' },
	MOHUA: { id: 14, name: 'MoHUA' },
	PMV_SCHEME: { id: 19, name: 'PM Vishwakarma' }


});

export const PMKVY_SCHEME_VERSIONS = Object.freeze({
	THREE: { id: 3, value: '3.0' },
	FOUR: { id: 4, value: '4.0' },
});

export const RPLTCNAME = Object.freeze({
	CAMPS: `Camps`,
	EMPLOYER_PREMISES: `Employer Premises`,
	CENTRES: `Centres`,
	BICE: 'Best in-Class Employer',
	RPL_GKRA_TYPE_1: 'Camps'
});

export const RPL_CEREMONY: any = Object.freeze({
	CREATED: 'ceremonyCreated',
	PHOTOPROOF: 'proofSubmitted',
	COMPLETED: 'ceremonyCompleted',
	INPROGRESS: 'init',
	APPROVED: 'ceremonyApproved',
	SENDBACK: 'ceremonySentBack',
	REJECTED: 'ceremonyRejected',
	PROOFRESUBMITTED: 'proofReSubmitted',
	AUTOREJECTED: 'ceremonyAutoRejected'
});

export const Honorifics = ['Mr', 'Mrs', 'Ms'];

export const NON_AA_TC_STATUS = Object.freeze({
	APPROVED: 'Approved',
	REJECTED: 'Rejected'
});
/**
 * Best-In-Class Employer Status
 */
export const BICE_STATUS = Object.freeze({
	INPROGRESS: { ID: 1, status: 'init' },
	DRAFT: { ID: 2, status: 'Draft' },
	AVAILABLE: { ID: 3, status: 'Available' },
	NOT_AVAILABLE: { ID: 4, status: 'Not Available' },
});

export const TYPE_OF_WASHROOM = Object.freeze({
	MALE: 'Male', FEMALE: 'Female', UNISEX: 'Unisex'
})

export const TP_PIA_REGISTERED_AS = Object.freeze({
	SECTION_25A: 'Section 25',
	REGISTERED_AS_TRUST: 'Registered as Trust',
	REGISTERED_AS_SOCIETY: 'Registered as Society',
	CORPORATE: 'Corporate',
	NSDC_TRAINING_PARTNER: 'NSDC Training partner',
	PROPRIETORSHIP: 'Proprietorship'
});

export const TYPE_OF_EMPLOYMENT = Object.freeze({
	WAGE_EMPLOYMENT: 'Wage Employment',
	SELF_EMPLOYMENT: 'Self Employment',
	CAPTIVE_EMPLOYMENT: 'Captive Employment',
	WAGE_SELF_EMPLOYMENT: 'Wage + Self Employment',
	OTHERS: 'Others'
});

export const BICE_EMPLOYER_TYPES = Object.freeze({
	PRIVATE_LIMITED: 'Private Limited',
	PUBLIC_COMPANY: 'Public Company',
	ASSOCIATION: 'Association',
	CO_OPERATIVE: 'Co-operative',
	OTHERS: 'Others'
});

export const ASSESSOR_EMMPLOYER_ASSOCIATIONS = Object.freeze({
	PERMANENT: 'Permanent',
	CONTRACTUAL: 'Contractual',
	THIRD_PARTY: 'Third Party'
});

export const BATCH_VALIDITY = ['3', '4', '5', '6', '7', '8', '9', '10']

export const ASSESSOR_CASES_FOR_BLACKLIST_WAR_SUSPEND = [
	{ 'value': 'If the assessor is not carrying his/her original Aadhar card/ identity proof at assessment venue' },
	{ 'value': 'If the assessor is not reaching the venue on time for assessment repeatedly' },
	{ 'value': 'If the assessor is continuously delaying in conducting the assessment or uploading the assessments' },
	{ 'value': 'If the assessor is not carrying his/her PMKVY ID card to the training center at the day of assessment' },
	{ 'value': 'If the assessor is not verifying the ID cards and attendance record of the candidates' },
	{ 'value': 'If the assessor is not following the code of conduct laid down by their concerned SSC or AA' },
	{ 'value': 'If the assessor is not bringing the requisite equipment’s required to conduct the assessment' },
	{ 'value': 'If it is found that the assessor has left the center during the assessment' },
	{ 'value': 'If the assessor is not conducting the assessment for the job role as mentioned in the SIP' },
	// tslint:disable-next-line:max-line-length
	{ 'value': 'If the assessor is conducting the assessment at different location from the one mentioned on SIP and not informing the SSC about the same' },
	{ 'value': 'If the assessor is not informing to the respective authoring for any breach in the in the Code of conduct' },
	// tslint:disable-next-line:max-line-length
	{ 'value': 'If the assessor shows the trend (more than 2 cases in a month) of denying to conduct the assessment just one day prior to the assessment' },
	{ 'value': 'If the assessor is not following the code of conduct laid down by their concerned SSC or AA' },
	{ 'value': 'If the assessor is found sharing the assessment material with TP before the assessment' },
	{ 'value': 'If the assessor is conducting the assessment for the job roles for which candidates were trained' },
	{ 'value': 'If the assessor is not reaching the venue to conduct the assessment without any prior intimation to the concerned authority' },
	{ 'value': 'If the assessor is sending someone else on his/her behalf for the assessment' },
	{ 'value': 'If the assessor is found to have allowed fake candidates to appear for assessments' },
	{ 'value': 'If the assessor shares fake credential for getting himself/herself approved for conducting assessments' },
	// tslint:disable-next-line:max-line-length
	{ 'value': 'If there is any complain by the TP/TC regarding the malpractice committed by the assessor. After proper investigation by the SSC, if the assessor is found guilty, assessor shall be blacklisted' },
	{ 'value': 'If the assessor is found manipulating the scores of the candidates' },
	{ 'value': 'If the assessor is found accepting any bribe, inducements, and any favours that pose a conflict of interest' }
];

/* Batch Dates Constant */
export const BATCH_DURATION_CONSTANT = Object.freeze({
	START_DATE_BUFFER_FROM_CREATION_DATE_FOR_SP: 60,
	START_DATE_BUFFER_FROM_CREATION_DATE_FOR_STT: 15,
	BUFFER_DAYS_AFTER_END_DATE: 30,
	ENROLLMENT_ENCLOSURE_DATE: Helpers.makeTimeZero(new Date('03/31/2020')),
	MAX_Buffer_Limit_For_RPL_Batch_StartDate: Helpers.makeTimeZero(new Date('05/01/2020')).toISOString(),
	END_DATE_TRAINER_ASSOCIATION_PMKVY: Helpers.makeTimeZero(new Date('12/31/2021')).toISOString(),
	END_DATE_TRAINER_ASSOCIATION_SANKALP: Helpers.makeTimeZero(new Date('12/31/2020')).toISOString(),
	END_DATE_TRAINER_ASSOCIATION_RPL_STATES: Helpers.makeTimeZero(new Date('12/31/2020')).toISOString(),
});

/* Batch Dates Constant */
export const SCHEME_INFO_MESSAGE_CONSTANT = Object.freeze({
	IS_MAINTENANCE: false,
	PMKVY_MODULE_DOWN_DUE_TO_MAINTENANCE: `<span style="color: red;">
	PMKVY Training Module is Down due to Urgent System Maintenance Activity!</span>`,
	SP_CSSM_TENTATIVE_PAYOUT_DISCLAIMER: `"This payout calculation is tentative only,
	based on common cost norms and completion of batch upto certification of all candidates.
	Actual payouts will be determined based on actual
	achievement of outcomes and agreement between SSDM and PIA in line with PMKVY 3.0 Guidelines."`,
	CREATE_BATCH_DISCLAIMER: `<ol>
	<li>
		<p>
			I am fully aware that I am enrolling this batch under the provision of  extension to enrolment  till  Sep 30, 2020 granted by Ministry of Skill Development and Entrepreneurship,
			under  PMKVY 2.0( 2016 -20), for the North Eastern states( 8 North East states)  of India as per OM B-12011/01/2016 - SDE (Part II)  dated 17 Jun 2020 .
			I am also aware of the condition that any fresh enrolment will be for reverse migrants only. I understand that Reverse migrants are those people who were working
			outside the state in which my training centre is located and have returned to their state of domicile/native state due to the outbreak of the COVID-19 pandemic.  They have left /lost their job /employment due to COVID-19 and are currently seeking skills to get in new employment.
		</p>
	</li>
	<li>
		<p>
			I am enrolling Reverse Migrants only for those numbers which are  available to me as not – enrolled  against the allocation.
		</p>
	</li>
	<li>
		<p>
			I  hereby  affirm   that as a Training Provider/ Project Implementation Agency that the batch I will be enrolling only Reverse  Migrants as candidates/ trainees and the data of the Reverse Migrants  has been provided by/collected from/sourced from the respective state/district administration.
		</p>
	</li>
	<li>
		<p>
			I am authorized to give this disclaimer by the organization I represent.
		</p>
	</li>
	<li>
		<p>
			I and the organization I represent, will be responsible for any  penal action on account of
			deviation from any instruction mentioned herein in this Disclaimer or any other instructions
			passed to me in this regard.
		</p>
	</li>
</ol>`,
	PMKVY_3_STT_CREATE_BATCH_DISCLAIMER: `<ol>
<li>
	<p>
	I hereby affirm that as a Training Provider/ Project Implementation Agency that candidates enrolled
	in batch for PMKVY scheme has been provided by/collected from/sourced from the respective District
	Administration/ District Skill Committee (DSC).
	</p>
</li>
<li>
	<p>
	I hereby affirm that as a Training Provider/ Project Implementation Agency that
	candidates enrolled in batch for PMKVY scheme are school/college dropouts or unemployed.
	</p>
</li>
</ol>`,
	PMKVY_3_SP_CREATE_BATCH_DISCLAIMER: `<ol>
<li>
	<p>
	I hereby affirm that as a Training Provider/ Special Project Implementing Agency the candidates
	 enrolled in this batch of PMKVY 3.0 Special Project training are as per the target beneficiary approved by EC.
	</p>
</li>
</ol>`,
	STT_GKRA_CREATE_BATCH_DISCLAIMER: `<ol>
	<li>
		<p>
		I am aware of the condition that any fresh enrollment under GKRA will be for reverse migrants/local youth
		from the district only. I understand that Reverse migrants are those people who were working outside the
		state in which my training center is located and have returned to their state of domicile/native state due
		to the outbreak of the COVID-19 pandemic. They have left /lost their job /employment due to COVID-19 and
		are currently seeking skills to get in new employment.
		</p>
	</li>
	<li>
		<p>
		Also, I confirm that the first preference has been given to the reverse migrants who are still available in
		the districts to enroll in the batch. Only if such reverse migrants are not available, then local youth have
		been enrolled in the batch on the basis of a recommendation by the District Administration.
		</p>
	</li>
	<li>
		<p>
		I  hereby  affirm that as a Training Provider/ Project Implementation Agency that the batch I will be enrolling
		only Reverse  Migrants/ local youth from the district as candidates/ trainees and the data of the Reverse
		Migrants/ local youth has been provided by/collected from/sourced from the respective state/district administration.
		</p>
	</li>
	<li>
		<p>
		I am authorized to give this disclaimer to the organization I represent.
		</p>
	</li>
	<li>
		<p>
		I and the organization I represent will be responsible for any  penal action on account of  deviation from any
		instruction mentioned herein in this Disclaimer or any other instructions passed to me in this regard.
		</p>
	</li>
</ol>`,
	RPL_GKRA_CREATE_BATCH_DISCLAIMER: `<ol>
	<li>
		<p> I am fully aware that I am enrolling in this batch under the provision of
		 Garib Kalyan Rozgar Abhiyan (GKRA), an initiative of the Ministry of Skill
		  Development and Entrepreneurship (MSDE), under  PMKVY 2.0 (2016 -20), as per
		   D.O. No. SD-17/46/2020-SNP (PT) dated 02nd June 2020.
		</p>
	</li>
	<li>
		<p>I affirm, hereby, as a Training Provider (TP)/ Project Implementation Agency
		 (PIA) that the batches being enrolled will comprise Reverse Migrants (RM)
		  as candidates/ trainees as the first preference, where candidates/ trainees
		   are still available in the district. I understand those reverse migrants
			are people who were working outside the state in which my training center
			 is located and have returned to their state of domicile/native state due
			  to the outbreak of COVID-19. They have left /lost their job /employment
			   due to COVID-19 and are currently seeking skills to get new employment.
		</p>
	</li>
	<li>
		<p> I affirm, hereby, as a Training Provider (TP)/ Project Implementation
		 Agency (PIA) that if such reverse migrants are not available, they will be
		  substituted with local youth from the district, as recommended by District Administration.
		</p>
	</li>
	<li>
		<p> I also, affirm as a TP/ PIA that the mobilization of candidates will
		 be through the district administration and as per the list of candidates
		  provided by the district administration.
		</p>
	</li>
	<li>
		<p>I am authorized to give this disclaimer by the organization I represent.</p>
	</li>
	<li>
		<p>I, and the organization I represent will be responsible for any
		 penal action on an account of deviation from the instructions
		  mentioned in this disclaimer or other instructions communicated
		   to me in this regard.</p>
	</li>
</ol>`,
	STT_MOMA_ENROLLMENT_DISCLAIMER: `<ol>
	<li>
		<p>I am fully aware that I am enrolling this batch under the provision of Seekho Aur Kamao (Learn and
			Earn), the Ministry of Minority Affairs (MoMA) skill training scheme being implemented by NSDC for J
			&amp;K and  Ladakh.
		</p>
	</li>
	<li>
		<p>I have acquainted myself, and continue to do so with the guidelines, SOP and other instructions on
		the scheme from time to time.</p>
	</li>
	<li>
		<p>I  am enrolling minority community candidates from J &amp;K and Ladakh as per the provisions of the
		scheme.
		</p>
	</li>
	<li>
		<p>I confirm that the candidate being enrolled have not been  professionally qualified or have already
		obtained any such training.</p>
	</li>
	<li>
		<p>I am authorized to give this disclaimer by the organization I represent.
		</p>
	</li>
	<li>
		<p>I, and the organization I represent, will be responsible for any  penal action on account of deviation
		from any instruction mentioned herein in this Disclaimer or any other instructions passed to me in this
		regard.
		</p>
	</li>
</ol>`,
	PMKVY3_RPL3_DAP_ENROLLMENT_DISCLAIMER: `<ol>
	<li>
		<p>I hereby affirm that as a Training Provider/ Project Implementation Agency that candidates enrolled in batch for PMKVY 3.0 has
		 been provided by/collected from/sourced from the respective District Administration/ District Skill Committee.
		</p>
	</li>
	<li>
		<p>I hereby affirm that as a Training Provider/ Project Implementation Agency that candidates enrolled in batch for
		PMKVY 3.0 are possessing prior experience in the respective job role(s).</p>
	</li>
</ol>`,
	PMKVY_STT_SKILL_HUB_DISCLAIMER: `
	<span>
    <p style="font-size:11pt;margin-right:0in;margin-bottom:5pt;margin-left:0.5in;text-align:center;background:white;color:rgb(0,0,0)" align="center">
    <b><span style="font-size:10.5pt;margin:0px;color:rgb(33,37,41)">Undertaking on behalf of Applicant Organization</span></b><span style="font-size:10.5pt;margin:0px;color:rgb(33,37,41)"></span></p>
    <p style="font-size:14px;margin-right:0in;margin-left:0.5in;background:white;box-sizing:border-box;margin-bottom:1rem;color:rgb(0,0,0)">
    <span style="font-size:10.5pt;margin:0px;color:rgb(33,37,41)"> I, on behalf of Applicant Organization, do hereby, in consideration of affiliation and allocation of 
	training targets under Pradhan Mantri Kaushal Vikas Yojana ("PMKVY Scheme"), state, confirm, assure, declare, and irrevocably undertake as under:</span></p>
    <p style="font-size:14px;margin-right:0in;margin-left:0.5in;background:white;box-sizing:border-box;margin-bottom:1rem;color:rgb(0,0,0)">
    </p>

    <ol>
   	 <li style="font-size:10.5pt;color:rgb(33,37,41)">
    		<span style="font-size:10.5pt"> That we have read and understood the shared guidelines. </span>
	 </li>
	<li style="margin-top:0in;background:white;box-sizing:border-box;color:rgb(33,37,41)">
    	<span style="font-size:10.5pt;margin:0px">That we shall regularly visit <span>&nbsp;</span>
			<a href="http://www.pmkvyofficial.org/" style="margin:0px" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.pmkvyofficial.org/&amp;">www.pmkvyofficial.org</a> <span>&nbsp;</span>
		and keep ourselves updated with the latest PMKVY Guidelines.
		</span>
	</li>
	 
	<li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
    	<span style="margin:0px"> That we are and shall continue to be in full compliance of PMKVY Guidelines, as amended from time to time, with respect to conducting trainings under the PMKVY Scheme.</span>
	</li>
	 
	 <li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
   		 <span style="margin:0px">That prior to disbursement of tranches for payouts as mentioned in PMKVY Guidelines:&nbsp;</span>
	 </li>
	
	<ol style="list-style-type:lower-alpha">
    <li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
    	we shall fulfil all conditions prior to such disbursements; and
	</li>
	
	<li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
    	we understand that such payouts shall be made upon release of funds by the Government of India.</li>
	</ol>

    <li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
   	 	That we will inter alia abide by all monitoring and audit-related instructions as amended from time to time.
	</li>
	
	<li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
   		That we shall follow the guidelines issued by Ministry of Health and Family Welfare ("MoHFW"), the Ministry of Skill Development and Entrepreneurship ("MSDE"), and applicable central, state and local authorities, during the tenure of the PMKVY Scheme on preventive measures to contain spread of COVID-19 in Training Centres.
	</li>
	 
	<li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
   		That in the event of any non-compliance with PMKVY Guidelines and / or if any of the information and /or documents furnished by us are found to be incorrect and / or misleading and / or mismatching with the data uploaded in Skill India Digital Hub, that is, Skill India Digital Hub or any such 
   		similar platform:
    </li>
	 <ol style="list-style-type:lower-alpha">
    <li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
    	 National Skill Development Corporation ("NSDC") shall have the right to take any action against us including but not limited to, withdrawal of the affiliation of a particular non-compliant Training Centre or of all our Training Centres affiliated to the PMKVY Scheme, target revocation, impose financial penalties, suspend Training Centres, 
		 blacklist us under PMKVY scheme, at the sole discretion of NSDC;
	</li>
	<li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
    	 NSDC shall have the right to recover the disbursed amount in full and withhold any further disbursement(s);
	</li>
	<li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
    	Without limiting any other rights which NSDC may have, we shall indemnify, hold harmless and keep indemnified NSDC, its associates, partners, directors, employees and/or other officers from and against all claims, including third party claims, liabilities, losses, fines, penalties, fees, costs (including legal fees and expenses) etc. suffered and / or incurred by NSDC,
	 	its affiliates or its directors or its employees.
	 </li>
	</ol>
    <li style="margin-top:0in;background:white;box-sizing:border-box;font-size:10.5pt;color:rgb(33,37,41)">
   		 Any decision taken under PMKVY Scheme and/or PMKVY Guidelines by MSDE, NSDC and/or any committee(s) setup by MSDE/NSDC shall be binding upon us; and we shall abide by all the decisions of MSDE, NSDC and/or committee(s).
	</li>
	</ol>
    <p style="font-size:14px;margin-right:0in;margin-left:0.5in;background:white;box-sizing:border-box;margin-bottom:1rem;color:rgb(0,0,0)">
    <span style="font-size:10.5pt;margin:0px;color:rgb(33,37,41)">The above undertakings, declarations, confirmations and statements shall be binding on us at all times.</span></p>
    </span>`,
	PMKVY_SP_MOHUA_DISCLAIMER: `<ol>
	<li>
		<p>I hereby affirm that as a Training Provider/ Project Implementation Agency that candidates enrolled in batch for MoHUA project has
		 been provided by/collected from/sourced from the respective builder/contractor.
		</p>
	</li>
	<li>
		<p>I hereby affirm that as a Training Provider/ Project Implementation Agency that candidates enrolled in batch for
		MoHUA project  are school/college dropouts or unemployed.</p>
	</li>
</ol>`,
	Mobile_UPDATE_DISCLAIMER: `<ol>
		<li>
			<p>
				This is to certify that the Training provider/training center has verified that the contact number ( entered above) belongs to the candidate/ student. Training provider/training center hereby  takes the full responsibility of information provided above and shall be liable for any penal action in case the information entered  is found to be false/invalid.
			</p>
		</li>
	</ol>`,
	OJT_Attendance_DISCLAIMER: `<ol>
		<li>
			<p>
				I on behalf of the training centre, hereby confirm that the OJT attendance proof document uploaded has been duly verified by the training center/institute. Training center/institute will solely be responsible, in case of any discrepancy.
			</p>
		</li>
	</ol>`,
});

/* Batch Dates Constant */
export const BATCH_DISCLAIMER_CONTENT = Object.freeze({
	TRAINER_DISCLAIMER_MOMA: `<b><i>The trainer who is been selected for this batch should not be
	assigned for any other batch at the same day and time<i></b>`
});
/* Scheme Name Details*/
export const SCHEME_NAME = Object.freeze({
	PMKVY: 'Pradhan Mantri Kaushal Vikas Yojana',
	NON_PMKVY: 'NON-PMKVY',
})

/* New Scheme Constants (july 2020) */
export const SCHEME_REFERENCE_TYPE = Object.freeze({
	PMKVY: { id: 1, name: 'pmkvy' },
	FEE_BASED: { id: 2, name: 'Fee Based' },
	// NON_PMKVY: { id: 3, name: 'non pmkvy' },
	NON_PMKVY_CSSM: { id: 3, name: 'nonPmkvyStates', display: 'Non-PMKVY RPL CSSM' },
	DEPARTMENT_SCHEMES: { id: 4, name: 'departmentSchemes' },
	MSDE: { id: 5, name: 'msde' },
	PMPKVY4: { id: 16, name: 'pmkvy40', display: 'PMKVY-4.0-CSCM' },
});

export const COMMON_FLOW_PMKVY_NONPMKVY = Object.freeze({
	PMKVY: SCHEME_REFERENCE_TYPE.PMKVY.name,
	NON_PMKVY_CSSM: SCHEME_REFERENCE_TYPE.NON_PMKVY_CSSM.name,
	DEPARTMENT_SCHEMES: SCHEME_REFERENCE_TYPE.DEPARTMENT_SCHEMES.name,
	MSDE: SCHEME_REFERENCE_TYPE.MSDE.name,
	FEE_BASED: SCHEME_REFERENCE_TYPE.FEE_BASED.name
});

export const SCHEME_WORKFLOW_TYPE = Object.freeze({
	CSCM: { id: 1, name: 'CSCM', CSCM_TYPE: { TYPE1: { id: 5, name: 'CSCM-RPL1' } } },
	CSSM: { id: 2, name: 'CSSM' }
});

export const SCHEME_WORKFLOW_DETAILS = Object.freeze({
	CSCM_STT: { name: 'CSCM-STT', description: 'CSCM STT Workflow', id: 1 },
	CSSM_STT: { name: 'CSSM-STT', description: 'CSSM STT Workflow', id: 2 },

	CSCM_SP: { name: 'CSCM-SP', description: 'CSCM SP Workflow', id: 3 },
	CSSM_SP: { name: 'CSSM-SP', description: 'CSSM SP Workflow', id: 4 },

	CSCM_RPL1: { name: 'CSCM-RPL1', description: 'CSCM-RPL1 Workflow', id: 5 },
	CSCM_RPL2: { name: 'CSCM-RPL2', description: 'CSCM-RPL2 Workflow', id: 6 },
	CSCM_RPL3: { name: 'CSCM-RPL3', description: 'CSCM-RPL3 Workflow', id: 7 },
	CSCM_RPL4: { name: 'CSCM-RPL4', description: 'CSCM-RPL4 Workflow', id: 8 },
	CSCM_RPL5: { name: 'CSCM-RPL5', description: 'CSCM-RPL5 Workflow', id: 9 },

	NON_PMKVY: { name: 'NON-PMKVY', description: 'NON-PMKVY Workflow', id: 10 },
	RABBIT_MQ: { name: 'Rabbit mq', description: 'Rabbit MQ related batches workflow', id: 11 },
	CSSM_RPL1: { name: 'CSSM-RPL1', description: 'CSSM-RPL1 Workflow', id: 12 },
	CSSM_RPL2: { name: 'CSSM-RPL2', description: 'CSSM-RPL2 Workflow', id: 13 },
	CSSM_RPL3: { name: 'CSSM-RPL3', description: 'CSSM-RPL3 Workflow', id: 14 },
	CSSM_RPL4: { name: 'CSSM-RPL4', description: 'CSSM-RPL4 Workflow', id: 15 },
	CSSM_RPL5: { name: 'CSSM-RPL5', description: 'CSSM-RPL5 Workflow', id: 16 }
});

export const ENROLLMENT_SOURCE_VALUES = Object.freeze({
	Unified_Candidate_Registration_Portal: {
		display: 'Unified Candidate Registration Portal',
		value: 'Unified Candidate Registration Portal'
	},
	DAP_Portal: { display: 'Demand Aggregation Portal (DAP Portal)', value: 'DAP Portal' },
});

/**
 * Scheme Mock Data
 */
export function getSchemesList() {
	return [
		{
			'_id': '5c04c32c70342317ee0f2710',
			'linkedCommonNormDetails': {
				'overwriteCommonNorm': false,
				'_id': '5c020be171b19e3e9ce1a444'
			},
			'schemeDetails': {
				'trancheSetupDetails': {
					'tranchPaymentDetails': [
						{
							'priority': 1,
							'paymentPercentage': '100',
							'batchStatusToInitiate': 'start',
							'noOfDaysForTranchInvoiceGeneration': '1',
							'canidateStatusToInitiate': 'certification',
							'percentageSentToUnqualifiedCandidates': '0'
						}
					],
					'kaushalMelaPaymentRequired': true,
					'kaushalMelaPaymentDetails': {
						'payment': '75',
						'noOfDays': {
							'feedNotToPayIfKaushalMelaNotDone': 68,
							'withinSscToApprove': 67,
							'untilTcCanEditBatch': 7
						}
					},
					'noOfTranches': 3
				},
				'creationDetails': {
					'schemeName': 'PMKVY',
					'description': 'Proident ex laborum numquam sed iste voluptas quo voluptatem ut iste maiores officia dolore',
					'schemeIcon': 'Scheme Admin/SchemeAdmin_000044/logoURL/bc69bcd7-ee5b-4290-8827-cc7f752056b6_Screenshot from 2018-11-30 17-31-47.png',
					'requiredDocuments': 'Nihil deleniti quo nisi quas Nam error dolor magna',
					'prequisite': 'Qui magna enim impedit sunt veniam dolorem debitis nihil nemo voluptas',
					'religionsCatered': [
						{
							'religionId': '1',
							'religionName': 'Hindu'
						},
						{
							'religionId': '2',
							'religionName': 'Muslim'
						},
						{
							'religionId': '3',
							'religionName': 'Christian'
						}
					],
					'categoriesCatered': [
						{
							'categoryId': '1',
							'categoryName': 'General'
						}
					],
					'gendersCatered': [
						{
							'genderId': '1',
							'genderName': 'Male'
						},
						{
							'genderId': '2',
							'genderName': 'Female'
						}
					],
					'consideredDisabilities': [
						{
							'disabilityId': '1',
							'disabilityName': 'None'
						}
					]
				},
				'trainingPartnerDetails': {
					'tpTypeDetails': [
						{
							'tcRegistrationProcess': {
								'registrationProcessType': 'AA',
								'amount': 0
							},
							'moduleType': {
								'id': '1',
								'name': 'program'
							},
							'tpType': {
								'id': '1',
								'name': 'Gov'
							},
							'schemeRegistrationFeeDetails': {
								'registrationFeetype': 'yes',
								'amount': 0
							},
							'additionalDocumentsRequired': 'yes'
						}
					],
					'targetAllocationRequired': true,
					'targetAllocationDetails': {
						'allocationProhibitionFor': [
							{
								'name': 'TP/PIA/SPIA',
								'id': '1'
							},
							{
								'name': 'TC',
								'id': '2'
							}
						],
						'allocatedAtLevel': [
							{
								'name': 'Sector',
								'id': '1'
							},
							{
								'name': 'jobrole/QP',
								'id': '2'
							},
							{
								'name': 'Location',
								'id': '3'
							}
						]
					}
				},
				'geographyRules': [
					{
						'state': {
							'id': '29',
							'name': 'KARNATAKA'
						},
						'districts': [
							{
								'id': '544',
								'name': 'MANDYA'
							},
							{
								'id': '545',
								'name': 'MYSURU'
							}
						],
						'aadhaarMandatory': true,
						'specialStateStudentWithoutAadhaarAllowed': false
					}
				],
				'trainingCenterLifecycleDetails': {
					'batchCreationDetails': {
						'noOfCandidatesEnrolled': '75',
						'sscAprovalRequired': true,
						'noOfDays': {
							'createOrUpdateFromBatchStart': 59,
							'editedFromBatchStart': 13,
							'batchEnd': 20,
							'withinSscToApprove': 25,
							'untilTcCanEditBatch': 83
						}
					},
					'batchStartDetails': {
						'noOfDays': {
							'priorAssessmentDatesCanBeRaised': 68,
							'withinBatchShouldBeStartedFromCreation': 39,
							'priorBatchExtensionRequestCanBeRaised': 82,
							'withinSscHasToApproveBatch': 44,
							'withinSscHasToApproveRejectChangeRequest': 94,
							'withinBatchCancellationReqPriorAsssessmentDate': 19,
							'sscHasToAssignAaForAssessment': 47,
							'aaHasToAssignAssessorForAssessment': 99,
							'percentfromBatchStartForMinPercentAttendance': 25
						}
					},
					'assessmentDetails': {
						'minimumNoOfCandidatesForAssessmentFeePayment': '68',
						'percentageOfAssessmentFeeReductionInCaseDelay': '30',
						'assessmentFeeForJobRoles': '58',
						'percentageOfBatchesSeemsToHaveAnIssue': '75',
						'percentageDefinedChangeOfAa': '100',
						'noOfChancesSscCanChangeAa': '28',
						'noOfChancesAaCanChangeAssessor': '43',
						'durationOfSuspensionTillTcBeBlocked': '83',
						'noOfDays': {
							'withinAaShouldBeAssigned': 99,
							'withinAssessmentDateCanBeChanged': 3,
							'withinSscValidatesTheMarks': 47,
							'withinSsCToBeProvidedWithAssessmentFee': 8,
							'withinAaMustAssignAssessor': 24,
							'withinAaValidatesMarks': 2,
							'withinAssessorShouldRespondOnAssigningForAssessment': 17,
							'withinReAssessmentReqCanbeRaisedFromAssessmentDate': 28,
							'priorAssessmentDateChangeReqFromCurrentAssessmentDate': 34,
							'priorAaChangeReqFromCurrentAssessmentDate': 47,
							'withinAssessorCanBeChanged': 84
						}
					},
					'certificationDetails': {
						'noOfDays': {
							'withinAssessementDate': 30
						},
						'durationOfSuspensionTillTcBlocked': '3',
						'percentageOfDiscountForTranches': '28'
					},
					'placementDetails': {
						'percentageOfCanidatesShouldBePlaced': 54,
						'percentageOfCandidatesShouldBePlacedAsWaged': 47,
						'percentageOfCandidatesToBeAssignedToSsc': 74,
						'percentageIncentiveAmount': 83,
						'percentageForPlacementConsideration': 46,
						'noOfMonthsForContinuityOfEmployment': 14,
						'noOfMonthsForIncentiveDisburesement': 79,
						'postPlacementSupport': {
							'status': false
						},
						'placementMonthDetails': {
							'withinDistrict': {
								'men': '1',
								'women': '3'
							},
							'outsideDistrict': {
								'men': '1',
								'women': '3'
							}
						},
						'noOfDays': {
							'canidateShouldBePlacedFromCertificationDate': 8,
							'withinPlacementDetailsHasToUploadByPo': 26,
							'withinTpOrTcHasToApprovePlacementDetails': 47,
							'withinPmuhasToTakeAction': 12,
							'withinNsdcHasToTakeAction': 73
						}
					}
				},
				'trainingPartnerSubSchemeRules': {
					'additionTrancheDetails': [
						{
							'trainingPartnerType': '7',
							'allowAdvanceAmountPayment': 'true',
							'paymentPercentage': 9,
							'noOfDaysForInvoice': 6,
							'batchStatusToinitiatePayment': 'start'
						}
					]
				}
			},
			'retirementDetails': {
				'status': 'new',
				'comments': 'NA'
			},
			'activityLog': {
				'createdBy': {
					'userName': 'NSDCADMIN_000006',
					'role': 'NSDC Admin'
				},
				'CreatedOn': '2018-12-03T05:46:20.890Z',
				'LastModifiedDate': '2018-12-03T05:50:42.009Z'
			},
			'qpInstantiationDetails': [],
			'active': true,
			'expired': false,
			'schemeId': 'SCHEME-iphr0',
			'status': 'approved',
			'basicDetails': {
				'scheme': {
					'id': '1',
					'name': 'PMKVY'
				},
				'schemeName': '',
				'schemeType': {
					'id': '1',
					'name': 'CSCM'
				},
				'trainingType': {
					'id': '2',
					'name': 'STT'
				},
				'paymentAdviceRequired': true
			},
			'workflowDetails': {
				'assigneeDetails': [
					{
						'comments': 'NA',
						'userName': 'NSDCADMIN_000006',
						'role': 'NSDC Admin',
						'firstName': 'NSDC Admin',
						'lastName': 'efg'
					},
					{
						'comments': 'NA',
						'role': 'Business Owner',
						'userName': 'BO_000015',
						'firstName': '',
						'lastName': ''
					},
					{
						'comments': 'NA',
						'role': 'Scheme Owner',
						'userName': 'SO_000001',
						'firstName': '',
						'lastName': ''
					},
					{
						'comments': 'NA',
						'role': 'Scheme Admin',
						'userName': 'SchemeAdmin_000044',
						'firstName': '',
						'lastName': ''
					}
				],
				'submitted': {
					'to': {
						'comments': 'NA',
						'role': 'Business Owner',
						'userName': 'BO_000015',
						'firstName': '',
						'lastName': ''
					},
					'by': {
						'comments': 'NA',
						'userName': 'SO_000001',
						'role': 'Scheme Owner',
						'firstName': ''
					}
				}
			},
			'createdVersion': 1,
			'__v': 3,
			'rulesEffectiveFrom': '2018-12-03T00:00:00.000Z',
			'version': 2
		}
	]
}
/**
 * Project  Model Data
 */
export const PMKVY_STT_SP_PROJECT_MODEL = Object.freeze({
	PROJECT_MODEL: [{ name: 'RTD', value: 'RTD' },
	{ name: 'Non-RTD', value: 'Non-RTD' }],
});
