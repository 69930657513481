// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// const localHost = `http://13.234.229.108:800`;
// const uahost = `http://${window.ocation.hostname}`;
// const testHostName = `http://13.126.105.23`;
const testHostName = `http://13.234.229.108`;
const uat = `http://13.234.229.108`;

const dockerHost = `http://services.ekaushal.com`;
const localHost = `http://${window.location.hostname}`;
const currentHost = `http://13.234.229.108`;
const functionalities = {
	payments: {
                suspendPayment: false,
		TPPayment: true,
                SchemePayment: false, 
		SelectSchemePayment: false,
		Pmkvy40Payment: true,
                TCPayment: true,
	},
	payment: false,
	console: true,
	formTest: false,
	uploadFromServer: false,
	showUserNameAndPassword: true,
        disableAadharChecksumVerification: false,
        form : {
        disallowNameConflict: true,
    }
};
const clientOrigin = window.location.origin;
export const environment = Object.freeze({
	production: false,
	userServiceUrl: currentHost ,
	geographyServiceUrl: currentHost ,
	ToTServiceURL: currentHost ,
	pdfGenerate: currentHost ,
	batchServiceUrl: currentHost ,
	nsdcCommonServiceUrl: currentHost ,
	sqaServiceUrl: currentHost ,
	schemeServiceURL: currentHost ,
	trainingPartnerServiceURL: currentHost ,
	smartServiceURL: currentHost ,
	documentServiceUrl: currentHost ,
	// documentServiceUrl: currentHost ,
	authServiceURL: currentHost ,
	paymentService: currentHost ,
	rozgarMelaUrl: currentHost ,
	pmkvycmServiceUrl: currentHost,
	aadharServiceURL : currentHost ,
        utilityService : currentHost ,
        excelService: currentHost ,
        userManagementServiceUrl: currentHost ,
	userManagmentServiceUrl: currentHost ,
	learningServiceUrl: currentHost ,
	sipReportingServiceUrl: currentHost ,
	sidServiceUrl: currentHost ,
	loginService: currentHost ,
	'google-reCaptcha-data-site-key':
		'6Le3uyAUAAAAAFIk054LtGL8o0LI3uiUz3cphqVw',
	amazonS3: 'http',
	csrf_url: `http://13.234.229.108/api/user/v1` ,
	clientHost: clientOrigin,
	functionalities
});



