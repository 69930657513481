import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { ApiService } from 'projects/services/src/app/API/api.service';
import { LoginService } from 'projects/services/src/app/API/login/login.service';
import { RegistrationService } from 'projects/services/src/app/API/registration.service';
import { UserService } from 'projects/services/src/app/API/user.service';
import { CurrentUserService } from 'projects/services/src/app/current-user.service';
import { StorageService } from 'projects/services/src/app/storage.service';
import { ValidationService } from 'projects/services/src/app/validation.service';
declare var swal: any;
declare var toastr: any;
@Component({
  selector: 'app-duplicate-spoc-change',
  templateUrl: './duplicate-spoc-change.component.html',
  styleUrls: ['./duplicate-spoc-change.component.css']
})
export class DuplicateSpocChangeComponent implements OnInit {
  spocCreationForm: FormGroup;
  verifyCredentialsForm: FormGroup;
  verifyPasswordForm: FormGroup;
  show = false;
  changeStarted = false
  verifyCredDisplay = false;
  token;
  userType;
  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private currentUserService: CurrentUserService,
    private registrationService: RegistrationService,
    private apiService: ApiService,
    private storageService: StorageService,
    private loginService: LoginService
  ) {
    this.spocCreationForm = this.formBuilder.group(
      {
        'firstName': ['', [Validators.required, Validators.pattern('^[A-Za-z][A-Za-z \s]*$'), Validators.maxLength(200)]],
        'email': ['', [Validators.required, ValidationService.emailV]],
        'mobileNumber': ['', Validators.compose([Validators.required,
        Validators.pattern('^[4-9][0-9]{9}$')])],
      });
    this.verifyCredentialsForm = this.formBuilder.group(
      {
        'userName': ['', [Validators.required]],
        'password': ['', [Validators.required]]
      });
    this.verifyPasswordForm = this.formBuilder.group(
      {
        'mobileOTP': ['', [Validators.required, Validators.pattern('[0-9]{6}'), Validators.maxLength(6)]],
        'emailOTP': ['', [Validators.required, Validators.pattern('[0-9]{6}'), Validators.maxLength(6)]]
      });
    this.show = false;

  }

  ngOnInit() {
    this.duplicateCheck();
    this.getLoggedInUser();
  }

  duplicateCheck() {
    this.userService.checkSPOCDuplicate().subscribe(res => {
      if (!res.isDuplicateSPOC) {
        swal('Not Authorized');
        this.router.navigate(['/login']);
      }
    }, () => {
      this.router.navigate(['/login']);
    })
  }
  getLoggedInUser() {
    this.token = this.currentUserService.decodeToken();
    this.userType = `Enter Your ${this.token.role} ID`
  }

  showPassClick() {
    this.show = !this.show;
  }
  generateOtp() {
    // Verifying Cerdential
    if (this.token.role == 'Training Partner') {
      this.generateOtpTP()
      return;
    }
    const verifyCredentialUrl = environment.userServiceUrl + '/api/user/validate';
    const cred = Object.assign({}, this.verifyCredentialsForm.value);
    cred.password = btoa(cred.password);

    const Credentials: { userName: string, password: string } = cred;
    if (cred.userName != this.token.userName) {
      swal('Please enter valid userName')
      return;
    }
    this.apiService.post(verifyCredentialUrl, Credentials).subscribe(
      res => {
        if (res) {
          if (res.message === 'Success') {
            const OTPData: { 'phoneNumber': number, 'email': string } = {
              'phoneNumber': Number(this.spocCreationForm.value.mobileNumber),
              'email': this.spocCreationForm.value.email
            };
            this.registrationService.generateOTPForSPOCChange('TCSpoc', OTPData)
              .subscribe(
                response => {
                  this.verifyCredDisplay = true;
                });
          }
        }
      });
  }
  verifyOTP() {
    if (this.token.role == 'Training Partner') {
      this.verifyOTPTP()
      return;
    }
    const createTrainingCentreApi = environment.userServiceUrl + '/api/user/v1/trainingcentre/spoc/change';
    const userAccountData = {
      'mobileOtp': Number(this.verifyPasswordForm.value.mobileOTP),
      'emailOtp': Number(this.verifyPasswordForm.value.emailOTP),
      'firstName': this.spocCreationForm.value.firstName,
      'email': this.spocCreationForm.value.email,
      'mobileNumber': Number(this.spocCreationForm.value.mobileNumber),
      'userName': this.verifyCredentialsForm.value.userName
    };
    this.registrationService.tcSpocChange(userAccountData)
      .subscribe(res => {
        Helpers.closeModal('spoc-popup');
        this.verifyCredentialsForm.reset();
        this.verifyPasswordForm.reset();
        this.verifyCredentialsForm.reset();
        swal('SPOC details Updated Successfully, Please login again');
        this.router.navigate(['/login']);
      });
  }

 async generateOtpTP() {
    // Verifying Cerdential
    if (this.verifyCredentialsForm.valid) {
      const verifyCredentialUrl = environment.userServiceUrl + '/api/user/validate';
      const cred = Object.assign({}, this.verifyCredentialsForm.value);
      const publicKey = await this.loginService.getPublicKey().toPromise();
			const encrypted = await this.loginService.encryptPassword(publicKey.publicKey, cred.password,publicKey.secret);
			cred.password = encrypted;
      const Credentials: { userName: string, password: string } = cred;
      if (cred.userName != this.token.userName) { 
        swal('Please enter valid userName')
        return;
      }
      this.registrationService.validateForSpocChange(Credentials).subscribe(
        res => {
          if (res) {
            if (res.message === 'Success') {
              const OTPData: { 'phoneNumber': number, 'email': string } = {
                'phoneNumber': Number(this.spocCreationForm.value.mobileNumber),
                'email': this.spocCreationForm.value.email
              };
              this.registrationService.generateOTPForSPOCChange('Training Partner', OTPData)
                .subscribe(
                  response => {
                    this.verifyCredDisplay = true;
                  });
            }
          }
        });
    } else {
      toastr.error('Please fill valid data!');
      Helpers.markFormGroupTouched(this.verifyCredentialsForm);
    }
  }
  verifyOTPTP() {
    if (this.verifyPasswordForm.valid) {
      const createTrainingPartnerApi = environment.userServiceUrl + '/api/user/v1/tp/verifySpocChange';
      const userAccountData = {
        'mobileOtp': this.verifyPasswordForm.value.mobileOTP,
        'emailOtp': this.verifyPasswordForm.value.emailOTP,
        'newSpoc': {
          'firstName': this.spocCreationForm.value.firstName,
          'email': this.spocCreationForm.value.email,
          'mobileNumber': Number(this.spocCreationForm.value.mobileNumber)
        }
      };
      this.registrationService.changeSPOC(userAccountData)
        .subscribe(res => {
          Helpers.closeModal('spoc-popup');
          this.verifyCredentialsForm.reset();
          this.verifyPasswordForm.reset();
          this.verifyCredentialsForm.reset();
          swal('SPOC details Updated Successfully, Please login again');
          this.router.navigate(['/login']);
        });
    } else {
      toastr.error('Please fill valid data!');
      Helpers.markFormGroupTouched(this.verifyPasswordForm);
    }
  }

  logout() {
    const cred = {
      userName: this.token.userName
    };
    this.loginService.logout(cred).subscribe(res => {
      this.router.navigate(['/login']);
      this.storageService.removeAll();
      this.currentUserService.SetCurrentUserOnLogout();
    }, err => {
      this.router.navigate(['/login']);
      this.storageService.removeAll();
      this.currentUserService.SetCurrentUserOnLogout();
    }
    );
  }
}

