import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslatePipePipe } from "./translation.pipe";

@NgModule({
	declarations: [
		TranslatePipePipe
	],
	imports: [
		CommonModule
	],
	exports: [
		TranslatePipePipe,
	]

})
export class TransLateModule { }
