import { Pipe, PipeTransform } from '@angular/core';
import { TranslateServicePipe } from './translation.service';

@Pipe({
	name: 'translatePipe',
	pure: false
})
export class TranslatePipePipe implements PipeTransform {

	constructor(
		private translate: TranslateServicePipe
	) { }
	transform(value: any, ...args: any[]): any {
		return this.translate.getLang(value);
	}

}