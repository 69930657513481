import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'projects/services/src/app/API/login/login.service';
import { RoutingService } from 'projects/services/src/app/routing.service';
import { StorageService } from 'projects/services/src/app/storage.service';
import { ShareDataService } from 'projects/shared/src/app/shared/share-data.service';
declare var toastr: any;

@Component({
  selector: 'app-cbcmsde-login',
  templateUrl: './cbcmsde-login.component.html',
  styleUrls: ['./cbcmsde-login.component.css']
})
export class CbcmsdeLoginComponent implements OnInit {

  userCredentials: { userName: string, password: string } = { userName: '', password: '' };
  loginForm: FormGroup;
  otpForm: FormGroup;
  contactDetailForm: FormGroup;
  verifyUpdateDetails: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private login: LoginService,
    private storageService: StorageService,
    private sharedService: ShareDataService,
    private routingService: RoutingService,
  ){

    this.otpForm = this.formBuilder.group({
      'mobileOTP': ['', Validators.compose([Validators.pattern('[0-9]{6}'), Validators.required])]
    });
    this.contactDetailForm = this.formBuilder.group({
      'phone': ['', Validators.compose([Validators.required,
      Validators.pattern('^[4-9][0-9]{9}$')])],
    });

  }
  ngOnInit(): void {
    
  }

  getotp() {
    this.login.generateOtp(this.contactDetailForm.get("phone").value).subscribe((res: any) => {
      if (res && res.mobileotp) {
        this.verifyUpdateDetails = true;
      }
    })
  }

  doLogin() {
    this.login.verifyOtp(this.contactDetailForm.get("phone").value, this.otpForm.get("mobileOTP").value).subscribe((res: any) => {
      if (res && res.data) {
        this.getCbcmsdeToken();
        this.sharedService.setData(null)
        this.storageService.setObject('access_token', res.data.authtoken);
        sessionStorage.setItem("mobileNo", JSON.stringify(this.contactDetailForm.get("phone").value));
        // this.routingService.goNext();
        this.router.navigate(['/cbcmsde-dash']);
      }
    }, err => {
      if (err && err.error && err.error.text) {
        toastr.error(err.error.text);
      }
    })
  }

  change() {
    this.verifyUpdateDetails = !this.verifyUpdateDetails;
  }

  getCbcmsdeToken() {
    this.login.getCbcmsdeAuthToken().subscribe((res: any) => {
      localStorage.setItem("cbcmsde-token", JSON.stringify(res.access_token))
    })
  }

  
}
