import { FormGroup, AbstractControl } from '@angular/forms';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { STRING as STRING_CONST } from 'src/app/constant/string';
// STRING

export interface TextFormElement extends Element {
	type: any;
	disabled: boolean;
	label: any;
	value?: any;
	align?: number;
	name: any;
	required?: boolean;
	showAstrisk?: boolean;
	placeholder?: any;
	validationName?: any;
	validationType?: any;
	pattern?: any;
	row: boolean;
	metainfo: any;
	lineAtStart?: boolean;
	lineAtEnd?: boolean;
	onChange?: any;
	onBlur?: any;
	maxFieldLength: number;
	minFieldLength: number;
	charPattern: any;
	silentRequired: boolean;
	metaPlaceholder: any;
	max?: number;
	min?: number;
}

export interface Element {
	type?: any;
	label?: any;
	name?: any;
	disabled?: boolean;
	required?: boolean;
	showAstrisk?: boolean;
	silentRequired?: boolean;
	value?: any;
	labelDiv?: number;
	inputDiv?: number;
	customValidationMessage?: any;
	remove?: boolean;
	order?: number;
	readOnly?: boolean;
	hide?: boolean;
	onChange?: any;
	onBlur?: any;
	markAsUntouched?: boolean;
	forceStyle?: boolean;
	metainfo?: any;
	metaLink?: {
		text: string,
		onClick: () => void;
	};
}

export interface SelectFormElement extends Element {
	type: any;
	label: any;
	disabled: boolean;
	name?: any;
	placeholder?: any;
	value?: any;
	align?: number;
	required?: boolean;
	showAstrisk?: boolean;
	validationName?: any;
	validationType?: any;
	options: { value: any; name: string }[];
	row: boolean;
	metainfo: any;
	lineAtEnd?: boolean;
	lineAtStart?: boolean;
	onChange?: any;
	onBlur?: any;
	multi?: boolean;
}

export interface ICheckboxGroupFormElement extends Element {
	type: any; // 'checkboxGroup';
	label: any;
	disabled: boolean;
	name: any;
	required?: boolean;
	showAstrisk?: boolean;
	value?: any;
	align?: number;
	atLeastChecked?: number; // validation
	validationName?: any;
	validationType?: any;
	onChange?: any;
	checkboxes: { name: any; label: string }[];
	row: boolean;
	lineAtEnd?: boolean;
	lineAtStart?: boolean;
}

interface ICommonStyle {
	labelDiv?: number;
	inputDiv?: number;
	customValidationMessage?: any;
	forceStyle?: boolean;
	remove?: boolean;
	order?: number;
	type?: any;
	disabled?: boolean;
	required?: boolean;
	showAstrisk?: boolean;
	value?: any;
	readOnly?: boolean;
	hide?: boolean;
}
export interface RadioFormElement extends Element {
	options: { value: any; name: string }[];
	displayType: any;
}
interface IRadioOptions extends ICommonOptions {
	displayType?: any;
}
export class Radio implements RadioFormElement {
	type = INPUT_TYPE.RADIO;
	label: any;
	name: any;
	required = false;
	showAstrisk = false;
	value?: any;
	align = 12;
	colDivision = 2;
	validationName?: any;
	validationType?: any;
	onChange?: any;
	disabled: boolean;
	labelDiv: any;
	inputDiv: any;
	displayType = DISPLAY_TYPE.BLOCK;
	options: { value: string, name: string }[];
	constructor(label: string, options: any, opts: IRadioOptions) {
		this.label = label;
		this.options = options;
		this.value = opts.value || this.value;
		this.displayType = opts.displayType || this.displayType;
		this.name = opts.name || toCamelCase(this.label);
		this.validationName = opts.validationName || this.label;
		this.validationName = removeColon(this.validationName);
		this.required = opts.required;
		this.showAstrisk = opts.showAstrisk;
		this.labelDiv = opts.labelDiv;
		this.inputDiv = opts.inputDiv;
		this.validationType = opts.validationType;
		this.onChange = opts.onChange;
	}
}

interface ICheckboxOptions extends ICommonOptions {
	checkboxes: any;
	colDivision: number;
	atLeastChecked?: number;
}

export class CheckBox implements ICheckboxGroupFormElement {
	type = INPUT_TYPE.CHECKBOX;
	label: any;
	name: any;
	required = false;
	showAstrisk = false;
	value?: any;
	align = 12;
	colDivision = 2;
	atLeastChecked?: number; // validation
	validationName?: any;
	validationType?: any;
	onChange?: any;
	disabled: boolean;
	checkboxes: { name: any; label: string }[];
	row = true;
	lineAtEnd = false;
	lineAtStart = false;
	labelDiv = 2;
	inputDiv = 10;
	customValidationMessage: any;
	remove = false;
	order = 1;
	readOnly = false;
	hide = false;
	markAsUntouched = false;
	constructor(label: string, opts: ICheckboxOptions) {
		this.label = label;
		this.value = opts.value || this.value;
		this.name = opts.name || toCamelCase(this.label);
		this.validationName = opts.validationName || this.label;
		this.validationName = removeColon(this.validationName);
		this.required = opts.required;
		this.showAstrisk = opts.showAstrisk;
		this.validationType = opts.validationType;
		this.atLeastChecked = opts.atLeastChecked;
		this.atLeastChecked = this.required ? (this.atLeastChecked || 1) : this.atLeastChecked;
		this.onChange = opts.onChange;
		this.checkboxes = opts.checkboxes.map((checkbox: any) => {
			return {
				label: checkbox.label,
				name: checkbox.name || toCamelCase(checkbox.label)
			};
		});
		this.align = opts.align || this.align;
		this.labelDiv = isPositiveNumber(opts.labelDiv) ? opts.labelDiv : this.labelDiv;
		this.inputDiv = isPositiveNumber(opts.inputDiv) ? opts.inputDiv : this.inputDiv;
		if (this.align === 6 && !opts.forceStyle) {
			this.labelDiv = 4;
			this.inputDiv = 8;
		}
		this.colDivision = opts.colDivision || this.colDivision;
		this.row = typeof opts.row === STRING.BOOLEAN ? opts.row : this.row;
		this.disabled = opts.disabled;
		this.lineAtEnd = opts.lineAtEnd;
		this.lineAtStart = opts.lineAtStart;
		this.customValidationMessage = opts.customValidationMessage;
		this.remove = opts.remove;
		this.order = opts.order || this.order;
		this.readOnly = opts.readOnly;
		this.hide = opts.hide;
		this.markAsUntouched = opts.markAsUntouched;
	}
}

export interface UploadFormElement extends Element {
	type: any;
	label: any;
	value?: any;
	disabled: boolean;
	name: any;
	required: boolean;
	showAstrisk: boolean;
	placeholder: any;
	validationName?: any;
	validationType?: any;
	pattern?: any;
	row: boolean;
	lineAtEnd: boolean;
	lineAtStart: boolean;
	onChange?: any;
	onBlur?: any;
	uploadOnSelect?: boolean;
	documentTemplateUrl?: any;
	isPublicUrl?: boolean;
	maxFiles?: number;
	minFiles?: number;
	viewImage?: boolean,
}

interface IUploadOptions extends ICommonOptions {
	placeholder?: any;
	multi?: boolean;
	maxSize?: number;
	allowedFormats?: string[];
	uploadOnSelect?: boolean;
	documentTemplateUrl?: any;
	isPublicUrl?: boolean;
	categories?: any[];
	isCategorised?: boolean;
	uploadType?: any;
	dropdownDiv?: number;
	api?: any;
	isReviewRequired?: boolean;
	maxFiles?: number;
	minFiles?: number;
	viewImage?: boolean;
}

export class Upload implements UploadFormElement {
	label: any;
	type: any = INPUT_TYPE.UPLOAD;
	value: any = STRING.EMPTY;
	name: any = STRING.EMPTY;
	required: any = false;
	showAstrisk: any = false;
	align: any = 12;
	placeholder: any = STRING.EMPTY;
	validationName?: any;
	validationType: any = VALIDATION_TYPE.FILE;
	pattern: any = STRING.EMPTY;
	onChange: any;
	onBlur: any;
	multi: any = false;
	allowedFormats: string[];
	maxSize: any = 3;
	row: any = true;
	disabled: boolean;
	lineAtEnd: boolean;
	lineAtStart: boolean;
	uploadOnSelect: boolean;
	labelDiv: any = 2;
	inputDiv: any = 10;
	customValidationMessage: any;
	dropdownDiv: any = 0;
	remove: any = false;
	order: any = 1;
	readOnly: any = false;
	hide: any = false;
	forceStyle: any = false;
	__props: any = {};
	documentTemplateUrl: any;
	isCategorised: any = false;
	isPublicUrl: any = false;
	// categories = [];
	private _categories: any = [];
	uploadType: any;
	api: any = `/api/document/v2`;
	formGroup: FormGroup;
	isReviewRequired: any;
	maxFiles?: number;
	minFiles?: number;
	viewImage?: boolean = false;
	set categories(categories: any[]) {
		categories = categories || [];
		let formValue: AbstractControl;
		if (this.formGroup) {
			formValue = this.formGroup.controls[this.name];
		}
		const formattedCategories: any = categories.map((category: any) => {
			return {
				name: category,
				enable: true,
				toString: function () {
					let that: any = this;
					return that.name;
				}
			}
		})
		if (this.formGroup) {
			if (!categories.length) {
				this.__props.validator = formValue.validator;
				formValue.validator = null;
				formValue.disable();
				// this.__props.required = this.required;
				// this.required = false
			} else {
				formValue.validator = this.__props.validator;
				formValue.enable();
				// this.required = this.__props.hasOwnProperty('required') ? this.__props.required : this.required;
			}
		}
		Helpers.copyArray(this._categories, formattedCategories);
		// Helpers.copyArray(this._categories, categories)
	}
	get categories() {
		return this._categories;
	}
	constructor(label: string, opts: IUploadOptions) {
		this.label = label;
		this.uploadType = opts.uploadType;
		this.value = opts.value || this.value;
		this.name = opts.name || toCamelCase(this.label);
		this.validationName = opts.validationName || this.label;
		this.validationName = removeColon(this.validationName);
		const defaultName = removeColon(this.label);
		this.required = opts.required;
		this.showAstrisk = opts.showAstrisk;
		this.placeholder = opts.placeholder || `Choose ${defaultName}`;
		this.validationType = opts.validationType;
		// this.pattern = opts.pattern;
		this.dropdownDiv = opts.dropdownDiv || this.dropdownDiv;
		this.onChange = opts.onChange;
		this.onBlur = opts.onBlur;
		this.align = opts.align || this.align;
		this.labelDiv = isPositiveNumber(opts.labelDiv) ? opts.labelDiv : this.labelDiv;
		this.inputDiv = isPositiveNumber(opts.inputDiv) ? opts.inputDiv : this.inputDiv;
		this.forceStyle = opts.forceStyle;
		this.categories = opts.categories;
		if (this.align === 6 && !this.forceStyle) {
			this.labelDiv = 4;
			this.inputDiv = 8;
		}
		this.multi = opts.multi || this.multi;
		this.isReviewRequired = opts.isReviewRequired || this.isReviewRequired;
		this.maxSize = opts.maxSize || this.maxSize;
		this.allowedFormats = opts.allowedFormats || [
			STRING.PDF,
			STRING.JPEG,
			STRING.PNG,
			STRING.JPG,
			STRING.DOCX,
			STRING.XLSX,
		];
		this.row = typeof opts.row === STRING.BOOLEAN ? opts.row : this.row;
		this.disabled = opts.disabled;
		this.lineAtEnd = opts.lineAtEnd;
		this.lineAtStart = opts.lineAtStart;
		this.uploadOnSelect = opts.uploadOnSelect;
		this.viewImage = opts.viewImage;
		this.customValidationMessage = this.customValidationMessage;
		this.remove = opts.remove;
		this.order = opts.order || this.order;
		this.readOnly = opts.readOnly;
		this.hide = opts.hide;
		this.documentTemplateUrl = opts.documentTemplateUrl;
		this.isPublicUrl = opts.isPublicUrl;
		this.api = opts.api || this.api;
		this.isCategorised = opts.isCategorised
		this.maxFiles = opts.maxFiles;
		this.minFiles = opts.minFiles;
	}
}

interface IDurationDate {
	name: any;
	placeholder: any;
	value?: any;
	id?: any;
	hideEndDate?: boolean
}

interface IDateValidation {
	endDate?: Date;
	startDate?: Date;
}

export class DurationDate implements IDurationDate, IDateValidation {
	name: any;
	placeholder: any;
	value?: any;
	id?: any;
	startDate: Date;
	endDate: Date;
	constructor(opts: IDurationDate, validation: IDateValidation = {}) {
		Object.assign(this, opts);
		this.id = Helpers.defineCalendar(validation.startDate, validation.endDate, {
			// defaultViewDate : ( () => {
			// 	const date = new Date();
			// 	date.setDate(date.getDate() - 1);
			// 	return date;
			// } )()
		});
	}
}

interface ITDuration extends ICommonOptions {
	maxTenureInYears?: number;
	minTenureInYears?: number;
	minStartDate?: Date;
	maxEndDate?: Date;
	maxStartDate?: Date;
	minEndDate?: Date;
	metainfo?: any;
	metainfoColor?: boolean;
	fromDateRequired?: boolean;
	toDateRequired?: boolean;
	onFromDateChange?: () => void;
	onToDateChange?: () => void;
	disableNotAllowed?: boolean;
	// silentRequired?: boolean;
}
export class TimeDuration {
	type = INPUT_TYPE.DATE_DURATION;
	name: any;
	label: any;
	required = false;
	showAstrisk = false;
	onChange: any;
	elements: IDurationDate[] = [];
	align?: number;
	validationName: any;
	validationType: any;
	row = true;
	disabled: boolean;
	lineAtStart: boolean;
	lintAtEnd: boolean;
	onBlur?: any;
	maxTenureInYears = 0;
	minTenureInYears = 0;
	minStartDate: any = 0;
	maxEndDate: any = 0;
	maxStartDate: any = 0;
	minEndDate: any = 0;
	labelDiv = 2;
	inputDiv = 5;
	disableStartDate = false; // Arpitha
	disableEndDate = false; // Arpitha
	customValidationMessage: any;
	metainfo: any;
	forceStyle = false;
	remove = false;
	order = 1;
	readOnly = false;
	hide = false;
	value: any;
	metainfoColor?: boolean
	private _fromDateChangeFncs = [];
	private _toDateChangeFncs = [];
	silentRequired = false;
	disableNotAllowed = false;
	fromDateRequired = true;
	toDateRequired = true;
	set onFromDateChange(fn) {
		this._fromDateChangeFncs.push(fn);
	}
	set onToDateChange(fn) {
		this._toDateChangeFncs.push(fn);
	}
	private run(key, param?: any) {
		for (const fn of this[key]) {
			if (typeof fn === STRING.FUNCTION) {
				fn(param);
				// fn(this._filter);
			}
		}
	}
	fromDateChanged() {
		this.run('_fromDateChangeFncs');
	}
	toDateChanged() {
		this.run('_toDateChangeFncs');
	}
	onInit() {
		this.elements[0] = new DurationDate(this.elements[0]);
		this.elements[1] = new DurationDate(this.elements[1]);
		if (this.disableNotAllowed) {
			this.elements[0] = new DurationDate(this.elements[0], {
				startDate: this.minStartDate,
				endDate: this.maxStartDate
			});
			this.elements[1] = new DurationDate(this.elements[1], {
				startDate: this.minEndDate,
				endDate: this.maxEndDate
			});
		}
	}
	constructor(label: string, elements: IDurationDate[], opts: ITDuration) {
		this.label = label;
		this.validationName = opts.validationName || label;
		this.validationName = removeColon(this.validationName);
		this.validationType = opts.validationType;
		this.name = opts.name || toCamelCase(this.label);
		this.required = opts.required;
		this.showAstrisk = opts.showAstrisk;
		this.elements = elements;
		this.value = opts.value;
		this.minTenureInYears = opts.minTenureInYears;
		this.onFromDateChange = opts.onFromDateChange;
		this.onToDateChange = opts.onToDateChange;
		this.metainfoColor = opts.metainfoColor;
		this.disableNotAllowed = opts.disableNotAllowed;
		elements[0] = new DurationDate(elements[0]);
		elements[1] = new DurationDate(elements[1]);
		if (this.disableNotAllowed) {
			elements[0] = new DurationDate(elements[0], {
				startDate: opts.minStartDate,
				endDate: opts.maxStartDate
			});
			elements[1] = new DurationDate(elements[1], {
				startDate: opts.minEndDate,
				endDate: opts.maxEndDate
			});
		}
		this.onChange = opts.onChange;
		this.onBlur = opts.onBlur;
		this.align = opts.align || this.align;
		this.labelDiv = isPositiveNumber(opts.labelDiv) ? opts.labelDiv : this.labelDiv;
		this.inputDiv = isPositiveNumber(opts.inputDiv) ? opts.inputDiv : this.inputDiv;
		if (this.align === 6 && !opts.forceStyle) {
			this.labelDiv = 4;
			this.inputDiv = 4;
		}
		this.row = typeof opts.row === STRING.BOOLEAN ? opts.row : this.row;
		this.disabled = opts.disabled;
		this.lineAtStart = opts.lineAtStart;
		this.lintAtEnd = opts.lineAtEnd;
		this.maxTenureInYears = opts.maxTenureInYears;
		this.minStartDate = opts.minStartDate;
		this.maxEndDate = opts.maxEndDate;
		this.maxStartDate = opts.maxStartDate;
		this.minEndDate = opts.minEndDate;
		this.customValidationMessage = opts.customValidationMessage;
		this.metainfo = opts.metainfo;
		this.forceStyle = opts.forceStyle || this.forceStyle;
		this.remove = opts.remove;
		this.order = opts.order || this.order;
		this.readOnly = opts.readOnly;
		this.hide = opts.hide;
		this.silentRequired = opts.silentRequired;
		this.fromDateRequired = (opts.fromDateRequired === false) ? false : true;
		this.toDateRequired = opts.toDateRequired === false ? false : true;
		this.disableStartDate = !!opts.disableStartDate;
		this.disableEndDate = !!opts.disableEndDate
	}
}

interface ICommonOptions extends ICommonStyle {
	validationName?: any;
	validationType?: any;
	required?: boolean;
	showAstrisk?: boolean;
	silentRequired?: boolean;
	name?: any;
	value?: any;
	onChange?: any;
	align?: number;
	placeholder?: any;
	row?: boolean;
	disabled?: boolean;
	disableStartDate?: boolean; // Arpitha
	disableEndDate?: boolean; // Arpitha
	lineAtStart?: boolean;
	lineAtEnd?: boolean;
	onBlur?: any;
	markAsUntouched?: boolean;
	metainfo?: any;
	metaLink?: {
		text: string,
		onClick: () => void;
	};
}

interface ITextOptions extends ICommonOptions, IFieldValidaton {
	pattern?: any;
	metainfo?: any;
	metaPlaceholder?: any;
	max?: number;
	min?: number;
}

interface IFieldValidaton {
	maxFieldLength?: number;
	minFieldLength?: number;
	charAllowedPattern?: any;
}

export class Text implements TextFormElement {
	type: string = INPUT_TYPE.TEXT;
	name: any;
	label: any;
	value = STRING.EMPTY;
	required: boolean;
	showAstrisk?: boolean;
	align?: number;
	placeholder: any;
	validationName: any;
	validationType: any;
	pattern: any;
	onChange: any;
	onBlur: any;
	row = true;
	metainfo: any;
	disabled: boolean;
	lineAtStart: boolean;
	lineAtEnd: boolean;
	maxFieldLength = -1;
	minFieldLength = -1;
	charPattern: any;
	silentRequired: boolean;
	metaPlaceholder: any;
	labelDiv = 2;
	inputDiv = 10;
	customValidationMessage: any;
	max: number;
	min: number;
	remove = false;
	order = 1;
	readOnly = false;
	hide = false;
	metaLink: {
		text: string,
		onClick: () => void;
	};
	constructor(label: string, opts: ITextOptions) {
		this.label = label;
		this.validationName = opts.validationName || label;
		this.validationName = removeColon(this.validationName);
		this.name = opts.name || toCamelCase(this.label);
		const defaultName = removeColon(this.validationName);
		this.required = opts.required;
		this.showAstrisk = opts.showAstrisk;
		this.placeholder = opts.placeholder || `Enter ${defaultName}`;
		this.validationType = opts.validationType;
		this.pattern = opts.pattern;
		this.onChange = opts.onChange;
		this.onBlur = opts.onBlur;
		this.value = opts.value || this.value;
		this.align = opts.align || this.align;
		this.labelDiv = isPositiveNumber(opts.labelDiv) ? opts.labelDiv : this.labelDiv;
		this.inputDiv = isPositiveNumber(opts.inputDiv) ? opts.inputDiv : this.inputDiv;
		if (this.align === 6 && !opts.forceStyle) {
			this.labelDiv = 4;
			this.inputDiv = 8;
		}
		this.row = typeof opts.row === STRING.BOOLEAN ? opts.row : this.row;
		this.metainfo = opts.metainfo;
		this.disabled = opts.disabled;
		this.lineAtStart = opts.lineAtStart;
		this.lineAtEnd = opts.lineAtEnd;
		this.maxFieldLength = opts.maxFieldLength || this.maxFieldLength;
		this.minFieldLength = opts.minFieldLength || this.minFieldLength;
		this.charPattern = opts.charAllowedPattern;
		this.silentRequired = opts.silentRequired;
		this.metaPlaceholder = opts.metaPlaceholder;
		this.customValidationMessage = opts.customValidationMessage;
		this.max = opts.max;
		this.min = opts.min;
		if (this.max || this.min) {
			this.charPattern = this.charPattern || '[0-9.]';
		}
		this.remove = opts.remove;
		this.order = opts.order || this.order;
		this.readOnly = opts.readOnly;
		this.hide = opts.hide;
		this.metaLink = opts.metaLink;
	}
}

export class SimpleText implements TextFormElement {
	type: string = INPUT_TYPE.SIMPLE_TEXT;
	name: any;
	label: any;
	value = STRING.EMPTY;
	required: boolean;
	showAstrisk?: boolean;
	align?: number;
	placeholder: any;
	validationName: any;
	validationType: any;
	pattern: any;
	onChange: any;
	onBlur: any;
	row = true;
	metainfo: any;
	disabled: boolean;
	lineAtStart: boolean;
	lineAtEnd: boolean;
	maxFieldLength = -1;
	minFieldLength = -1;
	charPattern: any;
	silentRequired: boolean;
	metaPlaceholder: any;
	labelDiv = 2;
	inputDiv = 10;
	customValidationMessage: any;
	max: number;
	min: number;
	remove = false;
	order = 1;
	readOnly = false;
	hide = false;
	metaLink: {
		text: string,
		onClick: () => void;
	};
	constructor(label: string, opts: ITextOptions) {
		this.label = label;
		this.validationName = opts.validationName || label;
		this.validationName = removeColon(this.validationName);
		this.name = opts.name || toCamelCase(this.label);
		const defaultName = removeColon(this.validationName);
		this.required = opts.required;
		this.showAstrisk = opts.showAstrisk;
		this.placeholder = opts.placeholder || `Enter ${defaultName}`;
		this.validationType = opts.validationType;
		this.pattern = opts.pattern;
		this.onChange = opts.onChange;
		this.onBlur = opts.onBlur;
		this.value = opts.value || this.value;
		this.align = opts.align || this.align;
		this.labelDiv = isPositiveNumber(opts.labelDiv) ? opts.labelDiv : this.labelDiv;
		this.inputDiv = isPositiveNumber(opts.inputDiv) ? opts.inputDiv : this.inputDiv;
		if (this.align === 6 && !opts.forceStyle) {
			this.labelDiv = 4;
			this.inputDiv = 8;
		}
		this.row = typeof opts.row === STRING.BOOLEAN ? opts.row : this.row;
		this.metainfo = opts.metainfo;
		this.disabled = opts.disabled;
		this.lineAtStart = opts.lineAtStart;
		this.lineAtEnd = opts.lineAtEnd;
		this.maxFieldLength = opts.maxFieldLength || this.maxFieldLength;
		this.minFieldLength = opts.minFieldLength || this.minFieldLength;
		this.charPattern = opts.charAllowedPattern;
		this.silentRequired = opts.silentRequired;
		this.metaPlaceholder = opts.metaPlaceholder;
		this.customValidationMessage = opts.customValidationMessage;
		this.max = opts.max;
		this.min = opts.min;
		if (this.max || this.min) {
			this.charPattern = this.charPattern || '[0-9.]';
		}
		this.remove = opts.remove;
		this.order = opts.order || this.order;
		this.readOnly = opts.readOnly;
		this.hide = opts.hide;
		this.metaLink = opts.metaLink;
	}
}

export class Aadhar extends Text {
	type: any;
	constructor(label, opts: ITextOptions) {
		super(label, opts);
		this.type = INPUT_TYPE.AADHAR;
		this.charPattern = '[0-9]';
	}
}

export class GeoLocation extends Text {
	type: any;
	constructor(label, opts: ITextOptions) {
		super(label, opts);
		this.type = INPUT_TYPE.GEO_LOCATION;
		// this.charPattern = '[0-9]';
	}
}

interface IDDate extends Element {
	align?: number;
	name?: any;
	placeholder?: any;
	validationName?: any;
	validationType?: any;
	row?: boolean;
	metainfo?: any;
	lineAtStart?: boolean;
	lineAtEnd?: boolean;
	onChange?: any;
	onBlur?: any;
	silentRequired?: boolean;
	metaPlaceholder?: any;
	minDate?: Date;
	maxDate?: Date;
	id?: any;
	emitInDateFormat?: boolean;
}

interface IDDateOption extends ICommonOptions {
	minDate?: Date;
	maxDate?: Date;
	label?: any;
	align?: number;
	name?: any;
	placeholder?: any;
	validationName?: any;
	validationType?: any;
	row?: boolean;
	metainfo?: any;
	lineAtStart?: boolean;
	lineAtEnd?: boolean;
	onChange?: any;
	onBlur?: any;
	silentRequired?: boolean;
	metaPlaceholder?: any;
	id?: any;
	emitInDateFormat?: boolean;
}


export class DDate implements IDDate {
	type: any;
	name: any;
	label: any;
	value = STRING.EMPTY;
	required: boolean;
	showAstrisk: boolean;
	align?: number;
	placeholder: any;
	validationName: any;
	validationType: any;
	pattern: any;
	onChange: any;
	onBlur: any;
	row = true;
	metainfo: any;
	disabled: boolean;
	lineAtStart: boolean;
	lineAtEnd: boolean;
	maxFieldLength = -1;
	minFieldLength = -1;
	silentRequired: boolean;
	metaPlaceholder: any;
	labelDiv = 2;
	inputDiv = 10;
	customValidationMessage: any;
	remove = false;
	order = 1;
	maxDate: Date;
	minDate: Date;
	id: any;
	readOnly = false;
	hide = false;
	emitInDateFormat = true;
	constructor(label: string, opts: IDDateOption) {
		this.label = label || STRING.EMPTY;
		Object.assign(this, opts);
		this.validationName = opts.validationName || label;
		this.validationName = removeColon(this.validationName);
		this.name = opts.name || toCamelCase(this.label);
		this.id = Helpers.defineCalendar(this.minDate, this.maxDate);
		this.type = INPUT_TYPE.DATE;
		const defaultName = removeColon(this.label);
		this.placeholder = this.placeholder || `Select ${defaultName}`;
		this.hide = opts.hide;
		this.emitInDateFormat = opts.emitInDateFormat;
		if (this.align === 6 && !opts.forceStyle) {
			this.labelDiv = 4;
			this.inputDiv = 8;
		}
	}

	onInit() {
		this.id = Helpers.defineCalendar(this.minDate, this.maxDate);
	}
}

interface IInputGroup extends Element {
	align?: number;
	validationName?: any;
	row?: boolean;
	lineAtStart?: boolean;
	lineAtEnd?: boolean;
	silentRequired?: boolean;
	inputs: any[];
}

interface IInputGroupOption extends ICommonOptions {
	label?: any;
	align?: number;
	validationName?: any;
	row?: boolean;
	lineAtStart?: boolean;
	lineAtEnd?: boolean;
	silentRequired?: boolean;
	inputs: any[];
}

export class InputGroup implements IInputGroup {
	DEFAULT = {
		labelDiv: 2,
		inputDiv: 10
	};
	label?: any;
	type?: any;
	align?: number;
	name?: any;
	validationName?: any;
	row?: boolean;
	lineAtStart?: boolean;
	lineAtEnd?: boolean;
	onChange?: any;
	onBlur?: any;
	hide = false;
	silentRequired?: boolean;
	inputs = [];
	labelDiv: number;
	inputDiv: number;
	required?: boolean;
	showAstrisk?: boolean;
	constructor(label, opts: IInputGroupOption) {
		Object.assign(this, opts);
		this.label = label;
		this.validationName = opts.validationName || label;
		this.validationName = removeColon(this.validationName);
		this.name = opts.name || toCamelCase(this.label);
		this.labelDiv = isPositiveNumber(opts.labelDiv) ? opts.labelDiv : this.DEFAULT.labelDiv;
		this.inputDiv = isPositiveNumber(opts.inputDiv) ? opts.inputDiv : this.DEFAULT.inputDiv;
		this.type = INPUT_TYPE.INPUT_GROUP;
		this.row = typeof opts.row === STRING.BOOLEAN ? opts.row : true;
	}
}

export interface ITextArea {
	styleRow: number;
}

export interface ITextAreaOptions extends ITextOptions {
	styleRow?: number;
}
export class Textarea extends Text implements ITextArea {
	type: any;
	styleRow: number;
	constructor(label, opts: ITextAreaOptions) {
		super(label, opts);
		this.styleRow = opts.styleRow || 4;
		this.type = INPUT_TYPE.TEXTAREA;
	}
}

interface INumberOptions extends ITextOptions {
	min?: number;
	max?: number;
}

export class Number extends Text {
	type: any;
	max: number;
	min: number;
	value: any = STRING.NULL;
	constructor(label, opts: INumberOptions) {
		super(label, opts);
		this.type = INPUT_TYPE.NUMBER;
		this.value = opts.value || this.value;
		this.charPattern = this.charPattern || '[0-9/.]';
		this.maxFieldLength =
			this.maxFieldLength > 0 ? this.maxFieldLength : 15;
		this.max = opts.max;
		this.min = opts.min;
	}
}

export interface FormGroupInterface {
	label?: any;
	submitText?: any;
	labelDiv?: number;
	inputDiv?: number;
	onSubmitClickFormValidity?: boolean;
	cancelText?: any;
	type?: any;
	direction?: any;
	forms: IForm[];
	row?: boolean;
	backButton?: boolean;
}

export class DFormGroup implements FormGroupInterface {
	label: any;
	submitText?: any;
	labelDiv?: number;
	inputDiv?: number;
	onSubmitClickFormValidity = false;
	cancelText?: any;
	type?: any;
	direction?: any;
	forms: IForm[];
	row = true;
	constructor(opts: FormGroupInterface) {
		Object.assign(this, opts);
	}
}

export const STATE = Object.freeze({
	FILLING: 'filling',
	REVIEWING: 'reviewing',
	EDITING: 'editing',
	VIEWING: 'viewing',
	UPDATE: 'update'
});


export interface IAddressFields {
	name?: boolean;
	detail?: boolean;
	landmark?: boolean;
	pincode?: boolean;
	state?: boolean;
	district?: boolean;
	tehsil?: boolean;
	city?: boolean;
	cityTextBox?: boolean;
	parliamentaryConstituency?: boolean;
	addressProof?: boolean;
	addressProofType?: boolean;
	multipleAddressProof?: boolean;
	geoLocation?: boolean;
}

export interface IAddressRequired {
	name?: boolean;
	cityTextBox?: boolean;
	detail?: boolean;
	landmark?: boolean;
	pincode?: boolean;
	state?: boolean;
	district?: boolean;
	tehsil?: boolean;
	city?: boolean;
	parliamentaryConstituency?: boolean;
	addressProofType?: boolean;
	addressProof?: boolean;
	multipleAddressProof?: boolean;
	geoLocation?: boolean;
}

export interface IAddressOptions {
	schemeType?: any;
	isPlane?: boolean;
	addressProofRequired?: boolean;
	addressProofTypeRequired?: boolean;
	nameRequired?: boolean;
	labelDiv?: number;
	inputDiv?: number;
	fields?: IAddressFields;
	required?: IAddressRequired;
	align?: number;
	showAstrisk?: IAddressRequired;
}

export class AddressOptions {
	isPlane?: boolean;
	addressProofRequired?: boolean;
	addressProofTypeRequired?: boolean;
	nameRequired?: boolean;
	labelDiv?: number;
	inputDiv?: number;
	private _fields?: IAddressFields;
	private _required?: IAddressRequired;
	set fields(fields) {
		const defaultFields = {
			name: false,
			detail: true,
			landmark: true,
			state: true,
			district: true,
			tehsil: true,
			city: true,
			pincode: true,
			parliamentaryConstituency: true,
			addressProof: false,
			addressProofType: false,
			multipleAddressProof: false,
			geoLocation: false,
		};
		this._fields = Object.assign({}, defaultFields, fields);
	}
	get fields() {
		return this._fields;
	}
	set required(required) {
		const defaultRequired = {
			name: true,
			detail: true,
			landmark: true,
			pincode: true,
			state: true,
			district: true,
			tehsil: true,
			city: true,
			parliamentaryConstituency: true,
			addressProofType: true,
			addressProof: true,
			multipleAddressProof: true,
			geoLocation: false
		};
		this._required = Object.assign({}, defaultRequired, required);
	}
	get required() {
		return this._required;
	}
	constructor(opts: IAddressOptions) {
		Object.assign(this, opts);
	}
}

export interface IForm {
	label?: any;
	name?: any;
	disabled?: boolean;
	hide?: boolean;
	formRef?: FormGroup;
	type?: any; // can be form or formArray or  modal or withoutStyle
	formElements: any[];
}

interface ISelectOptions extends ICommonOptions {
	placeholder?: any;
	asyncLoad?: boolean;
	metainfo?: any;
	multi?: boolean;
	metaTextcolor?: boolean;
}

export const DFORM_BUTTON_DIRECTION = Object.freeze({
	RIGHT: 'anti',
	CENTER: 'center',
	LEFT: 'normal'
});

export class Select implements SelectFormElement {
	type = INPUT_TYPE.SELECT;
	// type = 'select';
	label: any;
	name?: any;
	placeholder?: any;
	value?: any = STRING.EMPTY;
	align?: number;
	required?: boolean;
	showAstrisk?: boolean;
	validationName?: any;
	validationType?: any;
	options: { value: any; name: string }[];
	onChange: any;
	onBlur?: any;
	asyncLoad: boolean;
	row = true;
	metainfo: any;
	disabled: boolean;
	lineAtStart: boolean;
	lineAtEnd: boolean;
	labelDiv = 2;
	inputDiv = 10;
	multi = false;
	customValidationMessage: any;
	remove = false;
	order = 1;
	readOnly = false;
	hide = false;
	metaTextcolor: boolean;
	silentRequired = false;
	constructor(label, options: any, opts: ISelectOptions) {
		this.label = label;
		this.value = Helpers.isValidValue(opts.value) ? opts.value : this.value;
		this.validationName = opts.validationName || label;
		this.validationName = removeColon(this.validationName);
		this.validationName = this.validationName + ' , select';
		// Centre  Type , select
		const defaultName = removeColon(this.label);
		this.name = opts.name || toCamelCase(this.label);
		this.options = options || [];
		this.placeholder = opts.placeholder || `Select ${defaultName}`;
		this.required = opts.required;
		this.showAstrisk = opts.showAstrisk;
		this.validationType = opts.validationType;
		this.onChange = opts.onChange;
		this.onBlur = opts.onBlur;
		this.align = opts.align || this.align;
		this.labelDiv = isPositiveNumber(opts.labelDiv) ? opts.labelDiv : this.labelDiv;
		this.inputDiv = isPositiveNumber(opts.inputDiv) ? opts.inputDiv : this.inputDiv;
		if (this.align === 6 && !opts.forceStyle) {
			this.labelDiv = 4;
			this.inputDiv = 8;
		}
		this.asyncLoad = opts.asyncLoad || false;
		this.row = typeof opts.row === STRING.BOOLEAN ? opts.row : this.row;
		this.metainfo = opts.metainfo;
		this.disabled = opts.disabled;
		this.lineAtStart = opts.lineAtStart;
		this.lineAtEnd = opts.lineAtEnd;
		this.multi = typeof opts.multi === STRING.BOOLEAN ? opts.multi : this.multi;
		this.customValidationMessage = opts.customValidationMessage;
		this.remove = opts.remove;
		this.order = opts.order || this.order;
		this.readOnly = opts.readOnly;
		this.hide = opts.hide;
		this.metaTextcolor = opts.metaTextcolor || false;
		this.silentRequired = opts.silentRequired || false;
	}
}

function isPositiveNumber(input) {
	return input > -1;
}

function toCamelCase(str: string) {
	str = removeColon(str);
	console.warn(`Name for element is not provided:- ${str}`);
	return Helpers.toCamelCase(str);
}

function removeColon(str) {
	if (!str) {
		return STRING.EMPTY;
	}
	str = str.trim();
	if (str[str.length - 1] === STRING.COLON) {
		const strArr = str.split(STRING.EMPTY);
		strArr.splice(str.length - 1, 1);
		str = strArr.join(STRING.EMPTY);
		return str;
	}
	return str;
}

export const KEYS = Object.freeze({
	ENTER: 'Enter',
	BACKSPACE: 'Backspace',
	ARROW_LEFT: 'ArrowLeft',
	ARROW_RIGHT: 'ArrowRight',
	TAB: 'Tab'
});

export const STRING = STRING_CONST;

// const FEE_BASED_BASE_URL = 'Feebased-Process-documents';
const FEE_BASED_BASE_URL = 'https://s3.ap-south-1.amazonaws.com/nsdcuatpublic/Feebased-Process-documents';

export const S3URLS = Object.freeze({
	COURSE: Object.freeze({
		FUNDED: Object.freeze({
			// tslint:disable-next-line:max-line-length
			// QPNOS_AVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Undertaking-Funded-QP-NOS-available.doc`,
			// QPNOS_UNAVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Undertaking-Funded-QP-NOS-not-available.docx`,
			QPNOS_AVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Draft_Undertaking-Funded-QP-NOS available_revised_accepted.docx`,
			QPNOS_UNAVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Draft_Undertaking-Funded-QP-NOS not available_revised_accepted.docx`,
		}),
		NON_FUNDED: Object.freeze({
			// tslint:disable-next-line:max-line-length
			// QPNOS_AVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Undertaking-Non-Funded-QP-NOS-Available.doc`,
			// QPNOS_UNAVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Undertaking-Non-Funded-QP-NOS-Not-Available.docx`,
			QPNOS_AVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Draft_Undertaking-Non-Funded-QP-NOS available_revised_accepted.doc`,
			QPNOS_UNAVAIL: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/CourseApprovalDocumentTemplate/Draft_Undertaking-Non-Funded-QP-NOS not available_revised_accepted.docx`,
		})
	}),
	SECTOR: Object.freeze({
		// ADDITION_REQUEST_INFO: `${FEE_BASED_BASE_URL}/Sector-Addition/Sector addition request Information format.xlsx`,
		ADDITION_REQUEST_INFO: `https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/miscellaneous/TemplateForSector.docx`,
		SELF_DECLARATION: `${FEE_BASED_BASE_URL}/Sector-Addition/Self declaration for sector addition.docx`,
	})
});

export const REDIRECT_URLS = Object.freeze({
	NSDC_FUNDING_URL: `https://www.nsdcindia.org/funding`
})

export const VALIDATION_TYPE = Object.freeze({
	NAME: 'name',
	AA_NAME: 'aaName',
	FILE: 'file',
	LANDLINE_NUMBER: 'landlineNumber',
	EMAIL: 'email',
	MOBILE_NUMBER: 'mobileNumber',
	AADHAR: 'aadhar',
	DATE_DURATION: 'dateDuration',
	POSITIVE_NUMBER: 'positiveNumber',
	DECIMAL_NUMBER: 'decimalNumber',
	ALPHANUMERIC: 'alphanumeric',
	CAP_ALPHANUMBERIC: 'capAlphanumeric',
	PIN_CODE: 'pincode',
	ORGANISATION_NAME: 'organisationName',
	LONG_ORGANISATION_NAME: 'longOrganisationName',
	COURSE_NAME: 'courseName',
	COURSE_DESCRIPTION: 'courseDescription',
	SECTOR_NAME: 'sectorName',
	DESCRIPTION: 'description',
	LONG_DESCRIPTION: 'longDescription',
	COMPANY_DESCRIPTION: 'companyDescription',
	PERCENTAGE: 'percentage',
	PROJECT_NAME: 'projectName',
	PROJECT_PROPOSAL_ID: 'projectProposalId',
	COMPANY_NAME: 'companyName',
	TRAINING_TARGET: 'trainingTarget',
	DESIGNATION: 'designation',
	WEBSITE: 'website',
	TC_ID: 'centreid',
	TC_AREA: 'trainingCentreArea',
	ALTNUM: 'alternateNumber',
	ADDRESS_DETAIL: 'addressDetail',
	ADDRESS_LANDMARK: 'addressLandmark',
	TC_NAME: 'trainingCenterName',
	WORKING_HOURS: 'workingHours',
	URL: 'url'
});

export const INPUT_TYPE = Object.freeze({
	TEXT: 'text',
	SIMPLE_TEXT: '',
	TEXTAREA: 'textarea',
	SELECT: 'select',
	CHECKBOX: 'checkbox',
	DATE: 'date',
	NUMBER: 'number',
	UPLOAD: 'upload',
	DATE_DURATION: 'date-duration',
	AADHAR: 'aadhar',
	GEO_LOCATION: 'geo-location',
	FORM_ARRAY: 'form-array',
	RADIO: 'radio',
	INPUT_GROUP: 'inputGroup',
});

export const DISPLAY_TYPE = Object.freeze({
	INLINE: 'inline',
	BLOCK: 'block'
});
export const FORM_TYPE = Object.freeze({
	FORM: 'form',
	FORM_ARRAY: 'formArray',
	MODAL: 'modalForm',
	WITHOUT_STYLE: 'withoutStyle'
});

export interface IDashboardCard {
	title: any;
	description: any;
	logoUrl?: any;
	route: any;
	queryParams?: {};
	queryParamsOriginal?: {};
	relative?: boolean;
	notShow?: boolean;
	cardWidth?: any;
	setOwnCardWidth?: boolean;
	link?: string,
	hyperLink?: string
}

export class ProjectFunctionalities {
	TRAINING_CENTRE = {
		CREATE: true,
		EDIT: true,
		VIEW: true,
	}
	TRAINER = {
		CREATE: true,
		EDIT: true,
		VIEW: true,
	}
	SECTOR = {
		CREATE: true,
		EDIT: true,
		VIEW: true,
	}
	COURSE = {
		CREATE: true,
		EDIT: true,
		VIEW: true,
	}
	ASSESSMENT_AGENCY = {
		CREATE: true,
		EDIT: true,
		VIEW: true,
	}
	ASSESSOR = {
		CREATE: true,
		EDIT: true,
		VIEW: true,
	}
	BATCH: {
		CREATE: true,
		EDIT: true,
		VIEW: true
	}
	constructor(functionalities: IProjectFunctionalities) {
		if (functionalities) {
			Object.assign(this, functionalities)
		}
	}
}

export interface IProjectFunctionalities {
	TRAINING_CENTRE: {
		CREATE: boolean,
		EDIT: boolean,
		VIEW: boolean,
	},
	TRAINER: {
		CREATE: boolean,
		EDIT: boolean,
		VIEW: boolean,
	},
	SECTOR: {
		CREATE: boolean,
		EDIT: boolean,
		VIEW: boolean,
	},
	COURSE: {
		CREATE: boolean,
		EDIT: boolean,
		VIEW: boolean,
	},
	ASSESSMENT_AGENCY: {
		CREATE: boolean,
		EDIT: boolean,
		VIEW: boolean,
	},
	ASSESSOR: {
		CREATE: boolean,
		EDIT: boolean,
		VIEW: boolean,
	},
	BATCH: {
		CREATE: boolean,
		EDIT: boolean,
		VIEW: boolean
	}
}

export interface IDashboard {
	title: any;
	description: any;
	cards: IDashboardCard[];
}

export interface ITableFilter {
	name: any;
	value: any;
	options?: string[];
}

export interface ITableSearchFilter {
	label: any;
	placeholder: any;
	onSearch: any;
}

const DEFAULT = Object.freeze({
	PAGINATION: Object.freeze({
		PAGE_NO: 1,
		LIMIT: 10,
		COUNT: 0
	})
});

export interface IPagination {
	count?: number;
	pageNum?: number;
	pageNo?: number;
	limit?: number;
}

export class Pagination implements IPagination {
	count: number;
	pageNum: number = DEFAULT.PAGINATION.PAGE_NO;
	limit: number = DEFAULT.PAGINATION.LIMIT;
	constructor(opts: IPagination = {}) {
		Object.assign(this, opts);
	}
	set pageNo(page: number) {
		this.pageNum = page;
	}
	get pageNo() {
		return this.pageNum;
	}
}


export interface ITable {
	label?: any;
	headers?: any;
	data?: any[];
	searchFilters?: ITableSearchFilter[];
	isResetButton?: boolean;
	mixedFilters?: ICommonFilter[];
	filters?: ITableFilter[];
	pagination?: IPagination;
	defaultFilters?: any;
	clientFilters?: object;
	dynamicClasses?: any;
	onClick?: any;
	onFilterChange?: any;
	onPaginationChange?: any;
	fieldMapping?: any;
	withoutSerialNumber?: boolean;
}

export class DTable implements ITable {
	label?: any;
	headers?: any = [];
	_data?: any[] = [];
	_filterdData?: any[] = [];
	searchFilters?: ITableSearchFilter[] = [];
	filters?: ITableFilter[] = [];
	_pagination?: Pagination = new Pagination();
	mixedFilters?: ICommonFilter[] = [];
	defaultFilters?: any = {};
	isResetButton?: boolean;
	// defaultFilters?: any = [];
	dynamicClasses?: any = {};
	onClick?: any = {};
	fieldMapping?: any = {};
	clientSideSearch = true;
	clientFilters: any = {};
	private _filter: any = {};
	private _withoutSerialNumber: boolean;
	private onFilterChangeActions: any[] = [];
	private onPaginationChangeActions: any[] = [];
	private onChangeActions: any[] = [];
	constructor(opts: ITable = {}) {
		Object.assign(this, opts);
	}
	set pagination(pagination) {
		Object.assign(this._pagination, pagination);
	}

	get pagination() {
		return this._pagination;
	}

	set onPaginationChange(fn) {
		this.onPaginationChangeActions.push(fn);
	}
	set withoutSerialNumber(required: boolean) {
		this._withoutSerialNumber = required;
	}
	get withoutSerialNumber() {
		return (typeof this._withoutSerialNumber === STRING.BOOLEAN) ? this._withoutSerialNumber : false;
	}
	set onFilterChange(fn) {
		this.onFilterChangeActions.push(fn);
	}

	paginationChanged() {
		this.run('onPaginationChangeActions');
		// for (const fn of this.onPaginationChangeActions) {
		// 	if (typeof fn === STRING.FUNCTION) {
		// 		fn();
		// 	}
		// }
	}

	filterChanged(filter) {
		this._filter = filter;
		this.run('onFilterChangeActions', this._filter);
		// for (const fn of this.onFilterChangeActions) {
		// 	if (typeof fn === STRING.FUNCTION) {
		// 		fn();
		// 	}
		// }
	}

	private run(key, param?: any) {
		for (const fn of this[key]) {
			if (typeof fn === STRING.FUNCTION) {
				fn(param);
				// fn(this._filter);
			}
		}
	}

	set onDataChange(fn: any) {
		this.onChangeActions.push(fn);
	}
	set filteredData(filteredData) {
		this._filterdData = filteredData;
	}
	get filteredData() {
		return this._filterdData;
	}
	set data(tableData) {
		this._data = tableData;
		this._filterdData = tableData.slice();
		for (const fn of this.onChangeActions) {
			if (typeof fn === STRING.FUNCTION) {
				fn();
			}
		}
	}
	get data() {
		return this._data;
	}
}

export interface ITableAction {
	label?: any;
	stateForm?: any;
	onSelect: (data?: any) => void;
	// onSelect ?: any;
}

export class TableActions {
	actions: ITableAction[];
	constructor(actions: ITableAction[] = []) {
		this.actions = actions;
	}
	push(action: ITableAction) {
		this.actions.push(action);
	}
	pop() {
		return this.actions.pop();
	}
	get show() {
		return (this.actions && this.actions.length) ? true : false;
	}
}

export class TableAction implements ITableAction {
	label?: any;
	onSelect: () => void;
	constructor(opts: ITableAction) {
		Object.assign(this, opts);
	}
}

export interface IMultiTabTableTab {
	name: any;
	id: any;
	table: ITable;
	onSelect?: any;
	active?: boolean;
}

export class MultiTabTableTab implements IMultiTabTableTab {
	name: any;
	id: any;
	table: ITable;
	active = false;
	constructor(opts: IMultiTabTableTab) {
		Object.assign(this, opts);
	}
}

export class MultiTabTable implements IMultiTabTable {
	tabs: IMultiTabTableTab[];
	label?: any;
	constructor(opts: IMultiTabTable) {
		Object.assign(this, opts);
		// opts.tabs.forEach(tab => {
		// 	tab.table.onFilterChange = opts.onFilterChange;
		// });
	}
	set onFilterChange(fn) {
		this.tabs.forEach(tab => {
			tab.table.onFilterChange = fn;
		});
	}
	set tableData(data) {
		const selectedTab = this.tabs.find(t => t.active);
		selectedTab.table.data = data;
	}

	get activeTab() {
		const selectedTab = this.tabs.find(t => t.active);
		return selectedTab;
	}

	getTabById(tabId) {
		const selectedTab = this.tabs.find(t => t.id === tabId);
		return selectedTab;
	}

	set pagination(pagination) {
		const selectedTab = this.tabs.find(t => t.active);
		Object.assign(selectedTab.table.pagination, pagination);
	}

	get pagination() {
		const selectedTab = this.tabs.find(t => t.active);
		return selectedTab.table.pagination;
	}

}

export interface IMultiTabTable {
	tabs: IMultiTabTableTab[];
	label?: any;
	showCount?: any;
	// onFilterChange?: any;
}

export const FILTER_TYPES = {
	DATE: 'date',
	TEXT: 'text',
	SELECT: 'select'
};

export interface ICommonFilter {
	id?: any;
	label?: any;
	name: any;
	placeholder?: any;
	type?: any;
	value?: any;
	options?: any[];
	hide?: boolean;
}

export interface IDateFilter extends ICommonFilter {
	startDate?: Date;
	endDate?: Date;
}

export class DateFilter implements ICommonFilter {
	type: any;
	id?: any;
	label?: any;
	name: any;
	placeholder?: any;
	// startDate?: Date;
	private _startDate?: Date = new Date(1900, 1, 1);
	// endDate?: Date;
	private _endDate?: Date = new Date(5000, 1, 1);
	constructor(opts: IDateFilter) {
		Object.assign(this, opts);
		const defaultName = removeColon(this.label);
		this.placeholder = this.placeholder || `Choose ${defaultName}`;
		this.type = FILTER_TYPES.DATE;
	}
	set startDate(date: Date) {
		if (!date) {
			date = new Date(1900, 1, 1);
		}
		Helpers.copyDate(this._startDate, date);
		this.id = Helpers.defineCalendar(this._startDate, this._endDate);
	}

	get endDate() {
		return this._endDate;
	}

	set endDate(date: Date) {
		if (!date) {
			date = new Date(1900, 1, 1);
		}
		Helpers.copyDate(this._endDate, date);
		this.id = Helpers.defineCalendar(this._startDate, this._endDate);
	}

	get startDate() {
		return this._startDate;
	}
}

export class TextFilter implements ICommonFilter {
	type: any;
	id?: any;
	label?: any;
	name: any;
	value: any;
	placeholder: any;
	constructor(opts: ICommonFilter) {
		Object.assign(this, opts);
		const defaultName = removeColon(this.label);
		this.placeholder = this.placeholder || `Enter ${defaultName}`;
		this.type = FILTER_TYPES.TEXT;
	}
}

export class SelectFilter implements ICommonFilter {
	type: any;
	id?: any;
	label?: any;
	name: any;
	placeholder?: any;
	options?: any[];
	hide?: boolean;
	// value?: any;
	constructor(opts: ICommonFilter) {
		Object.assign(this, opts);
		const defaultName = removeColon(this.label);
		this.placeholder = this.placeholder || `Select ${defaultName}`;
		// this.value = opts.value || STRING.EMPTY;
		this.type = FILTER_TYPES.SELECT;
	}
}

export const PROJECT_TYPE = Object.freeze({
	FUNDED: 'Funded',
	NON_FUNDED: 'Non-Funded'
})

export interface ISignedDocument {
	signedTermSheet: {
		type: any,
		size: any,
		placeholder: String,
		required: Boolean,
		defaultMessage: String,
		fieldName: String,
	},
	signedAgreement: {
		type: any,
		size: any,
		placeholder: String,
		required: Boolean,
		defaultMessage: String,
		fieldName: String,
		hide: Boolean
	},
	otherSupportingDocument: {
		type: any,
		size: any,
		placeholder: String,
		required: Boolean,
		fieldName: String,
	}
}

export interface UserModal {
	userName: String,
	role: String
};

