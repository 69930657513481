
<div class="m-content">
  <div class="container">
    <div class="col-12">
      <div class="session-timeout-block">
          <div class="row sip-row">
            <div class="timeout-header">
               <p class="header">error <span class="status">404</span></p>
            </div>
          </div>
          <div class="row sip-row">
            <div class="Expired-message">
             <p class="session-reason">It's may looking like you have taken a wrong turn. <br>Don't worry....It happens to the best of us.<br>In the meantime we are working on why this happened.</p>
            </div>
          </div>
          <div class="row sip-row">
            <div class="login-button">
              <button class="homepage-btn" (click)="goToHomepage()">Go to HomePage</button>
            </div>
          </div>
          <div class="help-message">
            <h6>Need Help? Call us on 1800-123-9626</h6>
          </div>
      </div>
   </div>
  </div>
</div>
