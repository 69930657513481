<div class="m-content" *ngIf="showForm">
  <div class="dashboard">
    <div class="m-portlet">
      <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <span class="m-portlet__head-icon m--hide">
              <i class="la la-gear"></i>
            </span>
            <h3 class="m-portlet__head-text">
              Feedback Of Inspector
              <!-- <small>Fee Based</small> -->
            </h3>
          </div>
        </div>
      </div>
      <form class="m-form m-form--label-align-right" [formGroup]="feedbackform">
        <div class="m-portlet__body">
          <div class="m-form__section m-form__section--first">
            <!-- <div class="m-form__heading">
              <h3 class="m-form__heading-title">Batch Details:</h3>
            </div> -->

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">TC ID:</label>
              <div class="col-lg-5">
                <input type="text" class="form-control m-input" name="tcId" formControlName="tcId"
                  placeholder="Enter TC Id">
                <app-form-control-messages [control]="feedbackform.controls.tcId"
                  [name]="'tcId'"></app-form-control-messages>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">TC Name:</label>
              <div class="col-lg-5">
                <input type="text" class="form-control m-input" name="tcName" formControlName="tcName"
                  placeholder="Enter Tc Name">
                <app-form-control-messages [control]="feedbackform.controls.tcName"
                  [name]="'tcName'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Inspector Name</label>
              <div class="col-lg-5">
                <input type="text" class="form-control m-input" name="inspectorName" formControlName="inspectorName"
                  placeholder="Enter Inspector Name">
                <app-form-control-messages [control]="feedbackform.get('inspectorName')"
                  [name]="'inspectorName'"></app-form-control-messages>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Did Inspector reach at Training Centre on Informed
                time?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorReachedAtInformedTime">
                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of commonOptions" [ngValue]="option.value">{{option.id}}</option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorReachedAtInformedTime"
                  [name]="'Did Inspector reach at Training Centre on Informed
                  time?'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Was inspector's Behavior Satisfactory?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorBehaviorSatisfactory">

                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of commonOptions" [ngValue]="option.value">{{ option.id }}</option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorBehaviorSatisfactory"
                  [name]="'Was inspectors Behavior Satisfactory?'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Was Inspector well Groomed?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorWellGroomed">

                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of groomedOptions" [ngValue]="option.value">{{ option.id }}</option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorWellGroomed"
                  [name]="'Was Inspector well Groomed?'"></app-form-control-messages>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Did Inspector insist for Transportation?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorInsistForTransportation">
                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of commonOptions" [ngValue]="option.value">{{ option.id }}</option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorInsistForTransportation"
                  [name]="'Did Inspector insist for Transportation?'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Did inspector insist for accommodation?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorInsistForAccommodation">
                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of commonOptions" [ngValue]="option.value">{{ option.id }}</option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorInsistForAccommodation"
                  [name]="'Did inspector insist for accommodation?'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Did Inspector insist for financial assistance?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorInsistForFinancialAssistance">
                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of financialAssistanceOptions" [ngValue]="option.value">
                    {{ option.id }}
                  </option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorInsistForFinancialAssistance"
                  [name]="'Did Inspector insist for financial assistance?'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Did Inspector explain all the Non-Conformity found in
                Inspection?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorExplainAllTheNonConformityFoundInInspection">
                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of explainNonConformityOptions" [ngValue]="option.value">
                    {{ option.id }}
                  </option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorExplainAllTheNonConformityFoundInInspection"
                  [name]="'Did Inspector explain all the Non-Conformity found in
                  Inspection?'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Did Inspector give enough time for rectification of
                Non-Conformities?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="inspectorGiveEnoughTimeForRectificationOfNonConformities">
                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of commonOptions" [ngValue]="option.value">{{ option.id }}</option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.inspectorGiveEnoughTimeForRectificationOfNonConformities"
                  [name]="'Did Inspector give enough time for rectification of
                  Non-Conformities?'"></app-form-control-messages>
              </div>
            </div>

            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Did overall conduct of Inspection find
                satisfactory?<span
                style="color:red">*</span></label>
              <div class="col-lg-5">
                <select class="form-control m-input" formControlName="overallConductOfInspectionFindSatisfactory">
                  <option value=null disabled>Please Select</option>
                  <option *ngFor="let option of commonOptions" [ngValue]="option.value">{{ option.id }}</option>
                </select>
                <app-form-control-messages [control]="feedbackform.controls.overallConductOfInspectionFindSatisfactory"
                  [name]="'Did overall conduct of Inspection find
                  satisfactory?'"></app-form-control-messages>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label for="tp-details" class="col-3 col-form-label field-label">
                How Would you rate overall Experience on Inspection?<span
                style="color:red">*</span>
              </label>
              <div class="col-5">
                <span (click)="rating = 1" class="fa fa-star star-s {{rating > 0 ? 'star-checked' : ''}}"></span>
                <span (click)="rating = 2" class="fa fa-star star-s {{rating > 1 ? 'star-checked' : ''}}"></span>
                <span (click)="rating = 3" class="fa fa-star star-s {{rating > 2 ? 'star-checked' : ''}}"></span>
                <span (click)="rating = 4" class="fa fa-star star-s {{rating > 3 ? 'star-checked' : ''}}"></span>
                <span (click)="rating = 5" class="fa fa-star star-s {{rating > 4 ? 'star-checked' : ''}}"></span>
              </div>
            </div>
            <div class="form-group m-form__group row">
              <label class="col-lg-3 col-form-label">Remarks by Training Centre</label>
              <div class="col-lg-5">
                <!-- <input type="text" class="form-control m-input" name="remark" formControlName="remark"
                  placeholder="Enter Remark"> -->
                  <textarea class="form-control m-textarea notranslate" id="m_autosize_1" type="text"
                  name="Remark" placeholder="Enter Remark"
                  formControlName="remarks"></textarea>
                <app-form-control-messages [control]="feedbackform.get('remarks')"
                  [name]="'remark'"></app-form-control-messages>
              </div>
            </div>

          </div>
        </div>

        <div class="m-portlet__foot m-portlet__foot--fit">
          <div class="m-form__actions m-form__actions">
            <div class="row">
              <div class="col-lg-3"></div>
              <div class="col-lg-8">
                <button class="btn btn-info m-btn m-btn--custom" [disabled]="!feedbackform.valid"
                  id="m_sweetalert_demo_8" (click)="submitDetails()">Submit Form</button>
                <!-- <button class="btn btn-secondary save-as-draft-btn" (click)="saveAsDraft()">Save As Draft</button> -->
              </div>
            </div>
          </div>
        </div>


      </form>
    </div>
  </div>
</div>
