import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { SharedService } from './shared/shared.service';
import { Observable, throwError, map, catchError } from 'rxjs';
import { CurrentUserService } from './current-user.service';

declare var toastr: any;
@Injectable({ providedIn: 'root' })
export class InterceptorService implements HttpInterceptor {
	prevRequest: {
		requestUrl: String,
		requestTime: Date
	} = {
			requestUrl: '',
			requestTime: new Date
		}
	private _latestRequestId = 0
	private requestIds = {}
	private get latestRequestId() {
		return this._latestRequestId++;
	}
	private set requestMade(reqId) {
		const nextRequestId = reqId
		if (this.requestIds[reqId]) {
			console.warn('Duplicate req ID')
		}
		this.requestIds[nextRequestId] = true;
		// this.loadingService.blockUI();
	}
	private responseMade(reqId) {
		if (this.requestIds[reqId]) {
			this.requestIds[reqId] = false;
			delete this.requestIds[reqId]
		}
		const requests = Object.keys(this.requestIds);
		if (requests.length) {
			return;
		}
		// this.loadingService.unblockuI()
	}

	currentUserInfo: any;
	constructor(
		private router: Router,
		private storageService: StorageService,
		private sharedService: SharedService,
		private currentUserService: CurrentUserService
		// private loadingService: LoadingService
	) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const url = request.urlWithParams;
		const query = Helpers.parseQueryFromUrl(url);
		let ignoreServerResponseError = false;
		let showServerSuccessMessage = false;
		if (query.ignoreServerResponseError) {
			ignoreServerResponseError = true;
		}
		if (query.showServerSuccessMessage) {
			showServerSuccessMessage = true;
		}
		const excludeLoadingUrls = [];
		let excludeLoading = false;
		// console.log(request)
		for (const excludeLoadingUrl of excludeLoadingUrls) {
			if (request.url.includes(excludeLoadingUrl)) {
				excludeLoading = true;
			}
		}
		const latestRequestId = this.latestRequestId;
		if (!excludeLoading) {
			this.requestMade = latestRequestId;
			// this.loadingService.blockUI();
		}
		// 	if (request.method !== 'HEAD' && request.url === this.prevRequest.requestUrl) {
		// 		// Do your operations
		// 		const currentDate = new Date();
		// 		const seconds = (currentDate.getTime() - this.prevRequest.requestTime.getTime()) / 1000;
		// 		console.log(seconds);

		// 		if (seconds < 3) {
		// 			console.log('before 2 second');
		// 			return Observable.throw('duplicate request'); ;
		// 		}
		// 	}
		// 	if (request.method !== 'HEAD') {
		// 		this.prevRequest.requestUrl = request.url;
		// 		this.prevRequest.requestTime = new Date();
		//   }
		let tokenAuth;
		if (!request.url.endsWith('api/user/v1')) {
			this.sharedService.assignAllrequests(request.url);
		}
		const isAmazonS3Url = request.url.includes('s3.amazonaws.com/');
		if (isAmazonS3Url) {
			tokenAuth = request.clone({
				setHeaders: {
				},
				withCredentials: false, // for 3rd party URL and Before login
			});
		} else if (this.storageService.getObject('access_token')) {
			tokenAuth = request.clone({
				setHeaders: {
					'Authorization': localStorage.getItem('access_token'),
				},
				'withCredentials': true,
			});
			this.currentUserService.getCurrentUser().subscribe(res => {
				this.currentUserInfo = res;
			});
			const token = this.currentUserService.decodeToken();
			if (this.currentUserInfo && token && this.currentUserInfo.userName !== token.userName){
				this.router.navigate(['/session-expired']);
			}
		} else {
			tokenAuth = request.clone({
				setHeaders: {
				},
				withCredentials: true, // user not logged in
			});
		}

		return next.handle(tokenAuth)
			.pipe(map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					this.sharedService.removeUrlOnresponse(request.url);
					this.responseMade(latestRequestId)
					// this.loadingService.unblockuI();
					if (event.headers.get('authorization')) {
						this.storageService.setObject('access_token', event.headers.get('authorization'));
					}
					if (event.body && event.body.message && showServerSuccessMessage) {
						toastr.success(event.body.message)
					}
					return event;
				}
			})
			,catchError((err: HttpEvent<any>) => {
				this.responseMade(latestRequestId)
				this.sharedService.removeUrlOnresponse(request.url);
				// if (request.url.endsWith('loggedInUser')) {
				// 	if (!localStorage.getItem('access_token')) {
				// 		this.router.navigate(['/']);
				// 	}
				// }
				// this.loadingService.unblockuI();
				let message: string;
				if (err instanceof HttpErrorResponse) {
					if (ignoreServerResponseError) {
						return throwError(err);
					}
					if (err.error) {
						if ((request.url).includes('document') && (request.url).includes('signedURL') && request.method === 'GET') {
							toastr.info('Details are not available');
						}
					}
					if (err.error && err.error.message) {
						message = err.error.message;
					} else {
						if (err.status === 409 || err.status === 402) {
							// message = 'Data has already been taken or Duplicate Entry';
						} else if (err.status === 500) {
							message = 'Something went wrong in the server. Please try again';
						} else if (err.status === 400) {
							message = err.error.message;
						} else if (err.status === 401) {

						} else if (err.status === 403) {
							message = 'Unauthorized to use this section';
						} else if (err.status === 404) {
							message = 'Resource is not available';
						} else if (err.status === 412) {
							// message = 'Unauthorized Access';
							this.router.navigate(['/session-expired']);
						}
					}
					if (err.status === 401) {
						this.router.navigate(['/session-expired']);
					}
					if (message) {
						toastr.error(message);
					}
					return throwError(err);
				}
			}));

	}
}
