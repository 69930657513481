<div class="m-grid m-grid--hor m-grid--root m-page">
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
        id="m_login">
        <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
            <!-- registration details -->
            <div *ngIf="showform == 'registration'" class="m-stack m-stack--hor m-stack--desktop">
                <div class="m-stack__item m-stack__item--fluid">
                    <div class="m-login__wrapper">
                        <div class="m-login__logo">
                            <a href="#">
                                <img src="../../../assets/images/skill-india-logo-prod.png">
                            </a>
                        </div>
                        <div class="m-login__signin">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                </h3>
                            </div>
                            <form class="m-login__form m-form" [formGroup]="userDetailsForm">
                                <div class="form-group m-form__group">
                                    <select class="form-control m-select notranslate" id="exampleSelect1"
                                        formControlName="role" [(ngModel)]="userAccount.role"
                                        (change)="assignPlaceHolder()">
                                        <option value=""> {{'CHOOSEUSERGRP' | translatePipe}} </option>
                                        <option value="Training Partner">{{'TP' | translatePipe}}</option>
                                        <option value="Trainer">{{'TRAINER'| translatePipe}}</option>
                                        <option value="Assessor">{{'ASSESSORNAME' | translatePipe}}</option>
                                        <option value="internationalTrainer">{{'INTLTRAINER'| translatePipe}}</option>
                                        <option value="internationalAssessor">{{'INTLASSESSOR' | translatePipe}}
                                        </option>
                                        <option value="Candidate">{{'Candidate' | translatePipe}} </option>
                                        <option value="Proctor">{{'Proctor' | translatePipe}}</option>
                                        <option value="awardingBody">Awarding Body</option>
                                    </select>
                                    <app-form-control-messages [control]="userDetailsForm.controls.role"
                                        [name]="'Role'"></app-form-control-messages>
                                </div>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input notranslate" type="text"
                                        placeholder="{{placeHolder | translatePipe}}" name="name" formControlName="name"
                                        [(ngModel)]="userAccount.firstName" autocomplete="off">
                                    <span class="m-form__help">
                                        {{ AADHAAR_NAME | translatePipe}}
                                    </span>
                                    <app-form-control-messages [control]="userDetailsForm.controls.name"
                                        [name]="'Name'"></app-form-control-messages>
                                </div>

                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="email"
                                        placeholder="{{email | translatePipe}}" name="email" formControlName="email"
                                        [(ngModel)]="userAccount.email">
                                    <app-form-control-messages [control]="userDetailsForm.controls.email"
                                        [name]="'Email Address'"></app-form-control-messages>
                                </div>
                                <div class="form-group m-form__group">
                                    <input class=" form-control m-input notranslate" type="text"
                                        placeholder="{{ mobileNo | translatePipe}}" onkeypress="return event.charCode>= 48 && event.charCode
                                    <=57" name="mobile" maxlength="10" formControlName="mobile"
                                        [(ngModel)]="userAccount.phone.mobile">
                                    <app-form-control-messages [control]="userDetailsForm.controls.mobile"
                                        [name]=" 'Mobile Number'"></app-form-control-messages>
                                </div>
                                <div class="row form-group m-form__group m-login__form-sub">
                                    <div class="col m--align-left">
                                        <label class="m-checkbox m-checkbox--focus">
                                            <input type="checkbox" name="agree" formControlName="changeStatusChecked">
                                            {{"TERMS"|translatePipe}}
                                            <a href="javascript:;" data-toggle="modal" data-target="#m_modal_1_2"
                                                class="m-link m-link--focus">{{"CONDITIONS"|translatePipe}}</a>.
                                            <span></span>
                                        </label>
                                        <span class="m-form__help"></span>
                                    </div>
                                </div>
                                <div class="recaptchaAllign">
                                    <re-captcha (resolved)="resolveRecaptcha($event)"></re-captcha>
                                </div>
                                <div class="m-login__form-action">
                                    <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login"
                                        [disabled]="captchaInvalid" (click)="generateOTPs()">
                                        {{"REGISTER"| translatePipe}}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="m-stack__item m-stack__item--center">
                    <div class="m-login__account">
                        <span class="m-login__account-msg">
                            {{"lOGIN_ALREADY"|translatePipe}}
                        </span> &nbsp;&nbsp;
                        <a href="javascript:;" id="m_login_signup" class="m-link m-link--focus m-login__account-link"
                            (click)="goTOLogin()">
                            {{"Login"| translatePipe}}
                        </a>
                    </div>
                    <div class="">
                        <!-- <span class="">© 2018 Copyright Transneuron Technologies Pvt Ltd. · Terms of Service · Privacy </span> -->
                    </div>
                </div>
            </div>
            <!-- generate otp and password -->
            <div *ngIf="showform=='otp'" class="m-stack m-stack--hor m-stack--desktop">
                <div class="m-stack__item m-stack__item--fluid">
                    <div class="m-login__wrapper">
                        <div class="m-login__logo">
                            <a href="#">
                                <img src="../../../assets/images/sdms-01.png">
                            </a>
                        </div>
                        <div class="m-login__signin">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                </h3>
                            </div>
                            <form class="m-login__form m-form" [formGroup]="otpForm">
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="text" maxlength="6"
                                        placeholder="Enter Email OTP" name="eOTP" formControlName="emailOTP"
                                        [(ngModel)]="userAccount.emailOTP" autocomplete="off">
                                    <app-form-control-messages [control]="otpForm.controls.emailOTP"
                                        [name]=" 'Email OTP'"></app-form-control-messages>
                                </div>
                                <div class="form-group m-form__group"
                                    *ngIf="userAccount.role !== 'internationalTrainer' && userAccount.role !== 'internationalAssessor'">
                                    <input class="form-control m-input" type="text" maxlength="6"
                                        placeholder="Enter Mobile OTP" name="mOTP" formControlName="mobileOTP"
                                        [(ngModel)]="userAccount.mobileOTP" autocomplete="off">
                                    <app-form-control-messages [control]="otpForm.controls.mobileOTP"
                                        [name]=" 'Mobile OTP'"></app-form-control-messages>
                                </div>

                                <div class="m-login__form-action" *ngIf=isDisable>
                                    <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login"
                                        [disabled]="!otpForm.valid" (click)="verifyOTP()">
                                        Verify & Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="m-stack__item m-stack__item--center">
                    <div class="m-login__account">
                        <span class="m-login__account-msg">
                            Didn’t Received OTP ?
                        </span> &nbsp;&nbsp;
                        <a href="javascript:;" id="m_login_signup" class="m-link m-link--focus m-login__account-link"
                            (click)="reGenerateOTP()">
                            Re-Generate OTP
                        </a>
                    </div>
                    <div class="">
                        <!-- <span class="">© 2018 Copyright Transneuron Technologies pvt Ltd. · Terms of Service · Privacy </span> -->
                    </div>
                </div>
            </div>
            <!-- success message section and Go to login -->
            <div *ngIf="showform=='success'" class="m-stack m-stack--hor m-stack--desktop">
                <div class="m-stack__item m-stack__item--fluid">
                    <div class="m-login__wrapper">
                        <div class="m-login__logo">
                            <a href="#">
                                <img src="../../../assets/images/sdms-01.png">
                            </a>
                        </div>
                        <div class="m-login__signin">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                </h3>
                            </div>
                            <div class="text-Center sucess-msg">
                                We have sent the
                                <span style="font-weight:bold">Username</span> and
                                <span style="font-weight:bold">Password</span> to the registered email address. Please
                                login with those credentials.
                            </div>
                            <h3 *ngIf="showID"> Your Username is:
                                <span class="text-bold">{{generatedTPId}}</span>
                            </h3>
                            <div class="m-login__form-action text-center">
                                <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login"
                                    routerLink="/login">
                                    Login Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="m-stack__item m-stack__item--center">
                    <div class="">
                        <span class="">© 2018 Copyright Company. Inc. · Terms of Service · Privacy </span>
                    </div>
                </div>
            </div>
        </div>
        <!-- right section of the page -->
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1 m-login__content"
            style="background-image: url(https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/learningpages/register-now.png); background-attachment: unset;">
            <h2 class="sip-text">{{"Skill India Digital"| translatePipe}}</h2>
            <div class="m-grid__item m-grid__item--left">
                <h3 class="m-login__welcome">
                    {{"REGISTER_NOW"|translatePipe}}
                </h3>
                <p class="m-login__msg">
                    {{"WHAT_I_HERE"|translatePipe}}
                    <br>{{"REGISTER_NAME"|translatePipe}}
                    <br>{{"REGISTRATION_START"|translatePipe}}
                    <br>
                    <br> {{"NEXT"|translatePipe}}
                    <br> {{"REGISTRATION_FORM"|translatePipe}}
                </p>
                <div class="login-right">
                    <h4 class="help-text">{{"HELPlINE"|translatePipe}}</h4>
                    <!-- <h3 class="text-center">{{"OR"|translatePipe}}</h3>
                    <h4 class="help-text td">{{"WRITE_US"|translatePipe}} <a
                            style="text-decoration: underline">skillindia.helpdesk@nsdcindia.org</a></h4>
                    <br> -->
                    <button class="btn btn-focus m-btn--pill btn-back"
                        routerLink="/">{{"GO_TO_LOGIN"|translatePipe}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Terms and C -->
<div class="modal fade conatiner notranslate" id="m_modal_1_2" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">TERMS AND CONDITIONS</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="m-scrollable m-scroller" data-height="450"
                    style="height: 450px; overflow: auto; padding-right: 10px;">
                    <p>These general terms & conditions constitute a legally binding and enforceable contract between
                        the Users of this portal and National Skill Development Corporation, with registered office at
                        <!-- 301, 3rd Floor, West Wing Worldmark 1, Aerocity, New Delhi-110037  -->
                        Kaushal Bhawan, 5th-6th Floors, New Moti Bagh, New Delhi – 110023
                        (hereinafter referred to as <b>NSDC</b>).</p>
                    <p>These terms along with any other offer specific terms as may be issued by NSDC from time to time,
                        including individual contracts NSDC may have with you shall constitute the entire understanding
                        between NSDC and you.
                    </p>

                    <ol>
                        <li>
                            <b>Consent for use of Information:</b>
                            <ul>

                                <li>This portal/ SIP/website/application is created only to facilitate easy collection
                                    and sharing of data and to facilitate efficient roll out of schemes and other
                                    incentives. The user shall be solely responsible for his/her
                                    information, content, messages or other information that he/she submits. By entering
                                    such information such you automatically grant to NSDC an irrevocable, perpetual,
                                    non-exclusive, consent to use such information to
                                    facilitate its business operations. It also means you have read, reviewed,
                                    understood and agreed to abide by the Privacy Policy as well.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>User Covenants:</b>
                            <ul>
                                <li>&nbsp;Users of this portal are not permitted to host, display, upload, modify,
                                    publish, transmit, update or share any information that:
                                    <ul>
                                        <li>belongs to another person and to which the user does not have any right to;
                                        </li>
                                        <li>intentionally submitting to the Website/portal/SIP/Application any
                                            incomplete, false or inaccurate information;</li>
                                        <li>violating or attempting to violate the integrity or security of the Website/
                                            portal or any NSDC content;</li>
                                        <li>making any unsolicited communications to other users;</li>
                                        <li>deceives or misleads the addressee about the origin of such messages or
                                            communicates any information which is grossly offensive or menacing in
                                            nature;
                                        </li>
                                        <li>impersonate another person;</li>
                                        <li>contains software viruses or any other computer code, files or programs
                                            designed to interrupt, destroy or limit the functionality of any computer
                                            resource;
                                        </li>
                                        <li> using any engine, software, tool, agent or other device or mechanism (such
                                            as spiders, robots, avatars or intelligent agents) to navigate or search the
                                            Website/portal/SIP/Application;
                                        </li>
                                        <li> attempting to decipher, decompile, disassemble or reverse engineer any part
                                            of the Website/portal/SIP/Application
                                        </li>
                                        <li>is grossly harmful, harassing, blasphemous, defamatory, obscene,
                                            pornographic, peadophilic, libelous, invasive of another's privacy, hateful,
                                            or racially, ethnically objectionable, disparaging, relating or encouraging
                                            money laundering or gambling, or otherwise unlawful in any manner whatever;
                                        </li>
                                        <li>violates any law for the time being in force;
                                        </li>
                                    </ul>

                                </li>
                            </ul>
                        </li>



                        <li>
                            <b>Liability:</b>

                            <ul>
                                <li>Users of this Portal agree that it is merely an electronic platform to aggregate
                                    information and is not meant for provision of any specific service.
                                    <ul>
                                        <li>NSDC shall not be responsible or liable in any manner to the users of this
                                            portal for any losses, damage, injuries or expenses incurred by the Users as
                                            result of any disclosures made by NSDC. </li>
                                        <li>NSDC and their representatives, officers, employees, agents and contractors
                                            shall not be liable for any loss, damage, claim, expense, cost (including
                                            legal costs) or liability arising directly or indirectly from
                                            your use or non-use of this portal/ SIP/website/application </li>

                                    </ul>
                                </li>

                            </ul>

                        </li>
                        <li>
                            <b>Information Accuracy:</b>
                            <ul>
                                <li>NSDC does not provide or make any representation as to the quality, accuracy, bona
                                    fides or general or specific nature of any of the data available in this
                                    portal/SIP. This Site, Application, portal, SIP and all content
                                    on it and the Application are provided on an “as is” basis without warranties of any
                                    kind, either express or implied, including without limitation warranties of title or
                                    implied warranties of merchantability or fitness
                                    for a particular purpose.
                                </li>
                            </ul>
                        </li>

                        <li>
                            <b>Indemnity:</b>
                            <ul>
                                <li>
                                    By accepting these User Terms and using the portal, you agree that you shall defend,
                                    indemnify and hold NSDC, its affiliates, their partners and each of its officers,
                                    directors, other users, employees, attorneys and agents harmless from and against
                                    any
                                    and all claims, costs, damages, losses, liabilities and expenses (including
                                    attorneys' fees and costs) arising out of or in connection with: (a) Your violation
                                    or breach of any term of these User Terms or any Applicable
                                    Law or regulation, whether or not referenced herein; (b) Your violation of any
                                    rights of any third party and (c) Your use or misuse of the portal/
                                    SIP/website/application
                                </li>
                            </ul>
                        </li>

                    </ol>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade conatiner notranslate" id="m_modal_1_3" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header textcetre">
                <h3 class="modal-title text-center" id="exampleModalLabel"> <b>{{'SMART_VERSION' | translatePipe}}
                    </b></h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="m-scrollable m-scroller" data-height="450" style="overflow: auto; padding-right: 10px;">
                    <!-- <p>The black out period of SMART portal for onboarded Training Providers (TPs) and Training Centres
                        (TCs) was from 1st May 2019 to 31st May 2019. The period was used for data migration and
                        validation of the same from old SMART portal to Skill India Digital. We are now going live with
                        Beta Version of SMART on Skill India Digital. The transition will happen in two phases as under :
                    </p> -->

                    <ul>
                        <!-- <li><b>1st Phase</b> will commence from 1st June 2019</li>
                        <li><b>2nd Phase</b> will commence within first week of June 2019</li>
                        <p class="model-top"><strong>Modules/Functionalities which will be accessible in <b>Phase 1</b>:
                            </strong>
                        </p> -->
                        <p class="model-top">
                            <b>{{'SMART_BETA_VER' | translatePipe}}</b>
                        </p>
                        <ul class="f">
                            <li>{{'OLD_TP' | translatePipe}}</li>
                            <li>{{'START_REGISTRATION' | translatePipe}} </li>
                            <li> {{'SMART_CAAF' | translatePipe}} </li>
                            <li> {{'SMART_TC_CREATED' | translatePipe}} </li>
                            <li> {{'SMART_TC_LOGIN' | translatePipe}} </li>
                            <li>{{'TP/TC_STAKEHOLDER' | translatePipe}} </li><br>
                            <h5 class="align-text"><b>{{'RESET_PASSWORD' | translatePipe}}</b></h5>
                            <b class="align-text"><span
                                    style="text-decoration: underline">{{'Exceptions' | translatePipe}}</span>
                            </b>
                            <br><br>
                            <li>
                                <span>{{'DNR_TC' | translatePipe}} </span>
                                <a href="https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/QPCode.xlsx"
                                    target="_blank" rel="noopener">
                                    <b>{{'QP_LINK' | translatePipe}}</b>
                                </a>
                                <span> {{'DNR_TC_DATA' | translatePipe}}</span>
                            </li><br>
                            <li>{{'DATA_MIGRATION' | translatePipe}} </li>
                        </ul>
                        <!-- <h5 class="underline-p text-center"><b>Please use Forget Password link to change your password on first login with your existing SMART User ID.</b></h5>
                        <p class="model-top"><strong>Modules/Functionalities which will be accessible in <b>Phase 2</b>:

                            </strong>
                        </p>
                        <li> TCs which had submitted their forms on the old SMART website will be able to login on Skill
                            India portal and carry out required actions
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    {{'CLOSE' | translatePipe}}</button>
            </div>
        </div>
    </div>
</div>