<div class="m-content">
    <div class="container">
        <div class="col-12">
            <div class="session-timeout-block">
                <div class="row sip-row">
                    <div class="timeout-header">
                        <p class="header">Session Expired !!</p>
                    </div>
                </div>
                <div class="row sip-row">
                    <div class="Expired-message">
                        <h6 class="session-reason">To protect your privacy, sessions are ended after 1 hour of inactivity.. Kindly<br> login again to renew session</h6>
                    </div>
                </div>
                <div class="row sip-row">
                    <div class="login-button">
                        <button class="homepage-btn" (click)="goToLogin()">Login Again</button>
                    </div>
                </div>
                <div class="help-message">
                    <h6>Need Help? Call us on 1800-123-9626</h6>
                </div>
            </div>
        </div>
    </div>
</div>
