export const Constants = {
	RECORDSPERPAGE: 10,
	QPStatusIDForBorrowQP: '2',
	QPStatusForBorrowQP: 'Awaiting Standards Approval',
	PMKVY: 'pmkvy',
	FEE_BASED: 'feeBased'
};

export const EmploymentConstants = {
	SELFEMPLOYMENT: 'Self Employment',
	WAGEEMPLOYMENT: 'Wage Employment',
	APPRENTICESHIP: 'Apprenticeship',
	AGRICULTURE: 'Agriculture'
};

export const AMBER_RETENTION_DOCUMENT_TYPE = [
	"Salary slip issued by the employer",
	"Bank transaction details (such as NEFT details, bank account statement, or passbook entries) indicating monthly inflow in the retention period",
	"Salary certificate issued by the employer",
	"Proof of PF/ ESIC deduction",
	"Cash payment Proof – Bank Deposit Receipt+Employer Confirmation Letter",
	"Any other document approved by the PMU (specify)",
]
export const PlacementUsers = {
	TRAINING_CENTRE: 'Training Centre',
	PLACEMENT_OFFICER: 'PMKVYPO',
	PLACEMENT_SP_PMU: 'Placement SP Pmu',
	PLACEMENT_STT_PMU: 'Placement STT Pmu',
	PLACEMENT_AMBER_PMU: 'Placement Amber Pmu',
	PLACEMENT_SP_ADMIN: 'Placement SP Admin',
	PLACEMENT_STT_ADMIN: 'Placement STT Admin',
	PLACEMENT_SP_FV: 'Placement SP FieldVerification',
	PLACEMENT_STT_FV: 'Placement STT FieldVerification',
	PLACEMENT_AMBER_FV:'Amber Field Verification Officer',
	PLACEMENT_MOHUA_PMU:'Placement Mohua Pmu'
}

export const qpHoursTypes = [
	{
		id: 1,
		name: 'Scheme Hours'
	},
	{
		id: 2,
		name: 'Orientation Hours'
	}
]
export const schemeBatchStatus = [
	{
		'id': 1.0,
		'name': 'Batch Started',
		'description': ''
	},
	{
		'id': 2.0,
		'name': 'Batch Assessed',
		'description': ''
	},
	{
		'id': 3.0,
		'name': 'Batch Placed',
		'description': ''
	},
	{
		'id': 4.0,
		'name': 'Certificate Ceremony',
		'description': ''
	},
	{
		'id': 5.0,
		'name': 'Reward Ceremony',
		'description': ''
	}
]

export const employmentTypes = [
	{
		id: 1,
		name: 'Apprenticeship'
	},
	{
		id: 2,
		name: 'Waged'
	},
	{
		id: 3,
		name: 'Self-Employed'
	}
]

// commented below block on march 5 2k19 as harsha told to use above status combination
/* export const schemeBatchStatus = [
	{
		id: 1,
		name: 'Batch Started'
	},
	{
		id: 2,
		name: 'Batch Submitted for Assessment'
	},
	{
		id: 3,
		name: 'Batch Certified'
	},
	{
		id: 4,
		name: 'Batch Complete + 1 months'
	},
	{
		id: 5,
		name: 'Batch Complete + 2 months'
	},
	{
		id: 6,
		name: 'Batch Complete + 3 months'
	},
] */

export const SchemeVersionStatus = {
	// Form editable statusses
	status1: 'New',
	status2: 'Draft',
	status3: 'BusinessOwner_Rejected',
	status4: 'NsdcAdmin_Rejected',
	// BO screen Status
	status5: 'SchemeAdmin_Submitted',
	// NSDC Admin Status
	status6: 'BusinessOwner_Approved',
	// scheme Admin Non-editable statuses
	status7: 'Approved',
	status8: 'Pending'
};

export const ActorRoles = {
	bo: 'Business Owner',
	nsdc: 'NSDC Admin',
	schemeAdmin: 'Scheme Admin',
	schemeOwner: 'Scheme Owner',
	commonNormsAdmn: 'Common Norms Admin',
	nsdcAdmin: 'NSDCAdmin'
};

export const qpTypes = [
	{
		id: 1,
		name: 'Technical'
	},
	{
		id: 2,
		name: 'Non-Technical'
	},
	{
		id: 3,
		name: 'Both'
	}
]

export const disclaimerCertidicationAccessorAndTrainer = {
	disclaimer: 'I/ We hereby confirm that the information provided above is true to the best of my/ our knowledge.'
}

export const GRADINGMATRIXCOLORS = [
	{
		id: 1,
		name: 'Gold',
		value: '#d4af37'
	}, {
		id: 2,
		name: 'Silver',
		value: '#c0c0c0'
	}, {
		id: 3,
		name: 'Blue',
		value: '#3db6ff'
	}
]

export const PRIVATE_VAR_STORE_PREFIX = '__';

export const NIA_REQUIRED_DAYS = 15;

export const testScheme = [{
	"_id": "5d2ebe81a0b5741df28f7aba",
	"linkedCommonNormDetails": {
		"overwriteCommonNorm": false,
		"overridenCommonNormDetails": []
	},
	"schemeDetails": {
		"trancheSetupDetails": {
			"tranchPaymentDetails": [
				{
					"priority": 1,
					"paymentPercentage": 10,
					"batchStatusToInitiate": {
						"id": 1,
						"name": "Batch Started",
						"description": ""
					},
					"noOfDaysForTranchInvoiceGeneration": 12
				},
				{
					"priority": 2,
					"paymentPercentage": 80,
					"batchStatusToInitiate": {
						"id": 3,
						"name": "Batch Placed",
						"description": ""
					},
					"noOfDaysForTranchInvoiceGeneration": 12
				},
				{
					"priority": 3,
					"paymentPercentage": 10,
					"batchStatusToInitiate": {
						"id": 5,
						"name": "Reward Ceremony",
						"description": ""
					},
					"noOfDaysForTranchInvoiceGeneration": 12
				}
			],
			"noOfTranches": 3
		},
		"creationDetails": {
			"description": "asdsa",
			"requiredDocuments": "asdad",
			"religionsCatered": [
				{
					"religionId": "3",
					"religionName": "Christian"
				},
				{
					"religionId": "1",
					"religionName": "Hindu"
				}
			],
			"categoriesCatered": [
				{
					"categoryId": "3",
					"categoryName": "SC"
				},
				{
					"categoryId": "1",
					"categoryName": "General"
				}
			],
			"gendersCatered": [
				{
					"genderId": 1,
					"genderName": "Men"
				},
				{
					"genderId": 2,
					"genderName": "Women"
				}
			],
			"isDisabilityRequired": true,
			"consideredDisabilities": [
				{
					"disabilityId": "3",
					"disabilityName": "Acid Attack Victims"
				},
				{
					"disabilityId": "4",
					"disabilityName": "Blindness"
				},
				{
					"disabilityId": "5",
					"disabilityName": "Cerebral Palsy"
				}
			],
			"aadhaarNonMandatoryStates": [
				{
					"id": 35,
					"name": "ANDAMAN AND NICOBAR ISLANDS"
				},
				{
					"id": 28,
					"name": "ANDHRA PRADESH"
				},
				{
					"id": 12,
					"name": "ARUNACHAL PRADESH"
				}
			]
		},
		"tcRegistrationProcessDrivenDetails": [
			{
				"tcRegistrationDetails": {
					"aAndAMinimumTcStarRating": 2
				},
				"registrationProcessType": {
					"id": 1,
					"name": "A&A",
					"description": ""
				}
			},
			{
				"tcRegistrationDetails": {
					"aAndAMinimumTcStarRating": null
				},
				"registrationProcessType": {
					"id": 2,
					"name": "Non-A&A",
					"description": ""
				}
			}
		],
		"trainingPartnerDetails": {
			"tpTypeDetails": [
				{
					"tpType": {
						"id": 1,
						"name": "Gov",
						"description": ""
					}
				},
				{
					"tpType": {
						"id": 2,
						"name": "Non-Gov",
						"description": ""
					}
				},
				{
					"tpType": {
						"id": 3,
						"name": "Gov-MOU",
						"description": ""
					}
				}
			],
			"targetAllocationRequired": true,
			"targetAllocationDetails": {
				"allocationProvisionFor": [
					{
						"id": 1,
						"name": "TP/PIA/SPIA",
						"description": ""
					},
					{
						"id": 2,
						"name": "TC/Location/Employer",
						"description": ""
					}
				],
				"allocatedAtLevel": [
					{
						"name": "Sector",
						"id": 1,
						"description": ""
					},
					{
						"name": "Jobrole / QP",
						"id": 2,
						"description": ""
					},
					{
						"name": "State Wise",
						"id": 3,
						"description": ""
					}
				]
			}
		},
		"batchCreationDetails": {
			"minNoOfCandidatesEnrolledBeforeBatchStart": 12,
			"sscAprovalRequiredForBatch": true,
			"proposedBatchTimings": {
				"start": "2019-07-16T19:30:00.000Z",
				"end": "2019-07-17T08:30:00.000Z"
			},
			"proposedBatchSize": {
				"min": 37,
				"max": 161
			},
			"proposedBatchDuration": 1,
			"isTotCertifiedTrainerRequired": true,
			"minPercentageAttendaceForTranche": 70,
			"noOfDays": {
				"untilTcCanEditBatch": 38,
				"asBufferAfterBatchEnd": 57,
				"withinSscHasToApproveBatch": 7,
				"priorAsessementDatesForBatchDateUpdate": 86,
				"withinSscHasToApproveOrRejectTrainerChangeRequest": 40,
				"minFromBatchStartDateToCheckForMinAttendence": 17,
				"bufferForAssementPostBatchCompletion": 6,
				"bufferAfterBatchCreationForBatchToStart": 11,
				"priorBatchCanBeCancelled": 38
			}
		},
		"assessmentDetails": {
			"minNoOfCandidatesForAssessmentFeePayment": 66,
			"percentageOfAssessmentFeeReductionInCaseDelayInApprovingMarksBySsc": 15,
			"noOfChancesSscCanChangeAaForBatch": 63,
			"maxPercentageAgeOfBatchesAfterWhichSscCant": 19,
			"noOfChancesAaCanChangeAssessorForBatch": 18,
			"isToaCertifiedAssessorRequired": false,
			"noOfDays": {
				"withinSscHasToAssignAaForAssessment": 3,
				"withinAaHasToValidatesTheMarks": 38,
				"withinAssessorShouldRespondOnAssigningForAssessment": 31,
				"aAHasToAssignAssessorForAssessment": 30,
				"withinReAssessmentReqCanbeRaisedFromAssessmentDate": 38,
				"priorAssessmentDateChangeReqFromCurrentAssessmentDate": 27,
				"withinAssessorCanBeChanged": 39,
				"withinAssessmentDateShouldBeSelected": 14,
				"forMarksVerificationAndValidation": 10,
				"withinWhichReAssessmentToBeCompleted": 98,
				"priorAssessmentAgencyCanBeChanged": 15
			}
		},
		"certificationDetails": {
			"isCertificationCeremonyRequired": true
		},
		"costElementConfigurationDetails": {
			"costElementConfigs": [
				{
					"costElement": {
						"id": 6,
						"name": "Conveyance",
						"description": ""
					},
					"beneficiary": [
						{
							"id": 1,
							"name": "Candidate",
							"description": ""
						},
						{
							"id": 2,
							"name": "Training Partner",
							"description": ""
						},
						{
							"id": 3,
							"name": "SSC",
							"description": ""
						}
					],
					"batchTriggerStage": {
						"id": 1,
						"name": "Batch Started",
						"description": ""
					},
					"candidateEligibility": [
						{
							"id": 1,
							"name": "Men",
							"description": ""
						},
						{
							"id": 2,
							"name": "Women",
							"description": ""
						},
						{
							"id": 3,
							"name": "Transgender",
							"description": ""
						}
					],
					"isPWDRequired": true,
					"candidateStateGroup": [
						{
							"id": 2,
							"name": "North East",
							"description": ""
						},
						{
							"id": 3,
							"name": "Jammu & Kashmir",
							"description": ""
						},
						{
							"id": 3,
							"name": "Left Wing Extremists",
							"description": ""
						}
					],
					"domicile": [
						{
							"id": 1,
							"name": "Within District",
							"description": ""
						},
						{
							"id": 2,
							"name": "Outside District",
							"description": ""
						}
					],
					"noOfDays": {
						"fromStage": 12,
						"additionalRequired": 12
					}
				}
			]
		}
	},
	"currentStateDetails": {
		"qpInstantiationDetails": {
			"completed": true,
			"atleastOneQPOverriden": true,
			"status": "submitted",
			"individualPieceDetails": {
				"marksDetails": {
					"status": "submitted",
					"actor": {
						"role": "Standards Team"
					}
				}
			}
		},
		"overridenCommonNormsDetails": {
			"completed": false
		},
		"schemeRulesDetails": {
			"completed": false
		}
	},
	"isVisible": true,
	"isUpdatedNewVersion": false,
	"qpInstantiationDetails": [
		"5d2eed487751f519712dcf9a",
		"5d2eed487751f519712dcf9b"
	],
	"active": false,
	"expired": false,
	"schemeId": "SCHEME-ec8pgfqtiw",
	"status": "submitted",
	"basicDetails": {
		"schemeName": {
			"id": 2,
			"name": "PMKVY-CSSM",
			"description": ""
		},
		"trainingType": {
			"id": 1,
			"name": "STT",
			"description": ""
		},
		"subSchemeName": {
			"id": 4,
			"name": "PMKK",
			"description": ""
		},
		"state": {
			"id": 5,
			"name": "UTTARAKHAND"
		},
		"department": "MSDE",
		"paymentAdviceRequired": true
	},
	"activityLog": {
		"createdOn": "2019-07-17T06:21:53.451Z",
		"createdBy": {
			"userName": "NSDCADMIN",
			"role": "NSDC Admin",
			"firstName": ""
		},
		"lastModifiedDate": "2019-07-17T09:41:28.613Z"
	},
	"workflowDetails": {
		"assigneeDetails": [
			{
				"userName": "NSDCADMIN",
				"role": "NSDC Admin"
			},
			{
				"role": "Standards Team"
			},
			{
				"userName": "BO_000164",
				"role": "Business Owner",
				"firstName": "Pavan"
			},
			{
				"role": "Scheme Owner",
				"userName": "SO_000115",
				"firstName": "Pavan",
				"lastName": "Raju"
			},
			{
				"role": "Scheme Admin",
				"userName": "SchemeAdmin_000110",
				"firstName": "Pavan",
				"lastName": "Raju"
			}
		],
		"submitted": {
			"to": {
				"role": "Standards Team"
			},
			"by": {
				"userName": "BO_000164",
				"role": "Business Owner",
				"firstName": "Pavan"
			}
		}
	},
	"schemeTypeCombination": "pmkvy-cssm_stt_pmkk_uttarakhand",
	"createdVersion": 1,
	"__v": 8,
	"isPlacementRequired": false,
	"rulesEffectiveFrom": "2019-07-18T00:00:00.000Z",
	"rulesEffectiveTo": "2019-09-20T00:00:00.000Z",
	"qpInstantiationBunchDetails": {
		"gradingMatrixBunchDetails": {
			"gradingMatrixDetails": [
				"5d2eed347751f519712dcf98"
			]
		}
	}
}]
