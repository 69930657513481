import {
	StorageService
} from './storage.service';
import {
	Router,
	NavigationEnd,
	ActivatedRoute
} from '@angular/router';
import {
	Injectable
} from '@angular/core';
import {
	CurrentUserService
} from './current-user.service';
import {
	BatchService
} from './API/batch.service';
import {
	ApplyBatchesService
} from './apply-batches.service';
import {
	USER_ROLES_STRING
} from 'src/app/scheme-constants/scheme-constants';
import {
	STORE_KEY_NAME, TRAINING_CENTRE_TYPE
} from 'projects/training-partner/src/app/training-partner/constants/string.constant';
import { AA_TC_STATUS } from 'src/app/constant/pmkvy-constant';
declare var toastr: any;
declare var swal: any;
@Injectable({ providedIn: 'root' })
export class RoutingService {
	status: string;
	Userstatus: any;
	previousUrl: string;
	currentUrl: string;
	currentUser: any;
	isCovidSpace: Boolean = false;

	constructor(
		private router: Router,
		private storageService: StorageService,
		private applyBatchService: ApplyBatchesService,
		private _batchService: BatchService,
		private currentUserService: CurrentUserService,
		private activatedRoute: ActivatedRoute
	) {

		this.currentUrl = this.router.url;
		router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			}
		});
	}

	// goNext() {
	// 	if (this.storageService.getObject('access_token')) {
	// 		this.currentUserService.getAdditionalInfomartion().subscribe(
	// 			currentUser => {
	// 				console.log(currentUser);
	// 				this.currentUser = currentUser;
	// 				if (currentUser == null) {
	// 					if (this.router.url !== '/') {
	// 						this.router.navigate(['/login']);
	// 					}
	// 				} else {
	// 					this.currentUserService.setCurrentUser(currentUser);
	// 					const role = currentUser.role
	// 						.replace(/ +/g, '')
	// 						.toLowerCase();
	// 					console.log(role);

	// 					this.navigateToDashboard(role, currentUser.status);
	// 				}
	// 			},
	// 			err => {
	// 				this.router.navigate(['/login']);
	// 			}
	// 		);
	// 	}
	// }
	goNext() {
		console.log(".............................................welcome!.........")
		if (this.storageService.getObject('access_token')) {
			this.currentUserService.getAdditionalInfomartion().subscribe(
				currentUser => {
					// localStorage.setItem('loginUserData',  JSON.stringify(currentUser))
					console.log(currentUser);
					this.currentUser = currentUser;
					if (currentUser == null) {
						if (this.router.url !== '/') {
							this.router.navigate(['/login']);
						}
					} else {
						this.currentUserService.setCurrentUser(currentUser);
						const role = currentUser.role
							.replace(/ +/g, '')
							.toLowerCase();
						console.log(role);
						this.Userstatus = currentUser.status;
						console.log('this.Userstatus', this.Userstatus);
						const batchId = localStorage.getItem('__Id');
						const batchType = localStorage.getItem('__batchType');
						if ((role === 'trainer' || role === 'assessor' || role === 'proctor') && (batchId !== null)) {
							// this._batchService.applyForBatch(batchId, batchType).subscribe(res => {
							// 	if (res) {
							this.navigateToDashboard(role, this.status);
							// 	localStorage.removeItem('__Id');
							// 	localStorage.removeItem('__batchType');
							// 	swal(
							// 		'Applied Successfully',
							// 		'You have successfully applied for ' + batchId,
							// 		'success'
							// 	);
							// }
							// }, err => {
							// 	console.log('entering');
							// 	this.status = this.Userstatus;
							// 	this.navigateToDashboard(role, this.status);
							// })
						} else if (role === 'candidate') {
							const bId = localStorage.getItem(STORE_KEY_NAME.batchId);
							const tpId = localStorage.getItem(STORE_KEY_NAME.tpId);
							this._batchService.updatePmkvyEnrollCandidiates(bId, tpId).subscribe(res => {
								console.log(res);
								if (res) {
									this.status = this.Userstatus;
									// this.router.navigate(['/candidate/view-my-batches']);
									this.router.navigate(['/candidate/batch-details', bId]);
									localStorage.removeItem(STORE_KEY_NAME.batchId);
									localStorage.removeItem(STORE_KEY_NAME.tpId);
									swal(
										'Candidate Enroll Success for ' + bId,
										'success'
									);
								}
							}, err => {
								console.log('entering');
								this.status = this.Userstatus;
								this.navigateToDashboard(role, this.status);
							})
						} else if (role === 'trainingpartner') {
							this.status = this.Userstatus;
							this.navigateToDashboard(role, this.status, this.currentUser.deactivationRemarks);
						} else if (role === USER_ROLES_STRING.SSDM_PMU_SP || role === USER_ROLES_STRING.SSDM_PMU_STT ||
							role === USER_ROLES_STRING.SSDM_PMU_RPL) {
							this.isCovidSpace = this.activatedRoute.snapshot.queryParams.isCovidSpace;
							this.navigateToDashboard(role, this.status);
						} else if (role === "ssc" && this.currentUser.type === 'awardingBody') {
							this.navigateToDashboard(role, this.currentUser.status, "", this.currentUser.type)
						} else if (role == 'inspectionagency' && currentUser.type === 'pmkvy4.0 RPL') {
							this.navigateToDashboard(role, '','', 'pmkvy4' );
						}
						else if (role == 'SEO') {
							this.navigateToDashboard(role, this.status);
						}
						else {
							this.status = this.Userstatus;
							if (role == 'assessmentagency') {
								let sid;
								if (this.currentUser && this.currentUser.sid) {
									sid = "sidaa"
								}
								this.navigateToDashboard(role, this.status, sid, sid);
							} else {
								this.navigateToDashboard(role, this.status);
							}
						}

					}
				},
				err => {
					this.router.navigate(['/login']);
				}
			);
		}
	}
	public getPreviousUrl() {
		return this.previousUrl;
	}
	navigateToDashboard(role: string, status: string, deactivationRemarks?: string, userType?: string) {
		this.credentialPopup()
		if (role === "ssc" && userType === 'awardingBody') {
			switch (status) {
				case 'init':
					this.router.navigate(['/ab-registration/update-ab-details']);
					// this.router.navigate(['/assessment-agency-registration/update-assessment-agency-details'])
					break;
				case 'Pending for Approval':
					this.router.navigate(['/ab-profile']);
					break;
				case 'Rejected':
				case 'Scrap':
					this.router.navigate(['/ab-registration/update-ab-details']);
					break;
				case 'Active':
				case 'Approved':
					this.router.navigate(['/ssc']);
					break;
			}
		} else if (role === 'assessmentagency'){
			if (userType === 'sidaa') {
				switch (status) {
					case 'init':
						this.router.navigate(['/assessment-agency-registration/update-assessment-agency-details'])
						break;
					case 'New Request':
						this.router.navigate(['/assessment-agency-registration/profile-preview'], {queryParams:{isForPreview:true}})
						break;
					case 'Approved':
						this.router.navigate(['/assessmentagency'])
						break;
					case 'Accepted':
						this.router.navigate(['/assessmentagency'])
						break;
					case 'Sent Back': 
						this.router.navigate(['/assessment-agency-registration/update-assessment-agency-details']);
						// this.router.navigate(['/assessment-agency-registration/profile-preview'], {queryParams:{isForPreview:true}})
						break;
					case 'Discard':
						// this.router.navigate(['/assessment-agency-registration/update-assessment-agency-details'])
						this.router.navigate(['/assessment-agency-registration/profile-preview'], {queryParams:{isForPreview:true}})
						break;
				}
			} else {
				localStorage.setItem('role', role);
				this.router.navigate([`${role}`]);
			}
			// this.router.navigate(['/assessment-agency-registration/update-assessment-agency-details'])
		}else if (role === 'trainingpartner') {
			switch (status) {
				case 'init':
					// TO DO : in this case first time user has to change password
					// then he will able to submit form
					this.router.navigate(['/tp-registration/update-details']);
					break;
				case 'paymentAwaiting':
					// TO DO : in this case first time user has to change password
					// then he will able to submit form
					this.router.navigate(['/tp-registration/update-details']);
					break;
				case 'needtofillform':
					// in this case user will be redirect to registration- form update profile.
					this.router.navigate(['/registration/update-details']);
					break;
				case 'approved':
				case 'Applied RE-DA':
				case 'Under Re-assessment':
					// in this case user will redirect to main dashboard
					this.router.navigate(['/trainingpartner']);
					break;
				case 'Deactivated/blocked':
					if (deactivationRemarks) {
						toastr.error(`${deactivationRemarks}`);
					} else {
						toastr.error(
							'Application de-activated due to inactivity for 60 Days since User Id creation');
					}
					break;

				default:
					// for rest of the cases user will be redirected to track my profile application status dashboard
					this.router.navigate(['/inprocesstp']);
			}
		} else if (role === 'trainer') {
			console.log(status);
			switch (status) {
				case 'registrationInComplete':
					this.router.navigate(['/user-registration/trainer']);
					break;
				case 'registrationCompleted':
					this.router.navigate(['/trainer/applicant']);
					break;
				case 'apply':
					this.router.navigate(['/trainer/applicant/view-batches']);
					break;
				default:
					this.router.navigate(['/trainer/applicant']);
			}
		} else if (role === 'candidate') {
			this.router.navigate(['/candidate']);
			// if (this.currentUser.hasFullyReg) {
			// 	this.router.navigate(['/candidate']);
			// } else {
			// 	this.router.navigate([
			// 		'/candidate-registration/update-details'
			// 	]);
			// }
		} else if (role === 'candidateadmin') {
			this.router.navigate(['/candidateadmin']);
		} else if (role === 'assessor') {
			switch (status) {
				case 'registrationInComplete':
					this.router.navigate(['/user-registration/assessor']);
					break;
				case 'registrationCompleted':
					this.router.navigate(['/assessor/applicant']);
					break;
				case 'apply':
					this.router.navigate(['/assessor/applicant/view-batches']);
					break;
				default:
					this.router.navigate(['/assessor']);
			}
		} else if (role === 'proctor') {
			switch (status) {
				case 'registrationInComplete':
					this.router.navigate(['/user-registration/proctor']);
					break;
				case 'registrationCompleted':
					this.router.navigate(['/proctor/applicant']);
					break;
				case 'apply':
					this.router.navigate(['/proctor/applicant/view-batches']);
					break;
				default:
					this.router.navigate(['/proctor']);
			}
		} else if (role === 'trainingcentre') {
			if(this.currentUser.trainingCenterType == TRAINING_CENTRE_TYPE.PMVK_RPL_GOVT || this.currentUser.trainingCenterType == TRAINING_CENTRE_TYPE.PMVK_RPL_NON_GOVT) {
				toastr.error('Invalid User');
				this.router.navigate(['/login']);
				return;
			}
			// this.router.navigate(['/user-registration/trainingCenter/registration']);
			if (this.currentUser.qpCodeMismatch && this.currentUser.qpCodeMismatch === true) {
				toastr.error('Your Application is still in progress');
				this.router.navigate(['/login']);
			} else {
				switch (status) {
					case 'TC_CREATED':
						if (this.currentUser.status === AA_TC_STATUS.TC_CREATED && this.currentUser.trainingCenterType === TRAINING_CENTRE_TYPE.SPECIAL_SCHEMES_HOSPITAL ||
							this.currentUser.trainingCenterType === TRAINING_CENTRE_TYPE.SPECIAL_SCHEMES_HOSPITAL_STATES ||
							(this.currentUser.trainingCenterType === 'Skill Hub under PMKVY 3.0') ||
							(this.currentUser.MigratedFrom === 'pmkvy3.0-ITI')) {
							this.router.navigate([
								'/trainingcentre/training-of-trainers'
							]);
						} else {
							this.router.navigate(['/tc-registration']);
						}
						break;
					case 'paymentAwaiting':
						// TO DO : in this case first time user has to change password
						// then he will able to submit form
						this.router.navigate(['/tc-registration']);
						break;
					case 'Approved':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					case 'reInspectionFromSubmitted':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					case 'blockedDueToInspectionReject':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					case 'paymentDoneForUpdateCaaf':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break
					case 'appliedForUpdateCaaf':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break
					case 'paymentDoneForReAccrediation':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break
					case 'appliedForReAccrediation':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break
					case 'Qualified':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					case 'RECOMMENDED':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					case 'NOTRECOMMENDED':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;

					case 'reRequest':
						this.router.navigate(['/inprocesstc']);
						break;
					case 'Not Qualified':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					case 'approved':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					case 'reInspection':
						this.router.navigate([
							'/trainingcentre/training-of-trainers/tc-edit-caaf/re-inspection/mobile-upload'
						]);
						break;
					case 'blocked':
						toastr.error(
							'You Application is permanently rejected by the PMU Team'
						);
						this.router.navigate(['/login']);
						break;
					case 'init':
						toastr.error('Application is under approval process.You will get a notification once it get approved')
						this.router.navigate(['/login']);
						break;
					case 'adminApprovalPending':
						toastr.error('Application is under approval process.You will get a notification once it get approved')
						this.router.navigate(['/login']);
						break;
					case 'Deactivated/blocked':
						toastr.error(
							'Application de-activated due to inactivity for 60 Days since User Id creation'
						);
						// 	this.router.navigate(['/trainingcentre/training-of-trainers']);
						// this.router.navigate([
						// 	'/trainingcentre/training-of-trainers'
						// ]);
						break;
					case 'applicationWithDrawl':
						toastr.error(
							'Application de-activated due to centre withdrawl'
						);
						break;
					case 'DEACCREDIATED':
						this.router.navigate([
							'/trainingcentre/training-of-trainers'
						]);
						break;
					default:
						this.router.navigate(['/inprocesstc']);
				}
			}
		} else if (role === 'budgetapprover') {
			this.router.navigate(['approver']);
		} else if (role === 'budgetinitiator') {
			this.router.navigate(['approver']);
		} else if (role === 'msde'){
			this.router.navigate(['approver']);
		} else if (role === 'pmkvyfinancespoc') {
			this.router.navigate(['financespoc']);
		} else if (role === 'nsdc admin') {
			localStorage.setItem('role', 'nsdc admin');
		} else if (role === 'PMKVYPO') {
			localStorage.setItem('role', 'PMKVYPO');
			this.router.navigate(['PMKVYPO']);
		} else if (role === 'nsdcadmin') {
			localStorage.setItem('role', 'nsdcadmin');
			this.router.navigate(['nsdcadmin']);
		} else if (role === 'nsdcsuperadmin') {
			localStorage.setItem('role', 'nsdcsuperadmin');
			this.router.navigate(['nsdcsuperadmin/nsdc-admin-home']);
		} else if (role === 'targetpmu' || role === 'rplpmu' || role === 'sppmu' ||
			role === USER_ROLES_STRING.SSDM_PMU_SP || role === USER_ROLES_STRING.SSDM_PMU_STT ||
			role === USER_ROLES_STRING.SSDM_PMU_RPL || role === USER_ROLES_STRING.STATE_SCHEME_PMU ||
			role === USER_ROLES_STRING.AMBER_STT_PMU || role === USER_ROLES_STRING.SANKALP_STT_PMU ||
			role === 'sankalppmu' || role === 'aid_sp' || role === 'aid_stt' || role === USER_ROLES_STRING.STATE_SCHEME_PMU_CHECKER ) {
			this.router.navigate(['pmkvy-pmu'], { queryParams: { 'isCovidSpace': this.isCovidSpace } });
		} else if (role === 'sscschemespoc' || role === 'camps') {
			this.router.navigate(['pmkvy-rpl-IV'])
			// } else if (role === 'placementspfieldverification' || role === 'placementsttfieldverification' || role === 'placementamberfieldverification') {
		} else if (role === 'placementspfieldverification' || role === 'placementsttfieldverification' || role === 'amberfieldverificationofficer') {
			this.router.navigate(['placementadmin'])
		} else if (role === 'placementsppmu' || role === 'placementsttpmu' || role === 'placementamberpmu' || role === 'placementmohuapmu') {
			this.router.navigate(['placement-pmu'])
		} else if (role === 'bestinclassemployer' || role === 'camps') {
			this.router.navigate(['bestinclassemployer'])
		} else if (role === 'Assessment PMU') {
			this.router.navigate(['assessmentpmu']);
		} else if (role === 'employer') {
			this.router.navigate(['pmkvy-rpl-IV'])
		} else if (role === 'cmaspoc' || role === 'ncvtspoc') {
			localStorage.setItem('role', 'cmaspoc');
			this.router.navigate(['cmaspoc']);
		} else if (role === 'cmahead') {
			localStorage.setItem('role', 'cmahead');
			this.router.navigate(['cmahead']);

		} else if (role === 'nsdcpmu') {
			this.router.navigate(['nsdc-pmu'])
		} else if (role === 'ams') {
			this.router.navigate(['ams']);
		} else if (role === 'rozgarmelaagency' || role === 'rozarmelastseteam') {
			this.router.navigate(['rozarmelastseteam'])
		} else if (role === 'enumerator') {
			switch (status) {
				case 'Active':
					this.router.navigate([`${role}`]);
					break;
				case 'Deactivated':
					toastr.error(`${role.toUpperCase()} profile has been deactivated`);
			}
		} else if (role === 'gpspoc') {
			switch (status) {
				case 'Active':
					this.router.navigate([`${role}`]);
					break;
				case 'Deactivated':
					toastr.error(`${role.toUpperCase()} profile has been deactivated`);
			}
		} else if (role === 'dsc') {
			switch (status) {
				case 'Active':
					this.router.navigate([`${role}`]);
					break;
				case 'Deactivated':
					toastr.error(`${role.toUpperCase()} profile has been deactivated`);
			}
		}

		else if (role === 'pmkvy4pmu') {
			this.router.navigate(['ab-ip-pmu']);
		}
		else if (role === 'implementationpartner') {
			this.router.navigate(['implementationpartner']);
		} else if (role === 'nsdcpartneradmin') {
			this.router.navigate(['nsdc-partner-admin']);
		} else if (role === 'inspectionagency' && userType !=='pmkvy4') {
			this.router.navigate(['inspectionagency']);
		} else if (role === 'rpltcpmu') {
			this.router.navigate(['covidschemepmu']);
		}else if (role === 'inspectionagency' && userType =='pmkvy4' ) {
			this.router.navigate(['inspectionagencypmkvy4']);
		}else if (role === 'SEO') {
			this.router.navigate(['seo']);
		}else if (role === 'ministryadmin') {
			localStorage.setItem('role', 'Ministry Admin');
			this.router.navigate(['email-mobile-varification']);
		}else {
			localStorage.setItem('role', role);
			this.router.navigate([`${role}`]);
		}
	}
	getRootURL() {
		const r = this.router.url;
		const routeArr = r.split('/');
		const slicedArr = routeArr.slice(0, 2);
		return `${slicedArr[1]}`;
	}

	credentialPopup(){
		setTimeout(() => { 
			swal({
				title: `<span style="font-family: Arial, sans-serif; font-size: 16px;">Never share your credentials with anyone. If you do share your credentials, you will be solely responsible.</span>`,
				type: "warning",
				showCancelButton: false,
				confirmButtonText: "OK",
				allowOutsideClick: false,
			  }).then((result) => {
				if (result.value) {
				  
				}
			  });
		}, 1000);
	}
}


