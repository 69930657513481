import { Injectable } from '@angular/core';
import { ApiService } from './API/api.service';
import { of } from 'rxjs';

import { StorageService } from './storage.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
@Injectable({ providedIn: 'root' })
export class CurrentUserService {
	public _currentUser: any;
	constructor(
		private apiService: ApiService,
		private storageService: StorageService,
		private jwtHepler: JwtHelperService
	) {
	}
	setCurrentUser(value?: any) {
		console.log('datttaa', value);
		if (this.storageService.getObject('access_token')) {
			if (!value) {
				this.getAdditionalInfomartion().subscribe(loggedinUser => {
					console.log(loggedinUser);
					this._currentUser = loggedinUser;
				});
			} else {
				if (this._currentUser) {
					this._currentUser = Object.assign(this._currentUser, value);
				} else {
					this._currentUser = value;
				}
			}
		} else {
			this._currentUser = null;
		}
	}
	getCurrentUser(): any {
		// console.log('datttaa', this._currentUser);
		return of(this._currentUser)
	}
	
	getCurrentUserLocal(): any {
		// console.log('datttaa', this._currentUser);
		return {
			value:this._currentUser
		}
	}

	getAdditionalInfomartion() {
		const loggedInUserDetailsURL = `${environment.userServiceUrl}/api/user/v1/loggedInUser`;
		return this.apiService.get(loggedInUserDetailsURL);
	}
	// searchFormData(queryParams) {
	// 	const loggedInUserDetailsURL = `${environment.userServiceUrl}/api/user/v1/get/trainingrequest/applicants?` + queryParams;
	// 	return this.apiService.get(loggedInUserDetailsURL);
	// }
	updateToken() {
		const updateTokenUrl = `${environment.authServiceURL}/api/auth`
		return this.apiService.get(updateTokenUrl)
	}
	decodeToken() {
		return this.jwtHepler.decodeToken(this.storageService.getObject('access_token'));
	}
	SetCurrentUserOnLogout() {
		this._currentUser = null;
	}
	getMobileappstatus() {
		const mobileStatus = `${environment.userServiceUrl}/api/user/v1/trainingcentre/mobile/completionstatus`;
		return this.apiService.get(mobileStatus);
	}
	getTPPaymentInfo() {
		const tpRemainingPayment = `${environment.userServiceUrl}/api/user/v1/trainingpartner/add/registration/remainingfee`;
		return this.apiService.get(tpRemainingPayment);
	}

	getCurrentDate() {
		const url = `${environment.smartServiceURL}/api/smart/v1/currentDate`;
		return this.apiService.get(url);
	}

	getRefreshToken() {
		const url = `${environment.authServiceURL}/api/auth/refresh`;
		return this.apiService.get(url);
	}
}
