export const FORMS = Object.freeze({
	SCHEME_FORM: 'scheme_form',
	SECTOR_COURSE_FORM: 'add_form',
});

export const SCHEME_TYPE = Object.freeze({
	PMKVY: 'pmkvy',
	FEE_BASED: 'feeBased',
	NEW_FEEBASED: 'Fee Based',
	STT: 'STT', // 'Short Term Training'
	SP: 'Special Project',
	NEWSP: 'SP',
	RPL: 'RPL', // 'Recognition of Prior Learning',
	NON_PMKVY: 'NON-PMKVY', // 'Recognition of Prior Learning',
});

export const SCHEME_NAME = Object.freeze({
	FEE_BASED: 'Fee Based',
	NON_PMKVY: 'NON-PMKVY',
	PMKK: 'PMKK',
	PMKVY: 'PMKVY',
	MOMA_CSCM: { name: 'MOMA-CSCM', id: 1 },
	MOMA_CSSM: { name: 'MOMA-CSSM', id: 2 },
	PMKVY_SCHEMES: ['PMKVY-CSCM', 'PMKVY-CSSM'],
	PMKVY_SCHEMES_ID: [1, 2],
});

export const PMKVY_SCHEME_TYPES = Object.freeze({
	STT: { ID: 1, type: 'STT' },
	// 'Short Term Training'
	SP: { ID: 2, type: 'SP' },

	RPL: { ID: 3, type: 'RPL' },
	
	TOT: { ID: 100, type: 'TOTA'}// 'Recognition of Prior Learning'
});

export const TRAINING_TYPES = Object.freeze({
	STT: { ID: 1, type: 'STT' },
	// 'Short Term Training'
	SP: { ID: 2, type: 'SP' },

	RPL: { ID: 3, type: 'RPL' }, // 'Recognition of Prior Learning'
});

export const TRAINING_CENTRE_TYPE = Object.freeze({
	PMKK: 'PMKK',
	PMKK_SPOKE: 'PMKK SPOKE',
	NON_PMKK: 'NON PMKK',
	GOVT: 'Government',
	SPECIAL_SCHEMES_HOSPITAL: 'Special Schemes - Hospital',
	SPECIAL_SCHEMES_HOSPITAL_STATES: 'Special Schemes - Hospital(State)',
	COVID_UPSKILL_CENTRAL: 'C4W_Central',
	COVID_UPSKILL_STATE: 'C4W_State',
	COVID_C4W_RFP: 'C4W_RFP',
	SKILL_HUB_STT_UNDER_PMKVY_3: 'Skill Hub under PMKVY 3.0',
	PMVK_RPL_GOVT:"PMVK Government",
	PMVK_RPL_NON_GOVT:"PMVK Non Government",
	PMKVY_RPL_GOVT: "PMKVY RPL Government",
	PMKVY_RPL_NON_GOVT:"PMKVY RPL Non Government"
});

export const VALUES = Object.freeze({
	SCHEME_ID: 'schemeId',
	SCHEME_REFERENCE_ID: 'schemeReferenceId',
	TRAINING_TYPE: 'trainingType',
	SELECTED_SCHEME_ID: 'selectedSchemeId',
	RULE_ID: 'ruleId',
	SCHEME: 'scheme',
	ASSOCIATED_QP_JOBROLE: 'associatedQp/jobrole',
	ADD_SECTOR: 'add-sector',
	ADD_COURSE: 'add-course',
	LIST_PROJECT_MODAL: 'listProjectModal',
	TC_ID: 'tcId',
	OBJECT: 'object',
});

export const BATCH_STAGES = Object.freeze({
	ENROLLMENT: 'enrollment',
	ATTENDANCE: 'attendance',
	ASSESSMENT: 'assessment',
	PLACEMENT: 'placement',
	COMPLETED: 'completed',
	STARTED: 'ongoing',
	APPROVED: 'approved',
	REJECTED_BY_SSC: 'rejectedBySSC',
	FIRST_PLACEMENT_TRACKING_STATUS: 'firstPlacementTrackingStatus',
	SECOND_PLACEMENT_TRACKING_STATUS: 'secondPlacementTrackingStatus',
	THIRD_PLACEMENT_TRACKING_STATUS: 'thirdPlacementTrackingStatus',
	FOURTH_PLACEMENT_TRACKING_STATUS: 'fourthPlacementTrackingStatus',
	FIFTH_PLACEMENT_TRACKING_STATUS: 'fifthPlacementTrackingStatus',
	BATCH_CANCELLED_BY_CMA: 'BATCH_CANCELLED_BY_CMA',
	BATCH_CANCEL_REQUEST_PENDING: 'BATCH_CANCEL_REQUEST_PENDING',
	ENROLLMENT_WITH_ATTENDANCE: 'enrollWithAttendance'
});

export const BATCH_STATUS = {
	CANCELLED_BY_AEBAS: 'CANCELLED BY AEBAS',
	BATCH_CREATED: 'BATCH CREATED',
	BATCH_DRAFT: 'DRAFT',
	BATCH_CANCELLED_BY_SCPWD: 'CANCEL BY SCPWD',
	PENDING_FOR_ASSESSMENT_DATE: 'assigned',
	PENDING_FOR_MARKS_DATE: 'pendingForAssessorDate',
	PENDING_FOR_MARKS_APPROVAL: 'pendingForMarksApproval',
	PENDING_FOR_ASSIGN_ASSESSMENT_AGENCY: 'pendingForAssignAssessementAgency',
	REJECTED_BY_ASSESSOR: 'rejectedByAssessor',
	REJECTED_BY_ASSESSMENT_AGENCY: 'rejectedByAssessmentAgency',
	REJECTED_BY_SSC: 'rejectedBySSC',
	WAITING_FOR_ASSESSMENT_AGENCY: 'waitingForAssessmentAgency',
	WAITING_FOR_ASSESSOR: 'waitingForAssessor',
	MARKS_UPLOADED: 'marksUploaded',
	CERTIFIED: 'certifiedBySSC',
	NOT_CERTIFIED: 'noCertified',
	CANCELLED_DUE_TO_DROPOUT: 'Cancelled by TC due to Candidates Dropped out',
	APPROVED: 'Approved',
	ON_HOLD: 'On Hold',
	REJECTED: 'Rejected',
	SSC_REJECTED: 'SSC REJECTED',
	WAITING_FOR_ENROLLMENT: 'waitingForEnrollment',
	TRAINING_ON_GOING: 'onGoing',
	TRAINING_ATTENDENCE_COMPLETED: 'Training Attendance Completed',
	ASSESSMENT_COMPLETED: 'Assessment Completed',
	CEREMONY_CREATED: 'ceremonyCreated',
	CEREMONY_COMPLETED: 'ceremonyCompleted',
};

export const BATCH_ASSESSMENT_MODE = Object.freeze({
	SSC: 'SSC',
	SELF: 'Self',
	THIRD_PARTY: 'Third Party',
});

export const BATCH_ASSESSMENT_MODE_FOR_SKILL = Object.freeze({
	SELF: 'Self',
});

export const BATCH_TYPES_FOR_INDIA_SKILL  = Object.freeze({
	SSC: 'SSC',
	STATE: 'State',
	DISTRICT: 'District',
	NATIONAL: 'National'
});

export const BATCH_TYPE = Object.freeze({
	REGULAR: 'Regular',
	REASSESSMENT: 'Reassessment',
	OJT: 'OJT',
});

export const BATCH_MODE = Object.freeze({
	ONLINE: 'Online',
	OFFLINE: 'Offline',
});

export const COURSE_JOB_ROLE_MAPPING_TYPE = Object.freeze({
	QP_NOS: 'QP-NOS',
	OTHERS: 'Non-QP/NOS Alligned',
});

export const COURSE_JOB_ROLE_MAPPING_TYPES = [
	COURSE_JOB_ROLE_MAPPING_TYPE.QP_NOS,
	COURSE_JOB_ROLE_MAPPING_TYPE.OTHERS,
];

export const FEE_BASED_TC_TYPE = {
	FIXED: 'Fixed',
	MOBILE: 'Mobile',
};

export const MONTHS = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const STORE_KEY_NAME = Object.freeze({
	batchId: '__Id',
	tpId: '__tpId',
	filterData: '__data',
});
export const TRAINING_PARTNER_TYPE = Object.freeze({
	COMPANY: 'Company',
	FIRM: 'Firm',
	SOCIETY: 'Society',
	TRUST: 'Trust',
	PROPRIETORSHIP: 'Proprietorship',
	GOVERNMENT: 'Government Institute',
	LLP: 'Limited Liability Partnership (LLP)',
});

export const TRAINING_CENTRE_STATUS = Object.freeze({
	BLOCKED: 'Blocked',
	DEACCREDIATED: 'DEACCREDIATED',
	BLOCKED_DUE_TO_INSPECTION_REJECT: 'blockedDueToInspectionReject',
	REJECTED: 'Rejected',
	RE_REQUEST: 'reRequest',
	ON_GOING: 'ongoing',
});
export const TRAINING_CENTRE_TYPE_USER = Object.freeze({
	PMKK: "PMKK PMU",
	IA: "IA",
	PMU: "PMU",
	SSC: "SSC",
	SCPWD: "SCPWD",
});

export const ERROR_RESPONSE_MESSAGE = Object.freeze({
	INVALID_OTP_5_TIMES: "OTP failed to verify 5 times",
});

export const AADHAR_TYPE = Object.freeze({
	VIRTUAL_ID: { value: "virtualID", display: "Virtual ID" },
	AADHAR_NUMBER: { value: "aadharNumber", display: "Aadhar Number" },
	PASSPORT_ID: { value: "passportNumber", display: "Passport Number" },
});

export const NOTICE_OPERATION = Object.freeze({
	INSERT: "insert",
	UPDATE: "update",
	REMOVE: "remove",
});

export const NORTH_EAST_STATES = Object.freeze([
	17, 35, 12, 1, 13, 15, 16, 18, 11, 14, 37
]);

export const SUB_SCHEME_NAME = Object.freeze({
	SKILL_HUB_INITIATIVE: { id: 10, name: "SKILL HUB INITIATIVE" }
});

export const TRACK_BATCH_STATUS = Object.freeze({
	BATCH_ASSMT_RESULT_ASSESSMENT_DONE: 'BATCH_ASSMT_RESULT_ASSESSMENT_DONE',
	// BATCH_ASSMT_RESULT_SSC_ACCEPTED: 'BATCH_ASSMT_RESULT_SSC_ACCEPTED',
	BATCH_CANCELLED_BY_SSC: 'BATCH_CANCELLED_BY_SSC',
	BATCH_CANCELLED_BY_TC: 'BATCH_CANCELLED_BY_TC',
	BATCH_CANCEL_BY_SSDM_PMU: 'BATCH_CANCEL_BY_SSDM_PMU',
	BATCH_AUTO_CANCELLED: 'BATCH_AUTO_CANCELLED',
	BATCH_CANCELLED_BY_TP: 'BATCH_CANCELLED_BY_TP',
	BATCH_CANCELLED_DUE_TO_DROPOUT: 'BATCH_CANCELLED_DUE_TO_DROPOUT',
	BATCH_CANCELLED_DUE_TO_AEBAS_NON_COMPLAINCE: 'BATCH_CANCELLED_DUE_TO_AEBAS_NON_COMPLAINCE',
	BATCH_CANCELLED_BY_SCPWD: 'BATCH_CANCELLED_BY_SCPWD',
	CANCELLED_AS_PER_OM: 'CANCELLED_AS_PER_OM',
	BATCH_CANCEL_BY_ALT_ID_VERIFICATION_REJECTION: 'BATCH_CANCEL_BY_ALT_ID_VERIFICATION_REJECTION',
	BATCH_ASSMT_RESULT_SSC_REJECTED: 'BATCH_ASSMT_RESULT_SSC_REJECTED',
	BATCH_ASSMT_SSC_ASSIGNED: 'BATCH_ASSMT_SSC_ASSIGNED',
	BATCH_ASSMT_SSC_REJECTED:"BATCH_ASSMT_SSC_REJECTED",
	BATCH_ASSMT_SSC_ACCEPTED:"BATCH_ASSMT_SSC_ACCEPTED",
	BATCH_ASSMT_AA_ACCEPTED:"BATCH_ASSMT_AA_ACCEPTED",
	BATCH_ASSMT_AR_ASSIGNED:"BATCH_ASSMT_AR_ASSIGNED",
	BATCH_ASSMT_AA_ASSIGNED:"BATCH_ASSMT_AA_ASSIGNED",
	BATCH_ASSMT_AR_ACCEPTED:"BATCH_ASSMT_AR_ACCEPTED",
	BATCH_ASSMT_RESULT_SSC_ACCEPTED:"BATCH_ASSMT_RESULT_SSC_ACCEPTED",
	BATCH_ASSMT_AA_REJECTED:"BATCH_ASSMT_AA_REJECTED",
	BATCH_ASSMT_RESULT_AA_APPROVED:"BATCH_ASSMT_RESULT_AA_APPROVED",
	BATCH_CANDIDATE_ATTENDANCE_DONE:"BATCH_CANDIDATE_ATTENDANCE_DONE",
	BATCH_ASSMT_RESULT_SSC_AA_REJECTED:"BATCH_ASSMT_RESULT_SSC_AA_REJECTED",
	BATCH_ASSMT_RESULT_NOT_AR_SUBMITTED:"BATCH_ASSMT_RESULT_NOT_AR_SUBMITTED",
	ASSESSMENT_DONE:"ASSESSMENT DONE",
	BATCH_ASSMT_RESULT_INITIATED:"BATCH_ASSMT_RESULT_INITIATED",
	BATCH_ASSMT_RESULT_AA_REJECTED:"BATCH_ASSMT_RESULT_AA_REJECTED",
	BATCH_ASSMT_RESULT_AR_SUBMITTED:"BATCH_ASSMT_RESULT_AR_SUBMITTED",
	BATCH_ASSMT_AR_REJECTED:"BATCH_ASSMT_AR_REJECTED",
	BATCH_ASSMT_SELF_ASSIGNED:"BATCH_ASSMT_SELF_ASSIGNED",
	BATCH_SUBMITTED:"BATCH_SUBMITTED",
	BATCH_RESCHEDULED:"BATCH_RESCHEDULED",
	BATCH_AUTO_REVERSED_FROM_AA:"BATCH_AUTO_REVERSED_FROM_AA",
	BATCH_ASSMT_RESULT_INITIATED_PARTIALLY:"BATCH_ASSMT_RESULT_INITIATED_PARTIALLY",
	BATCH_SSDM_PMU_ASSIGNED:"BATCH_SSDM_PMU_ASSIGNED",
	BATCH_SUBMITTED_FOR_REASSESSMENT:"BATCH_SUBMITTED_FOR_REASSESSMENT",
	BATCH_SSDM_PMU_SENT_BACK:"BATCH_SSDM_PMU_SENT_BACK",
	BATCH_ASSMT_RESULT_AR_DRAFTED:"BATCH_ASSMT_RESULT_AR_DRAFTED",
	BATCH_ASSMT_1_RESULT_SSC_ACCEPTED:"BATCH_ASSMT_1_RESULT_SSC_ACCEPTED",
	BATCH_ASSMT_RESULT_SSC_:"BATCH_ASSMT_RESULT_SSC_&_AA_REJECTED",
});

 export const NSDCTP = "TP157021" 

 export const SUSPENDPAYMEMNT = Object.freeze({
	isSuspended: true,
	message: ''
 })