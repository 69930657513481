<div class="container">

  <div class="m-grid m-grid--hor m-grid--root m-page notranslate">
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
      id="m_login">
      <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside p-4">
        <div class="m-stack m-stack--hor m-stack--desktop">
          <div class="m-stack__item m-stack__item--fluid">
            <div class="m-login__wrapper pt-5">
              <div class="m-login__logo">
                <a>
                  <img src="../../../assets/images/skill-india-logo-prod.png">
                </a>
              </div>
              <div class="m-login__signin">
                <div class="m-login__head">
                  <h3 class="m-login__title">
                  </h3>
                </div>
                <div class="">
                  Your SPOC details also
                  exist with other Training
                  Provider or Training center on
                  SkillIndia Portal. To
                  change/update your SPOC click
                  on Change SPOC
                </div>
                <div class="mt-2">
                  <div class="row m-0">
                    <div class="d-flex m-auto">
                      <div class="card" (click)="changeStarted = true">
                        <div>
                          Change SPOC
                        </div>
                      </div>
                      <div class="card" (click)="logout()">
                        <div>
                          Logout
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Add a message given by govind -->
                <!-- two cards in row 1. Change SPOC 2. Logout -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content p-0 border-left" *ngIf="changeStarted">
        <div class="m-portlet__body">
					<form class="m-form m-form--fit m-form--label" [formGroup]="spocCreationForm">
						<div class="form-group m-form__group m--margin-top-10 form_header">
							SPOC Info:
						</div>
						<div class="form-group m-form__group row">
							<label for="tp-details" class="col-4 col-form-label tc-text-align">
								Full Name:
								<span class="mandatoryColor">*</span>
							</label>
							<div class="col-8">
								<input class="form-control m-input" type="text" placeholder="Enter full name" formControlName="firstName">
								<app-form-control-messages [control]="spocCreationForm.controls.firstName" [name]="'Name of spoc'"></app-form-control-messages>
							</div>
						</div>
						<div class="form-group m-form__group row">
							<label for="tp-details" class="col-4 col-form-label tc-text-align">
								Email Address:
								<span class="mandatoryColor">*</span>
							</label>
							<div class="col-8">
								<input class="form-control m-input" type="text" name="Spoc-email" placeholder="Enter the email address" formControlName="email">
								<app-form-control-messages [control]="spocCreationForm.controls.email" [name]="'Email of spoc'"></app-form-control-messages>
							</div>
						</div>
						<div class="form-group m-form__group row">
							<label for="tp-details" class="col-4 col-form-label tc-text-align">
								Mobile Number:
								<span class="mandatoryColor">*</span>
							</label>
							<div class="col-8">

								<input class="form-control m-input" type="text" maxlength="10" name="mobile-number"  onkeypress="return event.charCode >= 48 && event.charCode <= 57"  placeholder="Enter mobile number" formControlName="mobileNumber">
								<app-form-control-messages [control]="spocCreationForm.controls.mobileNumber" [message]="'Mobile Number should be 10 digits'"
								    [name]="'Mobile of spoc'"></app-form-control-messages>
							</div>
						</div>
						<div class="m-portlet__foot m-portlet__foot--fit">
							<div class="m-form__actions">
								<div class="row">
									<div class="col-2"></div>
									<div class="col-10 text-center">
										<button type="button" data-toggle="modal" data-target="#spoc-popup" class="btn btn-next" (click)="saveSpoc()" [disabled]="!spocCreationForm.valid">
											Save & Next
										</button>
										<!-- <button type="button" class="btn btn-default marTop" (click)="cancel()">
											Cancel
										</button> -->
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="spoc-popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div *ngIf="!verifyCredDisplay">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						Verify Your Credentials
					</h5>
					<button type="button" class="close" aria-label="Close" #closeModel data-dismiss="modal">
						<span aria-hidden="true">
							&times;
						</span>
					</button>
				</div>
				<div class="modal-body">
					<form class="m-form m-form--fit m-form--label-align-right" [formGroup]="verifyCredentialsForm">
						<div class="m-portlet__body">
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-3 col-form-label">
									Enter UserName:
									<span class="mandatoryColor">*</span>
								</label>
								<div class="col-9">
									<input class="form-control m-input" type="text" [placeholder]="userType" name="Request_ID" formControlName="userName">
									<app-form-control-messages [control]="verifyCredentialsForm.controls.userName" [name]="'Training Id'"></app-form-control-messages>
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-3 col-form-label">
									Enter your Password:
									<span class="mandatoryColor">*</span>
								</label>
								<div class="col-9">
									<input class="form-control m-input" [type]="show ? 'text' : 'password'" formControlName="password" placeholder="Enter Password">
									<a class="showPassword" (click)="showPassClick()" aria-hidden="true">{{show ? 'Hide' : 'Show'}}</a>
									<app-form-control-messages [control]="verifyCredentialsForm.controls.password" [name]="'Password'"></app-form-control-messages>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-next verifybutton" [disabled]="!verifyCredentialsForm.valid" (click)="generateOtp()">
						Verify
					</button>
					<button type="button" class="btn  btn-cancel " data-dismiss="modal">
						Cancel
					</button>
				</div>
			</div>
			<div *ngIf="verifyCredDisplay">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						Verify OTP
					</h5>
					<button type="button" class="close" aria-label="Close" #closeModel data-dismiss="modal">
						<span aria-hidden="true">
							&times;
						</span>
					</button>
				</div>
				<div class="modal-body">
					<form class="m-form m-form--fit m-form--label-align-right" [formGroup]="verifyPasswordForm">
						<div class="form-group m-form__group row">
							<label for="tp-details" class="col-3 col-form-label">
								Enter Mobile One Time Password:
								<span class="mandatoryColor">*</span>
							</label>
							<div class="col-9">
								<input class="form-control m-input" type="text" formControlName="mobileOTP" placeholder="Enter mobile one time password" maxlength="6">
								<app-form-control-messages [control]="verifyPasswordForm.controls.mobileOTP" [name]="'Mobile One Time Password'"></app-form-control-messages>
							</div>
						</div>
						<div class="form-group m-form__group row">
							<label for="tp-details" class="col-3 col-form-label">
								Enter Email One Time Password:
								<span class="mandatoryColor">*</span>
							</label>
							<div class="col-9">
								<input class="form-control m-input" type="text" formControlName="emailOTP" placeholder="Enter email one time password" maxlength="6">
								<app-form-control-messages [control]="verifyPasswordForm.controls.emailOTP" [name]="'Email One Time Password'"></app-form-control-messages>
							</div>
						</div>
					</form>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-next verifybutton" (click)="verifyOTP()">
						Verify
					</button>
					<button type="button" class="btn  btn-cancel" data-dismiss="modal">
						Cancel
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
