export const ROUTE = Object.freeze({
	TP_MY_SCHEME: '/trainingpartner/my-schemes',
	TP_PAYMENT: '/trainingpartner/payment',
	TP_SCHEME_FORM: `/trainingpartner/scheme-form`,
	TP_SCHEME_LOGO: `/trainingpartner/scheme_logo_upload`,
	TP_UPDATE_SCHEME_FORM: '/trainingpartner/update-scheme-form',
	TP_VIEW_SCHEME_FORM: '/trainingpartner/view-scheme-form',
	TP_APPROVED_SCHEME: '/trainingpartner/approved-scheme',
	TP_ADD_TRAINING_CENTRE: 'trainingpartner/add-trainingCentre',
	TP_VIEW_TRAINING_CENTRE: 'trainingpartner/view-training-centre',
	TP_ADD_COURSE: 'trainingpartner/add-course',
	TP_EDIT_COURSE: 'trainingpartner/edit-course',
	TP_UPDATE_COURSE: 'trainingpartner/update-course',
	TP_ADD_ASSESSMENT_AGENCY: 'trainingpartner/add-assessment-agency',
	TP_VIEW_ALL_ADDED_SECTOR_COURSES: 'trainingpartner/view-sector-courses',
	TP_VIEW_ALL_SCHEME_PROJECTS: `trainingpartner/projects`,
	TP_UPDATE_TC_FORM: 'trainingpartner/update-trainingCentre',
	TP_VIEW_SCHEME_TC_LIST: 'trainingpartner/view-training-centre',
	ADD_PHOTOS_TC: '/trainingpartner/add-trainingCentre/add-photos',
	ADD_PHOTOS_TC_UPDATE: '/trainingpartner/update-trainingCentre/add-photos',
	TP_CSR_LOGO: '/trainingpartner/csr_details_upload'
});
