import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApplyBatchesService {
	public batchId;

	constructor() {
	}

	setBatchId(id) {
		this.batchId = id;
	};

	getBatchId() {
		return this.batchId;
	};

}
