import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from'rxjs';
const DEFAULT_PROFILE_IMAGE_URL = '../../../assets/images/Dummy_Image_Man.jpg';
@Injectable({ providedIn: 'root' })
export class ShareDataService {
	sharedData: any;
	stateData: any;
	imageUrl: any;
	rozgarMelaId: any;
	userData: any = {
		imageUrl: DEFAULT_PROFILE_IMAGE_URL
	};
	tabName: any;
	isSSc: any;
	newArr: any = [];
	origLen: any;
	melaTabName: any;
	placementTabName: any;
	selectedCandidate: any = [];
	approveProDetailTabName: any;
	placementAdminTabName: any;
	uncheckedIds: any = [];
	role: BehaviorSubject<string> = new BehaviorSubject(null);
	assessmentRegistrationFormData: any = [];
	ceoData: any = [];
	isProjectEditAfterApprove: boolean;
	canData: any = [];
	selectedPage: any;
	candidateEnrolledTabName: any;
	aaEditProfileData: any = [];
	targetValidityList: any = [];
	cancelledBatchData: any = [];
	batchData: any;
	batchDataForFeedbackForm: any;
	scpwdDashboardTabName: any;
	feedbackType: any;
	historyObj: any;
	candidateId: any;
	scnDetails: any;
	isUpdate: boolean;
	Rendertype: any;
	batchForEvidenceComp: any;
	modalState = true;
	constructor() { }

	getData(): Observable<any> {
		return of(this.sharedData);
	}

	getSharedData() {
		return this.userData;
	}

	setData(data: any): void {
		// localStorage.setItem('formData', JSON.stringify(data));
		this.sharedData = data;
	}
	setStates(data: any): void {
		this.stateData = data;
		console.log(this.stateData);

	}
	getState(): Observable<any> {
		console.log(this.stateData);

		return of(this.stateData);
	}
	setRole(role) {
		this.role.next(role);
	}
	getRole() {
		return this.role.asObservable();
	}

	setImage(imageUrl: string): void {
		this.userData.imageUrl = imageUrl || DEFAULT_PROFILE_IMAGE_URL;
	}

	setMelaId(melaId): void {
		this.rozgarMelaId = melaId;
	}

	getMelaId(): Observable<any> {
		return of(this.rozgarMelaId);
	}
	getTabName(): Observable<any> {
		return of(this.tabName)
	}

	getCanEnrollmentTabName(): Observable<any> {
		return of(this.candidateEnrolledTabName)
	}

	setBatchDataForFeedbackForm(batchData) {
		if (batchData) {
			this.batchDataForFeedbackForm = batchData;
		}
	}

	setCandidateId(canId) {
		if (canId) {
			this.candidateId = canId
		}
	}

	getCandidateId(): Observable<any> {
		return of(this.candidateId)
	}


	setFeedBackType(type) {
			this.feedbackType = type;
	}

	setTypeRender(type) {
		this.Rendertype = type
		console.log('rendering type ..', this.Rendertype)
	}
	setFeedbackHistory(history) {
		this.historyObj = history;
	}

	setCanEnrollIsUpdateKey(key) {
		this.isUpdate = key;
		console.log('IS Enrollment form filed ==> ', this.isUpdate);

	}

	getCanEnrollIsUpdateKey(): Observable<any> {
		return of(this.isUpdate)
	}

	getRenderType(): Observable<any> {
		return of(this.Rendertype)
	}

	getFeedbackHistory(): Observable<any> {
		return of(this.historyObj)
	}
	getFeedbackType(): Observable<any> {
		return of(this.feedbackType)
	}

	getBatchDataForFeedbackForm(): Observable<any> {
		return of(this.batchDataForFeedbackForm)
	}

	getScpwdTabName(): Observable<any>{
		return of(this.scpwdDashboardTabName)
	}
	setTabName(tabName) {
		console.log(tabName)
		if (tabName) {
		this.tabName = tabName

		}else {
			this.tabName = undefined;
		}
	}
	getPlacementTabName(): Observable<any> {
		return of(this.melaTabName)
	}
	setPlacementTabName(tabName) {
		if (tabName) {
			this.placementTabName = tabName;
		} else {
			this.placementTabName = undefined;
		}
	}

	getMelaTabName(): Observable<any> {
		return of(this.melaTabName)
	}
	setMelaTabName(mTabName) {
		if (mTabName)  {
			this.melaTabName = mTabName;
		}
	}
	setCandidateEnrollmentTabName(cTabName) {
		if (cTabName) {
			this.candidateEnrolledTabName = cTabName
		}
	}

	setBatchData(batch) {
		if (batch) {
			this.batchData = batch;
		}
	}

	getBatchData(): Observable<any> {
		return of(this.batchData);
	}

	setScpwdTabName(cTabName) {
		if (cTabName) {
			this.scpwdDashboardTabName = cTabName
		}
	}

	isSSCLinking(msg) {
		if (msg) {
			this.isSSc = msg;
		}
	}
	checkisLinking(): Observable<any> {
		return of(this.isSSc);
	}

	removeDuplicateFromArray(origArr) {
		this.origLen = origArr.length;
		let found, x, y;

		for (x = 0; x < this.origLen; x++) {
			found = undefined;
			for (y = 0; y < this.newArr.length; y++) {
				if (origArr[x] === this.newArr[y]) {
					found = true;
					break;
				}
			}
			if (!found) {
				this.newArr.push(origArr[x]);
			}
		}
		return this.newArr;
	}
	setCheckedCandidate(selectedCan) {
		if (selectedCan) {
			const temp = selectedCan.filter(val => !this.uncheckedIds.includes(val));
			if (temp) {
				this.selectedCandidate = this.removeDuplicateFromArray(temp);
			}
			} else {
				this.selectedCandidate = []
		}
	}
	canUncheckArray(ids) {
		this.uncheckedIds = ids;
		console.log('Unchecked array', ids);
	}
	getSelectedCandidate(): Observable<any> {
		return of(this.removeDuplicateFromArray(this.selectedCandidate));
	}
	setApproveDetailTabName(tabName) {
		if (tabName) {
		this.approveProDetailTabName = tabName
		}else {
			this.approveProDetailTabName = undefined;
		}
	}
	getPlacementApproveDetailsTab(): Observable<any> {
		return of(this.approveProDetailTabName)
	}

	setPlacementAdminTabName(tabName) {
		if (tabName) {
			this.placementAdminTabName = tabName
		} else {
			this.placementAdminTabName = undefined;
		}
	}
	getPlacementAdminTabName() {
		return of(this.placementAdminTabName)
	}

	setCeoData(data) {
		if (data) {
			this.ceoData.push(data);
			console.log(this.ceoData);

		} else {
			this.ceoData = [];
		}
	}
	getCeoData() {
		console.log('getCeo',this.ceoData);

		return of(this.ceoData);
	}

	setAssessmentRegisterDataFormOne(orgInfoData, otherInfoData?, addressProof?, createdBy?) {
		if (orgInfoData === 'clear') {
			this.assessmentRegistrationFormData = [];
			return;
		}
		if (orgInfoData || otherInfoData || addressProof || createdBy) {
			this.assessmentRegistrationFormData.push(orgInfoData, otherInfoData, addressProof, createdBy)
			console.log(this.assessmentRegistrationFormData);

		} else {
			this.assessmentRegistrationFormData = [];
		}
			console.log(this.assessmentRegistrationFormData);
	}
	getAssessmentRegistrationFormData() {
		return of(this.assessmentRegistrationFormData);
	}

	saveCanData(data) {
		if (data) {
			this.canData = data;
			console.log(this.canData);

		} else {
			this.canData = []
		}
	}

	getCandata() {
		return of(this.canData)
	}

	aaProfileData(data?) {
		if (data) {
			this.aaEditProfileData.push(data)
		} else {
			this.aaEditProfileData = [];
		}
	}

	flagSetting(flag) {
		if(flag) {
			this.isProjectEditAfterApprove = flag
		} else {
			this.isProjectEditAfterApprove = false;
		}
	}

	getFlagStatus() {
		return of(this.isProjectEditAfterApprove)
	}



	setCurrentPagination(pageNo) {
		if (pageNo) {
			this.selectedPage = pageNo
		} else {
			pageNo = 1;
		}
	}
	getSelectedPageNo() : Observable<any> {
		return of(this.selectedPage)
	}

	setCancelledBatchData(batch) {
		this.cancelledBatchData = []
		if (batch) {
			this.cancelledBatchData = batch
		} else {
			this.cancelledBatchData = []
		}
	}

	getCancelledBatchData(): Observable<any>{
		return of(this.cancelledBatchData)
	}

	getImcDropdown(imcValues,type) {
		let imc = [];
		imcValues.forEach(e => {
		  e.trainingType.forEach(i => {
			if (i == type) {
			  imc.push(e);
			}
		  });
		});

		return imc
	}

	getScnDetails(data) {
		this.scnDetails = data;
	}

	setScnDetails(): Observable<any> {
		console.log(this.scnDetails);
		return of(this.scnDetails);
	}


}
