import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { STRING } from 'projects/common/src/app/types';
import { environment } from 'src/environments/environment';
const TYPE = Object.freeze({
	STRING: 'string',
	OBJECT: 'object'
});
const VALUE = Object.freeze({
	SPACE: ' ',
	DOT: '.',
	FILE_DIFFERENCIATOR: '<============>'
});
@Injectable({ providedIn: 'root' })
export class ValidationService {
	static get CONST() {
		return VALUE;
	}
	static getValidatorErrorMessage(
		validatorName: string,
		validatorValue?: any,
		fieldControl?: any,
		patternMessage?: any
	) {
		let config;
		const isSelect = fieldControl.includes(', select');
		if (
			fieldControl === 'Pincode' ||
			fieldControl === 'Aadhaar Number' ||
			fieldControl === 'Mobile Number' ||
			fieldControl === 'Land Line Number' ||
			fieldControl === 'Landline Number' ||
			fieldControl === 'Official Website of Employer'
		) {
			config = {
				required: `${fieldControl}  is required`,
				minlength: `${fieldControl} should be ${validatorValue.requiredLength
					} digits`,
				pattern: `Please Enter Valid Data for ${fieldControl}`,
				maxlength: `${fieldControl} maximum length should be ${validatorValue.requiredLength
					} digits`,
				invalidName: `Please Enter Valid ${fieldControl}`,
				invalidPercentage: 'Please enter valid percentage',
				min: `${fieldControl} should not be lessthan ${validatorValue.max
					} `
			};
		} else if (isSelect) {
			const x = fieldControl.split(' ');
			const modifiedFormControl = x.slice(0, x.length - 2).join(' ');
			config = {
				required: `Please select ${modifiedFormControl}`
			}

		} else if (patternMessage === undefined) {
			config = {
				fileSelectedNotUploaded: `Please Upload File`,
				fileUploaded: `Please Upload File`,
				alphaNumeric: `${fieldControl} should be alphanumeric`,
				// alphaNumeric: `${fieldControl} should be alphanumeric (should contain character and digit both)`,
				required: (() => fieldControl ? `${fieldControl}  is required` : 'required')(),
				aadhar: validatorValue,
				aadharChecksum: `Enter Valid Aadhaar Number`,
				invalidEmailAddress: 'Enter Valid Email Address',
				whiteSpace: 'Only white space not allowed',
				invalidMobileNumber: `Mobile number is not valid. Enter valid Number`,
				invalidPassword:
					'Invalid password. Password must contain one uppercase, one lowercase, one number and one special character.',
				minlength: `${fieldControl} must be atleast ${validatorValue.requiredLength
					} characters`,
				pattern: `Please Enter Valid Data for ${fieldControl}`,
				maxlength: `${fieldControl} maximum length should be ${validatorValue.requiredLength
					} characters`,
				minimumDate: `invalid date`,
				minimumEndDateThenAlowed: `Date should be ${validatorValue} or more`,
				maximumEndDateThenAlowed: `Date should be ${validatorValue} or less`,
				invalidName: `Please Enter Valid ${fieldControl}`,
				atLeastChecked: `Please select atleast ${validatorValue}`,
				allCheckboxRequired: `Please select all`,
				durationMismatch: `Invalid Duration`,
				durationMoreThanAllowed: `Duration should be less than ${validatorValue.maxYearTenure} Years`,
				durationLessThanMandatory: `Duration should be more than ${validatorValue.minYearTenure} Year`,
				minStartThenAlowed: `Start Date should be after ${Helpers.toCalendarDate(validatorValue.minDate)}`,
				maxEndThenAlowed: `End Date should be less than or equal to ${Helpers.toCalendarDate(validatorValue.maxDate)}`,
				maxStartDateThenAlowed: `Start Date should be before ${Helpers.toCalendarDate(validatorValue.maxStartDate)}`,
				minEndDateThenAlowed: `End Date should after ${Helpers.toCalendarDate(validatorValue.minEndDate)}`,
				invalidPercentage: 'Please enter valid percentage',
				max: `${fieldControl} should not be more than ${validatorValue.max
					} `,
				min: `${fieldControl} should not be less than ${validatorValue.min
					} `,
				invalidDate: (() => {
					const minDate = validatorValue.minDate;
					const maxDate = validatorValue.maxDate;
					if (minDate) {
						return `${fieldControl} should be after ${Helpers.toCalendarDate(minDate)}`;
					} else if (maxDate) {
						return `${fieldControl} should be before ${Helpers.toCalendarDate(maxDate)}`;
					}
				})(),
				maxAllowedFiles: `Number of Files in ${fieldControl} can't be more then ${validatorValue.max}`,
				minAllowedFiles: `Number of Files in ${fieldControl} can't be less then ${validatorValue.min}`,
				invalidAmount: `Please enter valid ${fieldControl}`,
				invalidNumber: `Please enter valid data for ${fieldControl}.`
			};
		} else {
			config = {
				alphaNumeric: `${fieldControl} should be alphanumeric (should contain character and digit both)`,
				required: `${fieldControl}  is required`,
				invalidEmailAddress: 'Invalid email address',
				whiteSpace: 'Only white space not allowed',
				invalidMobileNumber: `Mobile number is not valid. Enter valid Number`,
				invalidPassword:
					'Invalid password. Password must contain one upparcase, one lowercase, one number and one Special character.',
				minlength: `${fieldControl} must be atleast ${validatorValue.requiredLength
					} characters`,
				pattern: `${patternMessage}`,
				maxlength: `${fieldControl} maximum length should be ${validatorValue.requiredLength
					} characters`,
				minimumDate: `invalid date`,
				minimumEndDateThenAlowed: `Date should start ${validatorValue} or more`,
				invalidName: `Please Enter Valid ${fieldControl}`,
				atLeastChecked: `Please select atleast ${validatorValue}`,
				allCheckboxRequired: `Please select all`,
				invalidPercentage: 'Please enter valid percentage',
				MatchPassword: 'password does not match',
				max: `${fieldControl} should not be more than ${validatorValue.max
					} `,
				min: `${fieldControl} should not be less than ${validatorValue.min
					} `,
				maxAllowedFiles: `Number of Files in ${fieldControl} can't be more then ${validatorValue.max}`,
				minAllowedFiles: `Number of Files in ${fieldControl} can't be less then ${validatorValue.min}`,
				invalidNumber: `Please enter valid data for ${fieldControl}, Number should not start with zero.`
			};
		}
		if (validatorName === 'dob') {
			console.log(config);
		}
		return config[validatorName];
	}

	static percentageValidator(minPercentage: number = 0) {
		function isValid(percent) {
			return minPercentage
				? percent <= 100 && percent >= minPercentage
				: percent <= 100;
		}
		return function (control: FormControl) {
			const value = control.value;
			if (!value) {
				return;
			}
			const parsedValue = parseFloat(value);
			if (isValid(value) && String(parsedValue) === String(value)) {
				return null;
			} else {
				return {
					invalidPercentage: true
				};
			}
		};
	}

	static decimalNumberValidation() {
		function isValid(decimalValue) {
			decimalValue = decimalValue.toString()
			const decimalSplits = decimalValue.split('.');
			if (decimalSplits.length > 2) {
				return {
					pattern: true
				}
			}
			if (decimalSplits.length === 2) {
				const decimalLength = decimalSplits[1].length;
				if (decimalLength > 2 || decimalLength === 0) {
					return {
						pattern: true
					}
				}
			}
			return null;
		}
		return function (control: FormControl) {
			const value = control.value;
			if (!value) {
				return;
			}
			return isValid(value);
		};
	}

	static validAadharChecksum() {
		const d = [
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			[1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
			[2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
			[3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
			[4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
			[5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
			[6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
			[7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
			[8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
			[9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
		];

		// permutation table p
		const p = [
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
			[1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
			[5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
			[8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
			[9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
			[4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
			[2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
			[7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
		];

		// inverse table inv
		const inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];

		// converts string or number to an array and inverts it
		function invArray(array) {

			if (Object.prototype.toString.call(array) === '[object Number]') {
				array = String(array);
			}

			if (Object.prototype.toString.call(array) === '[object String]') {
				array = array.split('').map(Number);
			}

			return array.reverse();

		}
		// validates checksum
		function validate(array) {
			if (array.length !== 12) {
				return false;
			}
			let c = 0;
			const invertedArray = invArray(array);

			for (let i = 0; i < invertedArray.length; i++) {
				c = d[c][p[(i % 8)][invertedArray[i]]];
			}

			return (c === 0);
		}
		return function (control: AbstractControl) {
			let aadharNumber = control.value;
			if (environment.functionalities.disableAadharChecksumVerification) {
				return;
			}
			const error = {
				aadharChecksum: true
			};
			if (!aadharNumber) {
				return error;
			}
			aadharNumber = aadharNumber.token;
			if (!(typeof aadharNumber === STRING.STRING)) {
				return;
			}
			if (!validate(aadharNumber)) {
				return error;
			}
		};
	}

	static aadharValidate(control) {
		const aadhar = control.value;
		if (!aadhar) {
			return;
		}
		if (typeof aadhar === 'string') {
			return {
				aadhar: 'Aadhaar required'
			};
		}
		if (aadhar.token && aadhar.token.length < 12) {
			return {
				aadhar: 'Enter Valid Aadhaar Number'
			};
		}
		if (aadhar.token && !aadhar.encryptedValue) {
			return {
				aadhar: 'Please Verify Aadhaar'
			};
		}
		return null;
	}

	static alphaNumeric({ onlyCapital = false } = {}) {
		const numberRegex = /[0-9]/;
		const alphabetRegex = onlyCapital ? /[A-Z]/ : /[a-z]/i;
		// const alphabetRegex =  /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/
		return function (control) {
			const value = control.value;
			if (!value) {
				return null;
			}
			if (!alphabetRegex.exec(value)) {
				return {
					pattern: true
					// alphaNumeric: true
				};
			}
			// if (!numberRegex.exec(value) || !alphabetRegex.exec(value)) {
			// 	return {
			// 		alphaNumeric: true
			// 	};
			// }
		};

	}

	static numberRangeValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;
			const regex = /^(?:[1-9]\d?|0)?(?:\.\d{1,2})?$/;
			const valid = regex.test(value);
			return valid ? null : { invalidNumber: true };
		};
	}

	static nameValidator(control) {
		const name = control.value;
		if (name != null) {
			const namePattern = /[a-zA-Z]+[a-z&\.\s]*$/i;
			const nameMatch = name.match(namePattern);
			if (nameMatch && nameMatch[0] === name) {
				return null;
			} else {
				return { invalidName: true };
			}
		}
	}

	static emailValidator(control) {
		/* 4.subrmanyam@gmail.com is not working in this */
		// tslint:disable-next-line:max-line-length
		// const emailPattern = /^([a-z0-9])(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		// const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		const emailPattern = /^[_a-zA-Z0-9]+(?:[.-][_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
		if (control.value != null) {
			if (control.value.match(emailPattern)) {
				return null;
			} else {
				return { invalidEmailAddress: true };
			}
		}
	}
	static emailValidatorMand(control) {
		// tslint:disable-next-line:max-line-length
		const emailPattern = /^([a-z0-9])(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		// const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		if (control.value) {
			if (control.value.match(emailPattern)) {
				return null;
			} else {
				return { invalidEmailAddress: true };
			}
		}
	}
	static emailV(control) {
		// tslint:disable-next-line:max-line-length
		const emailPattern = '^[_a-zA-Z0-9]+(?:[.-][_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$';
		// const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
		console.log('control.value', control.value);
		if (control.value) {
			if (control.value.match(emailPattern)) {
				return null;
			} else {
				return { invalidEmailAddress: true };
			}
		}
	}
	static nowhiteSpaceValidator(control: FormControl) {
		if (control.value) {
			const whiteSpace = (control.value || '').trim().length == 0
			const isValid = !whiteSpace
			if (isValid) {
				return null
			} else {
				return { whiteSpace: true }
			}
		}
	}


	static passwordValidator(control) {
		if (
			control.value.match(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
			)
		) {
			return null;
		} else {
			return { invalidPassword: true };
		}
	}

	static MatchPassword(AC: AbstractControl) {
		const password = AC.get('newPassword').value;
		const confirmPassword = AC.get('confirmPassword').value;
		if (password !== confirmPassword) {
			AC.get('confirmPassword').setErrors({ MatchPassword: true });
		} else {
			AC.get('confirmPassword').setErrors(null);
		}
	}

	static emailMatch(AC: AbstractControl) {
		const email = AC.get('email').value;
		const confirmEmail = AC.get('reemail').value;
		if (email !== confirmEmail) {
			AC.get('reemail').setErrors({ MatchEmail: true });
		} else {
			AC.get('reemail').setErrors(null);
		}
	}


	static categorisedFileTypeValidator(...allowedFileTypes) {
		return function (control: FormControl) {
			const categorisedFiles = control.value || {};
			const categories = Object.keys(categorisedFiles);
			let allErrors = [];
			categories.map(category => {
				const files = categorisedFiles[category];
				let errors = files.map((file) => isValidFileType(file, allowedFileTypes));
				errors = errors.filter(error => error);
				allErrors = allErrors.concat(errors);
			});
			return allErrors;
		};
	}
	static fileTypeValidator(...allowedFileTypes) {
		return function (control: any) {
			const files = control.value;
			if (files instanceof Array) {
				let errors = files.map(file => isValidFileType(file, allowedFileTypes));
				errors = errors.filter(error => error);
				return errors;
			}
			return isValidFileType(files, allowedFileTypes);
		};
	}
	static fileSizeValidator(maximumSize: any) {
		maximumSize = maximumSize || 5; //  changed maximum size of file from 3mb to 5mb as discussed with Himanshu on 26/06/2018
		function isFileValid(file) {
			if (file) {
				// if ((file.size / 1000 / 1000).toFixed(4) > maximumSize) {
				if ((file.size / 1024 / 1024).toFixed(4) > maximumSize) {
					return {
						fileSize: true,
						maxSizePayload: maximumSize,
						payloadMessage: `File size of ${file.name
							} is more than ${maximumSize} MB`
					};
				} else {
					return null;
				}
			} else {
				return null;
			}
		}
		return function (control: any) {
			const file = control.value;
			if (file instanceof Array) {
				let errors = file.map(f => {
					return isFileValid(f);
				});
				errors = errors.filter(error => error);
				return errors;
			} else {
				return isFileValid(file);
			}
		};
	}
	static toDate(date) {
		if (date instanceof Date) {
			return date;
		}
		date = date.split('/').reverse();
		return new Date(date[0], date[1], date[2]);
	}

	static inputGroupValidation() {
		return function (control: FormControl) {
			const value = control.value;
			if (!value) {
				return;
			}
			if (!value.valid) {
				return {
					inputGroup: true
				};
			}
		};
	}

	static dateValidation({
		maxDate,
		minDate,
	}) {
		return function (control: FormControl) {
			let date = control.value;
			if (!date) {
				return null;
			}
			date = Helpers.toDateFromCalendar(date);
			if (minDate) {
				if (date < minDate) {
					return {
						invalidDate: {
							minDate
						}
					};
				}
			}
			if (maxDate) {
				if (date > maxDate) {
					if (date > maxDate) {
						return {
							invalidDate: {
								maxDate
							}
						};
					}
				}
			}
		};
	}
	static dateDurationValidation(maxYearTenure = 0, {
		minDate,
		maxDate,
		minStartDate,
		maxStartDate,
		minEndDate,
		maxEndDate,
		minYearTenure,
		fromDateRequired = true,
		toDateRequired = true,
	}: any = {}) {
		return function (control: FormControl) {
			minDate = minDate || minStartDate;
			maxDate = maxDate || maxEndDate;
			const date = control.value;
			if (!date) {
				return null;
			}
			const fromDate = date.fromDate;
			const toDate = date.toDate;
			date.fromDate = Helpers.toDateFromCalendar(fromDate);
			date.toDate = Helpers.toDateFromCalendar(toDate);
			if ((fromDateRequired && !fromDate) ||
				(toDateRequired && !toDate)) {
				return {
					required: true
				};
			}
			if (minDate) {
				if (date.fromDate.valueOf() <= minDate.valueOf()) {
					return {
						minStartThenAlowed: {
							minDate
						}
					};
				}
			}

			if (maxStartDate) {
				if (date.fromDate.valueOf() >= maxStartDate.valueOf()) {
					return {
						maxStartDateThenAlowed: {
							maxStartDate
						}
					};
				}
			}

			if (minEndDate) {
				if (date.toDate.valueOf() < minEndDate.valueOf()) {
					return {
						minEndDateThenAlowed: {
							minEndDate
						}
					};
				}
			}
			if (maxDate) {
				if (date.toDate.valueOf() > maxDate.valueOf()) {
					return {
						maxEndThenAlowed: {
							maxDate
						}
					};
				}
			}
			let validDuration = true;
			if (date.toDate && date.fromDate) {
				validDuration = (date.toDate.valueOf() - date.fromDate.valueOf()) < 0 ? false : true;
			}

			if (!validDuration) {
				return {
					durationMismatch: true
				};
			}
			let isValidDifference = true;
			let isValidMinDifference = true;
			if (maxYearTenure > 0) {
				const maxValidToDate = new Date(fromDate);
				maxValidToDate.setFullYear(fromDate.getFullYear() + maxYearTenure);
				if (toDate > maxValidToDate) {
					isValidDifference = false;
				}
			}
			if (minYearTenure > 0) {
				const minValidToDate = new Date(fromDate);
				minValidToDate.setFullYear(fromDate.getFullYear() + minYearTenure);
				if (toDate < minValidToDate) {
					isValidMinDifference = false;
				}
			}
			if (!isValidDifference) {
				return {
					durationMoreThanAllowed: {
						maxYearTenure
					},
				};
			}
			if (!isValidMinDifference) {
				return {
					durationLessThanMandatory: {
						minYearTenure
					},
				};
			}
			if (validDuration) {
				return null;
			}
			return {
				durationMismatch: true
			};
		};
	}

	static categorisedFileSelectedNotUploaded() {
		return function (control: FormControl) {
			const categorisedFiles = control.value || {};
			const error = {
				fileSelectedNotUploaded: true
			};
			const categories = Object.keys(categorisedFiles);
			const categoryValid = categories.map(category => {
				const files = categorisedFiles[category];
				const anyError = files.map(file => isValidFile(file)).reduce((prevError, e) => prevError || e, false)
				// const isValid = files.reduce((allValid, file) => allValid && !isFileSelectedNotUploaded(file), true);
				return anyError ? false : true;
			});
			const allCategoriesValid = categoryValid
				.reduce((allCategory, category) => allCategory && category, true)
			return allCategoriesValid ? null : error;
			// if (typeof value === TYPE.OBJECT && !(value instanceof Array)) {
			// 	return error;
			// }
			// if (value instanceof Array) {
			// 	for (const file of value) {
			// 		if (typeof file === TYPE.OBJECT) {
			// 			return error;
			// 		}
			// 	}
			// }
		};
	}
	static fileSelectedNotUploaded() {
		return function (control: FormControl) {
			const value = control.value;
			const error = {
				fileSelectedNotUploaded: true
			};
			if (typeof value === TYPE.OBJECT && !(value instanceof Array)) {
				return error;
			}
			if (value instanceof Array) {
				for (const file of value) {
					if (typeof file === TYPE.OBJECT) {
						return error;
					}
				}
			}
		};
	}

	static allowedFilesCount(max: Number, min: Number) {
		return function (control: FormControl) {
			const value = control.value;
			if (typeof value === TYPE.OBJECT && !(value instanceof Array)) {
				return null;
			}
			if (value instanceof Array) {
				const numberOfFiles = value.length;
				if (max) {
					if (numberOfFiles > max) {
						return {
							maxAllowedFiles: {
								payloadMessage: `Can't Select More then ${max} files`,
								max: max
							}
						}
					}
				}
				if (min) {
					if (numberOfFiles < min) {
						return {
							minAllowedFiles: {
								payloadMessage: `Can't Select Less then ${min} files`,
								min: min
							},
						}
					}
				}
			}
		};
	}
	static allowedMinFilesCount(min: Number) {
		return function (control: FormControl) {
			const value = control.value;
			if (typeof value === TYPE.OBJECT && !(value instanceof Array)) {
				return null;
			}
			if (value instanceof Array) {
				const numberOfFiles = value.length;
				if (min) {
					if (numberOfFiles < min) {
						return {
							minAllowedFiles: {
								payloadMessage: `Can't Select Less then ${min} files`,
								min: min
							},
						}
					}
				}
			}
		}
	}

	static categorisedFileRequired(categorisedRequired) {
		return function (control: FormControl) {
			const categorisedFiles = control.value || {};
			const error = {
				required: true
			};
			const categories = Object.keys(categorisedRequired);
			// const categories = Object.keys(categorisedFiles);
			const allSelected = categories.reduce((prevValue, category) =>
				!categorisedRequired[category] ||
				(categorisedFiles[category] &&
					categorisedFiles[category].length &&
					prevValue) || false,
				true);
			return allSelected ? null : error;
		}
	}

	static categorisedFileValidator(control: FormControl) {
		const categorisedFiles = control.value || {};
		const categories = Object.keys(categorisedFiles);
		return categories.map(category => isValidFile(categorisedFiles[category]))
			.filter(error => error);
	}
	static fileValidator(control: any) {
		// function isValidFile(fileName) {
		// 	const error = {
		// 		fileUploaded: true
		// 	};
		// 	if (typeof fileName === TYPE.OBJECT) {
		// 		return error;
		// 	}
		// 	fileName = String(fileName);
		// 	if (
		// 		fileName.indexOf(VALUE.FILE_DIFFERENCIATOR) === 0
		// 	) {
		// 		return error;
		// 	}
		// 	return null;
		// }
		const fileNames = control.value;
		if (fileNames instanceof Array) {
			return fileNames
				.map(fileName => {
					return isValidFile(fileName);
				})
				.filter(error => error);
		} else {
			return isValidFile(fileNames);
		}
	}

	static atLeastChecked(count) {
		return function (control) {
			const checkboxes = control.value || {};
			const checkboxKeys = Object.keys(checkboxes);
			let checked = 0;
			for (const key of checkboxKeys) {
				if (checkboxes[key]) {
					checked++;
				}
				if (checked === count) {
					break;
				}
			}
			if (checked === count) {
				return null;
			}
			if (checkboxKeys.length === count) {
				return {
					allCheckboxRequired: true
				}
			}
			return {
				atLeastChecked: count
			};
		};
	}

	static number(maxLength, onlyPositive = false) {
		function maxLengthCheck(string) {
			return maxLength === -1 || string.length <= maxLength;
		}
		return function (control) {
			const value = control.value;
			let pattern = /[-]{0,1}[1-9][0-9]*/i;
			if (onlyPositive) {
				pattern = /[1-9][0-9]*/i;
			}
			if (value) {
				const stringValue = value.toString().trim();
				if (
					stringValue.match(pattern) &&
					stringValue.match(pattern)[0] === stringValue &&
					maxLengthCheck(stringValue)
				) {
					return null;
				} else {
					return { pattern: true };
				}
			}
		};
	}

	static minimumDateValidator(minimumDate: any) {
		return function (control: any) {
			const date: any = control.value;
			console.log(minimumDate, date);
			if (date) {
				if (moment(date).isSameOrAfter(minimumDate)) {
					return null;
				} else {
					return {
						minimumDate: true
					};
				}
			} else {
				return null;
			}
		};
	}

	static checkDate(control: FormControl) {
		const dateErr = {
			fur: true
		};
		if (control.value && control.value !== '') {
			const DOB = moment(control.value).format('YYYY-MM-DD');
			const today = moment(new Date()).format('YYYY-MM-DD');
			if (DOB >= today) {
				return dateErr;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
	static maximumDateValidation(maxDate) {
		return function (control: FormControl) {
			if (control.value) {
				const splitDates = control.value.split('/');
				const day = Number(splitDates[0]);
				const month = Number(splitDates[1]);
				const year = Number(splitDates[2]);
				let startDate;
				console.log('dayyyy', day);
				if (day !== 31) {
					startDate = moment().date(day).month(month - 1).year(year).format('YYYY-MM-DD');
				} else {
					startDate = moment().date(day).month(month).year(year).format('YYYY-MM-DD');
				}
				if (maxDate) {
					const splitBoth = maxDate.split('/');
					const tabDay = Number(splitBoth[0]);
					const tabMonth = Number(splitBoth[1]);
					const tabYear = Number(splitBoth[2]);
					let tabDate;
					if (tabDay !== 31) {
						tabDate = moment().date(tabDay).month(tabMonth - 1).year(tabYear).format('YYYY-MM-DD');
					} else {
						tabDate = moment().date(tabDay - 1).month(tabMonth - 1).year(tabYear)
						tabDate = moment(tabDate).add(1, 'days').format('YYYY-MM-DD');
					}
					if (moment(startDate).unix() >= moment(tabDate).unix()) {
						return {
							maximumEndDateThenAlowed: moment(tabDate).format('DD/MM/YYYY')
						}
					} else {
						return false;
					}
					// const tabMinDate = moment(tabDate).add(minDate, 'days').format('YYYY-MM-DD');
				}
			} else {
				return false;
			}
		}
	}
	static batchDatesValidation(minDate: any, endDate: any) {
		return function (control: any) {
			if (control.value) {
				let day: any = 0;
				let month: any = 0;
				let year: any = 0;
				if (typeof control.value === 'string') {
					const splitDates = control.value.split('/');
					day = Number(splitDates[0]);
					month = Number(splitDates[1]);
					year = Number(splitDates[2]);
				} else {
					day = control.value.day;
					month = control.value.month;
					year = control.value.year;
				}
				let startDate;
				console.log('dayyyy', day);
				if (day !== 31) {
					startDate = moment().date(day).month(month - 1).year(year).format('YYYY-MM-DD');
				} else if (day === 31 && month === 12) {
					startDate = moment().date(day).month(month).year(year + 1).format('YYYY-MM-DD');
				} else {
					startDate = moment().date(day).month(month).year(year).format('YYYY-MM-DD');
				}
				console.log('startDate', startDate);

				const minimumDate = moment().add(minDate, 'days').format('YYYY-MM-DD');
				// const minEndDate = moment(startDate).add(minDate, 'days');
				if ((minDate || minDate === 0) && endDate) {
					console.log('inside ifffffffffffffff conditionnnnnnnnnnnn');

					const splitBoth = endDate.split('/');
					const tabDay = Number(splitBoth[0]);
					const tabMonth = Number(splitBoth[1]);
					const tabYear = Number(splitBoth[2]);
					let tabDate;
					if (tabDay !== 31) {
						tabDate = moment().date(tabDay).month(tabMonth - 1).year(tabYear).format('YYYY-MM-DD');
					} else {
						tabDate = moment().date(tabDay - 1).month(tabMonth - 1).year(tabYear)
						tabDate = moment(tabDate).add(1, 'days').format('YYYY-MM-DD');
					}
					const tabMinDate = moment(tabDate).add(minDate, 'days').format('YYYY-MM-DD');

					if (moment(startDate).unix() < moment(tabMinDate).unix()) {
						return {
							minimumEndDateThenAlowed: moment(tabMinDate).format('DD/MM/YYYY')
						}
					} else {
						return null;
					}
				} else if (endDate) {
					let endDay = 0;
					let endMonth = 0;
					let endYear = 0;
					if (typeof endDate === 'string') {
						const splitEnd = endDate.split('/');
						endDay = Number(splitEnd[0]);
						endMonth = Number(splitEnd[1]);
						endYear = Number(splitEnd[2]);
					} else {
						endDay = endDate.day;
						endMonth = endDate.month;
						endYear = endDate.year;
					}
					let checkEnd;
					if (endDay !== 31) {
						checkEnd = moment().date(endDay).month(endMonth - 1).year(endYear).format('YYYY-MM-DD');
					} else {
						checkEnd = moment().date(endDay - 1).month(endMonth - 1).year(endYear);
						checkEnd = moment(checkEnd).add(1, 'days').format('YYYY-MM-DD');
					}
					const endMinDate = moment(checkEnd).add(minDate, 'days').format('YYYY-MM-DD');
					if (moment(startDate).unix() < moment(endMinDate).unix()) {
						return {
							minimumEndDateThenAlowed: moment(endMinDate).format('DD/MM/YYYY')
						};
					} else {
						return null;
					}
				} else {
					const showDate = minimumDate;
					if (moment(startDate).unix() < moment(minimumDate).unix()) {
						return {
							minimumEndDateThenAlowed: moment(showDate).format('DD/MM/YYYY')
						};
					} else {
						return null;
					}
				}
			} else {
				return null;
			}
		};
	}
	static dateDurationValidationWithoutSameDate(maxYearTenure = 0, {
		minDate,
		maxDate,
		minStartDate,
		maxStartDate,
		minEndDate,
		maxEndDate,
		minYearTenure,
		fromDateRequired = true,
		toDateRequired = true,
	}: any = {}) {
		return function (control: FormControl) {
			minDate = minDate || minStartDate;
			maxDate = maxDate || maxEndDate;
			const date = control.value;
			if (!date) {
				return null;
			}
			const fromDate = date.fromDate;
			const toDate = date.toDate;
			date.fromDate = Helpers.toDateFromCalendar(fromDate);
			date.toDate = Helpers.toDateFromCalendar(toDate);
			if ((fromDateRequired && !fromDate) ||
				(toDateRequired && !toDate)) {
				return {
					required: true
				};
			}
			if (minDate) {
				if (date.fromDate.valueOf() <= minDate.valueOf()) {
					return {
						minStartThenAlowed: {
							minDate
						}
					};
				}
			}

			if (maxStartDate) {
				if (date.fromDate.valueOf() >= maxStartDate.valueOf()) {
					return {
						maxStartDateThenAlowed: {
							maxStartDate
						}
					};
				}
			}

			if (minEndDate) {
				if (date.toDate.valueOf() < minEndDate.valueOf()) {
					return {
						minEndDateThenAlowed: {
							minEndDate
						}
					};
				}
			}
			if (maxDate) {
				if (date.toDate.valueOf() > maxDate.valueOf()) {
					return {
						maxEndThenAlowed: {
							maxDate
						}
					};
				}
			}
			let validDuration = true;
			if (date.toDate && date.fromDate) {
				validDuration = (date.toDate.valueOf() - date.fromDate.valueOf()) <= 0 ? false : true;
			}

			if (!validDuration) {
				return {
					durationMismatch: true
				};
			}
			let isValidDifference = true;
			let isValidMinDifference = true;
			if (maxYearTenure > 0) {
				const maxValidToDate = new Date(fromDate);
				maxValidToDate.setFullYear(fromDate.getFullYear() + maxYearTenure);
				if (toDate > maxValidToDate) {
					isValidDifference = false;
				}
			}
			if (minYearTenure > 0) {
				const minValidToDate = new Date(fromDate);
				minValidToDate.setFullYear(fromDate.getFullYear() + minYearTenure);
				if (toDate < minValidToDate) {
					isValidMinDifference = false;
				}
			}
			if (!isValidDifference) {
				return {
					durationMoreThanAllowed: {
						maxYearTenure
					},
				};
			}
			if (!isValidMinDifference) {
				return {
					durationLessThanMandatory: {
						minYearTenure
					},
				};
			}
			if (validDuration) {
				return null;
			}
			return {
				durationMismatch: true
			};
		};
	}

	static numberWithoutLeadingZeros(control) {
		const numPattern = /^([0-9]|[1-9][0-9]{0,6})$/;
		if (control.value != null) {
			const num = control.value;
			if (num.match(numPattern)) {
				return null;
			} else {
				return { invalidNumber: true };
			}
		}
	}
}


function isValidFile(fileName) {
	const error = {
		fileUploaded: true
	};
	if (isValidS3Url(fileName)) {
		return null;
	}
	return error;
	// if (typeof fileName === TYPE.OBJECT) {
	// 	return error;
	// }
	// fileName = String(fileName);
	// if (
	// 	fileName.indexOf(VALUE.FILE_DIFFERENCIATOR) === 0
	// ) {
	// 	return error;
	// }
	// return null;
}

export function isValidS3Url(fileName) {
	if (typeof fileName === TYPE.OBJECT) {
		return false;
	}
	fileName = String(fileName);
	if (
		fileName.indexOf(VALUE.FILE_DIFFERENCIATOR) === 0
	) {
		return false;
	}
	return true;
}

function isValidFileType(file, allowedFileTypes) {
	if (file) {
		const fileName = typeof file === TYPE.STRING ? file : file.name;
		if (!fileName) {
			return null;
		}
		if (!allowedFileTypes || !allowedFileTypes.length) {
			return null;
		}
		const valid = allowedFileTypes.some(fileType => {
			const typeOfFileUploaded = fileName
				.toString()
				.split(VALUE.DOT)
				.pop();
			return (
				typeOfFileUploaded.toLowerCase() ===
				fileType.toLowerCase()
			);
		});
		if (!valid) {
			return {
				fileType: true,
				payloadMessage: `Please Select Appropriate type`
			};
		} else {
			return null;
		}
	} else {
		return null;
	}
}




function isFileSelectedNotUploaded(file) {
	return typeof file === TYPE.OBJECT
}



