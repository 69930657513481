import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Constants } from 'projects/shared/src/app/shared/constants/constants';
import { CurrentUserService } from '../current-user.service';
import * as _ from 'lodash';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { DomSanitizer } from '@angular/platform-browser';
import { USER_ROLES, PMKVY_SCHEME_MODEL_CONSTANTS } from 'src/app/scheme-constants/scheme-constants';
// tslint:disable-next-line:import-blacklist
import { map, Observable } from 'rxjs';
import { numberSymbols } from '@progress/kendo-angular-intl';
export interface ITcCreateBatchEligibilityCR {
	userName: string,
	isEligible: boolean
}
@Injectable({ providedIn: 'root' })
export class TrainingCentersService {
	public _trainingCentreSchemeAndTypeData: any;
	public _tcWorkflowData: any;
	public qpCode: any;
	constructor(
		private currentUserService: CurrentUserService,
		private apiService: ApiService,
		private sanitizer: DomSanitizer
	) { }



	// http://localhost:3003/api/tot-toa/v1/batch/tcs/previouslyConductedTC?state=35&itemsPerPage=10&pageNo=1&district=603
	getPreviouslyConductedTrainingCentres(
		state: number,
		district: number,
		userName: string,
		type: string,
		pageNo = 1,
		perPage = Constants.RECORDSPERPAGE
	) {
		console.log('inservice', arguments);
		let previouslyConductedTcUrl = `${
			// tslint:disable-next-line:max-line-length
			environment.ToTServiceURL
			}/api/tot-toa/v1/batch/tcs/previouslyConductedTC?state=${state}&itemsPerPage=${perPage}&pageNo=${pageNo}&district=${district}`;
		if (!_.isNil(userName)) {
			previouslyConductedTcUrl += `&userName=${userName}`;
		}
		if (!_.isNil(type)) {
			previouslyConductedTcUrl += `&type=${type}`;
		}

		return this.apiService.get(previouslyConductedTcUrl);
	}
	searchTrainingCenters(
		searchCriteria = {},
		pageNo = 1,
		perPage = Constants.RECORDSPERPAGE
	) {
		const searchTrainingCentersURL = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/all/${pageNo}/${perPage}`;
		return this.apiService.post(searchTrainingCentersURL, searchCriteria);
	}
	getTrainingCentre(trainingCentreName) {
		const searchTrainingCentersURL = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/${trainingCentreName}`;
		return this.apiService.get(searchTrainingCentersURL);
	}
	getTPSpocDetails(batchid) {
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/batch/${batchid}/TP/Spoc/detail`;
		return this.apiService.get(url);
	}
	getAllSchemeApprover() {
		const schemeApprover = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/workflow/filter/`;
		return this.apiService.get(schemeApprover);
	}
	getTrainingCentreDetails(trainingCentreId, dataRequired) {
		const URL = `${environment.userServiceUrl}/tc/${trainingCentreId}`;
		let requiredFields = {};
		switch (dataRequired) {
			case 'SPOC':
				requiredFields = {
					trainingCentreName: 1,
					'spoc.firstName': 1,
					'spoc.email': 1,
					'spoc.mobileNumber': 1,
					'trainingPartner.name': 1,
					centrePrincipal: 1
				};
				break;
		}
		return this.apiService.post(URL, requiredFields);
	}

	getTrainingCentreById(tcId) {
		const URL = `${environment.userServiceUrl}/tc/${tcId}`;
		return this.apiService.get(URL)
	}

	getTrainingCentreType() {
		const URL = `${environment.nsdcCommonServiceUrl
			}/api/nsdccommon/trainingcentretype`;
		return this.apiService.get(URL).pipe(map(res => res.data));
	}

	submitNewAppeal(AppealData) {
		// const appealformURL = `${environment.userServiceUrl}/trainingcentre/all`;
		const user = this.currentUserService.getCurrentUserLocal().value;
		AppealData.tcId = user.userName;
		const newAppealUrl = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc`;
		return this.apiService.post(newAppealUrl, AppealData);
	}

	updateAppeal(appealData: any) {
		const updateAppealUrl = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc?query=appealID:${appealData.appealID}`;
		return this.apiService.put(updateAppealUrl, appealData);
	}
	submitCommentstoSSC(payload: any) {
		const updateAppealUrl = `${environment.userServiceUrl
			}/api/user/v1/clarifcation/by/ia`;
		return this.apiService.post(updateAppealUrl, payload);
	}
	patchAppeal(appealData: any) {
		const updateAppealUrl = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/appeal/update`;
		return this.apiService.patch(updateAppealUrl, appealData);
	}

	createTemporaryTC(tcData) {
		const TrainingCenterURL = `${environment.userServiceUrl
			}/api/user/v1/ssc/locationBasedTc`;
		return this.apiService.post(TrainingCenterURL, tcData);
	}

	assignExceptionalTCToBatch(tcData) {
		const aUrl = `${environment.ToTServiceURL
			}/api/tot-toa/v1/batch/exceptionalTC`;
		return this.apiService.post(aUrl, tcData);
	}

	getAllTrainingCentreAppeals(query: string) {
		const getTcAppealUrl = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc?${query}`;
		return this.apiService.get(getTcAppealUrl);
	}
	getAllSSCPayments(queryparams) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live?' + queryparams;
		return this.apiService.get(URL);
	}
	getPaymentExcelReport(paymentHistoryObj) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live';
		return this.apiService.getBlobOnArguments(URL, paymentHistoryObj);
	}
	getTrainingCenterAppeals(tcID: string, pageNo: number, limit: number) {
		// tslint:disable-next-line:max-line-length
		// const getTcAppealUrl = `${
		// 	environment.smartServiceURL
		// 	}/api/smart/v1/appeal/tc?query=tcId:${tcID}&pageNo=${pageNo}&limit=${limit}&typeOfAppeal=Appeal Request`;
		// return this.apiService.get(getTcAppealUrl);
		const getTcAppealUrl = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/center?query=tcId:${tcID},requestAgainst:Training Centre&pageNo=${pageNo}&limit=${limit}`;
		return this.apiService.get(getTcAppealUrl);
	}

	getSpecificTrainingCentreAppeal(query: string) {
		const getSpecificAppealUrl = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/single?query=appealID:${query}`;
		return this.apiService.get(getSpecificAppealUrl);
	}

	getSpecificTrainingCentreAllAppeal(query: string) {
		const getSpecificAppealUrl = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/single/all?query=tcId:${query}`;
		return this.apiService.get(getSpecificAppealUrl);
	}

	getTrainingCentreAppealStatus() {
		const getTcAppealStatusUrl = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/appeal/status`;
		return this.apiService.get(getTcAppealStatusUrl);
	}
	setTrainingCentreSchemeAndTypeData(value?: any) {
		if (this._trainingCentreSchemeAndTypeData) {
			this._trainingCentreSchemeAndTypeData = Object.assign(
				this._trainingCentreSchemeAndTypeData,
				value
			);
		} else {
			this._trainingCentreSchemeAndTypeData = value;
		}
		console.log(this._trainingCentreSchemeAndTypeData, 'value');
	}
	getTrainingCentreSchemeAndTypeData() {
		return this._trainingCentreSchemeAndTypeData;
	}
	getTrainingCentreOfScheme(selectedSchemeId: string, tpId?: string, {
		pageNo, limit, withoutPagination, backEndQuery
	} = { pageNo: 1, limit: 10, withoutPagination: false, backEndQuery: {} }, param?) {
		const user = this.currentUserService.getCurrentUserLocal().value;
		const role = user.role;
		tpId = tpId || user.userName;
		const query = {
			query: Helpers.toBackendQuery(Object.assign({
				'trainingPartner.userName': tpId
			}, backEndQuery)),
			Scheme: 'SchemeBased',
			SchemeId: selectedSchemeId,
			pagination: withoutPagination ? 'no' : 'yes'
		};
		if (!withoutPagination) {
			Object.assign(query, {
				pageNo,
				limit
			});
		}

		if (param !== undefined) {
			const url = `${environment.userServiceUrl}/api/user/v1/trainingcentres?${Helpers.serialize(query)}&param=${param}`;
			return this.apiService.get(url);
		} else {
			const url = `${environment.userServiceUrl}/api/user/v1/trainingcentres?${Helpers.serialize(query)}`;
			return this.apiService.get(url);
		}
	}

	getAllTrainingCenterRequests(data) {
		const getAllTcRequestUrl = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/workflow`;
		return this.apiService.get(getAllTcRequestUrl, data);
	}
	approveTCByCMA(data) {
		console.log(data, 'data');
		const approveUrl = `${environment.userServiceUrl
			}/api/user/v1/continousmonitor/trainingpartner/cmaReviews/${data.tcId
			}?workflowID=${data.workflowID}`;
		return this.apiService.post(approveUrl, data);
	}
	setTcWorkflowData(value) {
		if (this._tcWorkflowData) {
			this._tcWorkflowData = Object.assign(this._tcWorkflowData, value);
		} else {
			this._tcWorkflowData = value;
		}
	}

	getAllTrainingCentersForCandidate(
		pageNo: number,
		limit: number,
		param?: any
	) {
		if (param) {
			const trainingCenterUrl = `${environment.userServiceUrl
				// tslint:disable-next-line:max-line-length
				}/api/user/v1/trainingcentres?query=status:Approved,${param}&pageNo=${pageNo}&limit=${limit}&param=jobRoles,trainingCentreName,userName,address`;
			return this.apiService.get(trainingCenterUrl);
		} else {
			const trainingCenterUrl = `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=status:Approved&pageNo=${pageNo}&limit=${limit}&param=jobRoles,trainingCentreName,userName,address`;
			return this.apiService.get(trainingCenterUrl);
		}
	}

	getMobileApkLink(mobile, tcId): Observable<any> {
		const getApkLink = `${environment.userServiceUrl}/api/user/v1/trainingcentre/get/mobile/feebased/${tcId}`;
		return this.apiService.post(getApkLink, mobile);
	}

	getAllTrainingCentres(tcType: string, tpID: string, limit: number, rating: number,
		pageNo?: number, stateCode?: any, districtCode?: any, projectId?: any,
		forCSSM?: any, schemeType?: any, caafTcList?, neTc?, covidScheme?, SchemeId?, jobRoleDetails?) {
		let query;
		const isPmkk = false;
		if (isPmkk) {
			if (tcType === PMKVY_SCHEME_MODEL_CONSTANTS.TC_AA) {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					trainingCenterType: 'PMKK',
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
				if (rating) {
					query = query.concat(`,rating:${rating}`)
				}
			} else {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					// status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
			}
		} else {
			if (tcType === PMKVY_SCHEME_MODEL_CONSTANTS.TC_AA) {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
				if (rating) {
					query = query.concat(`,rating:${rating}`)
				}
			} else {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					// status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
			}
		}


		if (projectId) {
			query = query.concat(`,associatedProjects.projectId:${projectId}`);
		}

		let trainingCenterUrl = pageNo ? `${environment.userServiceUrl
			}/api/user/v1/trainingcentres?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}` : `${environment.userServiceUrl
			}/api/user/v1/trainingcentres?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}`;

		if (caafTcList) {
			trainingCenterUrl = pageNo ? `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}&caafTcList=${caafTcList}` : `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}&caafTcList=${caafTcList}`;
		} else {
			trainingCenterUrl = pageNo ? `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}` : `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}`;

		}

		if (neTc) {
			trainingCenterUrl = `${trainingCenterUrl}&neTc=${neTc}`
		}
		if (covidScheme) {
			trainingCenterUrl = `${trainingCenterUrl}&covidScheme=${covidScheme}`
		}
		if (SchemeId) {
			trainingCenterUrl = `${trainingCenterUrl}&SchemeId=${SchemeId}`
		}
		if (jobRoleDetails && jobRoleDetails.qpCode) {
			trainingCenterUrl = `${trainingCenterUrl}&qpCode=${jobRoleDetails.qpCode}&qpVersion=${jobRoleDetails.qpVersion}`
		}
		return this.apiService.get(trainingCenterUrl);
	}

	getNonAATrainingCentres(tcType: string, tpID: string, limit: number, rating: number,
		pageNo?: number, stateCode?: any, districtCode?: any, projectId?: any, forCSSM?: any, status?: any, schemeType?) {
		let query;
		const isPmkk = false;
		if (isPmkk) {
			if (tcType === PMKVY_SCHEME_MODEL_CONSTANTS.TC_AA) {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					trainingCenterType: 'PMKK',
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
				if (rating) {
					query = query.concat(`,rating:${rating}`)
				}
			} else {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					// status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
			}
		} else {
			if (tcType === PMKVY_SCHEME_MODEL_CONSTANTS.TC_AA) {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
				if (rating) {
					query = query.concat(`,rating:${rating}`)
				}
			} else {
				query = (stateCode && districtCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					'address.district.id': districtCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// rating: rating,
					// status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
			}
		}


		// if (projectId) {
		// 	query = query.concat(`,associatedProjects.projectId:${projectId}`);
		// }


		const trainingCenterUrl = pageNo ? `${environment.userServiceUrl
			}/api/user/v1/trainingcentres?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&projectId=${projectId}&status=${status}&schemeType=${schemeType}` : `${environment.userServiceUrl
			}/api/user/v1/trainingcentres?query=${query}&limit=${limit}&forCSSM=${forCSSM}&projectId=${projectId}&status=${status}&schemeType=${schemeType}`;
		return this.apiService.get(trainingCenterUrl);
	}

	getAllPMKKTrainingCentres(tcType: string, tpID: string, limit: number, rating: number,
		pageNo?: number, isPmkk?, stateCode?: any, districtCode?: any, projectId?: any,
		forCSSM?: any, schemeType?: any, caafTcList?, neTc?, SchemeId?) {
		let query;
		if (isPmkk) {
			if (tcType === PMKVY_SCHEME_MODEL_CONSTANTS.TC_AA) {
				query = (stateCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					// 'address.district.id': districtCode,
					rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					rating: rating,
					trainingCenterType: 'PMKK',
					// 'address.state.id': stateCode,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.ALL_TC
				});
			} else {
				query = (stateCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					'address.state.id': stateCode,
					// rating: rating,
					// status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
			}
		} else {
			if (tcType === PMKVY_SCHEME_MODEL_CONSTANTS.TC_AA) {
				query = (stateCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					rating: rating,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
			} else {
				query = (stateCode) ? Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
					'address.state.id': stateCode,
					// rating: rating
				}) : Helpers.toBackendQuery({
					processType: tcType,
					'trainingPartner.userName': tpID,
					// 'address.state.id': stateCode,
					// rating: rating,
					// status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
				});
			}
		}


		if (projectId) {
			query = query.concat(`,associatedProjects.projectId:${projectId}`);
		}
		let trainingCenterUrl;
		if (caafTcList) {
			trainingCenterUrl = pageNo ? `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}&caafTcList=${caafTcList}` : `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}&caafTcList=${caafTcList}`;
		} else {
			trainingCenterUrl = pageNo ? `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}` : `${environment.userServiceUrl
				}/api/user/v1/trainingcentres?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}`;
		}
		if (neTc) {
			trainingCenterUrl = `${trainingCenterUrl}&neTc=${neTc}`
		}
		if (SchemeId) {
			trainingCenterUrl = `${trainingCenterUrl}&SchemeId=${SchemeId}`
		}
		return this.apiService.get(trainingCenterUrl);
	}
	getAllTrainingCentreForRozgarMela(pageNo: number, limit: number, state) {
		// tslint:disable-next-line:max-line-length
		const apiUrl = `${environment.userServiceUrl
			}/api/user/v1/trainingcentres?query=status:approved,address.state.name:${state}&pageNo=${pageNo}&limit=${limit}`;
		return this.apiService.get(apiUrl);
	}

	getTrainigCenterCount() {
		const trainingCenterUrl = `${environment.userServiceUrl
			}/api/user/v1/trainingcentres?pagination=no&param=userName`;
		return this.apiService.get(trainingCenterUrl);
	}
	getTcWorkflowData() {
		return this._tcWorkflowData;
	}

	updateSectorTarget(sectorTargets, tcId: string) {
		const trainingCentrePatch = {
			sectorTargets
		};
		const trainingCenterUrl = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/UpdateForm?tcId=${tcId}`;
		return this.apiService.patch(trainingCenterUrl, trainingCentrePatch);
	}

	SearchTrainingCentersForRozgarMela(
		pageNo: number,
		limit: number,
		param?: any
	) {
		const trainingCenterUrl = `${environment.userServiceUrl
			// tslint:disable-next-line:max-line-length
			}/api/user/v1/trainingcentres?query=status:approved,state=${param.state
			},district=${param.district},subDistrict=${param.subDistrict
			}&pageNo=${pageNo}&limit=${limit}`;
		return this.apiService.get(trainingCenterUrl);
	}

	getAllEmployers(pageNo, itemsPerPage, userRole, melaId, queryParams?, param?) {
		let getUserRole;
		if (param) {
			// tslint:disable-next-line:max-line-length
			getUserRole = environment.userServiceUrl + `/api/user/v1/getUserBasedOnRole?${param}&role=${userRole}&melaId=${melaId}&pageNo=${pageNo}&limit=${itemsPerPage}`;
			return this.apiService.get(getUserRole);
		} else {
			// tslint:disable-next-line:max-line-length
			getUserRole = environment.userServiceUrl + '/api/user/v1/getUserBasedOnRole?role=' + userRole + '&melaId=' + melaId + '&pageNo=' + pageNo + '&limit=' + itemsPerPage;
		}
		return this.apiService.get(getUserRole);
	}
	getAllEmployersForLink(pageNo, itemsPerPage, userRole, melaId, modelObj) {
		// tslint:disable-next-line:prefer-const
		let queryParams = {}
		const filter = []
		queryParams['limit'] = itemsPerPage;
		queryParams['pageNo'] = pageNo;
		queryParams['role'] = userRole;
		queryParams['melaId'] = melaId;
		if (modelObj && modelObj.state) {
			filter.push(`address.stateId:${modelObj.state.value}`)
		}
		if (modelObj && modelObj.district) {
			filter.push(`address.districtDetails.districtId:${modelObj.district.value}`)
		}
		if (modelObj && modelObj.keyword) {
			filter.push(`userName:${modelObj.keyword}`)
		}
		const query = filter.toString();
		// tslint:disable-next-line:max-line-length
		// https://testsdms.ekaushal.com/api/user/v1/getUserBasedOnRole?role=Employer&query=userName:a,address.stateId:35,address.districtDetails.districtId:603&limit=10&pageNo=1
		const getUserRole = environment.userServiceUrl + `/api/user/v1/getUserBasedOnRole?query=${query}`;
		return this.apiService.get(getUserRole, queryParams);
	}

	getJobRole() {
		const user = this.currentUserService.getCurrentUserLocal().value;
		const jobRoleDetails = `${environment.userServiceUrl
			}/api/user/v1/jobRoles?fields=jobRoles`;
		return this.apiService.get(jobRoleDetails);
	}
	getScheme() {
		const schemeDetails = `${environment.schemeServiceURL
			}/api/schemes/v1/schemes/Approved/Active/rule/Approved?fields=`;
		return this.apiService.get(schemeDetails);

	}
	// getActiveScheme(query) {
	// 	const schemeDetails = `${
	// 		environment.schemeServiceURL
	// 		}/api/v1/schemeModule/scheme?type=externalModule&status=${query}`;
	// 	return this.apiService.get(schemeDetails);
	// }
	getActiveScheme() {
		const schemeDetails = `${environment.smartServiceURL
			}/api/smart/v1/tc/scheme/list`;
		return this.apiService.get(schemeDetails);
	}
	getJobRoleDetails(qpCode, tcId?) {
		const code = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/jobdetails/qp/jobrolename?qp=${qpCode}&tcId=${tcId}`;
		return this.apiService.get(code);
	}
	// http://localhost:3000/api/user/v1/trainingcentre/scheme-approver/jobrole

	approveJobRoleBySchemeApprover(data: any, selectedRequest?) {
		const trainingCenterUrl = `${environment.userServiceUrl}/api/user/v1/trainingcentre/scheme-approver/jobrole`;
		return this.apiService.patch(trainingCenterUrl, data);
	}

	changejobRolesStatus(payloadObj) {
		const acceptIADateUrl = `${environment.userServiceUrl}/api/user/v1/ChangeSSCStatus`;
		return this.apiService.post(acceptIADateUrl, payloadObj);
	}
	approveNOC(data) {
		const trainingCenterUrl = `${environment.smartServiceURL}/api/smart/v1/schemechange/schemeapproveraction`;
		return this.apiService.put(trainingCenterUrl, data);
	}

	schemeSubmitResponse(schemObj, schemeId, isSchemeNeedtoBeAdded?) {
		// if (isSchemeNeedtoBeAdded && isSchemeNeedtoBeAdded === true) {
		// 	const schemeSubmitRes = `${
		// 		environment.userServiceUrl
		// 		}/api/user/v1/trainingcentre/alignjobroleToScheme/migratedTC/` + schemeId;
		// 	return this.apiService.patch(schemeSubmitRes, schemObj);
		// } else {
		const schemeSubmitRes = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/alignjobrole/` + schemeId;
		return this.apiService.patch(schemeSubmitRes, schemObj);
		// }

	}
	acceptInspectionDate(payloadObj, id) {
		const acceptIADateUrl = `${environment.userServiceUrl
			}/api/user/v1/traingcenter/acceptorreject/` + id;
		return this.apiService.post(acceptIADateUrl, payloadObj);
		// if (payloadObj.status === 'Accepted') {
		// 	const acceptIADateUrl = `${
		// 		environment.userServiceUrl
		// 		}/api/user/v1/traingcenter/acceptorreject/` + id;
		// 	return this.apiService.post(acceptIADateUrl, payloadObj);
		// } else {
		// 	const rejectIADateUrl = `${
		// 		environment.userServiceUrl
		// 		}/api/user/v1/traingcenter/addInspectiondate/after/reject/` + id;
		// 	return this.apiService.post(rejectIADateUrl, payloadObj);
		// }

	}
	acceptResidentialInspectionDate(payloadObj, id) {
		const acceptIADateUrl = `${environment.userServiceUrl
			}/api/user/v1/residential/acceptorreject/` + id;
		return this.apiService.post(acceptIADateUrl, payloadObj);
	}
	getIADateDetails() {
		const user = this.currentUserService.getCurrentUserLocal().value;
		const inspectionDateDetails = `${environment.userServiceUrl
			}/api/user/v1/trainingcentres?query=userName:${user.userName}&noPagination=yes&param=inspectionCenterDates`;
		return this.apiService.get(inspectionDateDetails);
	}

	// // For HA- Download Excel sheet
	// exportExcelAppeals(queryParams) {
	// 	const tcworkflowURL = environment.excelService + `/api/excel-report/v1/smart/xslsreport`+queryParams;
	// 	return this.apiService.getBlob(tcworkflowURL, 'application/vnd.ms-excel');
	// }

	// Appeal - PMU -Download Excel sheet
	exportExcelAppealsPMU(queryParams) {
		const tcworkflowURL = environment.excelService + `/api/excel-report/v1/smart/xslsreport?` + queryParams;
		return this.apiService.getBlob(tcworkflowURL, 'application/vnd.ms-excel');
	}
	getTcAppealRequest(query) {
		const tcAppeal = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc?${query}`;
		return this.apiService.get(tcAppeal);
	}
	getTcAppealResidentialRequest(query) {
		const getAssessmentURL = environment.userServiceUrl + '/api/user/v1/residentialworkflow?' + query;
		return this.apiService.get(getAssessmentURL);
	}
	getSScPaymentList(queryParams) {
		const sscPayment = `${environment.smartServiceURL
			}/api/smart/v1/tc/payment/list/ssc?` + queryParams;
		return this.apiService.get(sscPayment);
	}

	updateAppealAction(payLoadObj) {
		// const roleName = 'Appeal Pmu'
		const tcAppeal = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/appeal/update`;
		return this.apiService.patch(tcAppeal, payLoadObj);
	}
	submitForInspection(payload) {
		const url = environment.userServiceUrl + '/api/user/v1/trainingcentre/addInspectiondate/req?withResidential=' + payload.withResidential;
		return this.apiService.post(url, payload);
	}
	submitForInspectionResidential(payload) {
		const url = environment.userServiceUrl + '/api/user/v1/residentialworkflow/inspection';
		return this.apiService.post(url, payload);
	}
	submitForSSC() {
		const url = environment.userServiceUrl + '/api/user/v1/trainingcentre/ssc/req';
		return this.apiService.post(url, {})
	}
	getJobRoleBasedOnSerial(jobRoleSerial) {
		const user = this.currentUserService.getCurrentUserLocal().value;
		const jobRoleInfo = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/GetFromCaafForm/JobRole?tcID=${user.userName}&serialNumber=${jobRoleSerial}`;
		return this.apiService.get(jobRoleInfo);
	}


	/**
	 * PMKVY Scheme - AddProject - Update TC with Project info
	 * @projectId
	 * @projectName
	 * @schemeId
	 * @List of TC's
	 */

	addProjectToTc(projectId: string, projectName: string, schemeRefId: string, tcIds: string[], role?: string, addedBy?: string) {
		const url = `${environment.userServiceUrl}/api/user/v1/trainingcentre/update/linked/project?role=${role}`;
		return this.apiService.put(url,
			{
				tcids: tcIds,
				schemeReferenceId: schemeRefId,
				projectId: projectId,
				projectName: projectName
			}
		)
	}

	/**
	 * GET
	 * PMKVY - Project - Training Centres
	 * @projectId
	 */
	getTrainingCentres(projectId: string) {
		const url = `${environment.userServiceUrl}/api/user/v1/trainingcentres/projectId?projectId=${projectId}`
		return this.apiService.get(url)
	}

	/**
	 * GET
	 *Return Schemes
	 * @TC ID
	 */
	getSchemes(tpId: string, tcId?: string) {
		// const url = `${environment.userServiceUrl}/api/user/v1/trainingcentres/tcid/schemes?tcid=${tcId}`
		const url = (tcId) ? `${environment.userServiceUrl}/api/user/v1/trainingpartner/active/schemes?tpid=
		${tpId}&role=${USER_ROLES.TRAINING_PARTNER}&tcId=${tcId}`
			: `${environment.userServiceUrl}/api/user/v1/trainingpartner/active/schemes?tpid=
		${tpId}&role=${USER_ROLES.TRAINING_PARTNER}`;
		return this.apiService.get(url);
	}

	/**
	 * POST @Kavitha
	 * Update Status
	 */

	reInspectionEditCAAF() {
		const jobRoleInfo = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/`;
		return this.apiService.post(jobRoleInfo);
	}
	getJobRoleDetailsinSA(query) {
		const url = `${environment.userServiceUrl}/api/user/v1/trainingcentre/jobdetails/qp/jobrolename`;
		return this.apiService.get(url, query);
	}
	submitTcMoreDetails(body) {
		const url = `${environment.userServiceUrl
			// tslint:disable-next-line:max-line-length
			}/api/user/v1/trainingcentre/givingDetails/ssc`;
		return this.apiService.post(url, body);
	}
	updateMobileStatusReinspection(mobileStatus) {
		const url = `${environment.userServiceUrl
			// tslint:disable-next-line:max-line-length
			}/api/user/v1/trainingcentre/set/value`;
		return this.apiService.post(url, mobileStatus);
	}

	getTrainingCentreBySchemeRules(pageNo, itemsPerPage, shemeId?, trainingType?, userId?, modelObj?) {
		const queryParams = {}
		if (shemeId) {
			queryParams['schemeId'] = shemeId
		}
		if (trainingType) {
			queryParams['trainingType'] = trainingType
		}
		if (userId) {
			queryParams['tpid'] = userId
		}
		if (modelObj && modelObj.state) {
			queryParams['state'] = modelObj.state.name;
		}
		if (modelObj && modelObj.district) {
			queryParams['district'] = modelObj.district.name
		}
		if (modelObj && modelObj.tcId) {
			queryParams['tcid'] = modelObj.tcId
		}
		if (modelObj && modelObj.keyword) {
			queryParams['search'] = modelObj.keyword
		}
		queryParams['pageNum'] = pageNo;
		queryParams['limit'] = itemsPerPage;
		const apiUrl = `${environment.userServiceUrl}/api/user/v1/trainingcentres/scheme/rules`;
		return this.apiService.get(apiUrl, queryParams);
	}
	getSSCPendingReportList() {
		const url = `${environment.userServiceUrl
			// tslint:disable-next-line:max-line-length
			}/api/user/v1/trainingcentre/listOfpendingsscwork/pmu`;
		return this.apiService.get(url);
	}
	generateExcelReport(payload) {
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/generate/report';
		return this.apiService.getBlobOnArguments(URL, payload, 'application/vnd.ms-excel');
	}
	submitClarificationAskedByPmu(payload) {
		const url = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/pmu/clarification`;
		return this.apiService.post(url, payload);
	}
	tcAskedClarification(payload) {
		const user = this.currentUserService.getCurrentUserLocal().value;
		payload.tcId = user.userName;
		console.log(payload, '----------payload')
		const url = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/appeal/data`;
		return this.apiService.post(url, payload);
	}
	submitTcClarification(payload) {
		const url = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/clarification`;
		return this.apiService.post(url, payload);
	}
	getAllPaymetRquest(query) {
		const url = `${environment.smartServiceURL
			}/api/smart/v1/refund/payment/list?` + query;
		return this.apiService.get(url);
	}
	// getAllTCPayments(tpId?) {
	// 	if (tpId === undefined) {
	// 		const URL = environment.smartServiceURL + `/api/smart/v1/refund/payment/list`;
	// 		return this.apiService.get(URL)
	// 	} else {
	// 		const URL = environment.smartServiceURL + `/api/smart/v1/refund/payment/list?tpId=${tpId}`;
	// 		return this.apiService.get(URL)
	// 	}

	// }


	// Smart Admin Payment History
	getAllTCPayments(queryparams) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live?' + queryparams;
		return this.apiService.get(URL);
	}


	/**
	 * Training Centre Job Role Update
	 */
	updateJobRole(userName: string, jobRole: any) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/jobrole/update?tcId=${userName}`;
		return this.apiService.patch(URL, jobRole);
	}
	getSSCList(query) {
		const URL = `${environment.userServiceUrl}/api/user/v1/clarifcation/list/ia?` + query;
		return this.apiService.get(URL);
	}
	// For PMKKSPOC SSC- Download Excel sheet
	exportSSC(tab, queryParams) {
		const tcworkflowURL = environment.excelService + `/api/excel-report/v1/smart/xslsreport?tabName=${tab}&` + queryParams;
		return this.apiService.getBlob(tcworkflowURL, 'application/vnd.ms-excel');
	}

	getContinuousMonitoringData(query) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/trainingcentre/cm/list/user?` + query;
		return this.apiService.get(URL);
	}
	assignIA() {
	}
	applyForAffiliation(body) {
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/accrediation/payment';
		return this.apiService.post(URL, body);
	}
	applyForContinousMonitoring() {
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/continuousmonitoring/payment';
		return this.apiService.post(URL, {});
	}
	getReports(data) {
		const URL = environment.excelService + '/api/excel-report/v1/smart';
		console.log('---->url', URL);
		return this.apiService.getBlobOnArguments(URL, data, 'application/vnd.ms-excel');
	}
	getPaymentReports(data) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live';
		console.log('---->url', URL);
		return this.apiService.getBlobOnArguments(URL, data, 'application/vnd.ms-excel');
	}
	getMessages(tcId?) {
		console.log(tcId);

		const queryParams = {}
		if (tcId) {
			queryParams['tcId'] = tcId
		}
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/email/data';
		return this.apiService.get(URL, queryParams);
	}
	// getApplicationStageList(tcId) {
	// 	const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/status/data/${tcId}`;
	// 	return this.apiService.get(URL);
	// }
	getApplicationStageList(tcId) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/tc/application/stage/list/${tcId}`;
		return this.apiService.get(URL);
	}
	getApplicationResidentialStageList(tcId) {
		const URL = `${environment.userServiceUrl}/api/user/v1/residential/stage/list/${tcId}`;
		return this.apiService.get(URL);
	}
	_downloadFile(data: any, fileName: any) {
		let fileUrl;
		fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(data));
		const a = document.createElement('a');
		a.href = fileUrl.changingThisBreaksApplicationSecurity;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
	}

	getPaginationTCReports(url) {
		const URL = environment.excelService + '/api/excel-report/v1/smart?' + url;
		return this.apiService.get(URL);
	}
	getTCPaymentDownload(data) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live';
		return this.apiService.getBlobOnArguments(URL, data, 'application/vnd.ms-excel');
	}
	getTCTPCountDownload(data) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live/tctpcount';
		return this.apiService.getBlobOnArguments(URL, data, 'application/vnd.ms-excel');
	}
	getConsolidateDownload() {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live/consolidate/xlsx';
		return this.apiService.getBlob(URL, 'application/vnd.ms-excel');
	}
	getPaginationTCPaymentReports(url) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live?' + url;
		return this.apiService.get(URL);
	}

	getInprocessTCPaymentReports(queryparams) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live?' + queryparams;
		return this.apiService.get(URL);
	}

	getPaymentTCReadOnly(queryparams) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live?' + queryparams;
		return this.apiService.get(URL);
	}
	getTCTPCount(body) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live/tctpcount/jsondata';
		return this.apiService.post(URL, body);
	}
	getConsolidateJsonData() {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live/consolidate/report/jsondata';
		return this.apiService.get(URL);
	}
	getContinourMonitoringYearlyRequest(query) {
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/cm/data/page?' + query;
		return this.apiService.get(URL);
	}
	assignContinousMonitoringRequestToIA(body) {
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/cm/admin/assign';
		return this.apiService.post(URL, body);
	}
	assignCmRequestToDA(body) {
		const URL = environment.smartServiceURL + '/api/smart/v1/trainingcentre/cm/ia/assign';
		return this.apiService.post(URL, body);
	}
	getSchemeApproverList(schemeId) {
		const URL = environment.smartServiceURL + '/api/smart/v1/tc/get/schemeis/recommendedby/' + schemeId;
		return this.apiService.get(URL);
	}
	applytoConditionalAccred(payLoad) {
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/apply/accrediation/req';
		return this.apiService.post(URL, payLoad);
	}
	getTpDetails(query) {
		const tpDetails = `${environment.userServiceUrl
			}/api/user/v1/trainingpartners?${query}`;
		return this.apiService.get(tpDetails);
	}

	// -------start---21
	getTpDetailsDeactivated(query) {
		const tpDetailsDeac = `${environment.userServiceUrl
			}/api/user/v1/trainingpartners?${query}`;
		return this.apiService.get(tpDetailsDeac);
	}
	getTCSubmittedDetails(query, type?) {
		const tcSubmittedDetails = `${environment.smartServiceURL
			}/api/smart/v1/tc?` + query;
		return this.apiService.get(tcSubmittedDetails);
	}
	getTCSubmittedandNotSubmittedreport(data) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live';
		return this.apiService.getBlobOnArguments(URL, data, 'application/vnd.ms-excel');
	}
	getTPSubmittedandNotSubmittedreport(data) {
		const URL = environment.excelService + '/api/excel-report/v1/smart/live/appeal';
		return this.apiService.getBlobOnArguments(URL, data, 'application/vnd.ms-excel');
	}
	getProposalCode(proposalCode) {
		const tcSubmittedDetails = `${environment.userServiceUrl
			}/api/user/v1/trainingcentre/praposalcode/` + proposalCode;
		return this.apiService.get(tcSubmittedDetails);
	}

	/* TC eligibility to create batch */
	changeEligibilityToCreateBatch(data: ITcCreateBatchEligibilityCR) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/update/batch/creation/eligibility`;
		return this.apiService.patch(URL, data);
	}

	getAssessment(pageNo, limit, filter) {
		const paramQuery = {};
		if (filter.canId) {
			paramQuery['canId'] = filter.canId
		}
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/id/${filter.batchId}&pageNo=${pageNo}&itemsPerPage=${limit}`;
		return this.apiService.get(URL, paramQuery)
	}
	getAssessmentWithoutPage(filter) {
		const paramQuery = {};
		if (filter.canId) {
			paramQuery['canId'] = filter.canId
		}
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/id/${filter.batchId}`;
		return this.apiService.get(URL, paramQuery)
	}
	getAssessments(filter, data?) {
		const paramQuery = {};
		if (filter.canId) {
			paramQuery['canId'] = filter.canId
		}
		paramQuery['candidatesList'] = 'assessed';
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.ToTServiceURL}/api/tot-toa/v1/candidate/list/search?keyword=${data.keyword}&status=${data.status}&batchId=${filter.batchId}&pagination=no`;
		return this.apiService.post(URL, {
			type: (data && data.type ? data.type : ''), district: (data && data.district ? data.district : ''),
			state: (data && data.state ? data.state : ''), batchStartDate: (data && data.batchStartDate ? data.batchStartDate : ''),
			batchEndDate: (data && data.batchEndDate ? data.batchEndDate : '')
		})


	}

	getCandidateDetails(sipID, sdmsID, type) {
		// http://localhost:3006/api/v1/cert/candidateAdmin/candidateData?batchId=123&sdmsBatchId=123&type=certificate
		let getUrl = `${environment.batchServiceUrl}/api/v1/cert/candidateAdmin/candidateData?`
		if (sipID && sdmsID) {
			getUrl += `batchId=${sipID}&sdmsBatchId=${sdmsID}`
		} else if (sdmsID) {
			getUrl += `sdmsBatchId=${sdmsID}`
		} else {
			getUrl += `batchId=${sipID}`
		}

		if (type) {
			getUrl += `&type=${type}`
		}
		return this.apiService.get(getUrl)
	}

	downloadMarksheetOrCertificateForMigracted(key) {
		const URL = `${environment.documentServiceUrl}/api/document/v1/signedURL/migrated?key=${key}`;
		return this.apiService.get(URL)

	}

	downloadFile(url) {
		const getFile = `${environment.documentServiceUrl}/api/document/v1/signedURL?key=${url}`
		return this.apiService.get(getFile)
	}
	getCandidateForMarksheet(filter, data?, batchType?, assessmentMode?) {
		const paramQuery = {};
		if (filter.canId) {
			paramQuery['canId'] = filter.canId
		}
		// paramQuery['candidatesList'] = 'enrolled';
		if(assessmentMode !== 'Self' && assessmentMode !== 'Third Party') {
			paramQuery['candidatesList'] = 'assessed';
		}
		// tslint:disable-next-line:max-line-length
		let URL = `${environment.ToTServiceURL}/api/tot-toa/v1/candidate/list/search?keyword=${data.keyword}&status=${data.status}&batchId=${filter.batchId}&pagination=no&candidatesList=${paramQuery['candidatesList']}`;
		if (batchType) {
			URL += `&batchType=${batchType}`;
		}
		return this.apiService.post(URL, paramQuery)

	}

	/* Non A&A Approval for PMKVY */

	nonAandAApproval(tcId, workflowId, data, isNoNAATC?) {
		let URL;
		if (isNoNAATC) {
			URL = `${environment.userServiceUrl}/api/user/v1/review/nonAnATC/${tcId}?workflowID=${workflowId}`;
		} else {
			URL = `${environment.userServiceUrl}/api/user/v1/tcApproval/${tcId}?workflowID=${workflowId}`;
		}
		return this.apiService.post(URL, data);
	}


	getEnrolledTarget(schemeRefId) {
		const URL = `${environment.userServiceUrl}/api/user/v1/get/allocated/enrolled/target?schemeRefId=${schemeRefId}`;
		return this.apiService.get(URL)
	}

	getTcTarget(tcId, projectId) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/get/tc/jobrolewise/target?tcId=${tcId}&projectId=${projectId}&stt=true`;
		return this.apiService.get(URL)
	}

	getProjectTcTarget(tcId, projectId, tpId) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/get/tctarget/stt?tcId=${tcId}&projectId=${projectId}&tpId=${tpId}`;
		return this.apiService.get(URL);
	}

	getAssociatedTcs(pageNo, itemsPerPage, filterData?) {
		const queryParams = {};
		queryParams['pageNo'] = pageNo;
		queryParams['limit'] = itemsPerPage;
		if (filterData && filterData.bcStatus) {
			queryParams['bcstatus'] = filterData.bcStatus;
		}
		if (filterData && filterData.ssStatus) {
			queryParams['sstatus'] = filterData.ssStatus;
		}
		if (filterData && filterData.tcId) {
			queryParams['tcId'] = filterData.tcId
		}
		if (filterData && filterData.tcName) {
			queryParams['tcName'] = filterData.tcName
		}

		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/get/tclist/scheme/level`;
		return this.apiService.get(URL, queryParams);
	}

	tpmuActionAgainstTc(payLoad) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentre/tc/batch/creation/permission`;
		return this.apiService.patch(URL, payLoad);
	}
	migratedTCRefund(tcId) {
		const refundData = `${environment.smartServiceURL
			}/api/smart/v1/refund/tc?id=${tcId}`;
		return this.apiService.get(refundData);
	}
	getResidentialRequests() {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentres/residentialmatrix`;
		return this.apiService.get(URL);
	}

	getTcsFilteredByJobRole(tpId: string, tcType: number, qpCode: string) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentres/list?processType=${tcType}&qpCode=${qpCode}&tpId=${tpId}`;
		return this.apiService.get(URL);
	}

	getNonAATcTargetValidation(tpId: string, projectId: string, data: any, isSp?: boolean, stateId?, districtId?, firstTime?) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingpartner/validate/tcectors/sum?tpId=${tpId}
		&projectId=${projectId}&isSp=${isSp}&stateId=${stateId}&districtId=${districtId}&firstTime=${firstTime}`;
		return this.apiService.post(URL, data);
	}

	getSectorForTc(tcId) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentres/jobrole?tcId=${tcId}`;
		return this.apiService.get(URL)
	}

	checkTcTargetForSpProject(data) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentres/validataion/project/tc/update`;
		return this.apiService.get(URL, data)
	}
	getConversationHistory(tcId, status, appealID?) {
		let URL;
		if (appealID !== null || appealID !== undefined) {
			URL = `${environment.smartServiceURL}/api/smart/v1/appeal/tc?query=tcId:${tcId},appealID:${appealID}`;
		} else {
			URL = `${environment.smartServiceURL}/api/smart/v1/appeal/tc?query=tcId:${tcId},status:${status}`;
		}
		return this.apiService.get(URL)
	}

	getAccrediatedCommonJobRoleFromSchemeAndTC(tcId, schemeId, isAcademicScheme?, isJRMappingRequired?, isTotaScheme?, processType?) {
		let url = '';
		if (isJRMappingRequired) {
			// let payload = {
			// 	tcId: tcId,
			// 	schemeRefId: schemeId,

			// }
			url = `${environment.userServiceUrl}/api/user/v1/pmkvyProjectJobRole?tcId=${tcId}&schemeRefId=${schemeId}`;
			return this.apiService.get(url);
		}else if(processType !== ''){
			url = `${environment.userServiceUrl}/api/user/v1/accrediated/tc/jobrole/stt/tc?tcId=${tcId}&schemeRefId=${schemeId}&isAcademicScheme=${isAcademicScheme}&forTota=${isTotaScheme}&processType=${encodeURIComponent(processType)}`;
			return this.apiService.get(url);
		}
		else {
			url = `${environment.userServiceUrl}/api/user/v1/accrediated/tc/jobrole/stt/tc?tcId=${tcId}&schemeRefId=${schemeId}&isAcademicScheme=${isAcademicScheme}&forTota=${isTotaScheme}`;
			return this.apiService.get(url);
		}

	}
	getTCViewHistory(tcId, qp, version) {
		const refundData = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc?query=tcId:${tcId}, qp: ${qp},version: ${version}`;
		return this.apiService.get(refundData,);
	}

	submitAck() {
		const url = `${environment.smartServiceURL
			}/api/smart/v1/confirmAcknowledgement`;
		return this.apiService.patch(url);
	}
	cancelAppeal(payload) {
		const url = `${environment.smartServiceURL
			}/api/smart/v1/appeal/tc/cancel/appeal`;
		return this.apiService.post(url, payload);
	}
	getAmountofTC(tcID, merchantID) {
		const url = `${environment.smartServiceURL
			}/api/smart/v1/getRefundAmount?tcID=${tcID}&merchantID=${merchantID}`;
		return this.apiService.get(url);
	}
	getrefundListDueGuidelinesChnaged(queryParams?) {
		const URL = environment.smartServiceURL + '/api/smart/v1/refund/request/tp/list?' + queryParams;
		return this.apiService.get(URL);
	}

	// Pmkvy TC Sector target
	getTargetForNonTcSectorTargetInSp(tpId, projectId, tcId) {
		const data = {
			tpId: tpId,
			projectId: projectId,
			tcId: tcId
		}
		const URL = `${environment.userServiceUrl}/api/user/v1/sp/tc/targets`;
		return this.apiService.get(URL, data);

	}
	getApplicationStageListTP(tpId) {
		const URL = environment.smartServiceURL + `/api/smart/v1/tp/application/stage/list/${tpId}`;
		return this.apiService.get(URL);

	}
	getTcCmFields(data) {
		const URL = `${environment.smartServiceURL}
		/api/smart/v1/trainingcentre/cm/getCmStatusLogByYearQuarter?tcUserName=${data.tcId}&year=${data.year}&quarter=${data.quarter}`;
		return this.apiService.get(URL);
	}
	getTcCmData(data) {
		const URL = `${environment.smartServiceURL}
		/api/smart/v1/trainingcentre/cm/quarterly/data/${data.tcId}/${data.year}/${data.quarter}`;
		return this.apiService.get(URL);
	}
	submitCMData(payload) {
		const URL = `${environment.smartServiceURL}
		/api/smart/v1/trainingcentre/cm/quarterly/action`;
		return this.apiService.post(URL, payload);
	}
	getCMReports(tcId) {
		const typeOfRequest = 'assesmentRequest';
		const URL = `${environment.smartServiceURL}
		/api/smart/v1/trainingcentre/cm/list/reportlinks?typeOfRequest=${typeOfRequest}&tcId=${tcId}`;
		return this.apiService.get(URL);
	}
	assignSurpriceVisit(payload) {
		const url = `${environment.smartServiceURL}/api/smart/v1/trainingcentre/cm/ia/assign`;
		return this.apiService.post(url, payload);
	}
	getCmContinuousMonitoringData(queryParams) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/trainingcentre/cm/list/user?` + queryParams;
		return this.apiService.get(URL);
	}
	getCentreInspectorData() {
		const URL = `${environment.userServiceUrl}/api/user/v1/centreinspector`;
		return this.apiService.get(URL);
	}
	getGrievanceReports(queryParams) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/appeal/tc/report?` + queryParams;
		return this.apiService.get(URL);
	}
	getGrievanceExcelReports(queryParams) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/appeal/tc/report?` + queryParams;
		return this.apiService.getBlob(URL, 'application/vnd.ms-excel');
	}
	getExcelReports(queryParam) {
		const URL = `${environment.userServiceUrl}/api/user/v1/tp/pan/report?` + queryParam;
		return this.apiService.getBlob(URL, 'application/vnd.ms-excel');
	}
	downloadCmReports(queryParams) {
		const URL = `${environment.smartServiceURL}/api/smart/v1//trainingcentre/cm/report?` + queryParams;
		return this.apiService.get(URL);
	}
	downloadPANUIReports(queryParam) {
		const URL = `${environment.userServiceUrl}/api/user/v1/tp/pan/report?` + queryParam;
		return this.apiService.get(URL);
	}
	getPANExcelReports(queryParam) {
		const URL = `${environment.userServiceUrl}/api/user/v1/tp/pan/report?` + queryParam;
		return this.apiService.getBlob(URL, 'application/vnd.ms-excel');
	}
	getCmExcelReports(queryParam) {
		const URL = `${environment.smartServiceURL}/api/smart/v1//trainingcentre/cm/report?` + queryParam;
		return this.apiService.getBlob(URL, 'application/vnd.ms-excel');
	}
	getCMInspectionReports(tcId) {
		const typeOfRequest = 'inspectionRequest';

		const URL = `${environment.smartServiceURL}/api/smart/v1/trainingcentre/cm/list/reportlinks?typeOfRequest=${typeOfRequest}&tcId=${tcId}`;
		return this.apiService.get(URL);
	}
	getDADateOfGovTC(tcId) {
		const URL = environment.smartServiceURL + `/api/smart/v1/getdadateforgovernment/${tcId}`;
		return this.apiService.get(URL);

	}
	canApplyResRefund(tcId) {
		const URL = environment.smartServiceURL + `/api/smart/v1/refund/residential/fees/${tcId}`;
		return this.apiService.get(URL);
	}
	showFeesAtDifferentStageRes(payLoad) {
		const URL = environment.smartServiceURL + `/api/smart/v1/tccaaf/process/amount`;
		return this.apiService.post(URL, payLoad);
	}
	downloadSscPrerequisitesUIReports(queryParam) {
		const URL = `${environment.sqaServiceUrl}/api/sqa/specification/report?` + queryParam;
		return this.apiService.get(URL);
	}
	getSscPrerequisitesExcelReports() {
		const URL = `${environment.sqaServiceUrl}/api/sqa/specification/report`;
		return this.apiService.getBlob(URL, 'application/vnd.ms-excel');
	}

	/* Get RPL Training Centre to link in project based on state & District & Jobrole*/
	getAATrainingCentreForRpl(tcType: string, tpID: string, limit: number, rating: number,
		pageNo?: number, stateCode?: any, districtCode?: any, projectId?: any,
		forCSSM?: any, schemeType?: any, caafTcList?, neTc?, qpCode?, covidScheme?, vishwakarma1?) {
		const queryParams = {
			tcType: tcType,
			tpID: tpID,
			limit: limit,
			rating: rating,
			pageNo: pageNo,
			stateCode: stateCode,
			districtCode: districtCode,
			projectId: projectId,
			forCSSM: forCSSM,
			schemeType: schemeType,
			caafTcList: caafTcList,
			neTc: neTc,
			qpCode: qpCode

		}

		let query;
		if (tcType === PMKVY_SCHEME_MODEL_CONSTANTS.TC_AA) {
			query = (stateCode && districtCode) ? Helpers.toBackendQuery({
				processType: tcType,
				'trainingPartner.userName': tpID,
				status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS,
				'address.state.id': stateCode,
				'address.district.id': districtCode,
				// rating: rating
			}) : Helpers.toBackendQuery({
				processType: tcType,
				'trainingPartner.userName': tpID,
				// rating: rating,
				status: PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS
			});
		}

		let trainingCenterUrl = pageNo ? `${environment.userServiceUrl
			}/api/user/v1/trainingcentres/rpl?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}` : `${environment.userServiceUrl
			}/api/user/v1/trainingcentres/rpl?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}`;

		if (caafTcList) {
			trainingCenterUrl = pageNo ? `${environment.userServiceUrl
				}/api/user/v1/trainingcentres/rpl?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}&caafTcList=${caafTcList}` : `${environment.userServiceUrl
				}/api/user/v1/trainingcentres/rpl?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}&caafTcList=${caafTcList}`;
		} else {
			trainingCenterUrl = pageNo ? `${environment.userServiceUrl
				}/api/user/v1/trainingcentres/rpl?query=${query}&pageNo=${pageNo}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}` : `${environment.userServiceUrl
				}/api/user/v1/trainingcentres/rpl?query=${query}&limit=${limit}&forCSSM=${forCSSM}&schemeType=${schemeType}`;

		}

		if (neTc) {
			trainingCenterUrl = `${trainingCenterUrl}&neTc=${neTc}`
		}
		if (projectId) {
			trainingCenterUrl = `${trainingCenterUrl}&projectId=${projectId}`
		}
		if (covidScheme) {
			trainingCenterUrl = `${trainingCenterUrl}&covidScheme=${covidScheme}`
		}

		// if (status) {
		trainingCenterUrl = `${trainingCenterUrl}&status=${PMKVY_SCHEME_MODEL_CONSTANTS.TC_PMKVY_STATUS}`
		// }
		if (vishwakarma1) {
			trainingCenterUrl = `${trainingCenterUrl}&pmVishwaKarmaScheme=${vishwakarma1}`
		}
		return this.apiService.get(trainingCenterUrl);
	}

	isTcEligibleToChangeLoc() {
		const URL = `${environment.smartServiceURL}/api/smart/v1/locationchange/istceligible`
		return this.apiService.get(URL);
	}

	applyLocationChange(data) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/locationchange/apply`
		return this.apiService.post(URL, data);
	}

	updateTPSALocationChange(data) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/locationchange/tporschemeapproveraction`
		return this.apiService.put(URL, data);
	}

	addCovidJobroleToAATc(payload) {
		const url = `${environment.userServiceUrl}/api/batch/v1/update/covidQPs/link/tc`
		return this.apiService.post(url, payload);
	}

	getCovidJobroleFromAATc(filter, pageNo?, itemsPerPage?, isJobroleChecking?) {
		/*
			query = {
				tpId: filter.tpId,
				tpId: filter.tcId,
				qpCode: filter.qpCode,
				stateId: filter.stateId,
				districtId: filter.districtId,
			}
		*/
		let query = filter || {};
		if (filter && filter.qpCode) {
			query['qpCode'] = filter.qpCode;
		}
		if (pageNo || itemsPerPage) {
			query['pageNo'] = pageNo;
			query['limit'] = itemsPerPage;
		}
		if (isJobroleChecking) {
			query['pageNo'] = isJobroleChecking;
		}
		query['pagination'] = 'yes';
		const url = `${environment.userServiceUrl}/api/user/v1/trainingcentres/list/covidScheme`
		return this.apiService.get(url, query);
	}

	downloadExcelCovidQpsLinkedTc(pageNo?, itemsPerPage?) {
		let query = {};
		query['pageNo'] = pageNo || 1;
		query['size'] = itemsPerPage || 500;
		query['op'] = 'download';
		const url = `${environment.userServiceUrl}/api/user/v1/trainingcentres/list/covidScheme`
		return this.apiService.getBlob(url, 'application/vnd.ms-excel', query);
	}

	/* change for PMU role can add/edit job role of tc */
	getbatchCreatedOrNot(params) {
		const url = `${environment.userServiceUrl}/api/batch/v1/get/validatejobrole`;
		return this.apiService.get(url, params);
	}
	rplJobRolePayment(payload) {
		const URL = environment.userServiceUrl + '/api/user/v1/trainingcentre/rplJobrole/payment';
		return this.apiService.post(URL, payload);
	}
	submitFeedbackOfInspector(data) {
		const URL = `${environment.smartServiceURL}/api/smart/v1/inspectionFeedback`
		return this.apiService.post(URL, data);
	}
}



