import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from 'projects/services/src/app/storage.service';


@Injectable({ providedIn: 'root' })
export class TranslateServicePipe {
	langDictionary: any;
	selectedLanguage: any;
	constructor(
		private storageService: StorageService,
		private httpClient: HttpClient
	) { }

	useLang(val) {
		if (this.selectedLanguage !== val) {
			this.httpClient.get(`./assets/i18n/${val}.json`).subscribe(res => {
				this.storageService.setObject('langJson', JSON.stringify(res))
				this.selectedLanguage = val;
				this.langDictionary = (res);
			});
		}
	}

	getLang(value) {
		let langData
		if (this.storageService.getObject('langJson')) {
			langData = JSON.parse(this.storageService.getObject('langJson'))
		}
		var data = this.langDictionary || langData
		return data && data[value] || value;
	}
}
