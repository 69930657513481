import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { PRIVATE_VAR_STORE_PREFIX } from 'projects/shared/src/app/shared/constants/constants';

@Injectable({ providedIn: 'root' })
export class StorageService {
	systemIp: any = '000.000.000.000';

	constructor(private cookiesService: CookieService) { }
	browserFingerPrintId: any = 'default';
	// check for localstorage in browser
	localStorageSupport() {
		try {
			window.localStorage.setItem('test', 'random');
		} catch (e) {
			return false;
		}
		delete window.localStorage['test'];
		return true;
	}

	// store key value in browser supported storage
	setObject(key: string, value) {
		if (this.localStorageSupport()) {
			window.localStorage[key] = value;
		} else {
			console.log('for safari');
			this.cookiesService.put(key, value, {secure: true});
		}
	}

	// To get stored value
	getObject(key: string) {
		if (this.localStorageSupport()) {
			return window.localStorage[key];
		} else {
			console.log('for safari');
			return (this.cookiesService.get(key) || null);
		}
	}

	// Remove Individual key value
	removeStorage(key) {
		if (this.localStorageSupport()) {
			delete localStorage[key];
		} else {
			this.cookiesService.remove(key);
		}
	}

	// remove all stored value in browser
	removeAll() {
		let lang = this.getObject('language')
		let langjson = this.getObject('langJson')
		if (this.localStorageSupport()) {
			const keys = this.getAllLocalStorageKeys()
			keys.map(this.removeStorage.bind(this))
			// localStorage.clear();
		} else {
			this.cookiesService.removeAll();
		}
		if (langjson) {
			this.setObject('langJson', langjson)
		}
		this.setObject('language', lang)
	}

	getAllLocalStorageKeys() {
		let keys: string[] = [];
		for (let i = 0, len = localStorage.length; i < len; ++i) {
			const key = localStorage.key(i)
			keys.push(key);
			// console.log( localStorage.getItem( localStorage.key( i ) ) );
		}
		keys = keys.filter(key => {
			return !key.startsWith(PRIVATE_VAR_STORE_PREFIX)
		})
		return keys;
	}

	setBrowserFingePrintObj(fpId) {
		this.browserFingerPrintId = fpId;
	}

	getBrowserFingePrintObj() {
		console.log('fingerprint', this.browserFingerPrintId)
		return this.browserFingerPrintId || 'default';
	}
	set setIp(ip) {
		sessionStorage.setItem('sysIp', ip);
	}

	get getIp() {
		return (sessionStorage.getItem('sysIp') || '000.000.000.000');
	}
}
