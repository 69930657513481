import { AfterViewInit, Component, OnDestroy, OnInit, isDevMode } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { UserIdleService } from "angular-user-idle";
import { TranslateServicePipe } from "projects/common/src/app/translation/translation.service";
import { UserService } from "projects/services/src/app/API/user.service";
import { LoadingService } from "projects/services/src/app/loading.service";
import { RoutingService } from "projects/services/src/app/routing.service";
import { StorageService } from "projects/services/src/app/storage.service";
import * as Fingerprint2 from "fingerprintjs2";
import { fromEvent, map, merge, Observable, Observer } from "rxjs";
import { environment } from "src/environments/environment";
declare var toastr: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    isOnline: boolean;
    newtorkStatus: string;
    isTesting = false;
    isEnv: any;

    constructor(
        private translate: TranslateServicePipe,
        private _router: Router,
        private routingService: RoutingService,
        private storageService: StorageService,
        private loadinService: LoadingService,
        private userService: UserService,
        private userIdle: UserIdleService,
    ) {}
    // @HostListener('window:beforeunload', ['$event'])
    // public beforeunloadHandler($event) {
    // 	this.storageService.removeAll();
    // 	$event.returnValue = "Are you sure?";
    // }

    ngOnInit() {
        this.languageAaja();
        window.addEventListener('keydown', event => {
            if (event.keyCode === 123 || (event.ctrlKey && event.shiftKey && event.keyCode === 73)) {
                event.preventDefault();
            }
        });
        window.addEventListener('contextmenu', event => event.preventDefault());
        // localStorage.openpages = Date.now();
        // window.addEventListener("storage", (event) => {
        //     if(event.key === "openpages"){
        //         // Listen if anybody else is opening the same page!
        //         localStorage.page_available = Date.now();
        //     }
        //     if(event.key === "page_available" && !window.location.href.includes("#googtrans")){
        //         // alert("One more page already open");
        //         window.close();
        //         window.location.href = "/";
        //         var that: any = window.addEventListener.bind(this);
        //         window.removeEventListener("storage", that, false);
        //     }
        // })
        // window.addEventListener(
        //     "storage",
        //     (event) => {
        //         if (event.storageArea === localStorage) {
        //             const token = localStorage.getItem("access_token");
        //             if (token !== null && !window.location.href.includes("#googtrans")) {
        //                 this._router.navigateByUrl("/");
        //                 var that: any = window.addEventListener.bind(this);
        //                 window.removeEventListener("storage", that);
        //             }
        //         }
        //     },
        //     false
        // );
        // window.addEventListener(
        //     "popstate",
        //     function (event) {
        //         const token = localStorage.getItem("access_token");
        //         if (!token && !window.location.href.includes("#googtrans")) {
        //             history.pushState(null, null, "/");
        //         }
        //     },
        //     false
        // );
        this._router.events.subscribe((route: any) => {
            if (route instanceof NavigationStart) {
                this.loadinService.blockUI();
            }
            if (route instanceof NavigationEnd) {
                // const lang = this.storageService.getObject('language');
                // this.translate.use(lang ? lang : 'en');
                (<any>window).ga("set", "page", route.urlAfterRedirects); // google analytics
                (<any>window).ga("send", "pageview");
                this.loadinService.unblockuI();
                const online = this.checkNetworkAval().subscribe((onlinechecked) => {
                    this.isOnline = onlinechecked;
                    if (!this.isOnline) {
                        toastr.options = {
                            closeButton: true,
                            preventDuplicates: true,
                            // 'positionClass': 'toast-bottom-left',
                            showDuration: "300",
                            hideDuration: "1000",
                            timeOut: "5000",
                            extendedTimeOut: "1000",
                        };
                        toastr.warning(
                            "There is no Internet connection, Please connect to network"
                        );
                        // alert('no internet connection');
                    }
                });
                return;
            }
            const url = route.url;
            if (url && url.indexOf("#") === -1) {
                window.scrollTo(0, 0); // after navigation page scroll to top
            }
        });

        // this.destroySession();
        // Unregister service worker from existing clients - jitendra
        if (window.navigator && navigator.serviceWorker) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (const registration of registrations) {
                    registration.unregister();
                }
            });
        }
        this.getEssentialRequirment();
    }

    async getEssentialRequirment() {
        await this.getBrowerFingerPrint();
        await this.userService.getIpCliente().subscribe((res) => {
            console.log(res);
            this.storageService.setIp = res;
            //   this.checkForLoggedInUser();
        });
    }

    ngAfterViewInit() {
        const token = this.storageService.getObject("access_token");
        if (token) {
            const us = this;
            window.addEventListener("load", function (event) {
                console.log("--------> load");
                // us.initiateTimer();
            });
        }
    }

    getBrowerFingerPrint() {
        var hash;
        const scope = this;
        setTimeout(function () {
            Fingerprint2.get(function (components) {
                console.log(components); // an array of components: {key: ..., value: ...}
                const values = components.map(function (component) {
                    return component.value;
                });
                hash = Fingerprint2.x64hash128(values.join(""), 31);
                scope.storageService.setBrowserFingePrintObj(hash);
                // scope.setFingetPrintData(hash);
                console.log(hash);
            });
        }, 1000);
    }

    languageAaja() {
        const data = this.storageService.getObject("language");
        if (data) {
            this.translate.useLang(data);
        } else {
            this.storageService.setObject("language", "en");
            this.translate.useLang("en");
        }
    }

    checkForLoggedInUser() {
        if (this.storageService.getObject("access_token")) {
            if (
                window.location.pathname === "/" ||
                window.location.pathname === "/login"
            ) {
                this.routingService.goNext();
            } else {
                return true;
            }
        } else {
            let lang = this.storageService.getObject("language");
            let langjson = this.storageService.getObject("langJson");
            this.storageService.removeAll();
            if (langjson) {
                this.storageService.setObject("langJson", langjson);
            }
            this.storageService.setObject("language", lang);
            return true;
            // this._router.navigate(['']);
        }
    }

    checkNetworkAval() {
        return merge<any>(
            fromEvent(window, "offline").pipe(map(() => false)),
            fromEvent(window, "online").pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            })
        );
    }
    ngOnDestroy() {
        this.storageService.removeAll();
    }
}