import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TrainingCentersService } from 'projects/services/src/app/API/training-centers.service';
import { UserService } from 'projects/services/src/app/API/user.service';
declare var swal: any;
declare var toastr: any;
@Component({
	selector: 'app-feedback-of-inspector',
	templateUrl: './feedback-of-inspector.component.html',
	styleUrls: ['./feedback-of-inspector.component.css']
})
export class FeedbackOfInspectorComponent implements OnInit {
	showForm = false;
	feedbackform: FormGroup;
	commonOptions = [
		{ id: 'Yes', value: 'Yes' },
		{ id: 'No', value: 'No' }

	];

	groomedOptions = [
		{ id: 'Yes', value: 'Yes' },
		{ id: 'No', value: 'No' },
	]
	financialAssistanceOptions = [
		{ id: 'Yes', value: 'Yes' },
		{ id: 'No', value: 'No' }]
	explainNonConformityOptions = [
		{ id: 'Yes', value: 'Yes' },
		{ id: 'No', value: 'No' }
	];
	rating = 0;
	loggedInUserData: any;

	constructor(
		private fb: FormBuilder,
		private trainingCentreService: TrainingCentersService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userService: UserService
	) {
		this.feedbackform = this.fb.group({
			tcId: ['', [Validators.required,]],
			tcName: ['', [Validators.required]],
			inspectorName: ['', [Validators.required]],
			inspectorReachedAtInformedTime: [null, [Validators.required]],
			inspectorBehaviorSatisfactory: [null, [Validators.required]],
			inspectorWellGroomed: [null, [Validators.required]],
			inspectorInsistForTransportation: [null, [Validators.required]],
			inspectorInsistForAccommodation: [null, [Validators.required]],
			inspectorInsistForFinancialAssistance: [null, [Validators.required]],
			inspectorExplainAllTheNonConformityFoundInInspection: [null, [Validators.required]],
			inspectorGiveEnoughTimeForRectificationOfNonConformities: [null, [Validators.required]],
			overallConductOfInspectionFindSatisfactory: [null, [Validators.required]],
			remarks: ['', []],
		})

		this.activatedRoute.queryParams.subscribe(params => {
			if(!params._id) {
				this.router.navigate([
					"/",
				]);
			}
			this.loggedInUserData = {
				// userName: window.atob(params['_id']),
				// inspectorName: window.atob(params['inspectorName']),
				// tcName: window.atob(params['tcName'])
				_id: params['_id'],
			}
			this.getData(this.loggedInUserData._id);
			
		});

	}

	ngOnInit() {
		// this.calaculateExpiryDate(this.loggedInUserData.s);


		// this.currentUserService.getCurrentUser().subscribe(res => {
		// 	this.loggedInUserData = res;
		// 	if (this.loggedInUserData) {
		// 		if (this.loggedInUserData.role === 'Training Centre') {
		// 			if(!this.loggedInUserData.inspectionFeedback && this.loggedInUserData.inspectionFeedback.feedbackForm) {
		// 				swal('Token expired');
		// 				this.router.navigate(['/login']);
		// 				return;
		// 			}


		// 		} else {
		// 			swal('Invalid role');
		// 			this.router.navigate(['/login']);
		// 		};
		// 	}else {
		// 		swal('Please login to submit feedback');
		// 		this.router.navigate(['/login']);
		// 	};
		// }, error => {
		// 	this.router.navigate(['/login']);
		// })
	}
	getData(Id) {
		this.userService.getFeedbackData(Id).subscribe(res => {
			if (res && res.data) {
				if(res.data.inspectionFeedback && res.data.inspectionFeedback.feedbackFormSubmitted) {
					swal('Feedback already submitted');
					this.router.navigate(['/login']);
					return;
				}
				// let day = this.calaculateExpiryDate(res.data.inspectionFeedback && res.data.inspectionFeedback.feedbackExpiryDate);
				if (new Date(res.data.inspectionFeedback && res.data.inspectionFeedback.feedbackExpiryDate) < new Date()) {
                  //
				  swal('Feedback form is Expired')
				  this.router.navigate(['/login']);
				  return;
				}
				
				this.feedbackform.get('tcId').setValue(res.data.userName);
				this.feedbackform.get('tcId').updateValueAndValidity();
				this.feedbackform.get('tcId').setValidators(null);
				this.feedbackform.get('tcId').disable();
				this.feedbackform.get('tcName').setValue(res.data.trainingCentreName);
				this.feedbackform.get('tcName').updateValueAndValidity();
				this.feedbackform.get('tcName').setValidators(null);
				this.feedbackform.get('tcName').disable();
				this.feedbackform.get('inspectorName').setValue(res.data.inspectionFeedback.inspectorName);
				this.feedbackform.get('inspectorName').updateValueAndValidity();
				this.feedbackform.get('inspectorName').setValidators(null);
				this.feedbackform.get('inspectorName').disable();
				this.loggedInUserData['userName'] = res.data.userName,
				this.loggedInUserData['inspectorName'] = res.data.inspectionFeedback.inspectorName,
				this.loggedInUserData['tcName'] = res.data.trainingCentreName;
				this.showForm = true
				
			} else {
					swal('Request can not be processed, Please try after sometime');
		            this.router.navigate(['/login']);
			}
		})
	}

	submitDetails() {
		if (this.feedbackform.invalid) {
			toastr.error('Please fill valid details');
			return
		}
		if (this.rating == 0) {
			toastr.error('Please give ratings');
			return
		}
		let tcData = this.feedbackform.value;
		tcData['rateOverallExperienceOfInspection'] = this.rating;
		tcData = Object.assign(tcData, this.loggedInUserData);
		swal({
			text: "Are you sure, you want to submit?",
			type: "warning",
			showCancelButton: true,
			cancelButtonText: "Cancel",
			confirmButtonText: "Yes, Submit",
		}).then((action: any) => {
			if (action.value) {
				this.trainingCentreService
					.submitFeedbackOfInspector(tcData)
					.subscribe(
						(res) => {
							swal({
								title: "Success",
								text: "Feedback Of Inspector has been submitted successfully.",
								type: "success",
								confirmButtonText: "Close",
							});
							this.router.navigate([
								"/login",
							]);
						},
						(err) => {
							console.log("error in submitting Feedback Of Inspector");
						}
					);
			}
		});
	}

	calaculateExpiryDate(ciDate) {
		if (!ciDate) {
			return;
			}
			var expiration = moment(ciDate).format("YYYY-MM-DD");
			var current_date = moment().format("YYYY-MM-DD");
			var days = moment(expiration).diff(current_date, 'days');
			return days;
	}

}
