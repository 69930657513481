import { Helpers } from "projects/training-calendar/src/app/helpers";
import { ValidationService } from "projects/services/src/app/validation.service";
import { environment } from "src/environments/environment";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { RegistrationService } from "projects/services/src/app/API/registration.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ShareDataService } from "projects/shared/src/app/shared/share-data.service";

declare var toastr: any;

@Component({
	selector: "app-direct-registration",
	templateUrl: "./direct-registration.component.html",
	styleUrls: ["./direct-registration.component.css"],
})
export class DirectRegistrationComponent implements OnInit, OnDestroy {
	generatedTPId: string; // need to remove once start sending Username  to email
	userDetailsForm: FormGroup;
	otpForm: FormGroup;
	notallowed = false;
	showID = false;
	isDisable = true;
	showform = "registration";
	placeHolder = "SPOC";
	email = "EMAIL";
	mobileNo = "MOBILE_NO";
	// selectOption = 'CHOOSEUSERGRP';
	isDevEnv = environment.userServiceUrl.includes('localhost')
	userAccount: {
		firstName: string;
		email: string;
		phone: { mobile: number; landline: number };
		role: string;
		password: string;
		emailOTP: string;
		mobileOTP: string;
	} = {
			firstName: "",
			email: "",
			phone: { mobile: null, landline: null },
			role: "",
			password: "",
			emailOTP: "",
			mobileOTP: "",
		};
	reCaptcha: any[];
	captchaInvalid: Boolean = true;
	userRole: any;
	userStep: any;
	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private sharedService: ShareDataService,
		private registrationService: RegistrationService,
		private _activatedRoute: ActivatedRoute
	) {
		if (this.isDevEnv) {
			this.captchaInvalid = false;
		}
		this.userDetailsForm = this.formBuilder.group({
			role: ["", [Validators.required]],
			name: [
				"",
				[
					Validators.required,
					ValidationService.nameValidator,
					Validators.maxLength(200),
					Validators.pattern("^[a-zA-Z .']*$"),
				],
			],
			email: [
				"",
				[
					Validators.required,
					ValidationService.emailV,
					Validators.maxLength(50),
				],
			],
			mobile: [
				"",
				[
					Validators.required,
					Validators.pattern(/^[4-9][0-9]{9}$/),
					Validators.maxLength(10),
					Validators.minLength(10),
				],
			],
			changeStatusChecked: [
				false,
				[Validators.required, Validators.pattern("true")],
			],
		});
		this.otpForm = this.formBuilder.group({
			emailOTP: [
				"",
				[Validators.required, Validators.pattern("[0-9]{6}")],
			],
			mobileOTP: [
				"",
				[Validators.required, Validators.pattern("[0-9]{6}")],
			],
		});
		this.sharedService.getRole().subscribe((role) => {
			if (role != null) {
				this.userAccount.role = role;
				if (role === "Training Partner") {
					this.notallowed = true;
				}
			}
		});
	}

	ngOnInit() {
		this.showId();
		console.log(this._activatedRoute);
		if (this._activatedRoute.queryParams["value"].trainer) {
			this.userDetailsForm
				.get("role")
				.patchValue(this._activatedRoute.queryParams["value"].trainer);
			this.userAccount.role =
				this._activatedRoute.queryParams["value"].trainer;
		}
		if (this.userAccount.role === "Training Partner") {
			// Helpers.openModal("m_modal_1_3");
		}
	}

	showId() {
		this.showID = environment.functionalities.showUserNameAndPassword;
	}

	// To go to Login page
	goTOLogin() {
		this.router.navigate(["/login"]);
	}

	// To generate OTPs,  Sent to Email and mobile
	generateOTPs() {
		if (this.userDetailsForm.invalid || this.captchaInvalid) {
			Helpers.markFormGroupTouched(this.userDetailsForm);
			this.captchaReset();
			return;
		}
		if (this.userAccount.role === 'internationalTrainer') {
			this.userRole = 'Trainer'
			this.userStep = 'internationalReg'
			this.otpForm.get('mobileOTP').setValidators([]);
			this.otpForm.get('mobileOTP').updateValueAndValidity();
		} else if (this.userAccount.role === 'internationalAssessor') {
			this.userRole = 'Assessor'
			this.userStep = 'internationalReg'
			this.otpForm.get('mobileOTP').setValidators([]);
			this.otpForm.get('mobileOTP').updateValueAndValidity();
		} else {
			this.userRole = this.userAccount.role;
			this.otpForm
				.get("mobileOTP")
				.setValidators([
					Validators.required,
					Validators.pattern("[0-9]{6}"),
				]);
			this.otpForm.get("mobileOTP").updateValueAndValidity();
		}
		this.registrationService
			.generateOTP(
				this.userRole,
				Number(this.userAccount.phone.mobile),
				this.userAccount.email,
				this.userStep
			)
			.subscribe(
				(res) => {
					this.showform = "otp";
					toastr.success("OTP has been sent successfully");
				},
				(err) => {
					this.captchaReset();
					console.log("error in generating otp");
				}
			);
	}

	// Regenrate OTP if user have not received OTPs
	reGenerateOTP() {
		// TO do
		const OTPData: { phoneNumber: number; email: string } = {
			phoneNumber: Number(this.userAccount.phone.mobile),
			email: this.userAccount.email,
		};
		this.registrationService.reGenerateOTP(OTPData, this.userRole, this.userStep).subscribe(
			(res) => {
				this.showform = "otp";
				toastr.success("OTP has been sent successfully");
			},
			(err) => {
				console.log("error in generating otp");
			}
		);
	}

	// After OTP recieved verify OTP of Email and mobile, Once User verified account will be created created for the same
	verifyOTP() {
		this.isDisable = false;
		let source;
		if (this.userAccount.role === 'internationalTrainer') {
			this.userAccount.mobileOTP = '000000';
			source = 'INTR'
		} else if (this.userAccount.role === 'internationalAssessor') {
			this.userAccount.mobileOTP = '000000';
			source = 'INAR'
		}
		const OTPs = {
			phone: this.userAccount.mobileOTP,
			email: this.userAccount.emailOTP,
		};

		const userAccountData = {
			role: this.userRole,
			email: this.userAccount.email,
			firstName: this.userAccount.firstName,
			phone: {
				mobile: Number(this.userAccount.phone.mobile),
				landline: this.userAccount.phone.landline,
			},
		};

		this.registrationService.verifyOTP(OTPs, userAccountData, source)
			.subscribe(res => {
				this.showform = 'success';
				this.generatedTPId = res.userName;
			},
				err => {
					if (err.error) {
						this.isDisable = true;
						if (err.error instanceof HttpErrorResponse) {
							toastr.error(err.error.message);
						}
					}
				}
			);
	}
	assignPlaceHolder() {
		if (this.userAccount.role === "Training Partner") {
			// Helpers.openModal("m_modal_1_3");
		} else if (this.userAccount.role === "Candidate") {
			this.notallowed = false;
			// here navigate
			this.router.navigate(["/candidate-registration/registration"]);
		} else {
			this.notallowed = false;
			this.placeHolder = "NAME";
		}
	}

	resolveRecaptcha(captchaResponse: any[]) {
		this.captchaInvalid = false;
		this.reCaptcha = captchaResponse;
		console.log(this.reCaptcha);
		if (this.reCaptcha != null) {
			this.registrationService.validateCaptcha(this.reCaptcha).subscribe(
				(res) => {
					console.log(res);
					this.captchaInvalid = false;
				},
				(err) => {
					this.captchaReset();
					toastr.err("Please try again");
				}
			);
		} else if (this.reCaptcha === null) {
			this.captchaReset();
		}
	}

	captchaReset() {
		grecaptcha.reset();
		this.reCaptcha = null;
		this.captchaInvalid = true;
	}
	ngOnDestroy() {
		// Helpers.closeModal("m_modal_1_3");
	}
}