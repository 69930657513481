import { Component, OnInit } from '@angular/core';
import { EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-tc-view-details',
  templateUrl: './tc-view-details.component.html',
  styleUrls: ['./tc-view-details.component.css']
})
export class TcViewDetailsComponent implements OnInit {

 tc_details: { tcName: string, tcmobileappstatus: boolean, userName: string, scheme: string,
  isLocationBasedTC: boolean, isOpenForAllSSC: boolean, hasfilledcaafform: boolean,
 trainingPartner: { tpName: string, tpUserName: string},
 spoc: { firstName: string, email: string, mobileNumber: number, ciStatus: boolean, ciVerifies: boolean},
 address: { addressLine: string, landmark: string, pincode: number },
 centrePrincipal: { name: string, email: string, contactNo: number},
 commendationsAndInternationalAffiliations: [{ name: string, type: string, validity: string, breifDetails: string  }],
 general: { phone: number, buildingStatus: string, buildingConstrictionType: string, previousStateOfBuilding: string},
 jobRoles: [
   { serialNumber: string, name: string, jobdescription: string, classroom: string, laboratory: string
   sector: { sectorId: string, sectorName: string, subSectorId: string, subSectorName: string},
   trainerToTrainerRation: boolean,
   type: { classroom: boolean, laboratory: boolean, hybrid: boolean},
   addtionalArea: { infrastructureNeeded: string, carpetArea: string},
   trainer: [string]} ],
   trainingCentreFacilites: { securityGaurds: boolean, biometricAttendanceSystem: boolean, greeneryInTheSurrounding: boolean},
   typeOfTrainingCenter: { name: string},
   trainer: [ { name: string, serialNumber: string, aadharNumber: string, certifiedForSSC: string, highestQualification: string,
   yearsOfExperience: number, sectorRelatedExperience: number, teachingExperience: number}]}
  = {tcName : 'krinin Institute', tcmobileappstatus: false, userName: 'Tc_1234', scheme: 'rojagar',
  isLocationBasedTC: false, isOpenForAllSSC: false, hasfilledcaafform: false,
  trainingPartner: { tpName: 'Rk Tech', tpUserName: 'Rk_1299'},
   commendationsAndInternationalAffiliations: [{ name: 'trans', type: 'International', validity: '2018-06-05 12:23:58.206',
   breifDetails: 'rjghfkg fkghdkrutyir jfhfk fgreirit'  }],
  spoc: { firstName: 'aye velnze', email: 'alenz@gmail.com', mobileNumber: 9611434381, ciStatus: false, ciVerifies: false},
  address: { addressLine: 'first cross 2nd stage BAngalore', landmark: 'near KFC', pincode: 577302},
  centrePrincipal: { name: 'xyz', email: 'xyz@yahoo.com', contactNo: 8374329849239},
  general: { phone: 756844394238, buildingStatus: 'Stand Alone Building', buildingConstrictionType: 'Prefabricated',
   previousStateOfBuilding: 'College'},
  jobRoles: [
   { serialNumber: '12ere', name: 'JHB', jobdescription: 'fkldslkf dflksdnflk djfkdjkf', classroom: 'Class_IV', laboratory: 'lab_A',
   sector: { sectorId: 'sector_123', sectorName: 'Apparel', subSectorId: '1', subSectorName: 'Garment'},
   trainerToTrainerRation: true,
   type: { classroom: true, laboratory: true, hybrid: true},
   addtionalArea: { infrastructureNeeded: 'yes', carpetArea: '56' },
   trainer: ['bob']} ],
   trainingCentreFacilites : { securityGaurds: true, biometricAttendanceSystem: true, greeneryInTheSurrounding: true},
   typeOfTrainingCenter: { name: 'dkfjfieijiojo djnfksn'},
   trainer: [ { name: 'Bob', serialNumber: 'sc123', aadharNumber: '123567454738', certifiedForSSC: 'yes', highestQualification: 'B.E',
   yearsOfExperience: 10, sectorRelatedExperience: 5, teachingExperience: 12}]};
  constructor() { }

  ngOnInit() {
  }

}
