import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Observable, catchError, map, throwError } from 'rxjs';
import * as forge from 'node-forge';
@Injectable({ providedIn: 'root' })
export class LoginService {
  private httpClient: HttpClient;

  constructor(private apiService: ApiService, private http: HttpClient, handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);

  }
  getPublicKey(): Observable<any> {
    return this.httpClient.get<any>(environment.userServiceUrl + '/api/user/v1/getkey')
  }
  encryptPassword(publicKeyPem: string, password: string, secret: string): string {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encryptedPassword = publicKey.encrypt(password, 'RSA-OAEP', {
      md: forge.md.sha256.create(),
    });

    return forge.util.encode64(encryptedPassword) + secret;
  }
  login(body) {
    const loginAPI = environment.userServiceUrl + '/api/user/v1/login';
    return this.apiService.post(loginAPI, body);
  }
  logout(username, ignoreServerResponseError = true) {
    const query = {
      ignoreServerResponseError,
    };
    let logoutAPI = environment.userServiceUrl + '/api/user/v1/logout';
    if (ignoreServerResponseError) {
      logoutAPI = `${logoutAPI}?${Helpers.serialize(query)}`;
    }
    return this.apiService.post(logoutAPI, username);
  }

  getUserManuals() {
    const userUrl = environment.userServiceUrl + '/api/user/v1/usermanual';
    return this.apiService.get(userUrl);
  }
  updateSpocFlag() {
    const userUrl =
      environment.userServiceUrl + '/api/user/v1/cancelSpocUpdate';
    return this.apiService.post(userUrl, {});
  }

  getquickLinksForIwanttoskill() {
    return [
      {
        title: 'I want to skill myself',
        subTitle: 'Courses',
        value: [
          {
            name: 'Discover appropriate training courses',
            link: 'https://nsdcindia.org/qp-nos-results',
            icon: '../../../assets/images/process.svg',
          },
          {
            name: 'Explore eLearning courses available',
            link: 'https://eskillindia.org/',
            icon: '../../../assets/images/process.svg',
          },
        ],
      },
      {
        title: 'I want to skill myself',
        subTitle: 'Find Training Centres',
        value: [
          {
            name: 'Find details about NSDC approved Fee-Based Training Centres supported by NSDC',
            link: 'https://www.nsdcindia.org/find-nsdc-training-centre',
            icon: '../../../assets/images/plus.svg',
          },
          {
            name: 'Find details about NSDC approved PMKK Centres supported by NSDC',
            link: 'https://www.nsdcindia.org/find-nsdc-training-centre-pmkk',
            icon: '../../../assets/images/process.svg',
          },
          {
            name: 'Find details about NSDC approved PMKVY Centres supported by NSDC',
            link: 'http://pmkvyofficial.org/find-a-training-centre.aspx',
            icon: '../../../assets/images/plus.svg',
          },
        ],
      },
      {
        title: 'I want to skill myself',
        subTitle: 'Find nearest Training centre with courses offered',
        value: [
          {
            name: 'Find nearest Training Centre and available courses for upskilling',
            link: 'https://nsdcindia.org/find-nsdc-training-centre',
            icon: '../../../assets/images/plus.svg',
          },
          {
            name: 'Find nearest PMKK Centre and available courses for upskilling',
            link: 'https://www.nsdcindia.org/find-nsdc-training-centre-pmkk',
            icon: '../../../assets/images/process.svg',
          },
          {
            name: 'Find nearest PMKVY Centre and available courses for upskilling',
            link: 'http://pmkvyofficial.org/find-a-training-centre.aspx',
            icon: '../../../assets/images/plus.svg',
          },
        ],
      },
      {
        title: 'I want to skill myself',
        subTitle: 'Placement and Apprenticeship',
        value: [
          {
            name: 'Find job / placement opportunities around me',
            link: 'https://smis.nsdcindia.org/',
            icon: '../../../assets/images/process.svg',
          },
          {
            name: 'Explore Apprenticeship opportunity',
            link: 'https://apprenticeshipindia.org/apprenticeship/opportunity',
            icon: '../../../assets/images/plus.svg',
          },
        ],
      },
      {
        title: 'I want to skill myself',
        subTitle: 'Candidate Registration',
        value: [
          {
            name: 'Quick registration for future skill opportunity',
            link: 'https://skillindia.nsdcindia.org/candidate-registration/registration',
            icon: '../../../assets/images/plus.svg',
          },
        ],
      },
      {
        title: 'I want to skill myself',
        subTitle: 'Help Desk and Others',
        value: [
          {
            name: 'View success stories / testimonies',
            link: 'https://nsdcindia.org/successstories',
            icon: '../../../assets/images/process.svg',
          },
          {
            name: 'Talk to NSDC Skilling Help Desk',
            subTitletitle: `+91 - 88000 - 55555  (Monday- Saturday 9 AM to 6 PM)`,
            icon: '../../../assets/images/help4.jpg',
          },
        ],
      },
    ];
  }

  // start cbcmsde pahse-3 integration
  generateOtp(num: any) {
    const loginAPI = environment.loginService + '/api/v2/nodeloginservice/generateotpsip?mobileNumber=' + num;
    return this.apiService.post(loginAPI);
  }

  verifyOtp(num: any, otp: any) {
    const loginAPI = environment.loginService + '/api/v2/nodeloginservice/verifyotpsip?mobileNumber=' + num + "&otp=" + otp;
    return this.apiService.post(loginAPI);
  }
  getCbcmsdeAuthToken() {
    const loginAPI = environment.loginService + '/api/v2/nodeloginservice/cbcmsdeauth';
    return this.apiService.get(loginAPI);
  }

  getUserID(body: any) {
    const loginAPI = environment.loginService + "/api/v2/nodeloginservice/cbcmsdeuser";
    return this.apiService.post(loginAPI, body);
  }

  getCbcmsdeCourse(body) {
    const loginAPI = environment.loginService + '/api/v2/nodeloginservice/cbcmsdecourse';
    return this.apiService.post(loginAPI, body);
  }

  getCbcmsdeQuiz(body) {
    const loginAPI = environment.loginService + '/api/v2/nodeloginservice/cbcmsdequiz';
    return this.apiService.post(loginAPI, body);
  }

  getCbcmsdeProfile(mobile) {
    const loginAPI = environment.loginService + '/api/v2/nodeloginservice/cbcmsdeProfile?mobileNumber=' + mobile;
    return this.apiService.post(loginAPI);
  }
  // cmbcmcde integration end
  getSIDHServerTime(url?): Observable<any> {
    return this.httpClient
      .get(url || "https://uat-api-fe-sid.betalaunch.in/api/timestamp/get")
      .pipe(map((res: any) => {
        return res;
      }), catchError((error: any) => {
        return throwError(new Date());
      }))
  }
}


