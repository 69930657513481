import { StorageService } from 'projects/services/src/app/storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

	constructor(
		private router: Router,
		private storageService:  StorageService
	) { }

	ngOnInit() {
	}
	goToHomepage() {
		this.storageService.removeAll();
		this.router.navigate(['/']);

	}
}
