<div class="m-grid__item m-grid__item--fluid m-wrapper">
	<!-- END: Subheader -->
	<div class="m-content">
		<div class="row">
			<div class="col-md-12">
				<!--begin::Portlet-->
				<div class="m-portlet m-portlet--tab">
					<div class="m-portlet__head">
						<div class="m-portlet__head-caption">
							<div class="m-portlet__head-title">
								<span class="m-portlet__head-icon m--hide">
									<i class="la la-gear"></i>
								</span>
								<h4 class="m-portlet__head-text">Training Centre Details</h4>
							</div>
						</div>
					</div>

					<div class="m-portlet__body">
						<form class="m-form m-form--fit m-form--label">
						<!-- <form class="m-form m-form--fit m-form--label" [formGroup]="tCRequestForm"> -->
							<div class="form-group m-form__group m--margin-top-10 form_header">
								Training Centre Info:
							</div>
							<div class="form-group m-form__group row ">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									Training Centre Name:
								</label>
								<div class="col-8">
                    				<label for="tp-details" class="col-6 col-form-label left-text-align">
                        				{{tc_details.tcName}}
                    				</label>
								</div>
							</div>
							<div class="form-group m-form__group row ">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									tcmobileappstatus:
								</label>
								<div class="col-8  ">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
										{{tc_details.tcmobileappstatus}}
									</label>
								</div>
              				</div>
             				<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									userName:
								</label>
								<div class="col-8  ">
                   					<label for="tp-details" class="col-6 col-form-label left-text-align">
                        				{{tc_details.userName}}
                      				</label>
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									isLocationBasedTC:
								</label>
								<div class="col-8  ">
                   					<label for="tp-details" class="col-6 col-form-label left-text-align">
                        				{{tc_details.isLocationBasedTC}}
                      				</label>
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									isOpenForAllSSC:
								</label>
								<div class="col-8  ">
                   					<label for="tp-details" class="col-6 col-form-label left-text-align">
                        				{{tc_details.isOpenForAllSSC}}
                      				</label>
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									hasfilledcaafform:
								</label>
								<div class="col-8  ">
                   					<label for="tp-details" class="col-6 col-form-label left-text-align">
                        				{{tc_details.hasfilledcaafform}}
                      				</label>
								</div>
							</div>
							<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">Training Partner:</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									Name :
								</label>
								<div class="col-8">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
										{{tc_details.trainingPartner.tpName}}
									</label>
								</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									User Name:
								</label>
								<div class="col-8">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
										{{tc_details.trainingPartner.tpUserName}}
									</label>
								</div>
							</div>
							<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">Spoc Details:</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									First Name:
								</label>
								<div class="col-8  ">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
										{{tc_details.spoc.firstName}}
									</label>
								</div>
							</div>
							<div class=" form-group m-form__group row ">
								<label for="tp-details" class="col-2 col-form-label right-text-align ">
									email:
								</label>
								<div class="col-8 ">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.spoc.email}}
									</label>
								</div>
							</div>
							<div class="form-group m-form__group row ">
								<label for="tp-details " class="col-2 col-form-label right-text-align ">
									mobileNumber:
								</label>
								<div class="col-8 ">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.spoc.mobileNumber}}
									</label>
								</div>
							</div>
							<div class="form-group m-form__group row ">
								<label for="tp-details " class="col-2 col-form-label right-text-align ">
                    				civerified:
								</label>
								<div class="col-8 ">
                     				<label for="tp-details" class="col-6 col-form-label left-text-align">
                          				{{tc_details.spoc.ciVerifies}}
                        			</label>
								</div>
                			</div>
                			<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">Address:</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									addressLine :
								</label>
								<div class="col-8">
                    				<label for="tp-details" class="col-6 col-form-label left-text-align">
                        				{{tc_details.address.addressLine}}
                      				</label>
								</div>
							</div>
							<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										landmark:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
										{{tc_details.address.landmark}}
										</label>
									</div>
							</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									pincode:
								</label>
								<div class="col-8">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
									{{tc_details.address.pincode}}
									</label>
								</div>
							</div>
							<div class="form-group m-form__group m--margin-top-10 form_header subheader">AddressProof:</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									type:
								</label>
								<div class="col-8">
									<label for="tp-details" class="col-6 col-form-label left-text-align">
										Rent Agreement
									</label>
								</div>
							</div>
							<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">centrePrincipal:</div>
							<div class="form-group m-form__group row">
								<label for="tp-details" class="col-2 col-form-label right-text-align">
									name :
								</label>
								<div class="col-8">
                    				<label for="tp-details" class="col-6 col-form-label left-text-align">
                        				{{tc_details.centrePrincipal.name}}
                      				</label>
								</div>
							</div>
							<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										email:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
										{{tc_details.centrePrincipal.email}}
										</label>
									</div>
							</div>
							<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										contactNumber:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
										{{tc_details.centrePrincipal.contactNo}}
										</label>
									</div>
							</div>
							<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">Job Roles</div>
							<div  *ngFor = " let key of tc_details.jobRoles">
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										serialNumber:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.serialNumber}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										jobDesciption:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.jobdescription}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										name:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.name}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										TrainerToTrainerRation:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.trainerToTrainerRation}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										ClassRoom:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.classroom}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Laboratory:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.laboratory}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group m--margin-top-10 form_header subheader">Sector</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										sectorId:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.sector.sectorId}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										sectorName:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.sector.sectorName}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										subSectorId:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.sector.subSectorId}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										subSectorName:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.sector.subSectorName}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group m--margin-top-10 form_header subheader">Type</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										ClassRoom:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.type.classroom}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Laboratory:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.type.laboratory}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										hybrid:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.type.hybrid}}
										</label>
									</div>
								</div>
							</div>
							<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">Training Centre Facilites</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										SecurityGaurds:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.trainingCentreFacilites.securityGaurds}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										BiometricAttendanceSystem:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.trainingCentreFacilites.biometricAttendanceSystem}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										GreeneryInTheSurrounding:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.trainingCentreFacilites.greeneryInTheSurrounding}}
										</label>
									</div>
								</div>
								<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">Trainer</div>
							<div *ngFor = " let key of tc_details.trainer">
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Name:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.name}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										serialNumber:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.serialNumber}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										AadharNumber:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.aadharNumber}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										certifiedForSSC:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.certifiedForSSC}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										YearsOfExperience:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.yearsOfExperience}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										SectorRelated Experience:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.sectorRelatedExperience}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Teaching Experience:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.teachingExperience}}
										</label>
									</div>
								</div>
							</div>
							<hr>
							<div class="form-group m-form__group m--margin-top-10 form_header">General</div>
							<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Phone Number:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.general.phone}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										buildingStatus
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.general.buildingStatus}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										buildingConstrictionType:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.general.buildingConstrictionType}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										previousStateOfBuilding:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{tc_details.general.previousStateOfBuilding}}
										</label>
									</div>
								</div>
								<hr>
								<div class="form-group m-form__group m--margin-top-10 form_header">Commendations And International Affiliations</div>
								<div *ngFor = "let key of tc_details.commendationsAndInternationalAffiliations">
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Name:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.name}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Type:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.type}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										Validity:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.validity}}
										</label>
									</div>
								</div>
								<div class="form-group m-form__group row">
									<label for="tp-details" class="col-2 col-form-label right-text-align">
										BreifDetails:
									</label>
									<div class="col-8">
										<label for="tp-details" class="col-6 col-form-label left-text-align">
											{{key.breifDetails}}
										</label>
									</div>
								</div>
								</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
