import { DTable, SelectFilter, TextFilter, Pagination, MultiTabTable, MultiTabTableTab } from 'projects/common/src/app/types';
import { SCHEME_TYPE } from 'projects/training-partner/src/app/training-partner/constants';


export const BATCH_TAB_ID = Object.freeze({
	PENDING_REQUEST: 'pendingRequest',
	ACCEPTED: 'accepted',
	REJECTED: 'rejected',
	CANCELLED: 'cancelled'
});
interface IBatchTableEvent {
	pendingBatchPaginationChange?: () => any,
	approvedBatchPaginationChange?: () => any,
	rejectedBatchPaginationChange?: () => any,
	cancelledBatchPaginationChange?: () => any,
	pendingFilterChange?: () => any,
	approvedFilterChange?: () => any,
	rejectedFilterChange?: () => any,
	cancelledFilterChange?: () => any,
	batchRequestType?: any,
	statesList?: any,
	pagination?: any
}

export const B_ASSESSMENT_STATUS = {
	SSC_ASSESSMENT_ACCEPTED_BATCH: 'SSC ACCEPTED,SSC ACCEPTED BATCH', // - Assessment Results Approved by SSC – (SUBMITTED)
	SSC_ASSIGNED: 'SSC ASSIGNED', // - Assigned to SSC
	SSC_ACCEPTED_BATCH: 'SSC ACCEPTED BATCH', // SSC Accepted Batch
	AA_ASSIGNED: 'AA ASSIGNED', // SSC Assigned AA
	AR_ASSIGNED: 'AR ASSIGNED',
	AR_ASSESSMENT_ONGOING: 'AR INITIAL', // - Assessment On-going – (PENDING)
	AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED: 'AR DRAFT', // - Assessment On-going – (PENDING)
	AR_ASSESSMENT_ONGOING_MARKS_FILLED: 'AR DRAFTED', // - Pending for Assessment Submission – (PENDING)
	AR_ASSESSMENT_ONGOING_MARKS_SUBMITTED: 'AR SUBMITTED', // – Assessment Submitted – (SUBMITTED)
	AA_APPROVED_ASSESSMENT_ONGOING_MARKS: 'AA APPROVED', // - Assessment Results Approved by AA – (SUBMITTED)
	AA_REJECTED_ASSESSMENT_ONGOING_MARKS: 'AA REJECTED', // - Sent back by AA – (PENDING)
	SSC_ASSESSMENT_ACCEPTED: 'SSC ACCEPTED', // - Assessment Results Approved by SSC – (SUBMITTED)
	SSC_ASSESSMENT_REJECTED: 'SSC REJECTED', // – Assessment Results Approved by AA – (SUBMITTED)
	SSC_AA_ASSESSMENT_REJECTED: 'SSC & AA REJECTED', // - Sent back by SSC & AA – (PENDING)
	ASSESSMENT_DONE: 'ASSESSMENT DONE', // - Assessment Completed – (SUBMITTED)
	SSCPREREJECTED: 'SSC PREREJECTED',
	SSCPREAPPROVED: 'SSC PREAPPROVED',
	AAPREREJECTED: 'AA PREREJECTED',
	AAPREAPPROVED: 'AA PREAPPROVED',
	ARPRESUBMIT: 'AR PRESUBMIT',
	INITIAL: 'INITIAL',
	// For CR
	ASSESSMENT_DATE_CR: 'ASSESSMENT_DATE_CR_ASSIGNED_TO_SSC', 	// ASSESSMENT_DATE_CR
	ASSESSMENT_DATE_CR_REJECTED_BY_SSC: 'ASSESSMENT_DATE_CR_REJECTED_BY_SSC',
	ASSESSMENT_DATE_CR_ASSIGNED_TO_PMU: 'ASSESSMENT_DATE_CR_ASSIGNED_TO_PMU',
	ASSESSMENT_DATE_CR_REJECTED_BY_PMU: 'ASSESSMENT_DATE_CR_REJECTED_BY_PMU',
	// just for Getting(checked on the fly)
	ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU: 'ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU',
	ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC: 'ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC',
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC: 'ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC',
	ASSESSOR_CR_ACCEPTED_BY_SSC: 'ASSESSOR_CR_ACCEPTED_BY_SSC',
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC_ASSMT: 'ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC',
	ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC_ASSMT: 'ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC',
	ASSESSOR_CR_ACCEPTED_BY_SSC_ASSMT: 'ASSESSOR_CR_ACCEPTED_BY_SSC',


	ASSESSOR_CR: 'ASSESSOR_CR_ASSIGNED_TO_SSC',	// ASSESSOR_CR
	ASSESSOR_CR_REJECTED_BY_SSC: 'ASSESSOR_CR_REJECTED_BY_SSC',
	ASSESSOR_CR_ASSIGNED_TO_PMU: 'ASSESSOR_CR_ASSIGNED_TO_PMU',
	ASSESSOR_CR_REJECTED_BY_PMU: 'ASSESSOR_CR_REJECTED_BY_PMU',
	// just for Getting(checked on the fly)
	ASSESSOR_CR_ACCEPTED_BY_PMU: 'ASSESSOR_CR_ACCEPTED_BY_PMU',


	ASSESSMENT_AGENCY_CR: 'ASSESSMENT_AGENCY_CR_ASSIGNED_TO_SSC',	// ASSESSMENT_AGENCY_CR
	ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC: 'ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC',
	ASSESSMENT_AGENCY_CR_ASSIGNED_TO_PMU: 'ASSESSMENT_AGENCY_CR_ASSIGNED_TO_PMU',
	ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU: 'ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU',
	// just for Getting(checked on the fly)
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU: 'ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU',


	TRAINER_CR: 'TRAINER_CR',
	TRAINER_CR_REJECTED_BY_SSC: 'TRAINER_CR_REJECTED_BY_SSC',
	TRAINER_CR_WAITING_FOR_PMU: 'TRAINER_CR_WAITING_FOR_PMU',
	TRAINER_CR_REJECTED_BY_PMU: 'TRAINER_CR_REJECTED_BY_PMU',
	// just for Getting(checked on the fly)
	TRAINER_CR_ACCEPTED_BY_PMU: 'TRAINER_CR_ACCEPTED_BY_PMU',


	BATCH_END_DATE_CR: 'BATCH_END_DATE_CR',
	BATCH_END_DATE_CR_REJECTED_BY_SSC: 'BATCH_END_DATE_CR_REJECTED_BY_SSC',
	BATCH_END_DATE_CR_WAITING_FOR_PMU: 'BATCH_END_DATE_CR_WAITING_FOR_PMU',
	BATCH_END_DATE_CR_REJECTED_BY_PMU: 'BATCH_END_DATE_CR_REJECTED_BY_PMU',
	// just for Getting(checked on the fly)
	BATCH_END_DATE_CR_ACCEPTED_BY_PMU: 'BATCH_END_DATE_CR_ACCEPTED_BY_PMU',

	BATCH_TIMINGS_CR: 'BATCH_TIMINGS_CR',
	BATCH_TIMINGS_CR_REJECTED_BY_SSC: 'BATCH_TIMINGS_CR_REJECTED_BY_SSC',
	BATCH_TIMINGS_CR_WAITING_FOR_PMU: 'BATCH_TIMINGS_CR_WAITING_FOR_PMU',
	BATCH_TIMINGS_CR_REJECTED_BY_PMU: 'BATCH_TIMINGS_CR_REJECTED_BY_PMU',
	// just for Getting(checked on the fly)
	BATCH_TIMINGS_CR_ACCEPTED_BY_PMU: 'BATCH_TIMINGS_CR_ACCEPTED_BY_PMU',


	// Fee based status
	AA_REJECTED_SSC_REQ: 'AA REJECECTED SSC REQ',
	AUTO_REJECTED: 'AA REJECTED SSC REQ',

	// Cancel Batch PMKVY
	CANCEL_BY_TP: 'CANCEL BY TP',
	CANCEL_BY_TC: 'CANCEL BY TC',

	// trackBatch status
	BATCH_ASSMT_SSC_ASSIGNED: 'BATCH_ASSMT_SSC_ASSIGNED',

}

export const BATCH_TRACK_STATUS = {
	BATCH_CREATED: {
		value: 'BATCH_CREATED',
		display: 'Batch Created',
	},
	BATCH_DRAFT: {
		value: 'DRAFT',
		display: 'Drafted'
	},
	BATCH_CANDIDATE_ENROLLED: {
		value: 'BATCH_CANDIDATE_ENROLLED',
		display: 'Batch Enrolled'
	},
	BATCH_ASSMT_SSC_ASSIGNED: {
		value: 'BATCH_ASSMT_SSC_ASSIGNED',
		display: 'Submitted to SSC'
	},
	TRAINING_ON_HOLD: {
		value: 'TRAINING_ON_HOLD',
		display: 'Training On Hold'
	},
	BATCH_ASSMT_AA_ASSIGNED: {
		value: 'BATCH_ASSMT_AA_ASSIGNED',
		display: 'Assigned to Assessment Agency'
	},
	BATCH_ASSMT_AR_ASSIGNED: {
		value: 'BATCH_ASSMT_AR_ASSIGNED',
		display: 'Assigned Assessor'
	},
	BATCH_ASSMT_NOT_AA_ASSIGNED: {
		value: 'BATCH_ASSMT_NOT_AA_ASSIGNED',
		display: 'Pending with SSC to Assign AA'
	},
	BATCH_ASSMT_AA_ACCEPTED: {
		value: 'BATCH_ASSMT_AA_ACCEPTED',
		display: 'Pending with AA to Assign Assessor',
	},
	BATCH_ASSMT_AA_REJECTED: {
		value: 'BATCH_ASSMT_AA_REJECTED',
		display: 'Batch Rejected by AA'
	},
	BATCH_AUTO_REVERSED_FROM_AA: {
		value: 'BATCH_AUTO_REVERSED_FROM_AA',
		display: 'Auto Reversed from AA'
	},
	BATCH_AUTO_REVERSED_FROM_AR: {
		value: 'BATCH_AUTO_REVERSED_FROM_AR',
		display: 'Batch Auto Reversed from AR'
	},
	BATCH_ASSMT_AR_REJECTED: {
		value: 'BATCH_ASSMT_AR_REJECTED',
		display: 'Batch Rejected by Assessor'
	},
	BATCH_CANCELLED_BY_TP: {
		value: 'BATCH_CANCELLED_BY_TP',
		display: 'Batch Cancelled by TP'
	},
	BATCH_CANCELLED_BY_TC: {
		value: 'BATCH_CANCELLED_BY_TC',
		display: 'Batch Cancelled by TC'
	},
	BATCH_CANCELLED_DUE_TO_AEBAS_NON_COMPLAINCE: {
		value: 'BATCH_CANCELLED_DUE_TO_AEBAS_NON_COMPLAINCE',
		display: 'Batch Cancelled due to AEBAS Non compliance'
	},
	BATCH_CANCELLED_DUE_TO_DROPOUT: {
		value: 'BATCH_CANCELLED_DUE_TO_DROPOUT',
		display: 'Batch Cancelled due to candidate drop out'
	},
	BATCH_ASSMT_RESULT_NOT_AR_SUBMITTED: {
		value: 'BATCH_ASSMT_RESULT_NOT_AR_SUBMITTED',
		display: 'Pending with Assessor for Assessments'
	},
	BATCH_ASSMT_RESULT_AR_SUBMITTED: {
		value: 'BATCH_ASSMT_RESULT_AR_SUBMITTED',
		display: 'Pending with AA for Marks Approval'
	},
	BATCH_ASSMT_RESULT_AA_APPROVED: {
		value: 'BATCH_ASSMT_RESULT_AA_APPROVED',
		display: 'Pending with SSC for Marks Approval'
	},
	BATCH_ASSMT_RESULT_AA_REJECTED: {
		value: 'BATCH_ASSMT_RESULT_AA_REJECTED',
		display: 'Result send back to Assessor by AA'
	},
	BATCH_ASSMT_RESULT_SSC_AA_REJECTED: {
		value: 'BATCH_ASSMT_RESULT_SSC_AA_REJECTED',
		display: 'Result send back to Assessor by SSC & AA'
	},
	BATCH_ASSMT_RESULT_SSC_REJECTED: {
		value: 'BATCH_ASSMT_RESULT_SSC_REJECTED',
		display: 'Result send back to AA by SSC'
	},
	BATCH_ASSMT_RESULT_ASSESSMENT_DONE: {
		value: 'BATCH_ASSMT_RESULT_ASSESSMENT_DONE',
		display: 'Batch Certified'
	},
	BATCH_ASSMT_SSC_ACCEPTED: {
		value: 'BATCH_ASSMT_SSC_ACCEPTED',
		display: 'Batch Accepted By SSC'
	},
	BATCH_ASSMT_RESULT_INITIATED: {
		value: 'BATCH_ASSMT_RESULT_INITIATED',
		display: 'Assessment Started'
	},
	BATCH_CANCELLED_BY_SCPWD: {
		value: 'BATCH_CANCELLED_BY_SCPWD',
		display: 'Batch Cancelled by SCPWD'
	},
	BATCH_ASSMT_AR_ACCEPTED: {
		value: 'BATCH_ASSMT_AR_ACCEPTED',
		display: 'Batch Accepted by Assessor'
	},
	BATCH_ASSMT_SSC_REJECTED: {
		value: 'BATCH_ASSMT_SSC_REJECTED',
		display: 'Batch Sent Back by SSC'
	},
	BATCH_ASSMT_RESULT_SSC_ACCEPTED: {
		value: 'BATCH_ASSMT_RESULT_SSC_ACCEPTED',
		display: 'Result Accepted by SSC'
	},
	BATCH_CANCELLED_CRON: {
		value: 'BATCH_AUTO_CANCELLED',
		display: 'Start Date is Passed, Batch is Auto-cancelled.'
	},
	BATCH_SSDM_PMU_SENT_BACK: {
		value: 'BATCH_SSDM_PMU_SENT_BACK',
		display: 'Batch Sent Back by PMU'
	},
	BATCH_SUBMITTED_FOR_REASSESSMENT: {
		value: 'BATCH_SUBMITTED_FOR_REASSESSMENT',
		display: 'Batch Submitted for Re-Assessment'
	},
	BATCH_SSDM_PMU_ASSIGNED: {
		value: 'BATCH_SSDM_PMU_ASSIGNED',
		display: 'BATCH ASSIGNED TO PMU',
		SSDM_PMU_DISPLAY: 'BATCH ASSIGNED TO SSDM',
	},
	BATCH_CANCEL_BY_SSDM_PMU: {
		value: 'BATCH_CANCEL_BY_SSDM_PMU',
		display: 'BATCH CANCELLED BY SSDM PMU'
	},
	BATCH_CANCEL_BY_ALT_ID_VERIFICATION_REJECTION: {
		value: 'BATCH_CANCEL_BY_ALT_ID_VERIFICATION_REJECTION',
		display: 'Batch Cancelled by Altenate Id Verification'
	},
	BATCH_SSC_SENT_BACK: {
		value: 'BATCH_SSC_SENT_BACK',
		display: 'BATCH Sent Back by SSC'
	},
	BATCH_CANCELLED_BY_SSC: {
		value: 'BATCH_CANCELLED_BY_SSC',
		display: 'Batch Cancelled By SSC'
	},
	BATCH_CANDIDATE_ATTENDANCE_DONE_PARTIALLY: {
		value: 'BATCH_CANDIDATE_ATTENDANCE_DONE_PARTIALLY',
		display: 'Batch Candidate Attendance Started'
	},
	BATCH_CANDIDATE_ATTENDANCE_DONE: {
		value: 'BATCH_CANDIDATE_ATTENDANCE_DONE',
		display: 'Batch Candidate Attendance Completed'
	},
	BATCH_RESCHEDULED_BY_SSC: {
		value: 'BATCH_RESCHEDULED_BY_SSC',
		display: 'Batch Rescheduled By SSC'
	},
	BATCH_SUBMITTED_BY_TC: {
		value: 'BATCH_SUBMITTED_BY_TC',
		display: 'Batch Submitted By TC'
	},
	BATCH_ASSMT_RESULT_INITIATED_PARTIALLY: {
		value: 'BATCH_ASSMT_RESULT_INITIATED_PARTIALLY',
		display: 'Assessment Started'
	},
	BATCH_SENT_BACK_BY_SSC: {
		value: 'BATCH_SENT_BACK_BY_SSC',
		display: 'Batch Sent Back by SSC'
	},
	BATCH_CANCELLED_AS_PER_OM: {
		value: 'CANCELLED_AS_PER_OM',
		display: 'Batch Cancelled as per OM'
	},
	BATCH_ASSMT_RESULT_SSC_AND_AA_REJECTED: {
		value: 'BATCH_ASSMT_RESULT_SSC_&_AA_REJECTED',
		display: 'Result Rejected by SSC & AA'
	},
	BATCH_ASSMT_1_RESULT_SSC_ACCEPTED: {
		value: 'BATCH_ASSMT_1_RESULT_SSC_ACCEPTED',
		display: 'Assessment 1 Result Accepted by SSC'
	}
}

export const OFFLINE_ASSMNT_STATUS = {
	OFFLINE_ASSMT_INITIAL: {
		value: 'OFFLINE_ASSMT_INITIAL',
		display: 'Non-Assessor App Assessment - Initiated'
	},
	OFFLINE_ASSMT_AR_PENDING: {
		value: 'OFFLINE_ASSMT_AR_PENDING',
		display: 'Non-Assessor App Assessment - Pending for Submission by AR'
	},
	OFFLINE_ASSMT_AR_SUBMITTED: {
		value: 'OFFLINE_ASSMT_AR_SUBMITTED',
		display: 'Non-Assessor App Assessment - Submitted by AR'
	},
	OFFLINE_ASSMT_AA_APPROVED: {
		value: 'OFFLINE_ASSMT_AA_APPROVED',
		display: 'Non-Assessor App Assessment - Approved by AA'
	},
	OFFLINE_ASSMT_AA_SENT_BACK: {
		value: 'OFFLINE_ASSMT_AA_SENT_BACK',
		display: 'Non-Assessor App Assessment - Sent back by AA'
	},
	OFFLINE_ASSMT_AA_REJECTED: {
		value: 'OFFLINE_ASSMT_AA_REJECTED',
		display: 'Non-Assessor App Assessment - Rejected by AA'
	},
	OFFLINE_ASSMT_SSC_APPROVED: {
		value: 'OFFLINE_ASSMT_SSC_APPROVED',
		display: 'Non-Assessor App Assessment - Approved by SSC'
	},
	OFFLINE_ASSMT_SSC_SENT_BACK: {
		value: 'OFFLINE_ASSMT_SSC_SENT_BACK',
		display: 'Non-Assessor App Assessment - Sent back by SSC'
	},
	OFFLINE_ASSMT_SSC_REJECTED: {
		value: 'OFFLINE_ASSMT_SSC_REJECTED',
		display: 'Non-Assessor App Assessment - Rejected by SSC'
	},
	OFFLINE_ASSMT_PR_PENDING: {
		value: 'OFFLINE_ASSMT_PR_PENDING',
		display: 'Pending for Offline Assessment Evidence Submission'
	},
	OFFLINE_ASSMT_PR_SUBMITTED: {
		value: 'OFFLINE_ASSMT_PR_SUBMITTED',
		display: 'Offline Assessment Evidence Submitted for Approval'
	},
	OFFLINE_ASSMT_AA_INITIAL: {
		value: 'OFFLINE_ASSMT_AA_INITIAL',
		display: 'Pending for Offline Assessment Evidence Upload'
	},
	OFFLINE_ASSMT_AA_PENDING: {
		value: 'OFFLINE_ASSMT_AA_PENDING',
		display: 'Pending for Offline Assessment Evidence Submission'
	},
	OFFLINE_ASSMT_AA_SUBMITTED: {
		value: 'OFFLINE_ASSMT_AA_SUBMITTED',
		display: 'Offline Assessment Evidence Submitted for Approval'
	},
	OFFLINE_ASSMT_PR_INITIAL: {
		value: 'OFFLINE_ASSMT_PR_INITIAL',
		display: 'Pending for Offline Assessment Evidence Upload'
	}

}

export const CR_STATUS_VALUES = {
	ASSESSMENT_DATE_CR: 'Assessment Date Change Request',
	ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC: 'Request for Assessment Date Change Accepted By SSC',
	ASSESSMENT_DATE_CR_REJECTED_BY_SSC: 'Request for Assessment Date Change Rejected By SSC',
	ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU: 'Request for Assessment Date Change Accepted By PMU',
	ASSESSMENT_DATE_CR_REJECTED_BY_PMU: 'Request for Assessment Date Change Rejected By PMU',
	ASSESSMENT_AGENCY_CR: 'Assessment Agency Change Request',
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC: 'Request for Assessment Agency Change Accepted By SSC',
	ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC: 'Request for Assessment Agency Change Rejected By SSC',
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU: 'Request for Assessment Agency Change Accepted By PMU',
	ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU: 'Request for Assessment Agency Change Rejected By PMU',
	ASSESSOR_CR: 'Assessor Change Request',
	ASSESSOR_CR_ACCEPTED_BY_SSC: 'Request for Assessor Change Accepted By SSC',
	ASSESSOR_CR_REJECTED_BY_SSC: 'Request for Assessor Change Rejected By SSC',
	ASSESSOR_CR_ACCEPTED_BY_PMU: 'Request for Assessor Change Accepted By PMU',
	ASSESSOR_CR_REJECTED_BY_PMU: 'Request for Assessor Change Rejected By PMU'
}

export const BATCH_STATUS = {
	INITIAL: B_ASSESSMENT_STATUS.INITIAL,
	PENDING_FOR_ASSESSMENT_DATE: B_ASSESSMENT_STATUS.SSC_ASSIGNED,
	PENDING_FOR_ASSIGN_ASSESSMENT_AGENCY: B_ASSESSMENT_STATUS.SSC_ACCEPTED_BATCH,
	AR_ASSIGNED: B_ASSESSMENT_STATUS.AR_ASSIGNED,
	PENDING_FOR_ASSIGN_AA: B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED,
	WAITING_FOR_ASSESSMENT_AGENCY: B_ASSESSMENT_STATUS.AA_ASSIGNED,
	WAITING_FOR_ASSESSOR: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING,
	PENDING_FOR_MARKS_APPROVAL: B_ASSESSMENT_STATUS.AA_APPROVED_ASSESSMENT_ONGOING_MARKS,
	MARKS_UPLOADED_PENDING_FOR_AA: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_SUBMITTED,
	REJECTED_BY_ASSESSMENT_AGENCY: B_ASSESSMENT_STATUS.AA_REJECTED_ASSESSMENT_ONGOING_MARKS,
	REJECTED_BY_SSC: B_ASSESSMENT_STATUS.SSC_ASSESSMENT_REJECTED,
	AR_ASSESSMENT_ONGOING_MARKS_FILLED: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED,
	AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED,
	CERTIFIED: B_ASSESSMENT_STATUS.ASSESSMENT_DONE,
	PENDING_FOR_MARKS_DATE: 'pendingForAssessorDate',
	REJECTED_BY_ASSESSOR: 'rejectedByAssessor',
	NOT_CERTIFIED: B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED,
	SSC_ASSESSMENT_ACCEPTED_BATCH: B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED_BATCH,
	AA_REJECTED_SSC_REQ: B_ASSESSMENT_STATUS.AA_REJECTED_SSC_REQ,
	AUTO_REJECTED: B_ASSESSMENT_STATUS.AUTO_REJECTED,
	ASSESSMENT_DATE_CR: B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
	ASSESSMENT_DATE_CR_WAITING_FOR_PMU: B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ASSIGNED_TO_PMU,
	ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC: B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC,
	ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU: B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU,
	ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC_ASSMT: B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC,
	ASSESSMENT_DATE_CR_REJECTED_BY_PMU: B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_PMU,
	ASSESSMENT_DATE_CR_REJECTED_BY_SSC: B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC,
	ASSESSMENT_AGENCY_CR: B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR,
	ASSESSMENT_AGENCY_CR_WAITING_FOR_PMU: B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ASSIGNED_TO_PMU,
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC: B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC,
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC_ASSMT: B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC,
	ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU: B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU,
	ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC: B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC,
	ASSESSOR_CR: B_ASSESSMENT_STATUS.ASSESSOR_CR,
	ASSESSOR_CR_WAITING_FOR_PMU: B_ASSESSMENT_STATUS.ASSESSOR_CR_ASSIGNED_TO_PMU,
	ASSESSOR_CR_ACCEPTED_BY_SSC: B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC,
	ASSESSOR_CR_ACCEPTED_BY_SSC_ASSMT: B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC,
	ASSESSOR_CR_REJECTED_BY_PMU: B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_PMU,
	ASSESSOR_CR_REJECTED_BY_SSC: B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_SSC,
	ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU: B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU,

	TRAINER_CR: B_ASSESSMENT_STATUS.TRAINER_CR,
	TRAINER_CR_WAITING_FOR_PMU: B_ASSESSMENT_STATUS.TRAINER_CR_WAITING_FOR_PMU,
	TRAINER_CR_REJECTED_BY_PMU: B_ASSESSMENT_STATUS.TRAINER_CR_REJECTED_BY_PMU,
	TRAINER_CR_REJECTED_BY_SSC: B_ASSESSMENT_STATUS.TRAINER_CR_REJECTED_BY_SSC,

	BATCH_END_DATE_CR: B_ASSESSMENT_STATUS.BATCH_END_DATE_CR,
	BATCH_END_DATE_CR_WAITING_FOR_PMU: B_ASSESSMENT_STATUS.BATCH_END_DATE_CR_WAITING_FOR_PMU,
	BATCH_END_DATE_CR_REJECTED_BY_PMU: B_ASSESSMENT_STATUS.BATCH_END_DATE_CR_REJECTED_BY_PMU,
	BATCH_END_DATE_CR_REJECTED_BY_SSC: B_ASSESSMENT_STATUS.BATCH_END_DATE_CR_REJECTED_BY_SSC,


	BATCH_TIMINGS_CR: B_ASSESSMENT_STATUS.BATCH_TIMINGS_CR,
	BATCH_TIMINGS_CR_WAITING_FOR_PMU: B_ASSESSMENT_STATUS.BATCH_TIMINGS_CR_WAITING_FOR_PMU,
	BATCH_TIMINGS_CR_REJECTED_BY_PMU: B_ASSESSMENT_STATUS.BATCH_TIMINGS_CR_REJECTED_BY_PMU,
	BATCH_TIMINGS_CR_REJECTED_BY_SSC: B_ASSESSMENT_STATUS.BATCH_TIMINGS_CR_REJECTED_BY_SSC,

	// Cancel Batch PMKVY
	CANCEL_BY_TP: 'CANCEL BY TP',
	CANCEL_BY_TC: 'CANCEL BY TC',
	CANCEL_BY_SCPWD: 'CANCEL BY SCPWD',
	CANCEL_BY_SSDM_PMU: 'CANCEL BY SSDM PMU'
};

export const ASSMNT_STATUS = B_ASSESSMENT_STATUS;

export const BATCH_STATUS_MAPPING = {
	[BATCH_STATUS.PENDING_FOR_ASSESSMENT_DATE]: 'Pending For Acceptance',
	[BATCH_STATUS.PENDING_FOR_MARKS_DATE]: 'Pending For Approval',
	[BATCH_STATUS.WAITING_FOR_ASSESSMENT_AGENCY]: 'Assigned to Assessment Agency',
	// [BATCH_STATUS.INITIAL]: 'Pending for Approval',
	[BATCH_STATUS.REJECTED_BY_SSC]: 'Rejected By SSC',
	[BATCH_STATUS.WAITING_FOR_ASSESSOR]: 'Assigned to Assessor',
	[BATCH_STATUS.PENDING_FOR_MARKS_APPROVAL]: 'Pending for Approval',
	[BATCH_STATUS.PENDING_FOR_ASSIGN_ASSESSMENT_AGENCY]: 'Assessment Pending for Assignment to Assessment Agency',
	[BATCH_STATUS.AR_ASSIGNED]: 'Assigned to Assessor',
	[BATCH_STATUS.REJECTED_BY_ASSESSOR]: 'For Marks Approval',
	[BATCH_STATUS.REJECTED_BY_ASSESSMENT_AGENCY]: 'Assessment Agency Rejected Marks',
	// [BATCH_STATUS.MARKS_UPLOADED_PENDING_FOR_AA]: 'Marksheet Uploaded',
	[BATCH_STATUS.MARKS_UPLOADED_PENDING_FOR_AA]: 'Accepted By Assessor',
	[BATCH_STATUS.INITIAL]: 'Accepted By Assessor',
	[BATCH_STATUS.CERTIFIED]: 'Batch Certified',
	[BATCH_STATUS.SSC_ASSESSMENT_ACCEPTED_BATCH]: 'Assessment Pending for Assignment to Assessment Agency',
	// [BATCH_STATUS.NOT_CERTIFIED]: 'Marksheet Pending',
	// [BATCH_STATUS.NOT_CERTIFIED]: 'Batch not Assign to Assessment Agency',
	[BATCH_STATUS.NOT_CERTIFIED]: 'Assessment Pending for Assignment to Assessment Agency',
	[BATCH_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED]: 'Assessor Filled Marks yet Not Submitted',
	[BATCH_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED]: 'Assessor Filled Marks Partially',
	[BATCH_STATUS.AA_REJECTED_SSC_REQ]: 'Rejected By Assessment Agency',
	[BATCH_STATUS.AUTO_REJECTED]: 'Auto Reversed',
	[BATCH_STATUS.ASSESSMENT_DATE_CR]: 'Pending for Assessment Date Approval',
	[BATCH_STATUS.ASSESSMENT_DATE_CR_WAITING_FOR_PMU]: 'Assigned to PMU for Assessment Date Approval',
	[BATCH_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC]: 'Assessment Date CR Accepted',
	[BATCH_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC_ASSMT] : 'Assessment Date CR Accepted',
	[BATCH_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_PMU]: 'PMU Reject Assessment Date CR',
	[BATCH_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC]: 'Assessment Date CR Rejected',
	[BATCH_STATUS.ASSESSMENT_AGENCY_CR]: 'Pending for AssessmentAgency CR Approval',
	[BATCH_STATUS.ASSESSMENT_AGENCY_CR_WAITING_FOR_PMU]: 'Assigned to PMU for Assessment Agency Approval',
	[BATCH_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC]: 'Assessment Agency CR Accepted',
	[BATCH_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC_ASSMT] : 'Assessment Agency CR Accepted',
	[BATCH_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU]: 'PMU Reject Assessment Agency CR',
	[BATCH_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC]: 'Assessment Agency CR Rejected',
	[BATCH_STATUS.ASSESSOR_CR]: 'Pending for Assessor CR Approval',
	[BATCH_STATUS.ASSESSOR_CR_WAITING_FOR_PMU]: 'Assigned to PMU for Assessor Approval',
	[BATCH_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC]: 'Assessor CR Accepted',
	[BATCH_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC_ASSMT] : 'Assessor CR Accepted',
	[BATCH_STATUS.ASSESSOR_CR_REJECTED_BY_PMU]: 'PMU Reject Assessor CR',
	[BATCH_STATUS.ASSESSOR_CR_REJECTED_BY_SSC]: 'Assessor CR Rejected',

	[BATCH_STATUS.TRAINER_CR]: 'Pending for Trainer CR Approval',
	[BATCH_STATUS.TRAINER_CR_WAITING_FOR_PMU]: 'Assigned to PMU for Trainer Approval',
	[BATCH_STATUS.TRAINER_CR_REJECTED_BY_PMU]: 'PMU Reject Trainer CR',
	[BATCH_STATUS.TRAINER_CR_REJECTED_BY_SSC]: 'Trainer CR Rejected',

	[BATCH_STATUS.BATCH_END_DATE_CR]: 'Pending for Batch End Date CR Approval',
	[BATCH_STATUS.BATCH_END_DATE_CR_WAITING_FOR_PMU]: 'Assigned to PMU for Batch End Date Approval',
	[BATCH_STATUS.BATCH_END_DATE_CR_REJECTED_BY_PMU]: 'PMU Reject Batch End Date CR',
	[BATCH_STATUS.BATCH_END_DATE_CR_REJECTED_BY_SSC]: 'Batch End Date CR Rejected',

	[BATCH_STATUS.BATCH_TIMINGS_CR]: 'Pending for Batch End Date CR Approval',
	[BATCH_STATUS.BATCH_TIMINGS_CR_WAITING_FOR_PMU]: 'Assigned to PMU for Batch End Date Approval',
	[BATCH_STATUS.BATCH_TIMINGS_CR_REJECTED_BY_PMU]: 'PMU Reject Batch End Date CR',
	[BATCH_STATUS.BATCH_TIMINGS_CR_REJECTED_BY_SSC]: 'Batch End Date CR Rejected',

	[BATCH_STATUS.CANCEL_BY_TC] : 'Cancelled by TC',
	[BATCH_STATUS.CANCEL_BY_TP] : 'Cancelled by TP'
};


export const BATCH_TYPE_MAPPING = {
	[SCHEME_TYPE.PMKVY]: `PMKVY`
}

export const STATUS_FILTER_MAPPING = Object.freeze({
	PENDING_BATCHES: [
		BATCH_STATUS.PENDING_FOR_ASSESSMENT_DATE, // SSC ASSIGNED
		// BATCH_STATUS.PENDING_FOR_MARKS_APPROVAL, // AA APROVED
		// B_ASSESSMENT_STATUS.SSCPREREJECTED,
		// B_ASSESSMENT_STATUS.SSCPREAPPROVED,
		// B_ASSESSMENT_STATUS.AAPREAPPROVED,
		// B_ASSESSMENT_STATUS.AAPREREJECTED,
		// B_ASSESSMENT_STATUS.INITIAL,
		// B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED,
		// B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED,
		// B_ASSESSMENT_STATUS.ARPRESUBMIT,
		// B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_SUBMITTED,
		// B_ASSESSMENT_STATUS.AA_REJECTED_ASSESSMENT_ONGOING_MARKS,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC
	],
	ACCEPTED_BATCHES: [
		// B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED_BATCH,  // binding bAssmtStatus - SSCACCEPTED, SSCACCEPTEDBATCH
		// BATCH_STATUS.PENDING_FOR_ASSIGN_ASSESSMENT_AGENCY,
		B_ASSESSMENT_STATUS.SSC_ACCEPTED_BATCH, // SSCACCEPTEDBATCH
		BATCH_STATUS.WAITING_FOR_ASSESSMENT_AGENCY,	// AA ASSIGNED
		BATCH_STATUS.AA_REJECTED_SSC_REQ,	// AA REJECECTED SSC REQ
		BATCH_STATUS.AUTO_REJECTED,	// AUTO REJECTED
		BATCH_STATUS.AR_ASSIGNED,	// AR ASSIGNED
		// B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED,
		// BATCH_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED,
		// BATCH_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED,
		// BATCH_STATUS.MARKS_UPLOADED_PENDING_FOR_AA,	 // AR SUBMITTED
		BATCH_STATUS.INITIAL,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC
		/* 		B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED,
				BATCH_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED,
				BATCH_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED,
				BATCH_STATUS.MARKS_UPLOADED_PENDING_FOR_AA,	 */
		// BATCH_STATUS.PENDING_FOR_MARKS_APPROVAL
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC_ASSMT,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC_ASSMT,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC_ASSMT
	],
	ACCEPTED_BATCHES_WITH_CR: [
		B_ASSESSMENT_STATUS.ASSESSOR_CR,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ASSIGNED_TO_PMU,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_PMU,
		B_ASSESSMENT_STATUS.ASSESSOR_CR_ASSIGNED_TO_PMU,
		B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_PMU,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ASSIGNED_TO_PMU,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_PMU,
		B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_PMU,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_PMU,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_PMU
	],
	REJECTED_BATCHES: [
		BATCH_STATUS.REJECTED_BY_ASSESSMENT_AGENCY,
		BATCH_STATUS.REJECTED_BY_ASSESSOR,
		BATCH_STATUS.REJECTED_BY_SSC,
		B_ASSESSMENT_STATUS.SSC_AA_ASSESSMENT_REJECTED,

		// B_ASSESSMENT_STATUS.ASSESSOR_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC,
		// B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC
	],
	PENDING_BATCHES_CR: [
		// BATCH_STATUS.PENDING_FOR_ASSESSMENT_DATE, // SSC ASSIGNED
		// BATCH_STATUS.PENDING_FOR_MARKS_APPROVAL, // AA APROVED
		// B_ASSESSMENT_STATUS.SSCPREREJECTED,
		// B_ASSESSMENT_STATUS.SSCPREAPPROVED,
		// B_ASSESSMENT_STATUS.AAPREAPPROVED,
		// B_ASSESSMENT_STATUS.AAPREREJECTED,
		// B_ASSESSMENT_STATUS.INITIAL,
		// B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED,
		// B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED,
		// B_ASSESSMENT_STATUS.ARPRESUBMIT,
		// B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_SUBMITTED,
		// B_ASSESSMENT_STATUS.AA_REJECTED_ASSESSMENT_ONGOING_MARKS,
		B_ASSESSMENT_STATUS.ASSESSOR_CR,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR
	],
	ACCEPTED_BATCHES_CR: [
		// B_ASSESSMENT_STATUS.AA_ASSIGNED,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_ACCEPTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_ACCEPTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSOR_CR_ACCEPTED_BY_SSC
	],
	REJECTED_BATCHES_CR: [
		B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC,
		B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC
	],
	CANCELLED_BATCHES_CR: [
		B_ASSESSMENT_STATUS.CANCEL_BY_TC,
		B_ASSESSMENT_STATUS.CANCEL_BY_TP
	],
	CANCELLED_BATCHES: [
		BATCH_STATUS.CANCEL_BY_TC,
		BATCH_STATUS.CANCEL_BY_TP,
		BATCH_STATUS.CANCEL_BY_SCPWD,
		BATCH_STATUS.CANCEL_BY_SSDM_PMU
	]
})

export const STATESLIST = {
	states: ''
}

// export const BATCH_STATUS_QUERIES = {
// 	PENDING_FOR_ASSESSMENT_DATE: B_ASSESSMENT_STATUS.SSC_ASSIGNED,
// 	PENDING_FOR_ASSIGN_ASSESSMENT_AGENCY: B_ASSESSMENT_STATUS.SSC_ACCEPTED_BATCH,
// 	WAITING_FOR_ASSESSMENT_AGENCY: B_ASSESSMENT_STATUS.AA_ASSIGNED,
// 	WAITING_FOR_ASSESSOR: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING,
// 	PENDING_FOR_MARKS_APPROVAL: B_ASSESSMENT_STATUS.AA_APPROVED_ASSESSMENT_ONGOING_MARKS,
// 	MARKS_UPLOADED_PENDING_FOR_AA: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_SUBMITTED,
// 	REJECTED_BY_ASSESSMENT_AGENCY: B_ASSESSMENT_STATUS.AA_REJECTED_ASSESSMENT_ONGOING_MARKS,
// 	REJECTED_BY_SSC: B_ASSESSMENT_STATUS.SSC_ASSESSMENT_REJECTED,
// 	AR_ASSESSMENT_ONGOING_MARKS_FILLED: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED,
// 	AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED: B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED,
// 	CERTIFIED : B_ASSESSMENT_STATUS.ASSESSMENT_DONE,
// 	PENDING_FOR_MARKS_DATE: 'pendingForAssessorDate',
// 	REJECTED_BY_ASSESSOR: 'rejectedByAssessor',
// 	NOT_CERTIFIED : B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED,
// };

export const REQUEST_TYPE_MAPPING_WITH_B_ASSESSMENT_STATUS = Object.freeze({

	[B_ASSESSMENT_STATUS.AA_APPROVED_ASSESSMENT_ONGOING_MARKS]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.SSC_ASSIGNED]: 'For Assessment from TP',
	[B_ASSESSMENT_STATUS.SSC_ACCEPTED_BATCH]: 'For Assessment from TP',
	[B_ASSESSMENT_STATUS.AA_ASSIGNED]: 'For Assessment from TP',
	[B_ASSESSMENT_STATUS.AA_REJECTED_SSC_REQ]: 'For Assessment from TP',
	[B_ASSESSMENT_STATUS.AUTO_REJECTED]: 'For Assessment from TP',
	[B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_PARTIALLY_FILLED]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_FILLED]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.AR_ASSESSMENT_ONGOING_MARKS_SUBMITTED]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.AA_REJECTED_ASSESSMENT_ONGOING_MARKS]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.SSC_ASSESSMENT_ACCEPTED]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.SSC_ASSESSMENT_REJECTED]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.SSC_AA_ASSESSMENT_REJECTED]: 'For Marks Approval',
	[B_ASSESSMENT_STATUS.ASSESSMENT_DONE]: 'For Marks Approval',
	[BATCH_STATUS.ASSESSMENT_DATE_CR]: 'For Assessment Date Approval',
	[BATCH_STATUS.ASSESSOR_CR]: 'For Assessment Change Request',
	[BATCH_STATUS.ASSESSMENT_DATE_CR_WAITING_FOR_PMU]: 'For Assessment Date Approval',
	[B_ASSESSMENT_STATUS.ARPRESUBMIT]: 'For Assessment from TP',
	[B_ASSESSMENT_STATUS.INITIAL]: 'For Assessment from TP',

	[BATCH_STATUS.TRAINER_CR]: 'For Trainer CR Approval',
	[BATCH_STATUS.TRAINER_CR_WAITING_FOR_PMU]: 'For Trainer CR Approval',

	[BATCH_STATUS.BATCH_END_DATE_CR]: 'For Batch End Date CR Approval',
	[BATCH_STATUS.BATCH_END_DATE_CR_WAITING_FOR_PMU]: 'For Batch End Date CR Approval',

	[BATCH_STATUS.BATCH_TIMINGS_CR]: 'For Batch Timing Approval',
	[BATCH_STATUS.BATCH_TIMINGS_CR_WAITING_FOR_PMU]: 'For Batch Timing Approval',

	[B_ASSESSMENT_STATUS.ASSESSMENT_DATE_CR_REJECTED_BY_SSC]: 'For Assessment Date Change Request',
	[B_ASSESSMENT_STATUS.ASSESSOR_CR_REJECTED_BY_SSC]: 'For Assessment Change Request',
	[B_ASSESSMENT_STATUS.ASSESSMENT_AGENCY_CR_REJECTED_BY_SSC]: 'For Assessment Change Request',

	[B_ASSESSMENT_STATUS.CANCEL_BY_TC]: 'Cancel TC',
	[B_ASSESSMENT_STATUS.CANCEL_BY_TP]: 'Cancel TP'


})

export function getBatchFilters(tabId, type?, on?) {
	let statusList = [];
	let statuses = [];
	let states = [];
	/// status filters seperated for changed rrquest and batch assigned
	switch (tabId) {
		case BATCH_TAB_ID.ACCEPTED: {
			if (type === 'TPTC') {
				statuses = STATUS_FILTER_MAPPING.ACCEPTED_BATCHES
			} else if (type === 'CR') {
				statuses = STATUS_FILTER_MAPPING.ACCEPTED_BATCHES_CR
			}
			break
		}
		case BATCH_TAB_ID.PENDING_REQUEST: {
			if (type === 'TPTC') {
				// statuses = STATUS_FILTER_MAPPING.PENDING_BATCHES
			} else if (type === 'CR') {
				statuses = STATUS_FILTER_MAPPING.PENDING_BATCHES_CR
			}
			break;
		}
		case BATCH_TAB_ID.REJECTED: {
			if (type === 'TPTC') {
				// statuses = STATUS_FILTER_MAPPING.REJECTED_BATCHES
			} else if (type === 'CR') {
				statuses = STATUS_FILTER_MAPPING.REJECTED_BATCHES_CR
			}
			break;
		}
		case BATCH_TAB_ID.CANCELLED: {
			if (type === 'TPTC') {
				// statuses = STATUS_FILTER_MAPPING.REJECTED_BATCHES
			} else if (type === 'CR') {
				statuses = STATUS_FILTER_MAPPING.CANCELLED_BATCHES_CR
			}
			break;
		}
	}
	statusList = statuses.map(status => {
		return {
			label: BATCH_STATUS_MAPPING[status],
			value: status
		}
	});
	if (on) {
		states = on.map(s => {
			return {
				label: s.name,
				value: s.code
			}
		});
		console.log(states, on);
	}
	const BATCH_FILTERS = [
		new SelectFilter({
			label: 'Scheme/Program/Model',
			name: 'schemeName',
			placeholder: 'All',
			options: [
				{
					label: 'Fee Based',
					value: 'feeBased'
				},
				{
					label: 'PMKVY STT CSSM',
					value: 'STT CSSM'
				},
				{
					label: 'PMKVY SP CSSM',
					value: 'SP CSSM'
				},
				{
					label: 'PMKVY RPL',
					value: 'RPL'
				},
				{
					label: 'PMKVY STT CSCM',
					value: 'STT CSCM'
				},
				{
					label: 'PMKVY SP CSCM',
					value: 'SP CSCM'
				}
			]
		}),
		// new SelectFilter({
		// 	label: 'Job Role',
		// 	name: 'jobRole',
		// 	placeholder: 'All',
		// 	options: [
		// 	]
		// }),
		new SelectFilter({
			label: 'Batch Type',
			name: 'batchType',
			placeholder: 'All',
			options: [{
				// label: 'Fee Based',
				// value: 'Fee Based'
				label: 'Regular',
				value: 'regular'
			},
			{
				// label: 'Fee Based',
				// value: 'Fee Based'
				label: 'Re-Assessment',
				value: 'Reassessment'
			}]
		}),
		new SelectFilter({
			label: 'State',
			name: 'state',
			placeholder: 'All',
			options: states ? states : []
		}),
		new SelectFilter({
			label: 'Status',
			name: 'bAssessmentStatus',
			placeholder: 'All',
			hide: type === 'TPTC' && (tabId === 'pendingRequest' || tabId === 'rejected' || tabId === 'cancelled') ? true : false,
			// options: Object.keys(BATCH_STATUS_MAPPING).map(status => ({
			// 	label: BATCH_STATUS_MAPPING[status],
			// 	value: status
			// }))
			options: statusList
		}),
		new TextFilter({
			label: 'Batch Id Search:',
			placeholder: 'Batch ID',
			name: 'keyword'
		})
	];
	return BATCH_FILTERS

}

export function getBatchTable(on: IBatchTableEvent = {}) {
	console.log(on.batchRequestType);
	const pendingBatches = new DTable({
		mixedFilters: getBatchFilters(BATCH_TAB_ID.PENDING_REQUEST, on.batchRequestType, on.statesList),
		onFilterChange: on.pendingFilterChange,
		fieldMapping: {
			status: BATCH_STATUS_MAPPING,
			type: BATCH_TYPE_MAPPING,
		},
		clientFilters: {
			keyword: [
				// 'schemeName',
				// 'jobRole',
				'BatchId',
				// 'batchType',
				// 'tpName',
				// 'tcLocation',
				// 'proposedType',
				// 'receivedDate'
			],
		},
		headers: {
			schemeName: 'Scheme/Program/Model',
			subSchemeName: 'Sub Scheme',
			batchId: 'Batch ID',
			jobRole: 'Job Role (Qp Code)',
			// type: 'Type',
			batchType: 'Batch Type',
			batchSize: 'Batch Size',
			batchDate: 'Batch Start Date-Batch End Date',
			// RequestType: 'Request Type',
			tpName: 'TP Name',
			// tpId: 'TP ID',
			tcLocation: 'TC Name',
			languages: 'Preferred Assessment Language',
			stateId: 'State',
			// tcId: 'TC ID',
			candidatesSize: 'Enrolled Candidates',
			// AAName: 'AA Name',
			// ARName: 'Assessor Name',
			dropout: 'Total Dropout',
			ResultUploadDate: 'Result Upload Date',
			// QpName: 'Qp Name',
			AAName: 'Assessment Agency Name (AA ID)',
			ARName: 'Assessor Name (AR ID)',
			// AAId: 'Assessment Agency ID',
			// ARId: 'Assessor ID',
			tpSpocName: 'TP Spoc Name',
			TPMobileNo: 'TP Spoc Mobile No',
			TPEmailid: 'TP Spoc Email id',
			proposedType: 'Assessment Date(s)',
			status: 'Status',
			receivedDate: 'Received Date',
			action: 'Action'
		},
		pagination: new Pagination(),
		onPaginationChange: on.pendingBatchPaginationChange,
	});
	const approvedBatches = new DTable({
		mixedFilters: getBatchFilters(BATCH_TAB_ID.ACCEPTED, on.batchRequestType, on.statesList),
		// onFilterChange: (filter) => console.log(filter),
		onFilterChange: on.approvedFilterChange,
		fieldMapping: {
			status: BATCH_STATUS_MAPPING,
			type: BATCH_TYPE_MAPPING,
		},

		clientFilters: {
			keyword: [
				// 'schemeName',
				// 'jobRole',
				'BatchId',
				// 'batchType',
				// 'tpName',
				// 'tcLocation',
				// 'proposedType',
				// 'receivedDate'
			],
			status: ['status'],
			batchType: ['RequestType']
		},
		headers: {
			schemeName: 'Scheme/Program/Model',
			subSchemeName: 'Sub Scheme',
			batchId: 'Batch ID',
			jobRole: 'Job Role (Qp Code)',
			// type: 'Type',
			batchType: 'Batch Type',
			batchSize: 'Batch Size',
			batchDate: 'Batch Start Date-Batch End Date',
			// RequestType: 'Request Type',
			tpName: 'TP Name',
			// tpId: 'TP ID',
			tcLocation: 'TC Name',
			languages: 'Preferred Assessment Language',
			stateId: 'State',
			candidatesSize: 'Enrolled Candidates',

			dropout: 'Total Dropout',
			ResultUploadDate: 'Result Upload Date',
			// QpName: 'Qp Name',
			AAName: 'Assessment Agency Name (AA ID)',
			ARName: 'Assessor Name (AR ID)',
			// AAId: 'Assessment Agency ID',
			// ARId: 'Assessor ID',
			// tcId: 'TC ID',
			tpSpocName: 'TP Spoc Name',
			TPMobileNo: 'TP Spoc Mobile No',
			TPEmailid: 'TP Spoc Email id',
			proposedType: 'Assessment Date(s)',
			status: 'Status',
			receivedDate: 'Received Date',
			action: 'Action'
		},
		pagination: new Pagination(),
		onPaginationChange: on.approvedBatchPaginationChange,
	});
	const rejectedBatches = new DTable({
		mixedFilters: getBatchFilters(BATCH_TAB_ID.REJECTED, on.batchRequestType, on.statesList),
		// onFilterChange: (filter) => console.log(filter),
		onFilterChange: on.rejectedFilterChange,
		pagination: new Pagination(),
		onPaginationChange: on.rejectedBatchPaginationChange,
		fieldMapping: {
			status: BATCH_STATUS_MAPPING,
			type: BATCH_TYPE_MAPPING,
		},
		clientFilters: {
			keyword: [
				// 'schemeName',
				// 'jobRole',
				'BatchId',
				// 'batchType',
				// 'tpName',
				// 'tcLocation',
				// 'proposedType',
				// 'receivedDate'
			]
		},
		headers: {
			schemeName: 'Scheme/Program/Model',
			subSchemeName: 'Sub Scheme',
			batchId: 'Batch ID',
			jobRole: 'Job Role (Qp Code)',
			// type: 'Type',
			batchType: 'Batch Type',
			batchSize: 'Batch Size',
			batchDate: 'Batch Start Date-Batch End Date',
			// RequestType: 'Request Type',
			tpName: 'TP Name',
			// tpId: 'TP ID',
			tcLocation: 'TC Name',
			languages: 'Preferred Assessment Language',
			stateId: 'State',
			candidatesSize: 'Enrolled Candidates',
			dropout: 'Total Dropout',
			ResultUploadDate: 'Result Upload Date',
			// QpName: 'Qp Name',
			AAName: 'Assessment Agency Name (AA ID)',
			ARName: 'Assessor Name (AR ID)',
			// AAId: 'Assessment Agency ID',
			// ARId: 'Assessor ID',
			tpSpocName: 'TP Spoc Name',
			TPMobileNo: 'TP Spoc Mobile No',
			TPEmailid: 'TP Spoc Email id',
			// tcId: 'TC ID',
			proposedType: 'Assessment Date(s)',
			status: 'Status',
			receivedDate: 'Received Date',
			action: 'Action'
		},
	});
	const cancelledBatches = new DTable({
		mixedFilters: getBatchFilters(BATCH_TAB_ID.CANCELLED, on.batchRequestType, on.statesList),
		// onFilterChange: (filter) => console.log(filter),
		onFilterChange: on.cancelledFilterChange,
		pagination: new Pagination(),
		onPaginationChange: on.cancelledBatchPaginationChange,
		fieldMapping: {
			status: BATCH_STATUS_MAPPING,
			type: BATCH_TYPE_MAPPING,
		},
		clientFilters: {
			keyword: [
				// 'schemeName',
				// 'jobRole',
				'BatchId',
				// 'batchType',
				// 'tpName',
				// 'tcLocation',
				// 'proposedType',
				// 'receivedDate'
			]
		},
		headers: {
			schemeName: 'Scheme/Program/Model',
			subSchemeName: 'Sub Scheme',
			batchId: 'Batch ID',
			jobRole: 'Job Role (Qp Code)',
			// type: 'Type',
			batchType: 'Batch Type',
			batchDate: 'Batch start date-Batch end date',
			// RequestType: 'Request Type',
			tpName: 'TP Name',
			// tpId: 'TP ID',
			tcLocation: 'TC Name',
			languages: 'Preferred Assessment Language',
			stateId: 'State',
			candidatesSize: 'Enrolled Candidates',
			dropout: 'Total Dropout',
			ResultUploadDate: 'Result Upload Date',
			// QpName: 'Qp Name',
			AAName: 'Assessment Agency Name (AA ID)',
			ARName: 'Assessor Name (AR ID)',
			// AAId: 'Assessment Agency ID',
			// ARId: 'Assessor ID',
			tpSpocName: 'TP Spoc Name',
			TPMobileNo: 'TP Mobile No',
			TPEmailid: 'TP Email id',
			// tcId: 'TC ID',
			proposedType: 'Assessment Date(s)',
			status: 'Status',
			receivedDate: 'Received Date',
			action: 'Action'
		},
	});
	const batches = new MultiTabTable({
		tabs: [
			new MultiTabTableTab({
				active: true,
				id: BATCH_TAB_ID.PENDING_REQUEST,
				name: 'Pending Requests',
				table: pendingBatches,
			}),
			new MultiTabTableTab({
				id: BATCH_TAB_ID.ACCEPTED,
				name: 'Accepted',
				table: approvedBatches
			}),
			new MultiTabTableTab({
				id: BATCH_TAB_ID.REJECTED,
				name: 'Rejected',
				table: rejectedBatches
			}),
			new MultiTabTableTab({
				id: BATCH_TAB_ID.CANCELLED,
				name: 'Cancelled',
				table: cancelledBatches
			})
		],
	});
	return batches;
}
