<div class="m-grid m-grid--hor m-grid--root m-page notranslate">
  <div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
    id="m_login" style="margin: auto !important;">
    <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">

      <div *ngIf="showform == 'generateOTP'" class="m-stack m-stack--hor m-stack--desktop">
        <div class="m-stack__item m-stack__item--fluid">
          <div class="m-login__wrapper">
            <div class="m-login__logo">
              <a>
                <img src="../../../assets/images/skill-india-logo-prod.png">
              </a>
            </div>
            <div class="m-login__signin">
              <div class="m-login__head">
                <h3 class="m-login__title">
                </h3>
              </div>
              <form class="m-login__form m-form" [formGroup]="userDetailsForm">
                <div class="form-group m-form__group">
                  <input class="form-control m-input" type="text" placeholder="Enter your Username" name="username"
                    autocomplete="off" formControlName="userName">
                </div>
                <app-form-control-messages [control]="userDetailsForm.controls.userName" [name]="'Username'">
                </app-form-control-messages>
                <div class="row disclaimer">*{{"SENSITIVE_NAME_PASS" |translatePipe}}</div>
                <div class="col m--align-right pt-2">
                  <a href="javascript:;" id="m_login_forget_password" (click)="showform = 'Forget_User_Id'"
                    class="m-link  m--font-info">
                    {{"FORGOT_ID" | translatePipe}}
                  </a>
                </div>
                <div class="m-login__form-action ">
                  <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill   btn-login"
                    [disabled]="!userDetailsForm.valid" (click)="generateOTPs()">
                    {{"VERIFY_NEXT" |translatePipe}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="m-stack__item m-stack__item--center">
          <div class="m-login__account">
            <span class="m-login__account-msg">
              {{'lOGIN_ALREADY' | translatePipe}}
            </span> &nbsp;&nbsp;
            <a id="m_login_signup" class="m-link m-link--focus m-login__account-link" href="https://www.skillindiadigital.gov.in/home" target="_blank">
              {{"Login"| translatePipe}}
            </a>
          </div>
          <div class="">
            <!-- <span class="">© 2018 Copyright Transneuron Technologies Pvt Ltd. · Terms of Service · Privacy </span> -->
          </div>
        </div>
      </div>

      <div *ngIf="showform == 'otp'" class="m-stack m-stack--hor m-stack--desktop">
        <div class="m-stack__item m-stack__item--fluid">
          <div class="m-login__wrapper">
            <div class="m-login__logo">
              <a>
                <img src="../../../assets/images/skill-india-logo-prod.png">
              </a>
            </div>
            <div class="m-login__signin">
              <div class="m-login__head">
                <h3 class="m-login__title">
                </h3>
              </div>
              <form class="m-login__form m-form" [formGroup]="otpForm">
                <div class="form-group m-form__group">
                  <div *ngIf="hashedEmail" class="disclaimer">Email: {{hashedEmail}}</div>
                  <div *ngIf="hasedMobile" class="disclaimer mb-2">Mobile: {{hasedMobile}}</div>
                  <input class="form-control m-input" type="text" maxlength="6" placeholder="Enter Email OTP"
                    name="eOTP" formControlName="emailOTP">
                  <app-form-control-messages [control]="otpForm.controls.emailOTP" [name]="'Email OTP'">
                  </app-form-control-messages>
                </div>
                <div class="form-group m-form__group">
                  <h6 class="text-center otp-or"><b>OR</b></h6>
                </div>
                <div class="form-group m-form__group">
                  <input class="form-control m-input" type="text" maxlength="6" placeholder="Enter Mobile OTP"
                    name="mOTP" formControlName="mobileOTP">
                  <app-form-control-messages [control]="otpForm.controls.mobileOTP" [name]="'Mobile OTP'">
                  </app-form-control-messages>
                </div>
                <div class="col m--align-right">
                  <a href="javascript:;" id="m_login_forget_password" (click)="showform ='NoLongerAcess'"
                    class="m-link  m--font-info">
                    {{"No_Longer_Access" | translatePipe}}
                  </a>
                </div>
                <div class="m-login__form-action ">
                  <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill btn-login" [disabled]="!validOTP"
                    (click)="verifyOTP()">
                    Verify OTP & Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="m-stack__item m-stack__item--center">
          <div class="m-login__account">
            <span class="m-login__account-msg">
              Didn’t Recieve OTP ?
            </span> &nbsp;&nbsp;
            <a href="javascript:;" id="m_login_signup" class="m-link m-link--focus m-login__account-link"
               (click)="reGenerateOTP()">
              Re-Generate OTP
            </a>
          </div>
          <div class="">
            <!-- <span class="">© 2018 Copyright  Technologies pvt Ltd. · Terms of Service · Privacy </span> -->
          </div>
        </div>
      </div>

      <div *ngIf="showform == 'password'" class="m-stack m-stack--hor m-stack--desktop">
        <div class="m-stack__item m-stack__item--fluid">
          <div class="m-login__wrapper">
            <div class="m-login__logo">
              <a>
                <img src="../../../assets/images/skill-india-logo-prod.png">
              </a>
            </div>
            <div class="m-login__signin">
              <div class="m-login__head">
                <h3 class="m-login__title">
                </h3>
              </div>
              <form class="m-login__form m-form" [formGroup]="passwordResetForm" novalidate>
                <div class="form-group m-form__group">
                  <input class="form-control m-input custom-password" [type]="show ? 'text' : 'password'"
                    placeholder="Enter New Password" name="newpassword" formControlName="newPassword">
                  <a class="showPassword" (click)="showPassClick()" aria-hidden="true">{{show ? 'Hide' : 'Show'}}</a>
                </div>
                <app-form-control-messages [control]="passwordResetForm.controls.newPassword" [name]="'New  Password'">
                </app-form-control-messages>
                <div class="form-group m-form__group">
                  <input class="form-control m-input" type="password" placeholder="Enter Confirm Password"
                    name="confirmPassword" onpaste="return false" formControlName="confirmPassword">
                </div>
                <app-form-control-messages [control]="passwordResetForm.controls.confirmPassword"
                  [name]="'Confirm Password'"></app-form-control-messages>
                <div
                  *ngIf="passwordResetForm.controls['confirmPassword'].errors?.MatchPassword && passwordResetForm.controls['confirmPassword'].touched"
                  class="red">Passwords do not match</div>
                <div class="row disclaimer">*{{"SENSITIVE_NAME_PASS"|translatePipe}}</div>
                <div class="form-group m-form__group">
                  <div class="recaptchaAllign">
                    <re-captcha (resolved)="resolveRecaptcha($event)"></re-captcha>
                  </div>
                </div>
                <div class="m-login__form-action">
                  <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login"
                    [disabled]="!passwordResetForm.valid" (click)="setPassword()">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="m-stack__item m-stack__item--center">
          <div class="">
            <span class="">© 2018 Copyright Company. Inc. · Terms of Service · Privacy </span>
          </div>
        </div>
      </div>


      <div *ngIf="showform =='Forget_User_Id'" class="m-stack m-stack--hor m-stack--desktop">
        <div class="m-stack__item m-stack__item--fluid">
          <div class="m-login__wrapper">
            <div class="m-login__logo">
              <a href="#">
                <img src="../../../assets/images/skill-india-logo-prod.png">
              </a>
            </div>
            <div class="m-login__signin">
              <div class="m-login__head">
                <h3 class="m-login__title">
                </h3>
              </div>
              <form class="m-login__form m-form" [formGroup]="forgetUserIdForm">
                <div class="form-group m-form__group">
                  <select class="form-control m-select notranslate" id="exampleSelect1" formControlName="type">
                    <option value=""> {{'CHOOSEUSERGRP' | translatePipe}} </option>
                  <option value="Training Partner">{{'TP' | translatePipe}}</option>
                    <option value="Trainer">{{'TRAINER'| translatePipe}}</option>
                    <option value="Assessor">{{'ASSESSORNAME' | translatePipe}}</option>
                    <option value="Candidate">{{'Candidate' | translatePipe}} </option>
                    <option value="Training Centre">{{'Training Centre' | translatePipe}} </option>
                    <option value="Smart Admin">{{'Other User' | translatePipe}} </option>
                    <option value="Scheme Approver">{{'Scheme Approver' | translatePipe}} </option>
                    <option value="Inspection Agency">{{'Inspection Agency' | translatePipe}} </option>
                    <option value="SSC">{{'SSC' | translatePipe}} </option>
                  </select>
                  <app-form-control-messages [control]="forgetUserIdForm.controls.type" [name]="'Role'">
                  </app-form-control-messages>
                </div>
                <br> <br>
                <div class="form-group m-form__group">
                  <input class="form-control m-input" type="email" placeholder="{{'Email Address' | translatePipe}}"
                    name="email" formControlName="email" (focusout)="validOnlyOne('email')">
                  <app-form-control-messages [control]="forgetUserIdForm.controls.email" [name]="'Email Address'">
                  </app-form-control-messages>
                </div>

                <div class="form-group m-form__group">
                  <h6 class="text-center otp-or"><b>OR</b></h6>
                </div>
                <div class="form-group m-form__group">
                  <input class=" form-control m-input notranslate" type="number"
                    placeholder="{{ 'Enter Mobile Number' | translatePipe}}" onkeypress="return event.charCode>= 48 && event.charCode
                          <=57" name="mobile" maxlength="10" formControlName="phone"
                    (focusout)="validOnlyOne('mobile')">
                  <app-form-control-messages [control]="forgetUserIdForm.controls.phone" [name]=" 'Mobile Number'">
                  </app-form-control-messages>
                </div>

                <div class="row form-group m-form__group m-login__form-sub">
                  <div class="col m--align-left">
                    <label class="m-checkbox m-checkbox--focus">
                      <input type="checkbox" name="agree" formControlName="changeStatusChecked">
                      {{"TERMS"|translatePipe}}
                      <a href="javascript:;" data-toggle="modal" data-target="#m_modal_1_2"
                        class="m-link m-link--focus">{{"CONDITIONS"|translatePipe}}</a>.
                      <span></span>
                    </label> &nbsp;&nbsp;

                    <span class="m-form__help"></span>
                  </div>
                </div>
                <div class="recaptchaAllign">
                  <re-captcha (resolved)="resolveRecaptcha($event)"></re-captcha>
                </div>
                <br>
                <br>
                <div class="m-stack__item m-stack__item--center">
                  <div class="m-login__account">
                    <button id="m_login_signin_submit" class="btn m-btn--pill btn-back"
                      (click)="showform = 'generateOTP'">
                      <i class="la la-angle-left mr-1"></i> {{"Back"| translatePipe}}
                    </button>
                    <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login"
                      (click)="toGetUserId()">
                      {{"Submit"| translatePipe}}
                    </button> &nbsp;&nbsp;
                  </div>
                </div>
                <!-- <div class="m-login__form-action">
                          <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login" [disabled]="captchaInvalid" (click)="Submit()">
                              {{"Submit"| translatePipe}}
                          </button>
                      </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showform =='NoLongerAcess'" class="m-stack m-stack--hor m-stack--desktop">
        <div class="m-stack__item m-stack__item--fluid">
          <div class="m-login__wrapper">
            <div class="m-login__logo">
              <a>
                <img src="../../../assets/images/skill-india-logo-prod.png">
              </a>
            </div>
            <div class="m-login__signin">
              <div class="m-login__head">
                <h3 class="m-login__title">
                </h3>
              </div>
              <form class="m-login__form m-form" [formGroup]="NoLonger">
                <div class="form-group m-form__group">
                  <input class="form-control m-input notranslate" type="text" placeholder="Enter Name" name="name"
                    formControlName="requestName" autocomplete="off">
                  <app-form-control-messages [control]="NoLonger.controls.requestName" [name]="'Requester Name'">
                  </app-form-control-messages>
                </div>


                <div class="form-group m-form__group">
                  <input class="form-control m-input" type="email"
                    placeholder="{{'Enter email- different from existing one' | translatePipe}}"
                    formControlName="email">
                  <app-form-control-messages [control]="NoLonger.controls.email" [name]="'Email Address'">
                  </app-form-control-messages>
                </div>

                <div class="form-group m-form__group">
                  <input class=" form-control m-input notranslate" type="text"
                    placeholder="{{ 'Mobile Number - different from existing one' | translatePipe}}" onkeypress="return event.charCode>= 48 && event.charCode
                    <=57" name="mobile" maxlength="10" formControlName="phone">
                  <app-form-control-messages [control]="NoLonger.controls.phone" [name]=" 'Mobile Number'">
                  </app-form-control-messages>
                </div><br>
                <!-- <div class="col-12" style="padding:0">
                  <app-upload-file [formValue]="this.NoLonger.get('document')"
                    fileType="docment" info="only jpg, png, jpeg, pdf"></app-upload-file>
                  <app-form-control-messages [control]="NoLonger.controls.document"
                    [name]="'File Upload(supporting document)'">
                  </app-form-control-messages>
                  <input type="hidden" formControlName="document" #fileInput>
                </div> -->
                <div class="m-stack__item m-stack__item--center">
                  <div class="m-login__account">
                    <button id="m_login_signin_submit" class="btn m-btn--pill btn-back mr-2" routerLink="/login">
                      <i class="la la-angle-left mr-1"></i> {{"Back"| translatePipe}}
                    </button>
                    <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login"
                      (click)="retriveIdpaasword()">
                      {{"Submit"| translatePipe}}
                    </button>
                  </div>
                </div>
                <!-- <div class="m-login__form-action">
                    <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login" [disabled]="captchaInvalid" (click)="Submit()">
                        {{"Submit"| translatePipe}}
                    </button>
                </div> -->
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content"
      style="background-image: url(https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/learningpages/rightSide.png); background-attachment: unset;">
      <h2 class="sip-text">{{"Skill India Digital" |translatePipe}}</h2>
      <div class="m-grid__item m-grid__item--left">
        <h3 class="m-login__welcome">
          {{"FORGOT"|translatePipe}}
        </h3>
        <p class="m-login__msg">
          {{"WHAT_I_HERE"| translatePipe}}
          <br>{{"PROVIDE_EMAIL"|translatePipe}}
          <br>{{'Address and Mobile Number'|translatePipe}}
          <br> {{'VERIFY_REGISTERED'|translatePipe}}
          <br>
          <br>{{"NEXT" |translatePipe}}
          <br>{{'OTP'|translatePipe}}
        </p>
        <div class="login-right">
          <h3 class="help-text">{{"HELPlINE" |translatePipe}}</h3>
          <br>
          <button class="btn btn-focus  m-btn--pill btn-back"  (click)="goToSID()">{{"GO_TO_LOGIN"| translatePipe}}</button>
        </div>
      </div>
    </div> -->
  </div>
</div>
