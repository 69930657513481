<div class="m-grid m-grid--hor m-grid--root m-page">
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin" id="m_login">
        <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
            <div class="m-stack m-stack--hor m-stack--desktop">
                <div class="m-stack__item m-stack__item--fluid">
                    <div class="m-login__wrapper">
                        <div class="m-login__logo">
                            <a href="#">
                                <img src="../../../assets/images/skill-india-logo-prod.png">
                            </a>
                        </div>
                        <div class="m-login__signin">
                            <div class="m-login__head">
                                <h3 class="m-login__title">
                                </h3>
                            </div>
                            <form class="m-login__form m-form" [formGroup]="passwordResetForm" novalidate>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="password" placeholder="Enter Old Password" name="oldpassword" autocomplete="off" formControlName="oldPassword">
                                </div>
                                <app-form-control-messages [control]="passwordResetForm.controls.oldPassword" [name]="'Old Password'"></app-form-control-messages>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input custom-password" [type]="show ? 'text' : 'password'" placeholder="Enter New Password" name="newpassword" autocomplete="off" formControlName="newPassword">
                                    <a class="showPassword" (click)="showPassClick()" aria-hidden="true">{{show ? 'Hide' : 'Show'}}</a>
                                </div>
                                <app-form-control-messages [control]="passwordResetForm.controls.newPassword" [name]="'New  Password'"></app-form-control-messages>
                                <div class="form-group m-form__group">
                                    <input class="form-control m-input" type="password" placeholder="Enter Confirm Password" name="confirmPassword" onpaste="return false" autocomplete="off" formControlName="confirmPassword">
                                </div>
                                <app-form-control-messages [control]="passwordResetForm.controls.confirmPassword" [name]="'Confirm Password'"></app-form-control-messages>
                                <div *ngIf="passwordResetForm.controls['confirmPassword'].errors?.MatchPassword && passwordResetForm.controls['confirmPassword'].touched" class="red">Passwords do not match</div>
                                <div class="m-login__form-action">
                                    <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login" [disabled]="!passwordResetForm.valid" (click)="resetPassword()">
                    Reset & Re-login
                  </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <div class="m-stack__item m-stack__item--center">
                    <div class="m-login__account">
                        <span class="m-login__account-msg">
              Don't have an account yet ?
            </span> &nbsp;&nbsp;
                        <!-- <a href="javascript:;" id="m_login_signup" class="m-link m-link--focus m-login__account-link" routerLink="/direct-registration">
              Register Now
            </a> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content" style="background-image: url(https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/learningpages/p-reset.png); background-attachment: unset">
            <h2 class="sip-text">Skill India Digital Hub</h2>
            <div class="m-grid__item m-grid__item--left">
                <h3 class="m-login__welcome">
                    Login
                </h3>
                <p class="m-login__msg">
                    What do i do here?
                    <br>Here you need to login with Unique Id - Training Partner/Trainer/Assessor etc.
                    <br>
                    <br> What next?
                    <br> So you will be taken to Skill
                    <br>Development and Management Portal.
                </p>
                <div class="login-right">
                    <h4 class="help-text">Need Help? Call us on 1800-123-9626</h4>
                    <h3 class="text-center">Or</h3>
                    <h4 class="help-text td">Write us at <a style="text-decoration: underline">skillindia.helpdesk@nsdcindia.org</a></h4>
                    <br>
                    <button class="btn btn-focus  m-btn--pill btn-back" (click)="routeToHome()">Back to Homepage</button>
                </div>
            </div>
        </div>
    </div>
</div>
