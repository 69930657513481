import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { StorageService } from '../storage.service';
import { map, mergeMap, Observable, of } from 'rxjs';
// import { Headers, RequestOptions } from '@angular/http';
@Injectable({ providedIn: 'root' })
export class ApiService {
	private CSRF_URL = environment.csrf_url;
	private options = {};
	private cache: any = {};
	constructor(private httpClient: HttpClient,
	private storageService : StorageService) { }
	public get(url: string, query: Object = {}): Observable<any> {
		const cacheUrl = this.generateCacheUrl(url, query)
		if (query && query['cache'] && this.cache[cacheUrl]) {
			const cached = Helpers.copyObj(this.cache[cacheUrl]);
			return of(cached);
		}
		delete query['cache'];
		let _csrf;
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			if (args.headers) {
				_csrf = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', _csrf);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.get<any>(url, { params: <HttpParams>query, headers  })
				.pipe(map(res => {
					this.cache[cacheUrl] = Helpers.copyObj(res);
					return res;
				}))
		}));

	}
	private generateCacheUrl(url, props) {
		let cacheUrl;
		if (props) {
			cacheUrl = `${url}?${Helpers.serialize(props)}`
		} else {
			cacheUrl = url;
		}
		return cacheUrl;
	}
	/**
	 *
	 Http Client Blob
	 */
	public getBlob(url: string, type: string, query?: object): Observable<Blob> {
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;
			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp);
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj());
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.get(url, { headers: headers, responseType: 'blob', params: <HttpParams>query })
				.pipe(map(res => {
					return new Blob([res], { type: type });
				}));
		}));
	}
	/**
	 * Get the DownLoad Blob based on Arguments
	 */

	public getBlobOnArguments(url: string, body: object, type?: string): Observable<Blob> {
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;
			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp);
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj());
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.post(url, body, { headers: headers, responseType: 'blob' })
				.pipe(map(res => {
					console.log(res);
					return new Blob([res], { type: type });
				}));
		}));
	}

	public view(url: string, body?: Object, query?: Object): Observable<any> {
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;
			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.post<any>(url, body, { headers });
		}));
	}
	public put(url: string, body: Object = {}): Observable<any> {
		console.log('api service', url, body);

		let _csrf;
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {

			if (args.headers) {
				_csrf = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', _csrf);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.put<any>(url, body, { headers });
		}));
	}
	public post(url: string, body?: Object): Observable<any> {
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;
			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.post<any>(url, body, { headers });
		}));
	}
	public postPdf(url: string, body?: Object): Observable<any> {
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;
			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders({
				'Content-Type': 'application/json',
				'Accept': 'application/x-pdf'
			});
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.post<any>(url, body, { headers });
		}));
	}

	public delete(url: string, body?: Object): Observable<any> {
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;

			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}
			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.delete<any>(url, { headers });
		}));
	}

	public patch(url: string, body?: Object): Observable<any> {

		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;

			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}

			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.patch<any>(url, body, { headers });
		}));

	}

	public fileUpload(url: string, body?: Object, shouldObserveOnEntireResponse = true): Observable<any> {
		return this.head(this.CSRF_URL).pipe(mergeMap(args => {
			let csrfToken;
			if (shouldObserveOnEntireResponse) {
				this.options['observe'] = 'response';
			}

			if (args.headers) {
				csrfToken = args.headers.get('X-Csrf-Token');
			}

			let headers = new HttpHeaders();
			headers = headers.append('X-Csrf-Token', csrfToken);
			headers = headers.append('systemIPInfo', this.storageService.getIp)
			headers = headers.append('uIId', this.storageService.getBrowserFingePrintObj())
			// let tdate : any = new Date().toISOString();
			// headers = headers.append('reqTime', tdate);
			return this.httpClient.post<any>(url, body, { headers, observe: 'response' });
		}));


	}
	public getWithHeader(url, arg) {
		let headers = new HttpHeaders();
		headers = headers.set('Aadharnumber', arg);
		return this.httpClient.get<any>(url, { headers: headers });
	}

	public head(url: string, query?: Object): Observable<any> {
		this.options['observe'] = 'response';
		return this.httpClient.head<any>(url, this.options);
		// return this.httpClient.get<any>(url, { params: <HttpParams>query, observe: 'response' });
	}

	public getWithFileHeaders(url) {
		let head = new HttpHeaders({
			"Content-Type": "application/octet-stream",

			"filename": "batchData.xls"
		});
		console.log(head);
		return this.httpClient.get<any>(url, { headers: head })
	}

}
