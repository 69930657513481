import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DirectRegistrationComponent } from 'projects/common/src/app/direct-registration/direct-registration.component';
import { DuplicateSpocChangeComponent } from 'projects/common/src/app/duplicate-spoc-change/duplicate-spoc-change.component';
import { ForgotPasswordComponent } from 'projects/common/src/app/forgot-password/forgot-password.component';
import { LoginComponent } from 'projects/common/src/app/login/login.component';
import { PageNotFoundComponent } from 'projects/common/src/app/page-not-found/page-not-found.component';
import { PasswordResetComponent } from 'projects/common/src/app/password-reset/password-reset.component';
import { SessionExpiredComponent } from 'projects/common/src/app/session-expired/session-expired.component';
import { TcViewDetailsComponent } from 'projects/common/src/app/tc-view-details/tc-view-details.component';
import { RouteGuardService } from 'projects/services/src/app/route-guard.service';
import { USER_ROLES } from './scheme-constants/scheme-constants';
import { CbcmsdeLoginComponent } from 'projects/common/src/app/cbcmsde-login/cbcmsde-login.component';
import { FeedbackOfInspectorComponent } from 'projects/common/src/app/feedback-of-inspector/feedback-of-inspector.component';
import { MobileAndEmailVerificationComponent } from 'projects/common/src/app/mobile-and-email-verification/mobile-and-email-verification.component';
import { LoginTestComponent } from 'projects/common/src/app/login-test/login-test.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  { path: 'cbcmsde-login', component: CbcmsdeLoginComponent },
  { path: 'fi', component: FeedbackOfInspectorComponent },
  { path: 'email-mobile-varification', component: MobileAndEmailVerificationComponent },
  {
    path: 'cbcmsde-dash',
    loadChildren: ()=> 
      import('./../../projects/cbcmsde-phase/src/app/app.module').then(m=>m.AppModule),
    canActivate:[RouteGuardService]
  },
  {
    path: 'duplicate-spoc-change',
    component: DuplicateSpocChangeComponent,
    data: {
      allowedActors: ['Training Partner', 'Training Centre'],
    },
  },
  {
    path: '',
    loadChildren: () =>
      import('./../../projects/landing-page/src/app/app.module').then(
        (m) => m.AppModule
      ),
  },
  { path: 'password-reset', component: LoginTestComponent },
  { path: 'direct-registration', component: DirectRegistrationComponent },
  { path: 'forgot-password', component: LoginTestComponent },
  { path: 'tc-view-details', component: TcViewDetailsComponent },
  {
    path: 'cbcmsde',
    loadChildren: () => import('./../../projects/cbc-msde/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'ssc',
    loadChildren: () =>
      import('./../../projects/ssc/src/app/app.module').then(
        (m) => m.AppModule
      ),
    data: {
      allowedActors: ['SSC'],
    },
    canLoad: [RouteGuardService],
  },
  {
    path: 'sscgovernance',
    loadChildren: () => import('../../projects/ssc-governance/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['SSC Governance']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'approver',
    loadChildren: () => import('../../projects/approver/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Budget Approver', 'Budget Initiator','MSDE']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'scpwd',
    loadChildren: () =>
      import('../../projects/scpw-ssc/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['SCPWD', 'SSC']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'nsdcsuperadmin',
    loadChildren: () => import('../../projects/nsdc-super-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['NSDC Super Admin']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'nsdcadmin',
    loadChildren: () => import('../../projects/nsdcadmin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['NSDC Admin', 'Ministry Admin']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'candidateadmin',
    loadChildren: () => import('../../projects/candidate-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Candidate Admin']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'holdingagency',
    loadChildren: () => import('../../projects/holding-agency/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Holding Agency']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'inspectionagency',
    loadChildren: () =>
      import('./../../projects/inspection-agency/src/app/app.module').then(
        (m) => m.AppModule
      ),
    data: {
      allowedActors: ['Inspection Agency'],
    },
    canLoad: [RouteGuardService],
  },
  {
    path: 'desktopassessor',
    loadChildren:
      () => import('../../projects/desktop-assessor/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Desktop Assessor']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'feebasedda',
    loadChildren:
     () => import('../../projects/fee-based-da/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Fee Based DA']
    },
    canLoad: [RouteGuardService] // TODO:
  },
  {
    path: 'continuousmonitoring',
    loadChildren:
      () => import('../../projects/fee-based-cmateam/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Continuous Monitoring']
    },
    canLoad: [RouteGuardService] // TODO:
  },
  {
    path: 'cmaheadfb',
    loadChildren:
      () => import('../../projects/feebased-cma-head/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['CMA HEAD FB']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'user-registration',  // need to segrigate role based registration
    loadChildren: () => import('../../projects/registration/src/app/registration.module').then(m => m.RegistrationModule),
    canLoad: [RouteGuardService]
  },
  {
    // Do not keep child app module route default (blank)
    path: '',
    loadChildren: () =>
      import('./../../projects/registration/src/app/app.module').then(
        (m) => m.AppModule
      ),
  },
  {
    path: 'trainingpartner',
    loadChildren:
      () => import('../../projects/training-partner/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Training Partner', 'SEO']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'trainingcentre',
    loadChildren:
      () => import('../../projects/training-center/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Training Centre', 'SEO', 'Training Partner','Camps']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'inprocesstp',
    loadChildren: () => import('../../projects/inprocess-tp/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Training Partner']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'inprocesstc',
    loadChildren: () => import('../../projects/inprocess-tc/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Training Centre']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'standardsteam',
    loadChildren:
      () => import('../../projects/standard-team/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Standards Team']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'standardsqrc',
    loadChildren:
      () => import('../../projects/standard-team/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Standards QRC']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'standardsnsqc',
    loadChildren:
      () => import('../../projects/standard-team/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Standards NSQC']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'externalusers',
    loadChildren:
      () => import('../../projects/qrc-member/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['External Users']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'borrowingcommittee',
    loadChildren:
      () => import('../../projects/standard-team/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Borrowing Committee']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'businessowner',
    loadChildren:
      () => import('../../projects/new-business-owner/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Business Owner']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'employer',
    loadChildren: () => import('./../../projects/employer/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Employer']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'trainer',
    loadChildren: () => import('../../projects/trainer/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Trainer']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'schemeadmin',
    loadChildren: () => import('../../projects/new-scheme-admin/src/app/app.module').then(m => m.AppModule),

    data: {
      allowedActors: ['Scheme Admin']
    },
    canLoad: [RouteGuardService]
  },
  // {
  //   path: 'cfo',
  //   loadChildren: () => import('./cfo/cfo.module').then(m => m.CfoModule),
  //   data: {
  //     allowedActors: ['CFO']
  //   },
  //   canLoad: [RouteGuardService]
  // },
  {
    path: 'assessmentagency',
    loadChildren:
      () => import('../../projects/assessment-agency-v1/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Assessment Agency']
    },
    canLoad: [RouteGuardService]
  },

  {
    path: 'candidateattendence',
    loadChildren:
      () => import('./../../projects/candidateattendence/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Candidate Attendence']
    },
  },

  {
    path: 'assessor',
    loadChildren: () => import('./../../projects/assessor/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Assessor']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'proctor',
    loadChildren: () => import('./../../projects/proctor/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Proctor']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'target-pmu',
    loadChildren: () => import('./../../projects/target-pmu/src/app/app.module').then(m => m.AppModule)
  },
  {
    path: 'placement-pmu',
    loadChildren: () => import('./../../projects/placement-pmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Placement STT Pmu', 'Placement SP Pmu', 'Placement STT Moma Pmu', 'Placement Amber Pmu', 'Placement Mohua Pmu']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'assessmentpmu',
    loadChildren: () => import('./../../projects/assessmentpmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Assessment Pmu']
    },
  },
  {
    path: 'placementadmin',
    loadChildren: () => import('./../../projects/placement-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Placement SP FieldVerification', 'Placement STT FieldVerification', 'Amber Field Verification Officer']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'sscplacementadmin',
    loadChildren: () => import('./../../projects/placement-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['SSC']
    },
    canLoad: [RouteGuardService]
  },

  {
    path: 'pmkvypayout',
    loadChildren: () => import('./../../projects/pmkvy-ssc-payout/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['SSC']
    },
    canLoad: [RouteGuardService]
  },

  {
    path: 'smartadmin',
    loadChildren: () =>
      import('././../../projects/smart-admin/src/app/app-routing.module').then(
        (m) => m.AppRoutingModule
      ),
    data: {
      allowedActors: ['Smart Admin', 'SEO'],
    },
    canLoad: [RouteGuardService],
  },
  {
    path: 'qualitycontrol',
    loadChildren:
      () => import('../../projects/quality-control/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Quality Control']
    },
    canLoad: [RouteGuardService]
  },

  {
    path: 'smartspoc',
    loadChildren: () => import('../../projects/smart-spoc/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Smart SPOC']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'financespoc',
    loadChildren: () => import('../../projects/finance-spoc/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Finance SPOC', 'PMKVY Finance SPOC']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'reportpmu',
    loadChildren: () => import('../../projects/dbt/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Report Pmu']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'appealpmu',
    loadChildren: () => import('../../projects/appeal-pmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Appeal Pmu']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'appealsubadmin',
    loadChildren: () => import('../../projects/appeal-sub-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Appeal Sub Admin']
    },
    canLoad: [RouteGuardService]

  },
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('./../../projects/unauthorized/src/app/app.module').then(
        (m) => m.AppModule
      ),
  },
  {
    path: 'candidate',
    loadChildren:
      () => import('../../projects/candidate/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Candidate']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'pmkkspoc',
    loadChildren:
      () => import('../../projects/pmkk-spoc/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['pmkkspoc']
    },
  },
  {
    path: 'rozgarmelaadmin',
    loadChildren:
      () => import('../../projects/rozgar-mela-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['RozgarMela Admin']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'rozgarmelaspoc',
    loadChildren:
      () => import('../../projects/rozgar-mela-spoc/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['RozgarMelaSpoc']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'placement',
    loadChildren:
      () => import('../../projects/placement/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Training Centre']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'pmkvypo',
    loadChildren:
      () => import('../../projects/placement-officer/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['PMKVYPO']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'cmaspoc',
    loadChildren:
      () => import('../../projects/cma-spoc/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['CMA SPOC', 'NCVT SPOC']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'cmahead',
    loadChildren:
      () => import('../../projects/pmkvy-continous-monitoring/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['CMA HEAD']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'schemeapprover',
    loadChildren:
      () => import('../../projects/scheme-approver/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Scheme Approver']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'rozarmelastseteam',
    loadChildren: () => import('../../projects/nsdc-st-se-team/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['RozarMelaStSeTeam', 'Rozgarmela Agency']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'pmkvy-pmu',
    loadChildren:
      () => import('../../projects/pmkvy-pmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: [USER_ROLES.TARGET_PMU,
      USER_ROLES.RPL_PMU, USER_ROLES.SP_PMU,
      USER_ROLES.SSDM_PMU_SP,
      USER_ROLES.SSDM_PMU_STT,
      USER_ROLES.AID_STT,
      USER_ROLES.AID_SP,
      USER_ROLES.SSDM_PMU_RPL,
      USER_ROLES.STATE_SCHEME_PMU,
      USER_ROLES.SANKALP_PMU,
      USER_ROLES.SANKALP_STT_PMU,
      USER_ROLES.AMBER_STT_PMU, 
      USER_ROLES.State_Scheme_PMU_CHECKER,
      'SEO',
      'Fee Based DA'
      ]
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'pmkvy-rpl-IV',
    loadChildren: () => import('../../projects/pmkvy/src/app/pmkvy/rpl-iv/rpl-iv.module').then(m => m.RplIvModule),
    data: {
      allowedActors: [
        USER_ROLES.SSC_SCHEME_SPOC.ROLE,
        USER_ROLES.BEST_IN_CLASS_EMPLOYER,
        USER_ROLES.CAMPS,
        USER_ROLES.EMPLOYER,
        USER_ROLES.TRAINING_CENTRE,
      ]
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'commonnormsadmin',
    loadChildren: () => import('../../projects/common-norms/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Common Norms Admin', 'Scheme Admin']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'schemeowner',
    loadChildren: () => import('../../projects/scheme-owner/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Scheme Owner']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'bestinclassemployer',
    loadChildren: () => import('../../projects/pmkvy/src/app/pmkvy/best-in-class-employee/best-in-class-employee.module').then(m => m.BestInClassEmployeeModule),
    data: {
      allowedActors: [USER_ROLES.BEST_IN_CLASS_EMPLOYER,
      USER_ROLES.CAMPS, USER_ROLES.EMPLOYER, USER_ROLES.SSC_SCHEME_SPOC.ROLE]
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'accreditationadmin',
    loadChildren: () => import('../../projects/accreditations-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Accreditation Admin']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'nsdcitadmin',
    loadChildren: () => import('./../../projects/nsdc-notice-manager/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: [USER_ROLES.NSDC_IT_ADMIN]
    },
    canLoad: [RouteGuardService]
  },

  {
    path: 'covidschemepmu',
    loadChildren:
      () => import('./../../projects/covid-scheme-pmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['COVIDSCHEMEPMU', 'RPL TC PMU']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'rpl-tc-pmu',
    loadChildren:
      () => import('./../../projects/covid-scheme-pmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: [ 'RPL TC PMU']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'lockdownpmu',
    loadChildren: () => import('./../../projects/pmkvy-hold-unhold-pmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: [USER_ROLES.LOCKDOWN_PMU]
    }
  },
  {
    path: 'sscAsPmu',
    data: {
      allowedActors: [USER_ROLES.SSC]
    },
    loadChildren:
      () => import('./../../projects/ssc-as-pmu/src/app/app.module').then(m => m.AppModule),
    canLoad: [RouteGuardService]
  },
  {
    path: 'sankalapadmin',
    data: {
      allowedActors: [USER_ROLES.SANKALPADMIN, USER_ROLES.DSC]
    },
    loadChildren:
      () => import('./../../projects/sankalp-admin/src/app/app.module').then(m => m.AppModule),
    canLoad: [RouteGuardService]
  },
  {
    path: 'enumerator',
    data: {
      allowedActors: [USER_ROLES.ENUMERATOR]
    },
    loadChildren:
      () => import('./../../projects/enumerator/src/app/app.module').then(m => m.AppModule),
    canLoad: [RouteGuardService]
  },
  {
    path: 'gpspoc',
    data: {
      allowedActors: [USER_ROLES.GPSPOC]
    },
    loadChildren:
      () => import('./../../projects/gp-spoc/src/app/app.module').then(m => m.AppModule),
    canLoad: [RouteGuardService]

  },
  {
    path: 'dsc',
    data: {
      allowedActors: [USER_ROLES.DSC]
    },
    loadChildren:
      () => import('./../../projects/dsc/src/app/app.module').then(m => m.AppModule),
    canLoad: [RouteGuardService]

  },
  {
    path: 'thirdpartyagency',
    loadChildren: () => import('./../../projects/third-party-agencies/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: [USER_ROLES.THIRD_PARTY_AGENCIES],
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'adminoperation',
    loadChildren: () => import('./../../projects/nsdc-operational-team/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['AdminOperation']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'ab-profile', loadChildren: () => import('./../../projects/awarding-body/src/app/app.module').then(m => m.AppModule),    data: {
      allowedActors: ['SSC']
    },
    canLoad: [RouteGuardService]
  },

  {
    path: 'ab-ip-pmu',
    loadChildren:
      () => import('./../../projects/ab-ip-pmu/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: [
        USER_ROLES.PMKVY_PMU
      ]
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'implementationpartner',
    loadChildren:
      () => import('./../../projects/implementation-partner/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: [
        USER_ROLES.IMPLEMENTATION_PARTNER
      ]
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'inspectionagencypmkvy4',
    loadChildren:
      () => import('./../../projects/inspection-agency-pmkvy4/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['Inspection Agency']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'nsdc-partner-admin',
    loadChildren: () => import('./../../projects/nsdc-partner-admin/src/app/app.module').then(m => m.AppModule),
    data: {
      allowedActors: ['NSDCPartnerAdmin']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'nsdc-pmu',
    loadChildren:() => import('./../../projects/nsdc-pmu/src/app/app.module').then(m =>m.AppModule),
    data: {
      allowedActors: ['NSDCPMU']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'ams',
    loadChildren:() => import('./../../projects/ams/src/app/app.module').then(m =>m.AppModule),
    data: {
      allowedActors: ['AMS']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'daa',
    loadChildren:() => import('./../../projects/ams/src/app/app.module').then(m =>m.AppModule),
    data: {
      allowedActors: [USER_ROLES.DAA]
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'seo',
    loadChildren: ()=> import('./../../projects/seo/src/app/app.module').then(m=>m.AppModule),
    data: {
      allowedActors: ['SEO']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'tppmu',
    loadChildren: ()=> import('./../../projects/accreditations-admin/src/app/app.module').then(m=>m.AppModule),
    data: {
      allowedActors: ['TP PMU']
    },
    canLoad: [RouteGuardService]
  },
  {
    path: 'gurupmu',
    loadChildren: () => import('../../projects/guru/src/app/app.module').then(m => m.AppModule),
    data: {
        allowedActors: ['GURU PMU']
    },
    canLoad: [RouteGuardService]
  },

  { path: 'session-expired', component: SessionExpiredComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
