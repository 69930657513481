import { StorageService } from 'projects/services/src/app/storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-session-expired',
	templateUrl: './session-expired.component.html',
	styleUrls: ['./session-expired.component.css']
})
export class SessionExpiredComponent implements OnInit {

	constructor(
		private storageService: StorageService,
		private _router: Router
	) { }

	ngOnInit() {
		this.storageService.removeAll();
	}
	goToLogin() {
		this._router.navigate(['/login']);
	}
}
