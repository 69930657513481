import { Injectable } from '@angular/core';
import { Observable } from'rxjs';

import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { CurrentUserService } from '../current-user.service';
import { actors } from 'src/app/constant/schemeConstants';
import { schemeStatus } from 'src/app/constant/schemeConstants'
import { SCHEME_NAME } from 'projects/training-partner/src/app/training-partner/constants/string.constant';
import { STRING } from 'projects/common/src/app/types';
import { SCHEME_NAME_FILTER_DROPDOWN } from 'src/app/scheme-constants/scheme-constants';
import { commonNormsStatus } from 'src/app/constant/schemeConstants';
@Injectable({ providedIn: 'root' })
export class SchemeService {
	backPage: any;
	// tslint:disable-next-line:max-line-length
	constructor(
		private apiService: ApiService,
		private currentUserService: CurrentUserService,
		) { }
	// New NODE APIS

	// Clone scheme
	cloneScheme(sourceSchemeId, destinationSchemeId) {
		const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${sourceSchemeId}/${destinationSchemeId}/importRules`;
		return this.apiService.put(Url);
	}


	// Common Norm Configuration
	commonNormCondidtionConfiguration(status, action?) {
		const user = this.currentUserService.decodeToken();
		const currentUserrole = user.role;
		if (status === undefined || status == null || status === '') {
			return;
		}
		// draft StatecommonNormsStatus
		if (status === commonNormsStatus.CnDraft) {
			if (currentUserrole === actors.CNA) {
				return true;
			}
			if (currentUserrole !== actors.CNA) {
				return false;
			}
		}
		// Submitted
		if (status === commonNormsStatus.CnSubmitted) { return false };
		// Submitted
		if (status === commonNormsStatus.CnApproved) {
			if (currentUserrole === actors.SA) {
				if (action === 'updateOWCN' || action === 'overRide') {
					return true;
				} else if (action === 'view') {
					return false;
				}
			} else {
				return false;
			}
		};
		//  Rejcted
		if (status === commonNormsStatus.CnRejected) {
			if (currentUserrole === actors.CNA) {
				return true;
			}
			if (currentUserrole !== actors.CNA) {
				return false;
			}
		}

	}

	overWrittenCommoNormConfig(status, action?) {
		const user = this.currentUserService.decodeToken();
		const currentUserrole = user.role;
		if (currentUserrole === actors.SA) {
			if (status === 'draft' || status === 'rejected') {
				return true
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	// Scheme Form Configration
	schemeFormConditionalConfig(status) {
		const user = this.currentUserService.decodeToken();
		const currentUserrole = user.role;

		if (user.role !== actors.SA) {
			return true;
		} else if (user.role === actors.SA) {
			if (status === schemeStatus.schemeDraft
				|| status === schemeStatus.schemeRejected
				|| status === schemeStatus.schemeNew) {
				return false
			} else {
				return true
			}
		}
	}

	// Get the users to assign schemes to them
	getUsersBasedOnRoles(role) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/user?role=${role}`;
		return this.apiService.get(url);
	}

	// Assign Scheme to User
	assignSchemeToUser(schemeId, data) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}?action=assign`;
		return this.apiService.patch(url, data)
	}

	// New Scheme Apis - new Dev node server Apis
	// COMMON NORMS ADMIN
	// COMMON NORM APIS
	createOrUpdateOWCN(data, type, id, slot?) {
		if (type === 'overRide') {
			// const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}?slot=1&action=overrideCommonNorm`;
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorm/${id}`;
			return this.apiService.post(url, data);
		} else if (type === 'updateOCN') {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorm/${id}?slot=${slot}`;
			return this.apiService.patch(url, data);
		}
	}

	updateCommonNorms(data, type, id?, slot?) {
		if (type === 'create') {
			const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms`;
			return this.apiService.post(Url, data);
		}
		if (type === 'update') {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/${id}?slot=${slot}`
			return this.apiService.patch(url, data);
		}
	}

	// updating common norms
	putCommonNorms(data, id?, slot?) {
		if (slot) {
			const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/${id}?slot=${slot}`;
			return this.apiService.patch(Url, data);
		}
	}
	// -----------------------------------------> OCN APIS
	// get List of Common Norms- scheme admin
	getCommonNormsList(pageNo, perPage) {
		const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms?status=all&pageNo=${pageNo}&itemsPerPage=${perPage}`;
		return this.apiService.get(Url);
	}

	// Get common norms submitted to CFO for Approval
	getOCNSubmittedToCFO() {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorms/5cca8e2c69e12d6569c0720b`
		this.apiService.get(url)
	}

	submitOCNForApproval(OcnId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorm/${OcnId}?action=submit`;
		return this.apiService.patch(url);
	}

	approveOCN(OcnId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorm/${OcnId}?action=approve`;
		return this.apiService.patch(url);
	}

	rejectOCN(OcnId, comment?) {
		if (comment) {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorm/${OcnId}?action=reject`;
			return this.apiService.patch(url, comment);
		} else {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorm/${OcnId}?action=reject`;
			return this.apiService.patch(url);
		}
	}

	getOCNSchemsSubmittedForApprovals() {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=approve&status=submitted&subType=overridenCommonNorms`;
		return this.apiService.get(url);
	}

	getOCNSSubmittedForApproval(id) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorms/${id}?type=approve&status=submitted`
		return this.apiService.get(url);
	}
	// -----------------------------------------> OCN APIS
	// get Partivular Common Norm Details

	getAParticularCommonNormDetails(id, overRidden) {
		if (overRidden === false) {
			const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/${id}`;
			return this.apiService.get(Url);
		}
	}

	getLatestCommonNormAmendment() {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/activeCommonNorm/latest`;
		return this.apiService.get(url)
	}

	getOverWrittenCommonNormDetails(id) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/overridenCommonNorm/${id}`;
		return this.apiService.get(url);
	}

	// submit Common norm for approval
	submitCommonNormForApproval(id, status, comments?) {
		if (comments) {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/${id}?action=${status}`
			return this.apiService.patch(url, comments);
		} else {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/${id}?action=${status}`
			return this.apiService.patch(url);
		}
	}

	deleteAddedAssessmentFees(commonNormId, nsqlLevel) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/${commonNormId}?itemToDelete=assessmentFeeDetails&nsqfLevel=${nsqlLevel}`
		return this.apiService.delete(url);
	}

	// NSDCADMIN SCHEME APIS
	getDropDownData(type) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/dropdown?dropdownType=${type}`;
		return this.apiService.get(url);
	}

	getSchemeNames(forNonAATC?) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/dropdown/schemeConfig?rootNode=schemeName&forNonAATC=${forNonAATC}`
		return this.apiService.get(url);
	}

	getDropDownForTp(tpId) {
		const url = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/cm/tpSchemes/${tpId}`
		return this.apiService.get(url);
	}

	getTrainingTypesOfPmkvy(schemeName) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/dropdown/schemeConfig?rootNode=schemeName&parentNode=${encodeURIComponent(schemeName)}&childNodeOne=trainingTypes`;
		return this.apiService.get(url);
	}

	getSubSchemeNames(schemeName, trainingType?, isFromSeo?) {
		if (schemeName === SCHEME_NAME_FILTER_DROPDOWN.PMKVY_CSCM.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.PMKVY_CSSM.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.PMKVY_3_CSCM.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.PMKVY_3_CSSM.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.SEEKHO_AUR_KAMAO.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.MOHUA.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.PMKVY_4_CSSM.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.SEEKHO_AUR_KAMAO.name ||
			schemeName === SCHEME_NAME_FILTER_DROPDOWN.PMV_SCHEME.name

		) {
			// tslint:disable-next-line:max-line-length
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/dropdown/schemeConfig?rootNode=schemeName&parentNode=${encodeURIComponent(schemeName)}&childNodeOne=trainingTypes&childNodeTwo=${trainingType}`;
			return this.apiService.get(url);
		} else if (schemeName === 'NON-PMKVY') {
			// tslint:disable-next-line:max-line-length
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/dropdown/schemeConfig?rootNode=schemeName&parentNode=${encodeURIComponent(schemeName)}&childNodeOne=trainingTypes&childNodeTwo=common`;
			return this.apiService.get(url);
		}else if(isFromSeo){
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/dropdown/schemeConfig?rootNode=schemeName&parentNode=${encodeURIComponent(schemeName)}&childNodeOne=trainingTypes&childNodeTwo=${trainingType}`;
			return this.apiService.get(url);
		}
	}

	createNewScheme(data) {
		const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/`
		return this.apiService.post(Url, data);
	}

	updateSchemeDetails(data, id, slot) {
		const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}?slot=${slot}`
		return this.apiService.patch(Url, data);
	}

	// Scheme API's

	// Retirement Apis
	// list API
	getSchemeListForRetirement(pageNo, itemsPerPage) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/retirement?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
		return this.apiService.get(url);
	}

	retireSchemeBySchemeAdmin(id, data) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}/retirement`;
		return this.apiService.put(url, data);
	}

	retirementApprovalForScheme(id, action, data?) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}?action=${action}&actionType=retire`;
		return this.apiService.patch(url, data);
	}

	// Create New Version of scheme

	createNewSchemeVersion(data, id, slot) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}?action=createNewVersion`
		return this.apiService.put(url, data)
	}

	// Get Scheme List - for ALL Schemes
	// List API
	getCloneSchemeList(pageNo, itemsPerPage) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=cloning&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`
		return this.apiService.get(url);
	}

	getSchemeList(type, pageNo, itemsPerPage) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=dashboard&status=${type}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
		return this.apiService.get(url);
	}
	// View assigned all scheme filter
	getFilteredSchemeList(type, pageNo, itemsPerPage, schemeName, trainingType) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=dashboard&status=${type}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&schemeName=${schemeName}&trainingType=${trainingType}`;
		return this.apiService.get(url);
	}
	// For dynamic filter api----> get training type
	getTrainingTypes() {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/gettrainingtypes`;
		return this.apiService.get(url);
	}
	// For dynamic filter ------> get scheme names based on training type
	getSchemeNamesByTrainingType(trainingType) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/getschemesbytrainingtype?trainingtype=${trainingType}`;
		return this.apiService.get(url);
	}

	// Homepage filters
	getSchemeNamesByTrainingTypeForHome(trainingType) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/getschemesbytrainingtype?trainingtype=${trainingType}&fromCron=true`;
		return this.apiService.get(url);
	}
	getSubSchemeNamesBySchemeForHome(scheme,tt) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/getSubSchemesByScheme?scheme=${scheme}&tt=${tt}&fromCron=true`;
		return this.apiService.get(url);
	}

	// Schemes submitted to an actor for approval or reection.
	getAllSchemesSubmittedForApprovalToAnActor(pageNo, itemsPerPage) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=approve&status=submitted&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`
		return this.apiService.get(url);
	}

	getSchemesSubmittedToCFOAndFSpoc() {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=approve&status=submitted&subType=schemeBunch`;
		return this.apiService.get(url);
	}

	// Approve or Reject a scheme submitted for approvals
	approveOrRejectScheme(id, action, data?) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}?action=${action}&actionType=submit`
		if (data) {
			return this.apiService.patch(url, data);
		} else {
			return this.apiService.patch(url);
		}

	}

	// Submit Scheme for Approvals
	submitSchemeForApproval(id, action) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}?action=${action}`
		return this.apiService.patch(url)
	}

	// Assignment Flow - scheme assigned to an actor
	// List API
	getAllSchemesAssignedToAnActor(pageNo, perPage) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=assign&status=all&pageNo=${pageNo}&itemsPerPage=${perPage}`
		return this.apiService.get(url);
	}

	// Fetch a particular scheme details
	getAparticularSchemeDetails(id) {
		const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}`;
		return this.apiService.get(Url);
	}

	getSchemeDetails(id) {
		const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${id}`;
		return this.apiService.get(Url);
	}

	// DROPDOWN DATA APIS
	getDisabilities() {
		const url = `${environment.nsdcCommonServiceUrl}/api/nsdccommon/disability/v1`;
		return this.apiService.get(url);
	}

	getCasteCategories() {
		const url = `${environment.nsdcCommonServiceUrl}/api/nsdccommon/categories/v1`;
		return this.apiService.get(url);
	}

	getReligions() {
		const url = `${environment.nsdcCommonServiceUrl}/api/nsdccommon/religions/v1`;
		return this.apiService.get(url);
	}

	// QP Sector Service Apis
	getQpSectors() {
		const url = `${environment.sqaServiceUrl}/api/sqa/sectors`
		return this.apiService.get(url);
	}

	getQpSubSectors(sectorId) {
		const url = `${environment.sqaServiceUrl}/api/sqa/sectors/subsectors/${sectorId}`
		return this.apiService.get(url);
	}

	getnsqflevelFilter(pageNo, sectorId?, cnnCategory?, search?, nsqfLevel?, qpType?) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=9999&&search=status:NSQC Approved,sectorID:${sectorId},cnnCategory:${cnnCategory},nsqf:${nsqfLevel},qpType:${qpType}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`;
		return this.apiService.get(url);
	}

	getnsqflevelFilterV1(pageNo, schemeDetails, sectorId?, cnnCategory?, search?, nsqfLevel?, qpType?) {
		// tslint:disable-next-line:max-line-length
		if (schemeDetails.basicDetails.schemeName.name == 'NON-PMKVY' || schemeDetails.basicDetails.schemeName.name == 'Fee Based') {
			const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=9999&&search=status:NSQC Approved,sectorID:${sectorId},cnnCategory:${cnnCategory},nsqf:${nsqfLevel},qpType:${qpType}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`;
			return this.apiService.get(url);
		} else {
			const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=9999&&search=status:NSQC Approved,sectorID:${sectorId},cnnCategory:${cnnCategory},nsqf:${nsqfLevel},qpType:${qpType}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`;
			return this.apiService.get(url);
		}
	}

	getQpsBasedOnFilters(pageNo, itemsSize, sectorId?, subSectorID?, search?, nsqfLevel?) {
		// let url,
		const searchFlag: Boolean = search ? true : false;
		const sectorIdFlag: Boolean = sectorId ? true : false;
		const subSectorIDFlag: Boolean = subSectorID ? true : false;
		const nsqfFlag: Boolean = nsqfLevel ? true : false;
		if (search) {
			// tslint:disable-next-line:max-line-length
			// const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId},subSectorID:${subSectorID}&&fields=qpCode,jobRole,status,nsqfLevel,occupation,sectors,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`
			if (sectorId && subSectorID && nsqfLevel) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId},subSectorID:${subSectorID},nsqf:${nsqfLevel}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`;
			} else if (sectorId && subSectorID) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId},subSectorID:${subSectorID}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`
				return this.apiService.get(url);
			} else if (sectorId && nsqfLevel) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId},nsqf:${nsqfLevel}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`
				return this.apiService.get(url);
			} else if (sectorId) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`
				return this.apiService.get(url);
			} else if (nsqfLevel) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,nsqf:${nsqfLevel}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`
				return this.apiService.get(url);
			} else {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version&&keyword=${search}`
				return this.apiService.get(url);
			}
		} else {
			if (sectorId && subSectorID && nsqfLevel) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId},subSectorID:${subSectorID},nsqf:${nsqfLevel}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version`
				return this.apiService.get(url);
				// tslint:disable-next-line:max-line-length
			} else if (sectorId && subSectorID) {
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId},subSectorID:${subSectorID}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version`
				return this.apiService.get(url);
			} else if (sectorId && nsqfLevel) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId},nsqf:${nsqfLevel}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version`
				return this.apiService.get(url);
			} else if (sectorId) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,sectorID:${sectorId}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version`
				return this.apiService.get(url);
			} else if (nsqfLevel) {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved,nsqf:${nsqfLevel}&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version`
				return this.apiService.get(url);
			} else {
				// tslint:disable-next-line:max-line-length
				const url = `${environment.sqaServiceUrl}/api/sqa/qp/search?pageNo=${pageNo}&&size=${itemsSize}&&search=status:NSQC Approved&&fields=qpCode,jobRole,status,nsqfLevel,sectors,occupation,compulsoryNos,optionalNos,electiveNos,assmtCrt,version`
				return this.apiService.get(url);
			}
		}
	}

	getSingleQpDetails(qpCode, version) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.sqaServiceUrl}/api/sqa/qp?qpCode=${qpCode}&&version=${version}&&fields=qpCode,jobRole,status,nsqfLevel,occupation,sectors,version,compulsoryNos,optionalNos,electiveNos,assmtCrt`
		return this.apiService.get(url);
	}

	// Scheme servie QP APIs
	getAllQpLinkedSchemes() {
		// const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/instantiateQp`
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=approve&status=submitted&subType=qpLinking`
		return this.apiService.get(url);
	}

	getSingleQpDetailsSchemeService(qpId) {
		// const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${qpId}/instantiateQp`
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/singleQP/${qpId}`
		return this.apiService.get(url);
	}
	// Link multiple Qps to a scheme
	// linkMultipleQpsToScheme(schemeId, data) {
	// 	const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}/instantiateQp`
	// 	return this.apiService.post(url, data);
	// }


	linkQpToScheme(schemeId, data) {
		const Url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}/instantiateQp`
		return this.apiService.post(Url, data);
	}

	submitQpsForApproval(schemeId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/instantiateQp/${schemeId}`
		return this.apiService.patch(url);
	}

	submitQpsForApprovalNew(schemeId, gradingMatrixId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/${schemeId}/${gradingMatrixId}?action=submit`
		return this.apiService.put(url);
	}

	linkqps(schemeId, gradingMatrixId, categoryid, data): Observable<any> {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/${schemeId}/${gradingMatrixId}/${categoryid}`;
		return this.apiService.post(url, data);
	}

	previousselectedQp(schemeId): Observable<any> {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}`;
		return this.apiService.get(url);
	}

	getQpsLinkedToSchemesSubmitForApproval(schemeId, pageNo?, itemsSize?) {
		if (pageNo && itemsSize) {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/instantiateQp/${schemeId}?pageNo=${pageNo}&size=${itemsSize}
			&status=submitted&status=draft&status=approved&type=dashboard`;
			return this.apiService.get(url)
		} else {
			const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/instantiateQp/${schemeId}
			?status=submitted&status=draft&status=approved&type=dashboard&noPagination=true`
			return this.apiService.get(url)
		}
	}
	// getQpsLinked and not submitted for approval
	getQpslinkedToScheme(schemeId, pageNo?, itemsSize?) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/instantiateQp/${schemeId}?pageNo=${pageNo}&size=${itemsSize}
			&status=draft&status=''&type=dashboard`;
		return this.apiService.get(url)
	}

	// get all qps linked to a scheme
	getAllQpsLinkedToSchemeBasedOnStatus(schemeId, status) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/${schemeId}?type=approve&status=submitted`;
		return this.apiService.get(url);
	}

	overRideQpDetails(schemeId, data) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/singleQP/${schemeId}?action=overrideQP`
		return this.apiService.patch(url, data)
	}

	approveRejectQp(qpId, action, comment?) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/singleQP/${qpId}?action=${action}`
		return this.apiService.patch(url, comment);
	}

	/**
	 * Scheme nodeJs Service
	 */
	getSchemesV2(pageNo?, itemsPerPage?) {
		let query: any;
		if (pageNo && itemsPerPage) {
			query = {
				pageNo: pageNo,
				itemsPerPage: itemsPerPage
			}
		}
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=externalModule&status=active`;
		return this.apiService.get(url, query);
	}
	// curentlyty not using getSchemesV2ByFilter
	getSchemesV2ByFilter(schemeName?: string, trainingType?: string, subSchemeName?: string) {
		let url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=externalModule&status=active&isFilter=true`;
		if (schemeName && !trainingType && !subSchemeName) {
			// tslint:disable-next-line:max-line-length
			url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=externalModule&status=active&isFilter=true&schemeName=${schemeName}`;

		} else if (schemeName && trainingType && !subSchemeName) {
			// tslint:disable-next-line:max-line-length
			url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=externalModule&status=active&isFilter=true&schemeName=${schemeName}&trainingType=${trainingType}`;

		} else if (schemeName && trainingType && subSchemeName) {
			// tslint:disable-next-line:max-line-length
			url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=externalModule&status=active&isFilter=true&schemeName=${schemeName}&trainingType=${trainingType}&subSchemeName=${subSchemeName}`;

		} else if (schemeName && subSchemeName && !trainingType) {
			// tslint:disable-next-line:max-line-length
			url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=externalModule&status=active&isFilter=true&schemeName=${schemeName}&subSchemeName=${subSchemeName}`;
		}

		return this.apiService.get(url);
	}

	getSchemesV3ByFilter(pageNo, itemsPerPage, schemeNameId?: Number, trainingTypeId?: Number, schemeData?: {nonAnATC: string},) {
		// tslint:disable-next-line:max-line-length
		let url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=externalModule&status=active&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
		if (schemeNameId && trainingTypeId) {
			// tslint:disable-next-line:max-line-length
			url += `&isFilter=true&schemeNameId=${schemeNameId}&trainingTypeId=${trainingTypeId}`;

		} else if (schemeNameId) {
			// tslint:disable-next-line:max-line-length
			url += `&isFilter=true&schemeNameId=${schemeNameId}`;
		}
		if (schemeData && schemeData.nonAnATC) {
			url += `&nonAnATC=${schemeData.nonAnATC}`
		}
		return this.apiService.get(url);
	}

	/**
	 * Scheme Detail
	 * @Param : Scheme Id
	 */
	getSchemeDetailsV2(schemeId: string) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}`;
		return this.apiService.get(url);
	}

	/**
	 * Return Common norms linked to Scheme
	 */
	getCommonNorms(commonNormsId: string) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/${commonNormsId}`
		return this.apiService.get(url);
	}

	getCommonNormsForEffectiveDate(date: string, schemeId: any) {
		const URL = `${environment.schemeServiceURL}/api/v1/schemeModule/commonNorms/activeCommonNorm?requiredForDate=${encodeURIComponent(date)}
		&schemeId=${schemeId}`
		return this.apiService.get(URL);
	}

	getSchemeType(scheme: any) {
		let schemeName = Helpers.getValueFromObject(scheme, 'basicDetails.schemeName', STRING.EMPTY)
		if (typeof schemeName === STRING.OBJECT) {
			schemeName = schemeName.name;
		}
		const subSchemeName = Helpers.getValueFromObject(scheme, 'basicDetails.subSchemeName', STRING.EMPTY)
		if (typeof subSchemeName === STRING.OBJECT) {
			if (SCHEME_NAME.PMKVY_SCHEMES.includes(schemeName)) {
				return SCHEME_NAME.PMKVY
			}
			// if (schemeName.includes(SCHEME_NAME.PMKVY)) {
			// 	return SCHEME_NAME.PMKVY
			// }
			return schemeName;
		}
		const schemeType = Helpers.getValueFromObject(scheme, 'basicDetails.scheme.name', STRING.EMPTY)
		if (schemeType) {
			return schemeType;
		}
		if (SCHEME_NAME.PMKVY_SCHEMES.includes(schemeName)) {
			return SCHEME_NAME.PMKVY
		}
		// if (schemeName.includes(SCHEME_NAME.PMKVY)) {
		// 	return SCHEME_NAME.PMKVY
		// }
		return schemeName;
	}
	getSchemeTypeV1(scheme: any) {
		let schemeName = Helpers.getValueFromObject(scheme, 'basicDetails.schemeName', STRING.EMPTY);
		let schemeId = Helpers.getValueFromObject(scheme, 'basicDetails.schemeName', STRING.EMPTY);
		if (typeof schemeName === STRING.OBJECT) {
			schemeName = schemeName.name;
			schemeId = schemeName.id;
		}
		// let schemeCategory = Helpers.getValueFromObject(scheme, 'basicDetails.feebasedSchemeType', STRING.EMPTY)
		// if(schemeCategory){
		// 	return schemeCategory
		// }

		const subSchemeName = Helpers.getValueFromObject(scheme, 'basicDetails.feebasedSchemeType', STRING.EMPTY)
		// this is IMPORTANT to shwo project type in feebased flow
		// DONT change this
		// scheme model change -krs
		if (typeof subSchemeName === STRING.OBJECT) {

			// if (SCHEME_NAME.PMKVY_SCHEMES.includes(schemeName)) {
			if (SCHEME_NAME.PMKVY_SCHEMES_ID.indexOf(schemeId) !== -1) {
				return SCHEME_NAME.PMKVY
			}
			// if (schemeName.includes(SCHEME_NAME.PMKVY)) {
			// 	return SCHEME_NAME.PMKVY
			// }
			return schemeName;
		}
		const schemeType = Helpers.getValueFromObject(scheme, 'basicDetails.scheme.name', STRING.EMPTY)
		if (schemeType) {
			return schemeType;
		}
		if (SCHEME_NAME.PMKVY_SCHEMES_ID.indexOf(schemeId) !== -1) {
			return SCHEME_NAME.PMKVY
		}
		// if (schemeName.includes(SCHEME_NAME.PMKVY)) {
		// 	return SCHEME_NAME.PMKVY
		// }
		return schemeName;
	}
	getSchemeName(scheme: any) {
		let schemeName = Helpers.getValueFromObject(scheme, 'basicDetails.schemeName', STRING.EMPTY)
		if (typeof schemeName === STRING.OBJECT) {
			schemeName = schemeName.name;
		}
		const subSchemeName = Helpers.getValueFromObject(scheme, 'basicDetails.feebasedSchemeType', STRING.EMPTY)
		// this is IMPORTANT to shwo project type in feebased flow
		// DONT change this
		// scheme model change
		if (typeof subSchemeName !== 'undefined') {
			return subSchemeName;
		}
		const schemeType = this.getSchemeType(scheme);
		return schemeName || schemeType;
	}
	getSchemeNameV1(scheme: any) {
		let schemeName = Helpers.getValueFromObject(scheme, 'basicDetails.schemeName', STRING.EMPTY)
		if (typeof schemeName === STRING.OBJECT) {
			schemeName = schemeName.name;
		}
		// const subSchemeName = Helpers.getValueFromObject(scheme, 'basicDetails.subSchemeName', STRING.EMPTY)
		// if (typeof subSchemeName === STRING.OBJECT) {
		// 	return subSchemeName.name;
		// }
		// const schemeType = this.getSchemeType(scheme);
		// return schemeName || schemeType;
		return schemeName
	}
	/* Get RPL scheme temp API */
	getRplSchemes(userRole: string) {
		const url = `${environment.userServiceUrl}/api/user/v1/sscschemerole/schemes?role=${userRole}`
		return this.apiService.get(url);
	}


	/*Generate Termsheet for Special Project */
	generateTermSheetForSpecialProject(tpId: string, projectId: string) {
		const trainingType = 'specialProject';
		// tslint:disable-next-line:max-line-length
		const url = `${environment.pdfGenerate}/api/v1/generatepdfreports/generatetermsheetreports?userName=${tpId}&trainingType=${trainingType}&schemesId=${projectId}`
		return this.apiService.get(url);
	}

	// Grading Matrix
	updateGradingMatrix(schemeId, data) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}?action=updateGradingMatrix`;
		return this.apiService.patch(url, data);
	}

	deleteGradingMatrxi(schemeId, nsqfLevel?, qpType?) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}?itemToDelete=gradingMatrixDetails&nsqfLevel=${nsqfLevel}&qpType=${qpType}&deleteType=schemeDetails`;
		return this.apiService.delete(url);
	}
	resetGradingMatrxi(objectId, gradingMatrix?) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/gradingMatrix/${objectId}/${gradingMatrix}`;
		return this.apiService.delete(url);
	}
	deleteGradingMatrxix(objectId, gradingMatrix?, categoryid?) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/gradingMatrix/${objectId}/${gradingMatrix}/${categoryid}`;
		return this.apiService.delete(url);
	}
	generateRplTermsheet(userName: string, trainingType: string, rplType: number, projectId: number) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.pdfGenerate}/api/v1/generatepdfreports/generatetermsheetreports?userName=${userName}&trainingType=${trainingType}&rplType=${rplType}&schemesId=${projectId}`;
		return this.apiService.get(url);
	}

	// Coast Element Rules
	// createCostElementRule() {
	// 	const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}`;
	// 	return this.apiService.patch(url);
	// }

	deleteCostElementRule(schemeId, costElement) {
		const encodeCE = encodeURIComponent(costElement);
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeId}?itemToDelete=costElementConfigs&deleteType=schemeDetails&costElement=${encodeCE}`;
		return this.apiService.delete(url);
	}

	backOnsamePage() {
		return this.backPage;
	}

	setBackPage(value) {
		this.backPage = value;
	}

	cancelSchemeFlow(type) {
		if (type === '') {

		}
	}

	// Further Scheme Approval Process

	// ACTOR :- BO
	getSchemesForFurtherApporovals(pageNo, itemsPerPage) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=approve&status=submitted&subType=forSchemeBunchSubmitToFurtherActors&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
		return this.apiService.get(url);
	}

	submitSchemesForFurtherApproval(schemeId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/schemeBunch/${schemeId}?reqType=submitFromBO&action=submit`
		return this.apiService.put(url);
	}

	// ACTOR :- STD

	// MARKS API
	getQPLinkedSchemesForMarksApproval(pageNo, itemsPerPage) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=approve&status=submitted&subType=schemeBunch&reqType=marks&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`
		return this.apiService.get(url);
	}

	getListOfLinkedQpsToMarksSchemes(schemeId) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/${schemeId}?type=approve&status=submitted&reqType=marks&pageNo=1&itemsPerPage=10`
		return this.apiService.get(url);
	}

	approveQpMarks(qpId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/singleQP/${qpId}?action=approve&reqType=marks`
		return this.apiService.patch(url);
	}

	rejectQpMarks(qpId, comment) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/singleQP/${qpId}?action=approve&reqType=marks`
		return this.apiService.patch(url, comment);
	}

	submitApprovedMarksForFurtherApproval(schemeId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/schemeBunch/${schemeId}?reqType=marks&action=submit`
		return this.apiService.put(url);
	}

	// HOURS API
	getQPLinkedSchemesForHoursApproval(pageNo, itemsPerPage) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme?type=approve&status=submitted&subType=schemeBunch&reqType=hours&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`
		return this.apiService.get(url);
	}

	getListOfLinkedQpsToHoursSchemes(schemeId) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/${schemeId}?type=approve&status=submitted&reqType=hours&pageNo=1&itemsPerPage=10`
		return this.apiService.get(url);
	}

	approveQpHours(qpId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/singleQP/${qpId}?action=approve&reqType=hours`
		return this.apiService.patch(url);
	}

	rejectQpHours(qpId, comment) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/qpLinking/singleQP/${qpId}?action=approve&reqType=hours`
		return this.apiService.patch(url, comment);
	}

	submitApprovedHoursForFurtherApproval(schemeId) {
		const url = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/schemeBunch/${schemeId}?reqType=hours&action=submit`
		return this.apiService.put(url);
	}

	getTotalTargetHistory(projectId, tpId) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingpartner/target/history?projectId=${projectId}&tpId=${tpId}`
		return this.apiService.get(URL);
	}
	getJobroleTargetHistory(projectId, tpId, qpCode) {
		const URL = `${environment.userServiceUrl}/api/user/v1/tp/project/jobrole/list/from/stt/history?projectId=${projectId}&tpId=${tpId}&qpCode=${encodeURIComponent(qpCode)}`
		return this.apiService.get(URL);
	}
	// Api for target history in rpl
	getRPLJobroleTargetHistory(projectId, tpId, qpCode) {
		const URL = `${environment.userServiceUrl}/api/user/v1/tp/project/jobrole/list/from/rpl/history?projectId=${projectId}&tpId=${tpId}&qpCode=${encodeURIComponent(qpCode)}`
		return this.apiService.get(URL);
	}
	getRPLLocationTargetHistory(projectId, tpId, state, district, locationTarget) {
		const URL = `${environment.userServiceUrl}/api/user/v1/tp/project/jobrole/list/from/rpl/history?projectId=${projectId}&tpId=${tpId}&stateId=${state}&districtId=${district}&locationTarget=${locationTarget}`
		return this.apiService.get(URL);
	}

	getTcIdDropDown(userName) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingcentres?requestee=Training%20Partner`
		return this.apiService.get(URL);
	}

	getTcAssociatedBatchesDropDown(tcId, projectFields?) {
		let url = `${environment.userServiceUrl}/api/batch/v1/get/batches/${tcId}`;
		if (projectFields) {
			url = url.concat(`?projectFields=${projectFields}`);
		}
		return this.apiService.get(url);
	}


	getEnrolledCandidateOfBatch(pageNo, itemsPerPage, batchId, filter?) {
		const queryParam = {};
		if (filter) {
			queryParam['candidateId'] = filter;
		}
		const getApi = `${environment.userServiceUrl}/api/batch/v1/pmkvy/get/batch/enrol/candidates/with/pagination?batchId=${batchId}&pageNo=${pageNo}&itemsPerPage=${itemsPerPage}`;
		return this.apiService.get(getApi, queryParam);
	}
	genrateSkillCard(canId, batchId, sectorId, jobRoleName) {
		const URL = `${environment.pdfGenerate}/api/v1/generatepdfreports/skillcard?candId=${canId}&batchId=${batchId}&sectorId=${sectorId}&jobRoleNm=${jobRoleName}`;
		return this.apiService.get(URL)
	}

	generateSkillCardV1(canId, batchId, sectorId, jobRoleName, qpCode, version) {
		const encodedQpCode = encodeURIComponent(qpCode)
		const URL = `${environment.pdfGenerate}/api/v1/generatepdfreports/skillcard?candId=${canId}&batchId=${batchId}&sectorId=${sectorId}&jobRoleNm=${jobRoleName}&qpCode=${encodedQpCode}&version=${version}`;
		return this.apiService.get(URL)
	}
	getCertificateDetails(batchId, userName, qpCode) {
		// tslint:disable-next-line:max-line-length
		const URL = `${environment.batchServiceUrl}/api/v1/cert/marksheet?for=tot&howMany=one&batchId=${batchId}&userName=${userName}&qpCode=${qpCode}`;
		return this.apiService.get(URL);
	}
	getAssessmentOfJobRole(data) {
		const getBatchDetails = `${environment.ToTServiceURL}/api/tot-toa/v1/assessment/batch/${data.batchId}/candidate/${data.userName}/jobrole`;
		return this.apiService.get(getBatchDetails, {
			qpCode: data.qpCode
		});
	}

	checkDurationValidation(data) {
		const url = `${environment.userServiceUrl}/api/user/v1/scheme/duration/wrt/project/duration/validation`;
		return this.apiService.post(url, data);
	}

	// schemeApiRefactor: JAN 21, 2020
	getSingleSchemeDetailBasedOnScenario(schemeObjectId, scenario = 'projection', schemeFieldsToProject?,
		projectQPInstantiationDetails?: boolean, qpInstantiationDetailsFieldsToProject?: Array<string>, projectOCNDetails?: boolean, ocnFieldsToProject?: Array<string>) {
		let URL = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/${schemeObjectId}`

		if (scenario === 'qpInstantiationDetailsCount') {
			URL += `?type=custom&subType=qpInstantiationDetailsCount`
		} else {
			URL += `?type=custom&subType=projection`
			if (Array.isArray(schemeFieldsToProject)) {
				for (const key of schemeFieldsToProject) {
					URL += `&schemeFieldsToProject=${key}`
				}
			}
			if (projectQPInstantiationDetails) {
				URL += `&projectQPInstantiationDetails=true`
				if (Array.isArray(qpInstantiationDetailsFieldsToProject)) {
					for (const key of qpInstantiationDetailsFieldsToProject) {
						URL += `&qpInstantiationDetailsFieldsToProject=${key}`
					}
				}
			}
			if (projectOCNDetails) {
				URL += `&projectOCNDetails=true`
				if (Array.isArray(ocnFieldsToProject)) {
					for (const key of ocnFieldsToProject) {
						URL += `&ocnFieldsToProject=${key}`
					}
				}
			}

		}
		// http://localhost:3020/api/v1/schemeModule/scheme/5d1f77159cde0f542f9306b6?type=custom&subType=projection&
		// schemeFieldsToProject = basicDetails & schemeFieldsToProject=schemeDetails.batchCreationDetails
		// & projectQPInstantiationDetails=true & qpInstantiationDetailsFieldsToProject=qpVersion & qpInstantiationDetailsFieldsToProject=sector.sectorName
		// & projectOCNDetails=false & ocnFieldsToProject=transportConveyanceAndBoardingDetails & ocnFieldsToProject=
		return this.apiService.get(URL);
	}

	getQpInstantiationDetailsBasedOnSchemeObjectId(schemeObjectId, qpInstantiationDetailsFieldsToProject: string[],) {
		let URL = `${environment.schemeServiceURL}/api/v1/schemeModule/scheme/
		${schemeObjectId}?type=custom&subType=qpInstantiationDetailsWithProjection`
		if (qpInstantiationDetailsFieldsToProject) {
			if (Array.isArray(qpInstantiationDetailsFieldsToProject)) {
				for (const key of qpInstantiationDetailsFieldsToProject) {
					URL += `&qpInstantiationDetailsFieldsToProject=${key}`
				}
			}
		}
		return this.apiService.get(URL);
	}

	/* get Target revocation history for PMKVY */
	getPmkvyTargetRevocationHistoryTcWise(tcId, projectId) {
		const query = {
			projectId: projectId,
			tcId: tcId
		};
		const URL = `${environment.userServiceUrl}/api/user/v1/tc/targetRevoction/history`;
		return this.apiService.get(URL, query);
	}

	/* get Target revocation history project for PMKVY */
	getPmkvyProjectTargetRevocationHistory(projectId) {
		const query = {
			projectId: projectId
		};
		const URL = `${environment.userServiceUrl}/api/user/v1/project/targetRevoction/history`;
		return this.apiService.get(URL, query);
	}
	// get linked qp's list

	getViewLinkedQpsList(pageNo, itemsPerPage, parameter) {
		// let id = "5d279e0be654343173b982cd";
		const url = `${environment.paymentService}/api/v1/tranche/viewLinkedQp?pageNo=${pageNo}&itemsPerPage=${itemsPerPage}&id=${parameter}`;
		return this.apiService.get(url);
	}

	//get Naps Dbt Data
	getNapsDbtData(actionURL: String) {
		const url = `${environment.paymentService}/api/v1/tranche/getnapsdbtdata?${actionURL}`;
		return this.apiService.get(url);
	}

	updateNapsDbtData(data) {
		const url = `${environment.paymentService}/api/v1/tranche/updatenapsdbtstatus`;
		return this.apiService.put(url, data)
	}
	viewnapsdbtdata(id) {
		const url = `${environment.paymentService}/api/v1/tranche/viewnapsdbtdata?id=${id}`;
		return this.apiService.get(url);
	}

	getSidInterestsData(actionURL: String) {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getSidInterest?${actionURL}`;
		return this.apiService.get(url);
	}

	updateSidData(data) {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/tcapprovesidinterest`;
		return this.apiService.put(url, data)
	}

	viewSiddata(id) {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getSidInterestDetails?id=${id}`;
		return this.apiService.get(url);
	}
	getNsqfLevelDropDown() {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getNsqfLevel`;
		return this.apiService.get(url);
	}
	getSectorDropDown(nsqfLevel) {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getNsqfLevelBasedSectors?nsqfLevel=${nsqfLevel}`;
		return this.apiService.get(url);
	}
	getJobeRoleDropDown(sectorName) {
		let sec = encodeURIComponent(sectorName);
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getsectorBasedJobroles?sector=${sec}`;
		return this.apiService.get(url);
	}
	getJobroleDetails(jobrole){
		let role = encodeURIComponent(jobrole);
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getjobroleBasedQpcode?jobRole=${role}`;
		return this.apiService.get(url);
	}

	getProgramData(id){
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getActionPlanDetails?_id=${id}`;
		return this.apiService.get(url);
	}

	saveJssActionPlan(data) {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/saveActionPlan`;
		return this.apiService.post(url, data);
	}

	updateJssActionPlan(data) {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/editActionPlan`;
		return this.apiService.put(url, data)
	}


	deleteActionPlan(data) {
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/deleteActionPlan`;
		return this.apiService.put(url, data)
	}

	senDForApproval(id,type){
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/sentTpApproval?_id=${id}&type=${type}`;
		return this.apiService.get(url);
	}

	getProjectData(username, pageno, perpage, status){
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getprojectDetails?userName=${username}&itemsPerPage=${perpage}&pageNo=${pageno}&status=${status}`;
		return this.apiService.get(url);
	}

	getProjectsList(data:any){
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/getJssProjects`;
		return this.apiService.post(url, data);
	}
	approveOrRejectProject(data:any){
		const url = `${environment.sidServiceUrl}/api/v2/sidintegration/pmuApprovalJss`;
		return this.apiService.post(url, data);
	}
}

