<div class="m-grid m-grid--hor m-grid--root m-page" *ngIf="!pageLoad">
  <div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
    id="m_login">
    <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside">
      <div class="m-stack m-stack--hor m-stack--desktop">
        <div class="m-stack__item m-stack__item--fluid">
          <div class="m-login__wrapper">
            <div class="m-login__logo">
              <a>
                <img src="../../../assets/images/skill-india-logo-prod.png">
              </a>
            </div>
            <div class="m-login__signin">
              <div class="m-login__head">
                <h3 class="m-login__title">
                </h3>
              </div>
              <form class="m-login__form m-form" [formGroup]="loginForm" novalidate>
                <div class="form-group m-form__group">
                  <input class="form-control m-input notranslate" type="text"
                    placeholder="{{'Enter your Username' | translatePipe}}" name="email" autocomplete="off"
                    formControlName="username" [(ngModel)]="userCredentials.userName">
                </div>
                <app-form-control-messages [control]="loginForm.controls.username" [name]="'Unique ID'">
                </app-form-control-messages>
                <div class="form-group m-form__group">
                  <input class="form-control m-input m-login__form-input--last notranslate"
                    [type]="show ? 'text' : 'password'" placeholder="{{'Password' | translatePipe}}" name="password"
                    formControlName="password" [(ngModel)]="userCredentials.password">
                  <a class="showPassword" (click)="showPassClick()" aria-hidden="true">{{show ? 'Hide' : 'Show'}}</a>
                </div>
                <app-form-control-messages [control]="loginForm.controls.password" [name]="'Password'">
                </app-form-control-messages>
                <div class="row disclaimer">*{{"SENSITIVE_NAME_PASS" | translatePipe}}</div>
                <!-- <div class="row m-login__form-sub">
                  <div class="col m--align-right">
                    <a href="javascript:;" id="m_login_forget_password" routerLink="/forgot-password"
                      class="m-link  m--font-info">
                      {{"FORGOT" | translatePipe}}
                    </a>
                  </div>
                </div>
                <div class="row m-login__form-sub">
                  <div class="col m--align-right">
                    <a href="javascript:;" id="m_login_forget_password" routerLink="/cbcmsde-login"
                      class="m-link  m--font-info">
                      LOGIN For CBCMSDE
                    </a>
                  </div>
                </div> -->
                <div class="m-login__form-action">
                  <button id="m_login_signin_submit" class="btn btn-focus m-btn--pill btn-login"
                    [disabled]="!loginForm.valid" (click)="doLogin()">
                    {{"LOGIN" |translatePipe}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- <div class="m-stack__item m-stack__item--center">
          <div class="m-login__account">
            <span class="m-login__account-msg">
              {{"NO_ACCOUNT" |translatePipe}}
            </span> &nbsp;&nbsp;
            <a href="javascript:;" id="m_login_signup" class="m-link m-link--focus m-login__account-link"
              routerLink="/direct-registration">
              {{"REGISTER_NOW" | translatePipe}}
            </a>
          </div>
        </div> -->
      </div>
    </div>
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1	m-login__content page-right"
      style="background-image: url(../../../../assets/images/login-right.png); background-attachment: unset">
      <h2 class="sip-text"> {{"Skill India Digital" | translatePipe}} </h2>
      <div class="m-grid__item m-grid__item--left">
        <h3 class="m-login__welcome">
          {{"LOGIN"|translatePipe}}
        </h3>
        <p class="m-login__msg">
          {{"WHAT_I_HERE" |translatePipe}}
          <br>{{"YOU_NEED_LOGIN" | translatePipe}}
          <br>
          <br> {{"NEXT" |translatePipe}}
          <br> {{"GO_TO_PORTAL"| translatePipe}}
        </p>
        <div class="login-right">
          <h4 class="help-text">{{"HELPlINE" |translatePipe}}</h4>
          <!-- <h3 class="text-center">{{"OR" |translatePipe}}</h3>
                    <h4 class="help-text td">{{'WRITE_US'| translatePipe}} <a class="hyperlink"
                            href="mailto:skillindia.helpdesk@nsdcindia.org">skillindia.helpdesk@nsdcindia.org</a></h4>
                    <br>
                    <div>
                        <h4>{{'TC_ACCREDITATION' | translatePipe}} </h4>
                        <h4>{{'SEND_EMAIL' |translatePipe}}</h4>
                        <h4><a class="hyperlink"
                                href="mailto:support.smart@nsdcindia.org">support.smart@nsdcindia.org</a></h4>
                    </div> -->
          <br>
          <!-- <button class="btn btn-focus  m-btn--pill btn-back" routerLink="/">{{"GO_TO_LOGIN"|translatePipe}}</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade conatiner notranslate" id="m_modal_1_2" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header textcetre">
        <h3 class="modal-title text-center" id="exampleModalLabel"> <b>{{'SMART_VERSION' | translatePipe}}
          </b></h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="m-scrollable m-scroller" data-height="450" style="overflow: auto; padding-right: 10px; ">
          <!-- <p>The black out period of SMART portal for onboarded Training Providers (TPs) and Training Centres
                        (TCs) was from 1st May 2019 to 31st May 2019. The period was used for data migration and
                        validation of the same from old SMART portal to Skill India Digital. We are now going live with
                        Beta Version of SMART on Skill India Digital. The transition will happen in two phases as under :
                    </p> -->

          <ul>
            <!-- <li><b>1st Phase</b> will commence from 1st June 2019</li>
                        <li><b>2nd Phase</b> will commence within first week of June 2019</li>
                        <p class="model-top"><strong>Modules/Functionalities which will be accessible in <b>Phase 1</b>:
                            </strong>
                        </p> -->
            <p class="model-top">
              <b>{{'SMART_BETA_VER' | translatePipe}}</b>
            </p>
            <ul class="f">
              <li>{{'OLD_TP' | translatePipe}}</li>
              <li>{{'START_REGISTRATION' | translatePipe}} </li>
              <li> {{'SMART_CAAF' | translatePipe}} </li>
              <li> {{'SMART_TC_CREATED' | translatePipe}} </li>
              <li> {{'SMART_TC_LOGIN' | translatePipe}} </li>
              <li>{{'TP/TC_STAKEHOLDER' | translatePipe}} </li><br>
              <h5 class="align-text"><b>{{'RESET_PASSWORD' | translatePipe}}</b></h5>
              <b class="align-text"><span style="text-decoration: underline">{{'Exceptions' | translatePipe}}</span>
              </b>
              <br><br>
              <li>
                <span>{{'DNR_TC' | translatePipe}} </span>
                <a href="https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/QPCode.xlsx" target="_blank"
                  rel="noopener">
                  <b>{{'QP_LINK' | translatePipe}}</b>
                </a>
                <span> {{'DNR_TC_DATA' | translatePipe}}</span>
              </li><br>
              <li>{{'DATA_MIGRATION' | translatePipe}} </li>
            </ul>
            <!-- <h5 class="underline-p text-center"><b>Please use Forget Password link to change your password on first login with your existing SMART User ID.</b></h5>
                        <p class="model-top"><strong>Modules/Functionalities which will be accessible in <b>Phase 2</b>:

                            </strong>
                        </p>
                        <li> TCs which had submitted their forms on the old SMART website will be able to login on Skill
                            India portal and carry out required actions
                        </li> -->
          </ul>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{'CLOSE' | translatePipe}}</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade show add-preference-role-div" id="otpVerification" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" style="padding-right: 17px;">
  <div class="modal-dialog modal-dialog-centered modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">One Time Password Verification</h5>
        <button (click)="resetModel()" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body padding-top-null padding-bottom-null">
        <div class="m-login__signin">
          <div class="m-login__head">
            <h3 class="m-login__title">
            </h3>
          </div>
          <form class="m-login__form m-form" [formGroup]="otpForm">
            <div class="form-group m-form__group">
              <label for="">OTP has been sent to your {{userContactDetails?.email}} and
                {{userContactDetails?.phone}}</label>
              <br>
              <label for="">Please enter either of the OTP received on Email Address or Mobile Number in
                the below box to Login.</label>
              <input class="form-control m-input" type="text" maxlength="6" placeholder="Enter Email/Mobile OTP"
                name="eOTP" formControlName="emailOTP">
              <app-form-control-messages [control]="otpForm.controls.emailOTP" [name]="'Email/Mobile OTP'">
              </app-form-control-messages>
            </div>
            <!-- <div class="form-group m-form__group">
                        <h6 class="text-center otp-or"><b>OR</b></h6>
                      </div>
                      <div class="form-group m-form__group">
                        <label for="">{{userContactDetails?.phone}}</label>
                        <input class="form-control m-input" type="text" maxlength="6" placeholder="Enter Mobile OTP" name="mOTP" formControlName="mobileOTP">
                        <app-form-control-messages [control]="otpForm.controls.mobileOTP" [name]="'Mobile OTP'"></app-form-control-messages>
                      </div> -->
            <div class="m-login__form-action ">
              <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill btn-login" (click)="generateOTPs()">
                Resend OTP
              </button>
              <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill btn-login" [disabled]="!validOTP"
                (click)="verifyOTP('everyTime')">
                Verify OTP & Login
              </button>

            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="modal fade show add-preference-role-div" id="otpVerificationFirstTime" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" style="padding-right: 17px;">
  <div class="modal-dialog modal-dialog-centered modal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Verify Contact Details</h5>
        <button (click)="resetModel();" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body padding-top-null padding-bottom-null">
        <div class="m-login__signin">
          <div class="m-login__head">
            <h3 class="m-login__title">
            </h3>
            <label *ngIf="verifyUpdateDetails" style="font-size: 14px;" for="">
              Hi User, Kindly verify your email id
              <span class="contactDetails">{{userContactDetails?.email}} </span> and Mobile Number <span
                class="contactDetails">{{userContactDetails?.phone}}</span>, if you don't have access of
              these, You can update new email and mobile number and verify.

            </label>
          </div>
          <form *ngIf="!verifyUpdateDetails" class="m-login__form m-form" [formGroup]="contactDetailForm">
            <div class="form-group m-form__group">
              <label for="">Enter the email address</label>
              <input class="form-control m-input" type="text" name="Spoc-email" placeholder="Enter the email address"
                formControlName="email">
              <app-form-control-messages [control]="contactDetailForm.controls.email" [name]="'Email of spoc'">
              </app-form-control-messages>
              <!-- <input class="form-control m-input" type="text" placeholder="Enter Your Email" name="" formControlName="email">
                          <app-form-control-messages [control]="contactDetailForm.controls.email" [name]="'Enter Your Email'"></app-form-control-messages> -->
            </div>
            <!-- <div class="form-group m-form__group">
                          <h6 class="text-center otp-or"><b>OR</b></h6>
                        </div> -->
            <div class="form-group m-form__group">
              <label for="">Enter the mobile number</label>
              <input class="form-control m-input" type="text" maxlength="10" name="mobile-number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57" placeholder="Enter mobile number"
                formControlName="phone">
              <app-form-control-messages [control]="contactDetailForm.controls.phone"
                [message]="'Mobile Number should be 10 digits'" [name]="'Mobile of spoc'">
              </app-form-control-messages>
              <!-- <input class="form-control m-input" type="Number" maxlength="6" placeholder="Enter Mobile Number" name="" formControlName="phone">
                          <app-form-control-messages [control]="contactDetailForm.controls.phone" [name]="'Enter Mobile Number'"></app-form-control-messages> -->
            </div>
            <div class="m-login__form-action ">
              <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill btn-login"
                [disabled]="!validContactDetail" (click)="updateContactDetails()">
                Update & Verify
              </button>
            </div>
          </form>
          <form *ngIf="verifyUpdateDetails" class="m-login__form m-form" [formGroup]="otpForm">
            <div class="form-group m-form__group">
              <label for="">{{userContactDetails?.email}}</label>
              <input class="form-control m-input" type="text" maxlength="6" placeholder="Enter Email OTP" name="eOTP"
                formControlName="emailOTP">
              <app-form-control-messages [control]="otpForm.controls.emailOTP" [name]="'Email OTP'">
              </app-form-control-messages>
            </div>
            <!-- <div class="form-group m-form__group">
                        <h6 class="text-center otp-or"><b>OR</b></h6>
                      </div> -->
            <div class="form-group m-form__group">
              <label for="">{{userContactDetails?.phone}}</label>
              <input class="form-control m-input" type="text" maxlength="6" placeholder="Enter Mobile OTP" name="mOTP"
                formControlName="mobileOTP">
              <app-form-control-messages [control]="otpForm.controls.mobileOTP" [name]="'Mobile OTP'">
              </app-form-control-messages>
            </div>
            <div class="m-login__form-action ">
              <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill btn-login" (click)="generateOTPs()">
                Resend OTP
              </button>
              <button id="m_login_signin_submit" class="btn btn-focus  m-btn--pill btn-login" [disabled]="!validOTP"
                (click)="verifyOTP()">
                Verify OTP & Login
              </button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>



<div class="modal fade show" id="spoc-confirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">SPOC Change Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Do you still have access to this email {{spoc?.email}} and the mobile number {{spoc?.phone}}, if you
          want to change SPOC, <a href="javascript:;" rel="noopener noreferrer" (click)="spocChange()">click
            here</a> </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="ContinueForOtpVerification()">No,
          Continue</button>
        <button type="button" class="btn btn-primary" (click)="spocChange()">Change SPOC</button>
      </div>
    </div>
  </div>
</div>