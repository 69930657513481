import { StorageService } from 'projects/services/src/app/storage.service';
import { LoginService } from 'projects/services/src/app/API/login/login.service';
import { ShareDataService } from 'projects/shared/src/app/shared/share-data.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'projects/services/src/app/validation.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'projects/services/src/app/API/api.service';
import { Component, OnInit } from '@angular/core';
declare var swal: any;
@Component({
	selector: 'app-password-reset',
	templateUrl: './password-reset.component.html',
	styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
	passwordResetForm: FormGroup;
	userName = '';
	show: boolean;
	constructor(
		private apiService: ApiService,
		private formBuilder: FormBuilder,
		private router: Router,
		private loginService: LoginService,
		private sharedDataService: ShareDataService,
		private storageStorage: StorageService,
	) {
		this.passwordResetForm = this.formBuilder.group({
			'oldPassword': ['', Validators.required],
			'newPassword': ['', Validators.compose([Validators.required, Validators.minLength(8),
			Validators.maxLength(16), ValidationService.passwordValidator])],
			'confirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(16)])],
		},
			{
				validator: ValidationService.MatchPassword
			});
		this.show = false;
	}

	ngOnInit() {
		this.sharedDataService.getData()
			.subscribe(res => {
				console.log(res);
				if (res) {
					this.userName = res;
				}
			});
	}
	async resetPassword() {
		const publicKey = await this.loginService.getPublicKey().toPromise();
		const oldPassword = await this.loginService.encryptPassword(publicKey.publicKey, this.passwordResetForm.value.oldPassword,publicKey.secret);
		const newPassword = await this.loginService.encryptPassword(publicKey.publicKey, this.passwordResetForm.value.newPassword,publicKey.secret);
		const confirmPassword = await this.loginService.encryptPassword(publicKey.publicKey, this.passwordResetForm.value.confirmPassword,publicKey.secret);
	
		const resetpasswordAPI = environment.userServiceUrl + '/api/user/v1/password/change';
		const eCred = {
			userName: this.userName,
			// oldPassword: btoa(this.passwordResetForm.value.oldPassword),
			// newPassword: btoa(this.passwordResetForm.value.newPassword),
			// confirmPassword: btoa(this.passwordResetForm.value.confirmPassword)
			oldPassword: oldPassword,
			newPassword: newPassword,
			confirmPassword: confirmPassword
	
		};
		this.apiService.post(resetpasswordAPI, eCred)
			.subscribe(res => {
				swal('Password updated Successfully');
				const cred = {
					userName: this.userName
				};
				this.storageStorage.removeAll();
				this.router.navigate(['/login']);
			});
	}
	showPassClick() {
		this.show = !this.show;
	}
	routeToHome() {
		// TODO:
	}
}
