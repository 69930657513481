import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'projects/services/src/app/API/login/login.service';
import { RegistrationService } from 'projects/services/src/app/API/registration.service';
import { CurrentUserService } from 'projects/services/src/app/current-user.service';
import { RoutingService } from 'projects/services/src/app/routing.service';
import { StorageService } from 'projects/services/src/app/storage.service';
import { ShareDataService } from 'projects/shared/src/app/shared/share-data.service';
declare var toastr: any;

@Component({
  selector: 'app-mobile-and-email-verification',
  templateUrl: './mobile-and-email-verification.component.html',
  styleUrls: ['./mobile-and-email-verification.component.css']
})
export class MobileAndEmailVerificationComponent {
  userCredentials: { userName: string, password: string } = { userName: '', password: '' };
  loginForm: FormGroup;
  otpForm: FormGroup;
  contactDetailForm: FormGroup;
  verifyUpdateDetails: boolean = false;
  currentUserDetails: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private login: LoginService,
    private storageService: StorageService,
    private sharedService: ShareDataService,
    private routingService: RoutingService,
    private currentUserService: CurrentUserService,
    private registrationService: RegistrationService,
  ) {

    this.otpForm = this.formBuilder.group({
      'emailOTP': ['', Validators.compose([Validators.pattern('[0-9]{6}'), Validators.required])],
      'mobileOTP': ['', Validators.compose([Validators.pattern('[0-9]{6}'), Validators.required])]
    });
    this.contactDetailForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required, Validators.email])],
      'phone': ['', Validators.compose([Validators.required,
      Validators.pattern('^[4-9][0-9]{9}$')])]
    });

  }
  ngOnInit(): void {
    this.getCurrentUserDetails();
  }

  getotp() {
    if (this.contactDetailForm.valid) {

      this.sendOtpToVerify(Number(this.contactDetailForm.get("phone").value), this.contactDetailForm.get("email").value, true);
    } else {
      toastr.warning("Please enter proper contact details!")
    }
  }

  change() {
    this.verifyUpdateDetails = !this.verifyUpdateDetails;
  }

  getCurrentUserDetails() {
    this.currentUserService.getCurrentUser().subscribe(user => {
      if (user) {
        this.currentUserDetails = user;
        if (user && user.email !== "" && user.phone) {
          this.contactDetailForm.patchValue({
            email: user.email,
            phone: user.phone
          });
          this.sendOtpToVerify(user.phone, user.email, false);
        }
      } else {
        this.router.navigate(['/login']);
        this.logout();
      }
    })
  }

  logout() {
    this.router.navigate(['/']);
    this.storageService.removeAll();
    this.currentUserService.SetCurrentUserOnLogout();
  }

  sendOtpToVerify(mobileNumber: number, emailId: string, isLoggedInFirstTime?:boolean) {
    this.registrationService
      .generateOTP(
        this.currentUserDetails.role,
        Number(mobileNumber),
        emailId,
        "",
        isLoggedInFirstTime,
        this.currentUserDetails.userName
      )
      .subscribe(
        (res) => {
          this.verifyUpdateDetails = true;
          // sessionStorage.setItem("isVerified", JSON.stringify(true));
          toastr.success("OTP has been sent successfully");
          // this.router.navigate(['nsdcadmin']);
        },
        (err) => {
          toastr.error("error in generating otp");
          // sessionStorage.setItem("isVerified", JSON.stringify(false));
        }
      );
  }

  doLogin() {
    let source;
    const OTPs = {
      phone: Number(this.otpForm.get('mobileOTP').value),
      email: Number(this.otpForm.get('emailOTP').value),
    };

    const userAccountData = {
      role: this.currentUserDetails.role,
      email: this.contactDetailForm.get("email").value,
      firstName: this.currentUserDetails.firstName,
      phone: {
        mobile: Number(this.contactDetailForm.get("phone").value),
        // landline: this.currentUserDetails.phone.landline,
      },
      userName:this.currentUserDetails.userName
    };
    this.registrationService.verifyOTP(OTPs, userAccountData, source)
      .subscribe(res => {
        sessionStorage.setItem("isVerified", JSON.stringify(true));
        toastr.success("OTP verified");
        this.router.navigate(['nsdcadmin']);
      },
        err => {
          if (err.error) {
            toastr.error("Error while verifying the OTP");
          }
        }
      );
  }
}
