import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormErrorControlRoutingModule } from './form-error-control-routing.module';
import { FormControlMessageComponent } from './form-control-message/form-control-message.component';
import { TransLateModule } from 'projects/common/src/app/translation/translte.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
	imports: [
		CommonModule,
		FormErrorControlRoutingModule,
		TransLateModule,
    FormsModule,
    ReactiveFormsModule,
	],
	declarations: [FormControlMessageComponent],
	exports: [FormControlMessageComponent, FormsModule, ReactiveFormsModule, TransLateModule]
})
export class FormErrorControlModule { }
