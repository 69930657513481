import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class RegistrationService {

	constructor(private apiService: ApiService) { }
	generateOTP(role: string, phoneNumber: number, email: string, userStep?, firstTime?,userName?, forJss?) {
		let generateOTPUrl = `${environment.userServiceUrl}/api/user/reg/v1/generateOTP/${role}?scenario=${userStep}`;
		if(role === 'Ministry Admin'){
			generateOTPUrl += `&isLoggedInFirstTime=${firstTime}`;
			return this.apiService.post(generateOTPUrl, {
				phoneNumber,
				email,
				userName
			});
		}
		if(forJss){
			return this.apiService.post(generateOTPUrl, {
				phoneNumber,
				email,
				"scheme": "Jan Shikshan Sansthan"
			});
		}
		return this.apiService.post(generateOTPUrl, {
			phoneNumber,
			email
		});
	}
	generateOTPForTC(role: string, phoneNumber: number, email: string) {
		const generateOTPUrl = `${environment.userServiceUrl}/api/user/reg/v1/generateOTP/${role}?scenario=otp`;
		return this.apiService.post(generateOTPUrl, {
			phoneNumber,
			email
		});
	}

	verifyOTP(OTPs, userData, source?) {
		let URL;
		switch (userData.role) {
			case 'Training Partner':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Training Centre':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Candidate':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Centre Inspector':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Desktop Assessor':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Quality Control':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Best In Class Employer':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Centre Inspector':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Desktop Assessor':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Quality Control':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Trainer':
				URL = `${environment.userServiceUrl}/api/user/v1/${userData.role.toString().toLowerCase()}/${OTPs.phone}/${OTPs.email}?source=${source}`;
				break;
			case 'Centre Inspector':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Desktop Assessor':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Quality Control':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			case 'Assessor':
				URL = `${environment.userServiceUrl}/api/user/v1/${userData.role.toString().toLowerCase()}/${OTPs.phone}/${OTPs.email}?source=${source}`;
				// URL = `${environment.userServiceUrl}/api/user/v1/${userData.role.toString().toLowerCase()}/${OTPs.phone}/${OTPs.email}`;
				break;
			case 'Proctor':
				URL = `${environment.userServiceUrl}/api/user/v1/${userData.role.toString().toLowerCase()}/${OTPs.phone}/${OTPs.email}`;
				break;
			case 'awardingBody':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				// userData.role = "SSC";
				userData.type = "awardingBody";
			case 'SSC':
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
				break;
			default:
				URL = `${environment.userServiceUrl}/api/user/v1/register/${userData.role}/phoneotp/${OTPs.phone}/emailotp/${OTPs.email}`;
		}
		return this.apiService.post(URL, userData);
	}

	validateForSpocChange(credential) {
		const API = environment.userServiceUrl + '/api/user/validate';
		return this.apiService.post(API, credential);
	}
	generateOTPForSPOCChange(role, body) {
		const API = environment.userServiceUrl + '/api/user/reg/v1/generateOTP/' + role + '?scenario=spoc';
		return this.apiService.post(API, body);
	}
	changeSPOC(body) {
		const API = environment.userServiceUrl + '/api/user/v1/tp/verifySpocChange';
		return this.apiService.post(API, body);
	}
	tcSpocChange(body) {
		const API = environment.userServiceUrl + '/api/user/v1/tcspoc';
		return this.apiService.put(API, body);
	}
	generateOTPForForgotPassword(username, ignoreServerResponseError?: boolean) {
		let generateOTPAPI = environment.userServiceUrl + '/api/user/v1/ForgotPassword/' + username;
		if (ignoreServerResponseError) {
			generateOTPAPI = generateOTPAPI.concat(`?ignoreServerResponseError=${ignoreServerResponseError}`);
		}
		return this.apiService.post(generateOTPAPI, {});
	}

	generateOTPForForTrainerAddBatch(username, ignoreServerResponseError?: boolean) {
		let generateOTPAPI = environment.userServiceUrl + '/api/user/v1/ForgotPassword/' + username;
		if (ignoreServerResponseError) {
			generateOTPAPI = generateOTPAPI.concat(`?ignoreServerResponseError=${ignoreServerResponseError}`);
		}
		return this.apiService.post(generateOTPAPI, {});
	}

	setForgotPassword(passwordDetails) {
		const setPasswordAPI = environment.userServiceUrl + '/api/user/v1/ChangePassword';
		return this.apiService.post(setPasswordAPI, passwordDetails);
	}
	reGenerateOTP(body, role, userStep) {
		const regenerateOTPAPI = environment.userServiceUrl + `/api/user/reg/v1/generateOTP/${role}?scenario=${userStep}`;
		return this.apiService.post(regenerateOTPAPI, body);
	}

	verifyOTPForRegistration(userData, ignoreServerResponseError?: boolean) {
		let verifyOTP;
		if (ignoreServerResponseError) {
			verifyOTP = `${environment.userServiceUrl}/api/user/v1/verifyForgotPasswordOTP?ignoreServerResponseError=${ignoreServerResponseError}`;
		} else {
			verifyOTP = `${environment.userServiceUrl}/api/user/v1/verifyForgotPasswordOTP`;
		}
		return this.apiService.post(verifyOTP, userData);
	}

	verifyOTPForBatchTrainerAdd(userData, ignoreServerResponseError?: boolean) {
		let verifyOTP;
		if (ignoreServerResponseError) {
			verifyOTP = `${environment.userServiceUrl}/api/user/v1/verifyForgotPasswordOTP?ignoreServerResponseError=${ignoreServerResponseError}`;
		} else {
			verifyOTP = `${environment.userServiceUrl}/api/user/v1/verifyForgotPasswordOTP`;
		}
		return this.apiService.post(verifyOTP, userData);
	}

	verifyOTPTrainer(OTPs, trainerData) {
		const payload = {
			'trainer_id': trainerData.trainer_id,
			'phone_otp': OTPs.phone,
			'email_otp': OTPs.email,
			'jobrole_serialnumber': trainerData.jobrole_serialnumber,
			'oldTrainer_id': trainerData.oldtrainer_id
		}
		// ${trainerData.trainer_id}/phone_otp/${OTPs.phone}/email_otp/${OTPs.email}/jobrole_serialnumber/${trainerData.jobrole_serialnumber}
		const URL = `${environment.userServiceUrl}/api/user/v1/changetrainer/verifyotp`;
		return this.apiService.post(URL, payload);
	}
	verifyOtpforAddTrainer(OTPs, trainerData) {

		const payload = {
			'trainerid': trainerData.trainerid,
			'phone_otp': OTPs.phone,
			'email_otp': OTPs.email,
		}
		// ${trainerData.trainer_id}/phone_otp/${OTPs.phone}/email_otp/${OTPs.email}/jobrole_serialnumber/${trainerData.jobrole_serialnumber}
		const URL = `${environment.userServiceUrl}/api/user/v1/verifyotpforaddtrainer`;
		return this.apiService.post(URL, payload);
	}

	validateCaptcha(captcha) {
		const url = `${environment.userServiceUrl}/api/user/v1/api/recaptha/${captcha}`;
		return this.apiService.get(url);
	}
	getUserId(postData) {
		const generateOTPUrl = `${environment.userServiceUrl}/api/user/v1/getUserId `;
		return this.apiService.post(generateOTPUrl, postData);
	}
	retriveId(postData) {
		const generateOTPUrl = `${environment.userServiceUrl}/api/user/v1/passwordRecovery/raiseRequest`;
		return this.apiService.post(generateOTPUrl, postData);
	}


	generateOTPForNonAATc(role: string, phoneNumber: number, email: string, processType: string, userStep?) {
		const generateOTPUrl = `${environment.userServiceUrl}/api/user/reg/v1/generateOTP/${role}?processType=${processType}&scenario=${userStep}`;
		return this.apiService.post(generateOTPUrl, {
			phoneNumber,
			email
		});
	}
	generateOTPtoUpdateUserInfo(postData) {
		const generateOTPUrl = `${environment.smartServiceURL}/api/smart/v1/updateemailorphone/generateotp`;
		return this.apiService.post(generateOTPUrl, postData);
	}
	verifyOTPtoUpdateUserInfo(postData) {
		const generateOTPUrl = `${environment.smartServiceURL}/api/smart/v1/updateemailorphone/verifyotpforupdateemailandphone`;
		return this.apiService.post(generateOTPUrl, postData);
	}
	getRecoveryRequests(query) {
		const url = `${environment.userServiceUrl}/api/user/v1/GetPRRequest?${query}`;
		return this.apiService.get(url);
	}
	takeActionOnPRRequests(postData) {
		const generateOTPUrl = `${environment.smartServiceURL}/api/user/v1/passwordRecovery/takeAction`;
		return this.apiService.post(generateOTPUrl, postData);
	}
	generateOTPForCandidate(params) {
		const generateOTPUrl = `${environment.userServiceUrl}/api/user/v1/generate-otp`;
		return this.apiService.post(generateOTPUrl, params);
	}
	verifyOtpForCandidate(phoneNumber, mobile_otp?) {
		let payload = {
			phoneNumber: String(phoneNumber),
			mobile_otp: String(mobile_otp) || ''
		}
		const generateOTPUrl = `${environment.userServiceUrl}/api/user/v1/verify-otp`;
		return this.apiService.post(generateOTPUrl, payload);
	}
	updateUserContactInfo(postData) {
		const updateContactDetailsUrl = `${environment.userServiceUrl}/api/user/v1/authentication `;
		return this.apiService.post(updateContactDetailsUrl, postData);
	}

	verifyOTPForTPTCSpoc(userData, ignoreServerResponseError?: boolean) {
		let verifyOTP = `${environment.userServiceUrl}/api/user/v1/verifyTPTCSpocOTP`;

		return this.apiService.post(verifyOTP, userData);
	}

	// new nipun mohua changes for final assessment batch creation
	updateCandidateMobileNumber(batchId:any, canId:any, data:any) {
		let url = `${environment.userServiceUrl}/v1/candidates/update/candidateRecord/${batchId}/${canId}`;

		return this.apiService.put(url, data);
	}
}
