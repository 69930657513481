<div class="m-grid m-grid--hor m-grid--root m-page" *ngIf="pageLoad">
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
      id="m_login">
      <div class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside" style="width: 100%;">
        <div class="text-center"> <a> 
          <img src="../../../assets/images/skill-india-logo-prod.png">
        </a></div>
        <div class="" style="
        font-size: 32px; text-align: center; margin-top: 78px;
    "> This page is permanently moved to a new location. <br>Please click here to access: <a href="https://www.skillindiadigital.gov.in/home" target="_blank">Login</a></div>
      </div>
    </div>
  </div>