import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { ApiService } from './API/api.service';
import {
	CanLoad, Route, Router, ActivatedRouteSnapshot, CanActivate,
	RouterStateSnapshot, CanDeactivate
} from '@angular/router';
import { CurrentUserService } from './current-user.service';
import { map, Observable, take } from'rxjs';
import { USER_ROLES } from 'src/app/scheme-constants/scheme-constants';
import { ThankYouComponent } from 'projects/tc-registration-form/src/app/thank-you/thank-you.component';


declare const swal: any;
@Injectable({ providedIn: 'root' })
export class RouteGuardService implements CanLoad, CanActivate, CanDeactivate<ThankYouComponent> {
	constructor(
		private apiService: ApiService,
		private router: Router,
		private storageService: StorageService,
		private currentUserService: CurrentUserService
	) { }


	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		// const currentUser = this.currentUserService.getCurrentUser();
		const token = this.storageService.getObject('access_token');
		if (token) {
			return true;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}

	canLoad(route: Route): Observable<boolean> | boolean {

		if (this.storageService.getObject('access_token')) {
			// TO DO need to change logic of getting loggedIn User Details
			let allowedRole;
			let allowMultipleRoles = [];
			let mobileAndEmailVerified = JSON.parse(sessionStorage.getItem('isVerified'));
			if (route.data) {
				allowedRole = route.data.allowedActors[0];
				allowMultipleRoles = route.data.allowedActors;
			} else {
				const decodedUserDetails = this.currentUserService.decodeToken();
				allowedRole = decodedUserDetails.role;
			}
			const currentUserDetails = this.currentUserService.getCurrentUserLocal().value;
			if (currentUserDetails) {
				const idx = allowMultipleRoles.findIndex(x => x === currentUserDetails.role);
				if (idx !== -1) {
					if(currentUserDetails.role === 'Ministry Admin' && !mobileAndEmailVerified){
						this.router.navigate(['unauthorized']);
						return false;
					}
					return true;
				} else if (currentUserDetails.role === allowedRole) {
					return true;
				} else if (currentUserDetails.role === USER_ROLES.TARGET_PMU ||
					currentUserDetails.role === USER_ROLES.SP_PMU ||
					currentUserDetails.role === USER_ROLES.RPL_PMU ||
					currentUserDetails.role === USER_ROLES.PMKVY_PMU) {
					return true;
				} else {
					this.router.navigate(['unauthorized']);
					return false;
				}
			} else {
				return this.currentUserService.getAdditionalInfomartion().pipe(take(1),map(
					loggedInUser => {
						console.log(loggedInUser);
						console.log(allowedRole);
						const idx = allowMultipleRoles.findIndex(x => x === loggedInUser.role);
						this.currentUserService.setCurrentUser(loggedInUser);
						if (idx !== -1) {
							if(loggedInUser.role === 'Ministry Admin' && !mobileAndEmailVerified){
								this.router.navigate(['unauthorized']);
								return false;
							}
							return true;
						} else if (loggedInUser.role === allowedRole) {
							return true;
						} else if (loggedInUser.role === USER_ROLES.TARGET_PMU ||
							loggedInUser.role === USER_ROLES.SP_PMU ||
							loggedInUser.role === USER_ROLES.RPL_PMU) {
							return true;
						} else {
							this.router.navigate(['unauthorized']);
							return false;
						}

					}
				));
			}
		} else {
			this.router.navigate(['unauthorized']);
			return false;
		}


		// 	if (route.data.allowedActors && route.data.allowedActors.length) {
		// 		console.log('inside metof');
		// 		const userAuthURL = environment.authServiceURL + '/auth';
		// 		this.apiService.get(userAuthURL, this.storageService.getObject('access_token'))
		// 			.subscribe(userDetails => {
		// 				console.log(userDetails.role.replace(/ +/g, '').toLowerCase());

		// 				console.log(route.data.allowedActors[0].replace(/ +/g, '').toLowerCase());

		// 				if (userDetails.role.replace(/ +/g, '').toLowerCase() === (route.data.allowedActors[0].replace(/ +/g, '').toLowerCase())) {
		// 					console.log('herre');
		// 					return true;
		// 				} else {
		// 					console.log('false');

		// 					this.router.navigate(['unauthorized']);
		// 					return false;
		// 				}
		// 			});
		// 	} else {
		// 		console.log('outside');

		// 		return true;
		// 	}
		// } else {
		// 	this.router.navigate(['/login']);
		// 	return false;
		// }

	}
	canDeactivate(component: ThankYouComponent, currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot): Observable<boolean> | boolean {
	 	console.log(currentState)
	// 	console.log(component.userName)
		if (currentState.url === '/tc-registration/thank-you' || currentState.url === '/inprocesstc/tc-update/thank-you') {
			swal(``)
			swal({
				title: 'Review started on your application, You can not edit/update',
				text: '',
				type: 'info',
				showCancelButton: false,
				cancelButtonText: 'Cancel',
				cancelButtonColor: '#d33',
			}).then((action: any) => {
				if (action.value) {
					this.router.navigate(['/']);
				}
			})
			return true
		} else {
			return false
		}
	}
}
