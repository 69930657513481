export const SCHEME_DAYS_COUNT = Object.freeze({
	priorAssessmentDateChangeReqFromCurrentAssessmentDate: 2,
	priorAssessorChangeReqFromCurrentAssessmentDate: 2,
	priorAssessmentAgencyReqFromCurrentAssessmentDate: 2,
	untilCanCancelBatch: 2,
	withinAssessmentDateShouldBeSelected: 2,
	noOfDaysToChangeAssessmentDateForNonPMKVY: 45,
	withinAssessmentDateShouldBeSelectedAs15: 15,
	// withinAssessmentDateShouldBeSelectedForSTTSP: 80,
	withinAssessmentDateShouldBeSelectedForSTTSP: 480,

	// withinOfflineAssessmentTakenPlace: 365
	withinOfflineAssessmentTakenPlace: 480,
});
