import { ValidationService } from 'projects/services/src/app/validation.service';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'projects/services/src/app/API/api.service';
import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'projects/services/src/app/API/registration.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ShareDataService } from 'projects/shared/src/app/shared/share-data.service';
import { ERROR_RESPONSE_MESSAGE } from 'projects/training-partner/src/app/training-partner/constants/string.constant';
import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { LoginService } from 'projects/services/src/app/API/login/login.service';

declare var swal: any;
declare var toastr: any;

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {


	generatedTPId: string; // need to remove once start sending Username  to email
	userDetailsForm: FormGroup;
	forgetUserIdForm: FormGroup;
	otpForm: FormGroup;
	NoLonger: FormGroup;
	passwordResetForm: FormGroup;
	show: boolean;
	showform = 'generateOTP';
	OTPs;
	userRole: any;
	userStep: any;
	hashedEmail;
	hasedMobile;
	userAccount: {
		firstName: string, email: string, phone: { mobile: number, landline: number },
		role: string, password: string, emailOTP: string, mobileOTP: string
	} = { firstName: '', email: '', phone: { mobile: null, landline: null }, role: '', password: '', emailOTP: '', mobileOTP: '' };

	reCaptcha: any[];
	captchaInvalid: Boolean = true;
	constructor(
		private apiService: ApiService,
		private router: Router,
		private formBuilder: FormBuilder,
		private sharedService: ShareDataService,
		private registrationService: RegistrationService,
		private loginService: LoginService,

	) {
		this.userDetailsForm = this.formBuilder.group({
			'userName': ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_@#-.]*$')]],
		});
		this.forgetUserIdForm = this.formBuilder.group({
			'type': ['', [Validators.required]],
			'email': ['', [Validators.required, ValidationService.emailV, Validators.maxLength(200)]],
			'phone': ['', [Validators.required, Validators.pattern(/^[4-9][0-9]{9}$/), Validators.maxLength(10), Validators.minLength(10)]],
			'changeStatusChecked': [false, [Validators.required, Validators.pattern('true')]],
		});

		this.NoLonger = this.formBuilder.group({
			'requestName': ['', [Validators.required, ValidationService.nameValidator, Validators.maxLength(200), Validators.pattern('^[a-zA-Z .\']*$')]],
			'email': ['', [Validators.required, ValidationService.emailV, Validators.maxLength(200)]],
			'phone': ['', [Validators.required, Validators.pattern(/^[4-9][0-9]{9}$/), Validators.maxLength(10), Validators.minLength(10)]]
		})

		this.otpForm = this.formBuilder.group({
			'emailOTP': ['', [Validators.pattern('[0-9]{6}')]],
			'mobileOTP': ['', [Validators.pattern('[0-9]{6}')]]
		});
		this.passwordResetForm = this.formBuilder.group({
			'newPassword': ['', Validators.compose([Validators.required, Validators.minLength(8),
			Validators.maxLength(16), ValidationService.passwordValidator])],
			'confirmPassword': [null, Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(16)])],
		},
			{
				validator: ValidationService.MatchPassword
			});
		this.show = false;

	}

	ngOnInit() {

	}
	// toggle password; hide;
	showPassClick() {
		this.show = !this.show;
	}



	// To generate OTPs,  Sent to Email and mobile
	generateOTPs() {
		// const creds = this.userDetailsForm.get('userName').value.toUpperCase();
		const creds = this.userDetailsForm.get('userName').value;
		this.registrationService.generateOTPForForgotPassword(creds, true)
			.subscribe(
				res => {
					this.showform = 'otp';
					this.hashedEmail = res.email || '';
					this.hasedMobile = res.phone || ''
				}, err => {
					if (err && err.error && err.error.message) {
						toastr.error(err.error.message);
						// toastr.error(atob(err.error.message));
						// this.showform = 'generateOTP';
					}
				});
	}

	verifyOTP() {
		const setPasswordDetails = {
			phoneOtp: this.otpForm.get('mobileOTP').value,
			emailOtp: this.otpForm.get('emailOTP').value,
			userID: this.userDetailsForm.get('userName').value,
		};

		this.registrationService.verifyOTPForRegistration(setPasswordDetails, true).subscribe(res => {
			if (res) {
				swal('OTP is verified Successfully');
				this.showform = 'password';
			}
		}, err => {
			if (err && err.error && err.error.message) {
				toastr.error(atob(err.error.message));
				if (atob(err.error.message).trim() === ERROR_RESPONSE_MESSAGE.INVALID_OTP_5_TIMES) {
					this.showform = 'generateOTP';
				}
			}
		})
	}

	retriveIdpaasword() {
		if (this.NoLonger.invalid) {
			Helpers.markFormGroupTouched(this.NoLonger);
			return;
		}
		let payload = this.NoLonger.value;
		payload['userName'] = this.userDetailsForm.get('userName').value
		this.registrationService.retriveId(payload)
			.subscribe(
				res => {
					swal('Your request is submitted successfully')
					this.goToSID();
				},
				err => {
					console.log('error in generating otp');
				});
	}

	creds(creds: any) {
		throw new Error('Method not implemented.');
	}

	async setPassword() {
		const publicKey = await this.loginService.getPublicKey().toPromise();
		const password = await this.loginService.encryptPassword(publicKey.publicKey, this.passwordResetForm.value.newPassword,publicKey.secret);
		const confirmPassword = await this.loginService.encryptPassword(publicKey.publicKey, this.passwordResetForm.value.confirmPassword,publicKey.secret);
		
		const setPasswordDetails = {
			phoneOtp: this.otpForm.get('mobileOTP').value,
			emailOtp: this.otpForm.get('emailOTP').value,
			userID: this.userDetailsForm.get('userName').value,
			// password: btoa(this.passwordResetForm.value.newPassword),
			// confirmPassword: btoa(this.passwordResetForm.value.confirmPassword),
			password: password,
			confirmPassword: confirmPassword,
		};
		if (this.captchaInvalid) {
			this.captchaReset();
			toastr.error('please check the re-captcha');
			return;
		}
		this.registrationService.setForgotPassword(setPasswordDetails)
			.subscribe(res => {
				swal('Password is updated Successfully');

				this.goToSID();
			}, err => {
				toastr.error('OTP is incorrect, Please enter valid OTP');
				this.captchaReset();
			});
	}

	get validOTP() {
		if ((!!this.otpForm.get('mobileOTP').value || !!this.otpForm.get('emailOTP').value)) {
			if (!!this.otpForm.get('mobileOTP').value) {
				return !!this.otpForm.get('mobileOTP').valid;
			} else {
				return !!this.otpForm.get('emailOTP').valid;
			}
		}
	}

	// captcha
	resolveRecaptcha(captchaResponse: any[]) {
		this.captchaInvalid = false;
		this.reCaptcha = captchaResponse;
		console.log(this.reCaptcha);
		if (this.reCaptcha != null) {
			this.registrationService.validateCaptcha(this.reCaptcha).subscribe(res => {
				console.log(res);
				this.captchaInvalid = false;
			}, err => {
				this.captchaReset();
				toastr.err('Please try again')
			})
		} else if (this.reCaptcha === null) {
			this.captchaReset();
		}
	}

	captchaReset() {
		grecaptcha.reset();
		this.reCaptcha = null;
		this.captchaInvalid = true;
	}

	validOnlyOne(type) {
		if (type == 'email' && !this.forgetUserIdForm.get('email').value && !this.forgetUserIdForm.get('phone').value ) {
			this.forgetUserIdForm.get('email').setValidators([Validators.required, ValidationService.emailV, Validators.maxLength(200)]);
			this.forgetUserIdForm.get('email').updateValueAndValidity();
			return;
		} else {
			this.forgetUserIdForm.get('phone').setValidators([]);
			this.forgetUserIdForm.get('phone').updateValueAndValidity();
		}

		if (type == 'phone' && !this.forgetUserIdForm.get('phone').value && !this.forgetUserIdForm.get('email').value) {
			this.forgetUserIdForm.get('phone').setValidators([Validators.required, Validators.pattern(/^[4-9][0-9]{9}$/), Validators.maxLength(10), Validators.minLength(10)]);
			this.forgetUserIdForm.get('phone').updateValueAndValidity();
		} else {
			this.forgetUserIdForm.get('email').setValidators([]);
			this.forgetUserIdForm.get('email').updateValueAndValidity();
		}

		// if (type == 'email' && !this.forgetUserIdForm.get('email').value) {
		// 	this.forgetUserIdForm.get('email').setValidators([Validators.required, ValidationService.emailV, Validators.maxLength(200)]);
		// 	this.forgetUserIdForm.get('email').updateValueAndValidity();
		// 	return;
		// }
	}

	toGetUserId() {
		if (this.forgetUserIdForm.invalid) {
			Helpers.markFormGroupTouched(this.forgetUserIdForm);
			return;
		}
		// this.forgetUserIdForm.get('phone').setValue(Number(this.forgetUserIdForm.get('phone').value))
		let payload = this.forgetUserIdForm.value;
		payload['phone'] = Number(this.forgetUserIdForm.get('phone').value);
		this.registrationService.getUserId(payload).subscribe(res => {
			this.forgetUserIdForm.reset();
			swal('User ID has been sent to your registered email address/ mobile number. Please check the entered email address/ mobile number');
			this.goToSID();
		})
	}

	reGenerateOTP() {
		// TO do
		const creds = this.userDetailsForm.get('userName').value;
		this.registrationService.generateOTPForForgotPassword(creds, true).subscribe(
			(res) => {
				this.showform = "otp";
				toastr.success("OTP has been sent successfully");
			},
			(err) => {
				console.log("error in generating otp");
			}
		);
	}
	goToSID() {
		window.open('https://www.skillindiadigital.gov.in/home', '_blank');
	}
}
