import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtHelperService, JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieModule } from 'ngx-cookie';
import { DirectRegistrationComponent } from 'projects/common/src/app/direct-registration/direct-registration.component';
import { DuplicateSpocChangeComponent } from 'projects/common/src/app/duplicate-spoc-change/duplicate-spoc-change.component';
import { ForgotPasswordComponent } from 'projects/common/src/app/forgot-password/forgot-password.component';
import { LoginComponent } from 'projects/common/src/app/login/login.component';
import { PasswordResetComponent } from 'projects/common/src/app/password-reset/password-reset.component';
import { TcViewDetailsComponent } from 'projects/common/src/app/tc-view-details/tc-view-details.component';
import { FormErrorControlModule } from 'projects/form-error-control/src/app/form-error-control/form-error-control.module';
import { InterceptorService } from 'projects/services/src/app/interceptor.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { SessionExpiredComponent } from 'projects/common/src/app/session-expired/session-expired.component';
import { PageNotFoundComponent } from 'projects/common/src/app/page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CbcmsdeLoginComponent } from 'projects/common/src/app/cbcmsde-login/cbcmsde-login.component';
import { FeedbackOfInspectorComponent } from 'projects/common/src/app/feedback-of-inspector/feedback-of-inspector.component';
import { MobileAndEmailVerificationComponent } from 'projects/common/src/app/mobile-and-email-verification/mobile-and-email-verification.component';
import { LoginTestComponent } from 'projects/common/src/app/login-test/login-test.component';
export function tokenGetterFunc() {
  return localStorage.getItem('access_token');
}
const JWT_Module_Options: JwtModuleOptions = {
  config: {
      tokenGetter: tokenGetterFunc,
      allowedDomains: ['localhost:4200'],
      disallowedRoutes: []
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginTestComponent,
    DirectRegistrationComponent,
    DuplicateSpocChangeComponent,
    PasswordResetComponent,
    ForgotPasswordComponent,
    TcViewDetailsComponent,
    SessionExpiredComponent,
    PageNotFoundComponent,
    CbcmsdeLoginComponent,
    FeedbackOfInspectorComponent,
    MobileAndEmailVerificationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot(),
    JwtModule.forRoot(JWT_Module_Options),
    FormErrorControlModule,
		RecaptchaModule,
    BrowserAnimationsModule
  ],
  providers: [
    TranslateService,
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
			provide: RECAPTCHA_SETTINGS,
			useValue: { siteKey: '6LeZQrQUAAAAAFwCkYXOKdEfVJ0i5vAck6Y3sRZW' } as RecaptchaSettings,
		},
		{
			provide: RECAPTCHA_LANGUAGE,
			useValue: 'en'
		}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
