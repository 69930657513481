export const schemeDisabilities = Object.freeze({
	disabilities: [
		{id: 1, name: 'Locomotor Disability'},
		{id: 2, name: 'Leprosy Cured Person'},
		{id: 3, name: 'Dwarfism'},
		{id: 4, name: 'Acid Attack Victims'},
		{id: 5, name: 'Blindness /Visual Impairment'},
		{id: 6, name: 'Low-vision (Visual Impairment)'},
		{id: 7, name: 'Deaf'},
		{id: 8, name: 'Hard of Hearing'},
		{id: 9, name: 'Speech and Language Disability'},
		{id: 10, name: 'Intellectual Disability /Mental Retardation'},
		{id: 11, name: 'Autism Spectrum Disorder'},
		{id: 12, name: 'Specific Learning Disabilities'},
		{id: 13, name: 'Mental Behavior- Mental Illness'},
		{id: 14, name: 'Haemophilia'},
		{id: 15, name: 'Thalassemia'},
		{id: 16, name: 'Deaf Blindness'},
		{id: 17, name: 'Cerebral Palsy'},
		{id: 18, name: 'Multiple Sclerosis'},
		{id: 19, name: 'Muscular Dystrophy'},
		{id: 20, name: 'Parkinson\'s Disease'},
	]
});

export const actors = Object.freeze({
	nsdcAdmin : 'NSDC Admin',
	BO: 'Business Owner',
	SO: 'Scheme Owner',
	SA: 'Scheme Admin',
	CNA: 'Common Norms Admin',
	ST: 'Standards Team',
	FS: 'Finance SPOC',
	CFO: 'CFO',
	LRT: 'LRT Team'
});

export const commonNormsStatus = Object.freeze({
		CnDraft: 'draft',
		CnSubmitted: 'submitted',
		CnRejected: 'rejected',
		CnApproved: 'approved'
});

export const schemeStatus = Object.freeze({
		schemeNew: 'new',
		schemeDraft: 'draft',
		schemeSubmitted: 'submitted',
		schemeApproved: 'accepted',
		schemeRejected: 'rejected',
		schemeRetired: 'retired'
})

export const scmSlot1 = Object.freeze({
		'creationDetails': {
		  'schemeName': 'abc',
		  'description': 'Cillum minima et non esse qui amet quisquam asperiores eius voluptate culpa',
		  'schemeIcon': 'Scheme Owner/SO_000001/logoURL/35db00ac-6741-4050-9173-6eb6e376faf9_Screenshot (2).png',
		  'requiredDocuments': 'Soluta porro cupidatat ducimus consequatur Deserunt adipisci id vero minima deleniti ipsum cillum commodi magna ipsa sed ipsam',
		  'prequisite': 'Ut officia velit modi voluptas minima suscipit cillum numquam exercitation ut excepteur fuga Voluptas esse et',
		  'religionsCatered': [
			{
			  'religionId': '2',
			  'religionName': 'Muslim'
			},
			{
			  'religionId': '3',
			  'religionName': 'Christian'
			},
			{
			  'religionId': '4',
			  'religionName': 'Atheist'
			},
			{
			  'religionId': '6',
			  'religionName': 'Sikh'
			},
			{
			  'religionId': '8',
			  'religionName': 'other'
			}
		  ],
		  'categoriesCatered': [
			{
			  'categoryId': '1',
			  'categoryName': 'General'
			}
		  ],
		  'gendersCatered': [
			{
			  'genderId': '1',
			  'genderName': 'Male'
			},
			{
			  'genderId': '2',
			  'genderName': 'Female'
			}
		  ],
		  'consideredDisabilities': [
			{
			  'disabilityId': '1',
			  'disabilityName': 'None'
			},
			{
			  'disabilityId': '3',
			  'disabilityName': 'Acid Attack Victims'
			},
			{
			  'disabilityId': '6',
			  'disabilityName': 'Deaf'
			},
			{
			  'disabilityId': '8',
			  'disabilityName': 'Dwarfism'
			},
			{
			  'disabilityId': '9',
			  'disabilityName': 'Haemophilia'
			},
			{
			  'disabilityId': '10',
			  'disabilityName': 'Hard of Hearing'
			},
			{
			  'disabilityId': '11',
			  'disabilityName': 'Intellectual Disability'
			},
			{
			  'disabilityId': '12',
			  'disabilityName': 'Leprosy Cured Person'
			},
			{
			  'disabilityId': '14',
			  'disabilityName': 'Low-vision'
			},
			{
			  'disabilityId': '17',
			  'disabilityName': 'Muscular Dystrophy'
			},
			{
			  'disabilityId': '18',
			  'disabilityName': 'Parkinson\'s Disease'
			},
			{
			  'disabilityId': '20',
			  'disabilityName': 'Specific Learning Disabilities'
			},
			{
			  'disabilityId': '21',
			  'disabilityName': 'Speech and Language Disability'
			},
			{
			  'disabilityId': '22',
			  'disabilityName': 'Thalassemia'
			}
		  ]
		},
		'trancheSetupDetails': {
		  'noOfTranches': 3
		}
})
