import { Injectable } from '@angular/core';

declare var mApp: any;
@Injectable({ providedIn: 'root' })
export class LoadingService {

	constructor() { }

	blockUI() {
		mApp.blockPage({
			overlayColor: '#000000',
			type: 'loader',
			state: 'success',
			message: 'Please wait...',
		});
	}

	blockModal() {
		mApp.block('.modal-content', {
			overlayColor: '#000000',
			type: 'loader',
			state: 'primary',
			message: 'Processing...'
		});
	}

	unblockuI() {
		mApp.unblockPage();
		mApp.unblock('.modal-content');
	}

}
