export const COMMON = Object.freeze({

});
export const ADDRESS_PROOF_TYPE = [
	'Bank Statement',
	'Electricity Bill(Not Older than 2 Months)',
	'GST Registration',
	'Incorporation Certificate',
	'Provident Fund Registration Certificate',
	'Registration Certificate',
	'Rent Agreement + Telephone/Electricity Bill',
	'Telephone Bill(BSNL/MTNL Only)',
	'MoU/Agreement'
]

export const ADDRESS_PROOF_TYPE_FEEBASED = [
	'Electricity Bill(Not Older than 2 Months)',
	'Rent Agreement + Telephone/Electricity Bill',
	'Telephone Bill(BSNL/MTNL Only)',
]

export const ADDRESS_PROOF_TYPE_SMART = [
	{ name: 'Rent Agreement + Telephone/Electricity Bill',value:'Rent Agreement + Telephone/Electricity Bill' },
	{ name: 'Electricity Bill(Not Older than 2 Months)',value:'Electricity Bill(Not Older than 2 Months)' },
	{ name: 'Telephone Bill(BSNL/MTNL Only)',value: 'Telephone Bill(BSNL/MTNL Only)'}
]

export const USER_IDENTITY_TYPE = [
	'Passport',
	'Voter Identity Card',
	'Driving Licence',
	'Aadhaar Letter/ Card',
	'PAN Card',
	'Any other Government issued ID proof'
]
export const STRING = Object.freeze({
	PDF: 'pdf',
	JPG: 'jpg',
	JPEG: 'jpeg',
	PNG: 'png',
	DOCX: 'docx',
	XLSX: 'xlsx',
	EMPTY: '',
	NA: 'N/A',
	BOOLEAN: 'boolean',
	COLON: ':',
	WHITE_SPACE: ' ',
	COMMA_SPACE: ', ',
	NULL: null,
	ZERO: '0',
	ON_CHANGE: 'onChange',
	ON_BLUR: 'onBlur',
	FUNCTION: 'function',
	STRING: 'string',
	NUMBER: 'number',
	OBJECT: 'object',
	DEFAULT_DATE: '0001-01-01T00:00:00Z',
	EMPTY_STRING: '',
	THREE_DOT: '...',
	FORWARD_SLASH: '/',
	UNDERSCORE: '_'
});
