import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { environment } from "src/environments/environment";
import { Helpers } from "projects/training-calendar/src/app/helpers";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { catchError, map, Observable, throwError } from "rxjs";
// import { constant } from 'async';

@Injectable({ providedIn: 'root' })
export class UserService {
	private httpClient: HttpClient;
	constructor(private apiService: ApiService, private http: HttpClient, handler: HttpBackend) { 
		this.httpClient = new HttpClient(handler);
	}

	getPublicKey(): Observable<any> {
		return this.httpClient.get<any>('http://localhost:8080/getPublicKey').pipe(
		  map(response => this.importPublicKey(response.publicKey))
		);
	  }
	
	  private async importPublicKey(pemKey: string): Promise<any> {
		const binaryDerString = window.atob(pemKey);
		const binaryDer = this.str2ab(binaryDerString);
	
		return window.crypto.subtle.importKey(
		  'spki',
		  binaryDer,
		  {
			name: 'RSA-OAEP',
			hash: 'SHA-256',
		  },
		  true,
		  ['encrypt']
		);
	  }
	
	  private str2ab(str: string): ArrayBuffer {
		const buf = new ArrayBuffer(str.length);
		const bufView = new Uint8Array(buf);
		for (let i = 0, strLen = str.length; i < strLen; i++) {
		  bufView[i] = str.charCodeAt(i);
		}
		return buf;
	  }
	
	  async encryptPassword(publicKey: CryptoKey, password: string): Promise<ArrayBuffer> {
		const encoder = new TextEncoder();
		const data = encoder.encode(password);
		return window.crypto.subtle.encrypt(
		  {
			name: 'RSA-OAEP',
		  },
		  publicKey,
		  data
		);
	  }
	Query;
	URI;
	static get USER_STEP() {
		return Object.freeze({
			LINK_TRAINER: "LinkTrainerToTP",
			LINK_ASSESSOR_TO_ASSESSMENT_AGENCY:
				"LinkAssessorToAssessmentAgency",
			LINK_PROCTOR_TO_ASSESSMENT_AGENCY: "LinkProctorToAssessmentAgency",
			LINK_ASSESSMENT_AGENCY_TO_SSC: "LinkAssessmentAgencyToSSC",
		});
	}

	delinkProctor(approveData: any) {
		const delink = {
			reason: approveData.reason,
			assessmentAgencyID: approveData.assessmentAgencyID,
			proctorID: approveData.proctorID,
			delinkType: approveData.delinkType,
		};
		const delinkPtr = `${environment.userServiceUrl}/api/user/v1/assessmentAgency/proctor/de-link`;
		console.log(delinkPtr);
		return this.apiService.put(delinkPtr, delink);
	}

	proctorExtension(approveData: any, AAName: any) {
		const delink = {
			assessmentAgencyUserName: AAName.assessmentAgencyUserName,
			assessmentAgnecyName: AAName.assessmentAgnecyName,
			validity: approveData.duration,
			isEmpanelmentDurationExtended: AAName.isEmpanelmentDurationExtended,
		};
		const delinkPtr = `${environment.userServiceUrl}/api/user/v1/proctor/link/proctorto/assessmentagency/duration/extend/${approveData.userName}`;
		console.log(delinkPtr);
		return this.apiService.put(delinkPtr, delink);
	}

	getAllUsers(roles, pageNo, itemsPerPage, searchUser) {
		let getAllUsersUrl = `${environment.userServiceUrl}/api/user/v1/getAllUsersBasedOnRole`;
		for (let i = 0; i < roles.length; i++) {
			if (i === 0) {
				getAllUsersUrl += "?";
			} else {
				getAllUsersUrl += "&";
			}
			getAllUsersUrl += "roles=" + roles[i];
		}
		getAllUsersUrl +=
			"&pageNo=" +
			pageNo +
			"&itemsPerPage=" +
			itemsPerPage +
			"&searchUser=";
		if (searchUser !== undefined) {
			getAllUsersUrl += searchUser;
		}
		return this.apiService.get(getAllUsersUrl);
	}

	verifyOTP(userId, otpDetails) {
		const queryParams = Helpers.serialize(otpDetails);
		const URL =
			environment.userServiceUrl +
			`/api/user/v1/VerifyUserOTP/${userId}?${queryParams}`;
		return this.apiService.post(URL, otpDetails);
	}

	generateOTP(userName, userStep?: any, batchId?: any) {
		let URL = userStep
			? `${environment.userServiceUrl}/api/user/v2/GenerateUserOTP/${userName}/${userStep}`
			: environment.userServiceUrl +
			"/api/user/v1/GenerateUserOTP/" +
			userName;
		if (batchId) {
			URL = URL.concat(`?batchId=${batchId}`);
		}
		return this.apiService.post(URL);
	}

	generateOTPtoExtendDuration(role) {
		const URL = `${environment.userServiceUrl}/api/user/v2/GenerateUserOTP/${role}/PmkvyExtendAssessmentAgencyToSSCLinkage`;
		return this.apiService.post(URL);
	}

	verifyOTPForUpdate(
		phone_otp,
		email_otp,
		userStep,
		role,
		email,
		mobileNumber
	) {
		const URL =
			environment.userServiceUrl +
			`/api/user/v1/VerifyUserOTPForEmailOrMobileUpdate?userstep=${userStep}&role=${role}`;
		return this.apiService.post(URL, {
			phone_otp,
			email_otp,
			email,
			mobileNumber,
		});
	}

	removeUserAccess(userDetails) {
		const url = `${environment.userServiceUrl}/api/user/v1/access`;
		return this.apiService.put(url, userDetails);
	}

	updateUserFirstName(userDetails) {
		const url = `${environment.userServiceUrl}/api/user/v1/firstname`;
		return this.apiService.put(url, userDetails);
	}

	updateCandidateProfile(userID, userDetails, isModified?) {
		if (isModified) {
			const URL = `${environment.userServiceUrl}/v1/candidates/${userID}?updateUniqueDetail=${isModified}`;
			return this.apiService.put(URL, userDetails);
		} else {
			const URL = `${environment.userServiceUrl}/v1/candidates/${userID}`;
			return this.apiService.put(URL, userDetails);
		}
	}

	paymentButtonDisablefor12Hr(reftype, userName) {
		const updateAPI =
			environment.userServiceUrl +
			"/api/user/v1/paymentdetails/" +
			userName +
			"/check";
		return this.apiService.get(updateAPI);
	}

	pmkvy40Payment() {
		const updateAPI =
			environment.userServiceUrl +
			"/v1/candidates/pmkvy/payment";
		return this.apiService.get(updateAPI);
	}

	getMaritialStatus() {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/pmkvy/dropdownvalues/maritalstatus`;
		return this.apiService.get(URL);
	}

	getJobRole(query) {
		const URL =
			environment.userServiceUrl +
			"/api/user/v1/trainingcentre/jobdetails/serial?" +
			query;
		return this.apiService.get(URL);
	}
	submitSSCReviewforAssessment(body) {
		const URL =
			environment.userServiceUrl +
			"/api/user/v1/trainingcentre/ssc/takeAction";
		return this.apiService.post(URL, body);
	}
	addCertificate(data, type) {
		console.log(data, type);
		if (type === "create") {
			const url =
				environment.userServiceUrl +
				`/api/user/v1/trainer/Certification`;
			return this.apiService.post(url, data);
		} else if (type === "edit") {
			const url =
				environment.userServiceUrl +
				`/api/user/v1/trainer/Certification`;
			return this.apiService.put(url, data);
		}
	}

	getTrainingCentreList(data) {
		const scheme = "SchemeBased";
		const param = "userName,trainingCentreName";
		const url = `${environment.userServiceUrl}/api/user/v1/autosuggest/trainingcentres?query=trainingPartner.userName:${data.userName},trainingCentreName:${data.tcName}&Scheme=${scheme}&SchemeId=${data.selectedSchemeId}&pageNo=${data.pageNo}&limit=${data.itemsPerPage}&param=${param}`;
		return this.apiService.get(url);
	}

	getUserDetails(userName) {
		const url = `${environment.userServiceUrl}/api/user/v1/getDetails/${userName}`;
		return this.apiService.get(url);
	}

	changeUserPassword(userName) {
		const url = `${environment.userServiceUrl}/api/user/v1/ChangeUserPassword/${userName}`;
		return this.apiService.get(url);
	}

	linkCertReqtoTrainingReq(data) {
		const url = `${environment.ToTServiceURL}/api/tot-toa/v1/applicant/trainingRequest/linkcertificate?trReqId=${data.trReqId}&certReqId=${data.certReqId}&userName=${data.userName}`;
		return this.apiService.put(url);
	}

	amsChangePassword(userDetails) {
		const url = `${environment.userServiceUrl}/api/user/v1/ams/ChangeUserPassword`;
		return this.apiService.post(url, userDetails);
	}

	getCertificateAvailability(pageNo, perPage, displayTab, filterData) {
		const qpCode = filterData && filterData.qpCode ? filterData.qpCode : "";
		const batchType =
			filterData && filterData.batchType ? filterData.batchType : "";
		const userName =
			filterData && filterData.userName ? filterData.userName : "";

		console.log(displayTab, filterData);
		const url = `${environment.userServiceUrl}/api/user/v1/trainer/Certification?pageNo=${pageNo}&limit=${perPage}&displaytab=${displayTab}
		&filter=qpCode:${qpCode},batchType:${batchType},userName:${userName}`;
		return this.apiService.get(url);
	}

	getCertificatesAddedForApprovales(
		pageNo,
		perPage,
		displayTab,
		filterData?
	) {
		console.log(filterData);
		const sector = filterData && filterData.sector ? filterData.sector : "";
		const qpCode =
			filterData && filterData.jobRole ? filterData.jobRole : "";
		const state = filterData && filterData.state ? filterData.state : "";
		const userType =
			filterData && filterData.userType ? filterData.userType : "";
		const typeOfCertification =
			filterData && filterData.typeOfCertification
				? filterData.typeOfCertification
				: "";

		console.log(displayTab, filterData);
		const url = `${environment.userServiceUrl}/api/user/v1/trainer/Certification?pageNo=${pageNo}&limit=${perPage}&displaytab=${displayTab}
		&filter=sector:${sector},qpCode:${qpCode},state:${state},userType:${userType},typeOfCertification:${typeOfCertification}`;
		// &fields=batchType,location,
		// sector,subSectors,jobRoleId,state,district,certificateID,certificateType,supportingDocumentURLDetails,jobName,version,name,
		// jobRoleDesc,nsqfLevel,certificateIssueDate,assmtCrt,platformPercentage,remarks,certificateValidTillDate,certificateStatus,submittedOn`;
		return this.apiService.get(url);
	}

	getParticularJobRoleCertificateDetails(type, data) {
		const userName =
			data.userName && data.userName !== ""
				? ",userName:" + data.userName
				: "";
		const url = `${environment.userServiceUrl}/api/user/v1/trainer/Certification?displaytab=${type}
		&filter=qpCode:${data.jobrole}${userName},batchType:${data.type},certReqId:${data.certReqId}`;
		return this.apiService.get(url);
	}

	getIndividualCertificateDetails(sectorId, qpCode, batchType, userName) {
		const url = `${environment.userServiceUrl}/api/user/v1/individual/certificate?pageNo=1&limit=10&filter=sector:${sectorId},qpCode:${qpCode},batchType:${batchType},userName:${userName}`;
		return this.apiService.get(url);
	}

	haApprovedAebasRequest(body) {
		const URL = environment.userServiceUrl + "/api/user/v1/verify/aebas";
		return this.apiService.post(URL, body);
	}

	getAllFeeBasedTps(pageNo, itemsPerPage, status?) {
		// tslint:disable-next-line:max-line-length
		const url = `${environment.userServiceUrl
			}/api/user/v1/schemetpworkflow?pageNo=${pageNo}&limit=${itemsPerPage}&status=${status ? status : ""
			}`;
		return this.apiService.get(url);
	}

	searchTps(pageNo, itemsPerPage, tpID, tpName) {
		if (tpID === "") {
			this.Query = "requesteeName:" + tpName;
		} else if (tpName === "") {
			this.Query = "tpID:" + tpID;
		} else {
			this.Query = "tpID:" + tpID + "," + "requesteeName:" + tpName;
		}

		// tslint:disable-next-line:max-line-length
		this.URI = `${environment.userServiceUrl}/api/user/v1/schemetpworkflow?pageNo=${pageNo}&limit=${itemsPerPage}&status=APPROVED&query=${this.Query}`;

		if (tpID === "" && tpName === "") {
			this.URI = `${environment.userServiceUrl}/api/user/v1/schemetpworkflow?pageNo=${pageNo}&limit=${itemsPerPage}&status=APPROVED`;
		}

		return this.apiService.get(this.URI);
	}
	getMarksheetandCertificateDetailsOfCan(candidateId, batchId, docType?) {
		const documentType = docType
			? docType === "marksheet"
				? "&marksheet=YES"
				: "&certificate=YES"
			: "";
		const url = `${environment.userServiceUrl}/v1/candidates/get/canidate/mark/certificate/data?candidateId=${candidateId}&batchId=${batchId}${documentType}`;
		return this.apiService.get(url);
	}

	getCandidateListForReassessment(batchId) {
		const url = `${environment.userServiceUrl}/api/batch/v1/reassessment/nonpmkvy/validation?batchId=${batchId}`;
		return this.apiService.get(url);
	}

	submitCandidatesForReassessment(data) {
		const url = `${environment.userServiceUrl}/api/batch/v1/submit/nonpmkvy/reassessment/batch?batchId=${data.batchId}&qpCode=${data.qpCode}&version=${data.version}`;
		return this.apiService.post(url, data.applicant);
	}

	getTrainingRequest(data) {
		const URL =
			environment.userServiceUrl +
			"/api/user/v1/applicant/preferences/status";
		return this.apiService.put(URL, data);
	}

	changeAssessmentDatesForFeebasedBatch(batchId, qpCode, data) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/assessmentDateCR/${batchId}?qpCode=${qpCode}`;
		return this.apiService.post(URL, data);
	}

	checkForAssessmentDateDuration() {
		const URL = `${environment.userServiceUrl}/api/batch/v1/feebased/schemes/list`;
		return this.apiService.get(URL);
	}

	getIpCliente(): Observable<string> {
		return this.httpClient
			.get("https://api.ipify.org", {responseType: 'text'}) // ...using post request '
			.pipe(map((res: any) => {
				return res;
			}),catchError((error: any) => {
				console.log(error);
				return throwError("000.000.000.000");
			})); //...errors if any
	}

	getTargetDetailsSTT(data: any) {
		const url =
			environment.userServiceUrl +
			`/api/user/v1/tc/targets?tpId=${data.tpId}&projectId=${data.projectId}&tcId=${data.tcId}&schemeType=${data.schemeTypeId}&scnId=${data.scnId}`;
		console.log(url);
		return this.apiService.get(url);
	}

	getJobRoles(data: any) {
		const url =
			environment.userServiceUrl +
			`api/user/v1/tc/targets?tpId=${data.tpId}&projectId=${data.projectId}&tcId=${data.tcId}&schemeType=${data.schemeTypeId}&scnId=${data.scnId}`;
		console.log(data);
		console.log(url);
		return this.apiService.get(url);
	}

	getTotalExperience(experience) {
		const url = `${environment.userServiceUrl}/api/user/v1/calculatexperience`;
		return this.apiService.put(url, experience);
	}

	getTPCourseHistory(courseData, pageNo, perPageNo) {
		const url = `${environment.userServiceUrl
			}/api/user/v1/trainingpartner/feebased/course/history?tpId=${courseData.tpId
			}
		&schemeId=${courseData.selectedSchemeId
				? courseData.selectedSchemeId
				: courseData.schemeID
			}
		&sectorId=${courseData.sectorReferenceId
				? courseData.sectorReferenceId
				: courseData.sectorID
			}
		&uniqueID=${courseData.uniqueId ? courseData.uniqueId : courseData.uniqueID}
		&pageNo=${pageNo}
		&limit=${perPageNo}`;
		return this.apiService.get(url);
	}

	checkBatchCourseValidity(batchId) {
		const url = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/${batchId}/course/notification`;
		return this.apiService.get(url);
	}

	getExpiringCourses(tpId, schemeId) {
		const url = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/course/notification?tpId=${tpId}&schemeId=${schemeId}`;
		return this.apiService.get(url);
	}

	getBatchCancellationRequests(pageNo, limit, query?) {
		if (query) {
			query = Helpers.serializeObject(query);
		}
		const url = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/cancelbatchworkflows?pageNo=${pageNo}&limit=${limit}&query=${query}`;
		return this.apiService.get(url);
	}

	getBatchSizeChangeRequests(pageNo, limit, query?) {
		if (query) {
			query = Helpers.serializeObject(query);
		}
		const url = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/batchsizeworkflows?pageNo=${pageNo}&limit=${limit}&query=${query}`;
		return this.apiService.get(url);
	}

	getBatchDateChangeRequests(pageNo, limit, query?) {
		if (query) {
			query = Helpers.serializeObject(query);
		}
		const url = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/batchdurationworkflows?pageNo=${pageNo}&limit=${limit}&query=${query}`;
		return this.apiService.get(url);
	}

	actionForBatchChangeRequests(workflowId, batchData) {
		const url = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/updatebatchworkflow?workflowId=${workflowId}`;
		return this.apiService.put(url, batchData);
	}

	updateBatchSizeRequest(data) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/size`;
		return this.apiService.put(URL, data);
	}

	updateBatchDateRequest(data) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/duration`;
		return this.apiService.put(URL, data);
	}
	checkSPOCDuplicate(query?) {
		const URL = `${environment.userServiceUrl}/api/user/v1/Validate/check/spocDuplicate`;
		return this.apiService.get(URL, query);
	}

	markBatchAsCertified(payload) {
		const URL = `${environment.userServiceUrl}/v1/candidates/excel/candidate/trackstatus`;
		return this.apiService.put(URL, payload);
	}

	markBatchAsTrained(payload) {
		const URL = `${environment.userServiceUrl}/v1/candidates/excel/candidate/trackstatus`;
		return this.apiService.put(URL, payload);
	}

	markBatchAsAssessed(payload) {
		const URL = `${environment.userServiceUrl}/v1/candidates/excel/candidate/trackstatus`;
		return this.apiService.put(URL, payload);
	}
	takeActionOnThirdPartyAgencyBatches(query) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/thirdparty/update`;
		return this.apiService.put(URL, query);
	}

	getBatchForThirdParty(batchId) {
		const getApi = `${environment.userServiceUrl}/api/batch/v1/scheme/feeBased?batchId=${batchId}`;
		return this.apiService.get(getApi);
	}

	addEmployerAsThirdPartyAgency(formData, schemeId) {
		const URL = `${environment.userServiceUrl}/v1/employers/reg/siib/employer?schemeId=${schemeId}`;
		return this.apiService.post(URL, formData);
	}

	getEmployerAsThirdPartyAgency(pageNum, limit) {
		const URL = `${environment.userServiceUrl}/v1/employers/reg/siib/employer?pageNum=${pageNum}&limit=${limit}`;
		return this.apiService.get(URL);
	}

	getEmployersListForCMA(pageNum, limit) {
		const URL = `${environment.userServiceUrl}/v1/employers/reg/siib/employer/workflow?pageNum=${pageNum}&limit=${limit}`;
		return this.apiService.get(URL);
	}

	actionForEmployer(payload) {
		const URL = `${environment.userServiceUrl}/v1/employers/reg/siib/employer/workflow/status`;
		return this.apiService.post(URL, payload);
	}

	resubmitEmployer(userName, payload) {
		const URL = `${environment.userServiceUrl}/v1/employers/reg/siib/employer/resubmit/${userName}`;
		return this.apiService.put(URL, payload);
	}

	addOJTDetailsForBatch(payload, batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/update/siib/ojt/${batchId}`;
		return this.apiService.put(URL, payload);
	}

	getOJTDetailsForBatch(batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/siib/ojt/${batchId}`;
		return this.apiService.get(URL);
	}

	submitPlacementTrackingDocumentUpload(batchId, candidateId, payload) {
		const url = `${environment.userServiceUrl}/v1/candidates/${candidateId}/batch/${batchId}/placementTrackingDetails`;
		return this.apiService.put(url, payload);
	}

	aadhaarEnrollMsg(schemeType?) {
		const url = `${environment.userServiceUrl}/api/user/v1/getAadharText?schemeType=${schemeType}`;
		return this.apiService.get(url);
	}

	capacityAuth() {
		const url = `${environment.userServiceUrl}/`
	}

	generateOTPForAdmin(payload) {
		const URL = `${environment.userServiceUrl}/api/user/v1/otp/AdminMail`;
		return this.apiService.post(URL, payload);
	}

	verifyOTPForAdmin(payload) {
		const URL = `${environment.userServiceUrl}/api/user/v1/validateAdminMail`;
		return this.apiService.post(URL, payload);
	}

	uploadRTDDocument(payload) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingpartner/uploadRTDDocument?batchId=${payload.batchId}&rtdDocument=${payload.rtdDocument}`;
		// return this.apiService.post(URL);
		return this.apiService.post(URL,payload);
	}

	generateOTPForMobile(data, role, scenario?) {
		const URL = `${environment.userServiceUrl}/api/user/reg/v1/generateOTP/${role}?scenario=${scenario}`;
		return this.apiService.post(URL, data);
	}

	verifyOTPForMobile(payload, phoneOTP) {
		const URL = `${environment.userServiceUrl}/api/user/v1/register/UIDAI/phoneotp/${phoneOTP}/emailotp/0`;
		return this.apiService.post(URL, payload);
	}

	getUIDAICourses(trainingType) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/uidaicourses?trainingType=${trainingType}`;
		return this.apiService.get(URL);
	}

	getUIDAICourseFees(payload) {
		const URL = `${environment.userServiceUrl}/api/user/v1/trainingpartner/feebased/uidaifees`;
		return this.apiService.post(URL, payload);
	}


	updateUIDAICandidateDetails(userName, payload) {
		const url = `${environment.userServiceUrl}/v1/candidates/update/uidai/${userName}`;
		return this.apiService.put(url, payload);
	}

	checkMobileNumberExistance(payload) {
		const url = `${environment.userServiceUrl}/v1/candidates/check/candidate/existence`;
		return this.apiService.post(url, payload);
	}

	pmuActionOnRTD(query) {
		const URL = `${environment.userServiceUrl}/api/user/v1/rtd/pmu/action?batchId=${query.batchId}&status=${query.status}&pmuComment=${query.pmuComment}`;
		return this.apiService.post(URL);
	}

	// new nipun mohua changes for final assessment batch creation
	verifyReassessmentBatchExistForBatchID(batchId) {
		let url = `${environment.userServiceUrl}/api/batch/v1/isActiveReassessmentBatchExists/${batchId}`;

		return this.apiService.get(url);
	}
	getFeedbackData(query) {
		const URL = `${environment.userServiceUrl}/api/smart/v1/getOneTCDetail?Id=${query}`;
		return this.apiService.get(URL);
	}


	getTpdetailsBasedOnState(state: string, page: number, limit: number, tpId?:string, tpName?:string) {
		let url = `${environment.userServiceUrl}/api/user/v1/getTPDetailsByParameters?location=${state}&page=${page}&limit=${limit}`;
		if (tpId) {
			url += `&tpId=${tpId}`
		}
		if (tpName) {
			url += `&tpName=${tpName}`
		}
		return this.apiService.get(url);
	}

	getSchemeForPMV(){
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/pmv/schemes`;
		return this.apiService.get(URL);
	}

	addEKYCCandidatesToBatch(batchId, payload){
		const URL = `${environment.userServiceUrl}/v1/candidates/add/candidate/batch/interest/${batchId}`
		return this.apiService.put(URL, payload);
	}

	downloadExcelForSEO(payload) {
		const URL = `${environment.pmkvycmServiceUrl}/api/v2/batchservice/sscExcel/sscstateexcel`
		let query = {
			type: payload.type, 
			schemeName: payload.schemeName, 
			id:payload.id, 
			subSchemeID:payload.subSchemeID,
			subSchemeName: payload.subschemeName, 
			"trainingTypeId": payload.trainingTypeId, 
			trainingType: payload.trainingType
		}
		if(payload?.tcId) query['tcId'] = payload.tcId;
		if(payload?.tcName) query['tcName'] = payload.tcName;

		if(payload?.tpId) query['tpId'] = payload.tpId;
		if(payload?.tpName) query['tpName'] = payload.tpName;

		if(payload?.pageNo) query['pageNo'] = payload.pageNo || 1;
		if(payload?.itemPerPage) query['itemPerPage'] = payload.itemPerPage || 10;

		return this.apiService.getBlob(
			URL,
			"application/vnd.ms-excel",query
		);
	}
	updateTrainerForTrainingOnHoldBatches(batchId, trainerData) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/trainer/certified/jobRoles/${batchId}`;
		return this.apiService.put(URL, trainerData);
	}

	unHoldTheTrainingOnHoldBatches(batchId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/tc/unhold/batch/${batchId}`;
		return this.apiService.put(URL);
	}

	getSubSchemBasedOnScheme(schemId) {
		const URL = `${environment.userServiceUrl}/api/batch/v1/get/subSchemeBySchemeID?schemeId=${schemId}`;
		return this.apiService.get(URL);
	}
}
