import { Helpers } from 'projects/training-calendar/src/app/helpers';
import { PMKVY_RPL_TYPES } from 'src/app/scheme-constants/scheme-constants';

export const AA_TC_SECTOR_TARGET_STATUS = Object.freeze({
	INPROGRESS: { value: 'init', display: 'In Progress' },
	SEND_BACK: { value: 'sendBack', display: 'SEND BACK', display2: 'SENT BACK' },
	APPROVED: { value: 'approved', display: 'Approved' },
	REJECTED: { value: 'Rejected', display: 'Rejected' },
	DRAFT: { value: 'draft', display: 'Draft' }
});


export const NOS_VALUES = Object.freeze([
	{ unitCode: '1', unitTitle: 'Domain Training' },
	{ unitCode: '2', unitTitle: 'Soft Skills and Entrepreneurship Tips' },
	{ unitCode: '3', unitTitle: 'Familiarization to the Assessment Process and Terms' },
])

export const NOS_VALUES_FOR_PMKVY_3 = Object.freeze({
	About_PMKVY_RPL_MSDE_NSDC: { unitCode: '4', unitTitle: 'About PMKVY, RPL, MSDE and NSDC' },
	Domain_knowledge_for_the_job_role: { unitCode: '5', unitTitle: 'Domain knowledge for the job role' },
	Soft_Skill: { unitCode: '6', unitTitle: 'Soft Skill' },
	About_Assessment: { unitCode: '7', unitTitle: 'About Assessment' },
});

export const NOS_VALUES_FOR_MSDE_SCHEME = Object.freeze([
	{ unitCode: '1', unitTitle: 'About RPL' },
	{ unitCode: '2', unitTitle: 'MSDE' },
	{ unitCode: '3', unitTitle: 'SANKALP' },
	{ unitCode: '4', unitTitle: 'AZADI KA AMRUT MAHOTSAVA' },
]);

export const BRIDGE_NOS_VALUES = Object.freeze([
	{ unitCode: '1', unitTitle: 'Bridge Module' },
])

export const CENTRE_LOCATION_TYPES = Object.freeze([
	{ unitCode: '1', unitTitle: 'Training Centre Location' },
	{ unitCode: '2', unitTitle: 'Neutral Centre Location' }
])

export const ONLY_TRAINING_CENTRE_LOCATION_TYPES = Object.freeze([
	{ unitCode: '1', unitTitle: 'Training Centre Location' }
])

export const CENTRE_LOCATION_TYPES_FOR_EXCEPT_MODULE_3 = Object.freeze([
	{ unitCode: '1', unitTitle: 'Training Centre Location' },
])

export const CENTRE_LOCATION_TYPES_OBJECT = Object.freeze({
	TRAINING_CENTRE_LOCATION: { unitCode: '1', unitTitle: 'Training Centre Location' },
	NEUTRAL_CENTRE_LOCATION: { unitCode: '2', unitTitle: 'Neutral Centre Location' }
})

export const DEMAND_STATUS = Object.freeze({
	DEMAND_ACCEPTED: { value: 'DEMAND_ACCEPTED', name: 'DEMAND ACCEPTED' },
	DEMAND_REJECTED: { value: 'DEMAND_REJECTED', name: 'DEMAND REJECTED' },
	DEMAND_RAISED: { value: 'DEMAND_RAISED', name: 'DEMAND RAISED' }
})
export const DEMAND_KEYS = Object.freeze({
	jobRoleWise: { value: 'jobrolewise', name: 'Job Role Wise' },
	batchWise: { value: 'batchwise', name: 'Batch Wise' }
})

export const NEUTRAL_CENTRE_TYPES = Object.freeze([
	{ unitCode: '1.0', unitTitle: 'Pradhan Mantri Kaushal Kendras (PMKKs)' },
	{ unitCode: '2.0', unitTitle: 'India International Skill Centers (IISCs)' },
	{ unitCode: '3.0', unitTitle: 'Industrial Training Institutions (ITI) iv. National Skill Training Institutions (NSTIs)' },
	{ unitCode: '4.0', unitTitle: 'Autonomous Skill Development/ Institutions established by Line Ministries' },
	{ unitCode: '5.0', unitTitle: 'Skill Universities (Government or Private)' },
	{ unitCode: '6.0', unitTitle: 'Trainer-Assessor Academy' },
	{ unitCode: '7.0', unitTitle: 'Centre of Excellence' },
	{ unitCode: '8.0', unitTitle: 'Others (Text option to be provide if others is selected)' }
])

export const SP_PROJECT_THEME = Object.freeze([
	'Inclusion',
	'Industry',
	'Innovation - Academic Institutes',
	'Innovation - Others',
	'International Placements'
]);

export const SP_COVID_PROJECT_THEME = Object.freeze([
	'Covid Special Training'
]);

export const SP_PROJECT_TAGS = Object.freeze({
	Social_Inclusion: 'Social Inclusion',
	Green_Economy: 'Green Economy',
	Future_of_Work: 'Future of Work',
	Promoting_Entrepreneurship: 'Promoting Entrepreneurship',
	Promoting_Apprenticeship: 'Promoting Apprenticeship',
	Industry_Connect: 'Industry Connect',
	Making_Skills_Aspirational: 'Making Skills Aspirational',
	International_Mobility: 'International Mobility',
	TVET_Integration_in_Informal_Economy_and_Formal_Education: 'TVET Integration in Informal Economy and Formal Education',
	Innovation: 'Innovation'
});

export const SP_PROJECT_TAG_SUBTEXT = Object.freeze([
	{
		TAG: SP_PROJECT_TAGS.Social_Inclusion,
		SUB_TEXT: `Project for vulnerable communities (PwDs/ SC/ST/ Transgender/ Women/ other sensitive groups)
	and vulnerable geographies (hard to reach areas/ LWE, Aspirational districts/ J&K/ NE, etc.)`
	},
	{
		TAG: SP_PROJECT_TAGS.Green_Economy,
		SUB_TEXT: `Project focusing on promoting greener and sustainable economy`
	},
	{
		TAG: SP_PROJECT_TAGS.Future_of_Work,
		SUB_TEXT: `Project delivering training in Future of Work skills/ job roles`
	},
	{
		TAG: SP_PROJECT_TAGS.Promoting_Entrepreneurship,
		SUB_TEXT: `Project promoting and facilitating entrepreneurship, use of EEE module,
	 facilitation with Mudra loans, etc.`},
	{
		TAG: SP_PROJECT_TAGS.Promoting_Apprenticeship,
		SUB_TEXT: `Project promoting and facilitating apprenticeship opportunities`
	},
	{
		TAG: SP_PROJECT_TAGS.Industry_Connect,
		SUB_TEXT: `Project with corporates, industry associations, use of CSR funds, corporate PMKKs,
		 any other partnership with private sector`},
	{
		TAG: SP_PROJECT_TAGS.Making_Skills_Aspirational,
		SUB_TEXT: `Projects aiming to make skilling aspirational, participation or relation with WorldSkills event,
	 other related promotion by organization of skill competitions, awarding or recognizing youth, etc.`},
	{
		TAG: SP_PROJECT_TAGS.International_Mobility,
		SUB_TEXT: `Project with international placement, QPs benchmarked with international standards, MoU for international labour mobility,
	 ToT ToA involving international experts, partnership with TITP, etc.`},
	{
		TAG: SP_PROJECT_TAGS.TVET_Integration_in_Informal_Economy_and_Formal_Education,
		SUB_TEXT: `Project targeting informal sector workers/ informal employment},
	 collaboration with TVET schools, colleges, universities programs - B.Voc, etc.`},
	{
		TAG: SP_PROJECT_TAGS.Innovation,
		SUB_TEXT: `Use of innovative practices and methods in project lifecycle`
	},
]);

export const PMKVY_AADHAAR_ENROLL_NOTE = Object.freeze({
	FIRST_VERSION: {
		HINDI: `कृपया उम्मीदवार के साथ विस्तार साझा करें, कि यूआईडीएआई नंबर यूआईडीएआई सर्वर के साथप्रमाणीकरण के
		 लिए उपयोग किया जाएगा और किसी भी अन्य इकाई के साथ साझा नहीं किया जाएगा`,
		ENGLISH: `Please share the detail with individual, that their UIDAI Number will be used
		 for Authentication with UIDAI server and will not be share with any other entity.`
	},
	SECOND_VERSION: {
		HINDI: `मैं प्रधानमंत्री कौशल विकास योजना के लिए अपनी पहचान स्थापित करने के उद्देश्य से अपना आधार कार्ड नंबर देने की सहमति देता/ देती हूँ। इसके अलावा बॉयोमेट्रिक या ओटीपी अथवा दोनों की ही जरूरत होने पर मैं इसे भी उपलब्ध कराने के लिए सहमत हूँ। मेरे इस सत्यापन को लेकर मुझे किसी तरह की अपत्ति नहीं होगी। मैं जानता/जानती हूँ कि मेरे द्वारा दी गई यह जानकारी का उपयोग आधार प्रणाली के जरिये मेरी पहचान को सत्यापित करने के लिए ही किया जाएगा और उपरोक्त उद्देश्य के अलावा किसी अन्य अद्देश्य के लिए इसका उपयोग नहीं किया जाएगा।`,
		ENGLISH: `I hereby consent to provide my Aadhaar Number and/or Biometric and/or One Time Pin (OTP) data for Aadhaar based authentication for the purpose of establishing my identity for the Pradhan Mantri Kaushal Vikas Yojna (PMKVY) Scheme.I have no objection in authenticating myself and fully understand that information provided by me shall be used for authenticating my identity through Aadhaar Authentication System for the purpose stated above and no other purpose.`
	}
});

export const RPL_TYPE_1_CAMPS_FLOW_LIST = Object.freeze([
	PMKVY_RPL_TYPES.CAMPS.id,
	PMKVY_RPL_TYPES.CAMPS.compareWith,
	PMKVY_RPL_TYPES.COVID_RPL_TYPE_1.id,
	PMKVY_RPL_TYPES.COVID_RPL_TYPE_1.compareWith,
	PMKVY_RPL_TYPES.GKRA_TYPE_1.id,
	PMKVY_RPL_TYPES.GKRA_TYPE_1.compareWith
]);

export const RPL_TYPE_2_FLOW_LIST = Object.freeze([
	PMKVY_RPL_TYPES.EMPLOYER_PREMISES.id,
	PMKVY_RPL_TYPES.EMPLOYER_PREMISES.compareWith
]);

export const RPL_TYPE_3_CENTRES_FLOW_LIST = Object.freeze([
	PMKVY_RPL_TYPES.CENTRES.id,
	PMKVY_RPL_TYPES.CENTRES.compareWith,
	PMKVY_RPL_TYPES.COVID_RPL_TYPE_3.id,
	PMKVY_RPL_TYPES.COVID_RPL_TYPE_3.compareWith
]);

export const AA_TC_STATUS = Object.freeze({
	QUALIFIED: 'Qualified',
	APPROVED: 'Approved',
	TC_CREATED: 'TC_CREATED',
	ON_GOING: 'ongoing',
	RE_REQUEST: 'reRequest',
	PAYMENT_DONE_FOR_UPDATE_CAAF: 'paymentDoneForUpdateCaaf',
	PAYMENT_DONE_FOR_RE_ACCREDIATION: 'paymentDoneForReAccrediation',
	APPLIED_FOR_REACCREDIATION: 'appliedForReAccrediation',
	APPLIED_FOR_UPDATE_CAAF: 'appliedForUpdateCaaf',
	NOT_QUALIFIED: 'Not Qualified',
});

export const AA_TC_JOBROLE_STATUS = Object.freeze({
	Accrediated: 'Accrediated',
	Conditionally_Accrediated: 'Conditionally Accrediated'
});

export const SP_INDEMNITY_BONDS = Object.freeze({
	SP_TYPE_1: 'https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/pmkvy/SampleIndemnityBond.DOCX',
	SP_COVID: 'https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/pmkvy/SampleCovidIndemnityBond.DOCX'
});

export const SANKALP_INDEMNITY_BONDS = Object.freeze({
	SANKALP_TYPE_1: 'https://nsdcpublicdocuments.s3.ap-south-1.amazonaws.com/sankalp/SampleIndemnityBond.DOCX',
});

export const DEFAULT_CEREMONY_DATES = Object.freeze({
	// MAX_END_DATE_PMKVY_2: Helpers.makeTimeZero(new Date('01/28/2023'))
	MAX_END_DATE_PMKVY_2: Helpers.makeTimeZero(new Date('09/30/2023'))
});

export const PMKVY_HOLD_TYPE = Object.freeze({
	STATE_WISE: 'statewise',
	DISTRICT_WISE: 'districtwise',
	BATCH_WISE: 'batchwise',
});

export const LOCKDOWN_HOLD_CONSTANTS = Object.freeze({
	HOLD_DATE_BUFFER: 15,
	UN_HOLD_DATE_BUFFER: 15
});

export const MSDE_SCHEME_BATCH_MODE_CONSTANT = Object.freeze({
	Offline_Class_room: 'Offline/Class-room',
	Online_only: 'Online only',
	BLENDED: 'Blended (Online+In-person)'
});
